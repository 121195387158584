@font-face {
    font-family: 'CircularStd';
    src: url('CircularStd-Book.eot');
    src: url('CircularStd-Book%EF%B9%96.eot#iefix') format('embedded-opentype'),
        url('CircularStd-Book.woff2') format('woff2'),
        url('CircularStd-Book.woff') format('woff'),
        url('CircularStd-Book.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'CircularStd';
    src: url('CircularStd-BookItalic.eot');
    src: url('CircularStd-BookItalic%EF%B9%96.eot#iefix') format('embedded-opentype'),
        url('CircularStd-BookItalic.woff2') format('woff2'),
        url('CircularStd-BookItalic.woff') format('woff'),
        url('CircularStd-BookItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'CircularStd';
    src: url('CircularStd-Medium.eot');
    src: url('CircularStd-Medium%EF%B9%96.eot#iefix') format('embedded-opentype'),
        url('CircularStd-Medium.woff2') format('woff2'),
        url('CircularStd-Medium.woff') format('woff'),
        url('CircularStd-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'CircularStd';
    src: url('CircularStd-BoldItalic.eot');
    src: url('CircularStd-BoldItalic%EF%B9%96.eot#iefix') format('embedded-opentype'),
        url('CircularStd-BoldItalic.woff2') format('woff2'),
        url('CircularStd-BoldItalic.woff') format('woff'),
        url('CircularStd-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'CircularStd';
    src: url('CircularStd-BlackItalic.eot');
    src: url('CircularStd-BlackItalic%EF%B9%96.eot#iefix') format('embedded-opentype'),
        url('CircularStd-BlackItalic.woff2') format('woff2'),
        url('CircularStd-BlackItalic.woff') format('woff'),
        url('CircularStd-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'CircularStd';
    src: url('CircularStd-Black.eot');
    src: url('CircularStd-Black%EF%B9%96.eot#iefix') format('embedded-opentype'),
        url('CircularStd-Black.woff2') format('woff2'),
        url('CircularStd-Black.woff') format('woff'),
        url('CircularStd-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'CircularStd';
    src: url('CircularStd-Bold.eot');
    src: url('CircularStd-Bold%EF%B9%96.eot#iefix') format('embedded-opentype'),
        url('CircularStd-Bold.woff2') format('woff2'),
        url('CircularStd-Bold.woff') format('woff'),
        url('CircularStd-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'CircularStd';
    src: url('CircularStd-MediumItalic.eot');
    src: url('CircularStd-MediumItalic%EF%B9%96.eot#iefix') format('embedded-opentype'),
        url('CircularStd-MediumItalic.woff2') format('woff2'),
        url('CircularStd-MediumItalic.woff') format('woff'),
        url('CircularStd-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Sofia Pro';
    src: local('Sofia Pro Regular'), local('Sofia-Pro-Regular'),
        url('SofiaProRegular.woff2') format('woff2'),
        url('SofiaProRegular.woff') format('woff'),
        url('SofiaProRegular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

