/*********** CSS TABLE OF CONTENTS *******************

|--- 0.Common
|--- 1.Header
|--- 2.Menu
|--- 3.Landing 1
|--- 4.Landing 2
|--- 5.Landing 3
|--- 6.Landing 4
|--- 7.Landing 5
|--- 8.Landing 6
|--- 9.Landing 7
|--- 10.Contact
|--- 11.About
|--- 12.Career Details
|--- 13.Career Page
|--- 14.Case Study
|--- 15.Pricing Pages
|--- 16.Pricing Pages
|--- 17.Contact Pages
|--- 18.Thank You
|--- 19.404
|--- 20.Terms page

******************************************************/
.bg-whisper {
  background: #F7F7FB;
}

/*=== Media Query ===*/
/*=====================================*/
/*-- :::: Space Decrease :::: --*/
/*=====================================*/
.space-db--0 {
  margin-bottom: 0px;
}

.space-db--5 {
  margin-bottom: -5px;
}

.space-db--10 {
  margin-bottom: -10px;
}

.space-db--15 {
  margin-bottom: -15px;
}

.space-db--20 {
  margin-bottom: -20px;
}

.space-db--25 {
  margin-bottom: -25px;
}

.space-db--30 {
  margin-bottom: -30px;
}

.space-db--35 {
  margin-bottom: -35px;
}

.space-db--40 {
  margin-bottom: -40px;
}

.space-db--45 {
  margin-bottom: -45px;
}

.space-db--50 {
  margin-bottom: -50px;
}

.space-db--55 {
  margin-bottom: -55px;
}

.space-db--60 {
  margin-bottom: -60px;
}

.space-db--65 {
  margin-bottom: -65px;
}

.space-db--70 {
  margin-bottom: -70px;
}

.space-db--75 {
  margin-bottom: -75px;
}

.space-db--80 {
  margin-bottom: -80px;
}

.space-db--85 {
  margin-bottom: -85px;
}

.space-db--90 {
  margin-bottom: -90px;
}

.space-db--95 {
  margin-bottom: -95px;
}

.space-db--100 {
  margin-bottom: -100px;
}

.space-db--105 {
  margin-bottom: -105px;
}

.space-db--110 {
  margin-bottom: -110px;
}

.space-db--115 {
  margin-bottom: -115px;
}

.space-db--120 {
  margin-bottom: -120px;
}

.space-db--125 {
  margin-bottom: -125px;
}

.space-db--130 {
  margin-bottom: -130px;
}

.space-db--135 {
  margin-bottom: -135px;
}

.space-db--140 {
  margin-bottom: -140px;
}

.space-db--145 {
  margin-bottom: -145px;
}

.space-db--150 {
  margin-bottom: -150px;
}

.space-dt--0 {
  margin-top: 0px;
}

.space-dt--5 {
  margin-top: -5px;
}

.space-dt--10 {
  margin-top: -10px;
}

.space-dt--15 {
  margin-top: -15px;
}

.space-dt--20 {
  margin-top: -20px;
}

.space-dt--25 {
  margin-top: -25px;
}

.space-dt--30 {
  margin-top: -30px;
}

.space-dt--35 {
  margin-top: -35px;
}

.space-dt--40 {
  margin-top: -40px;
}

.space-dt--45 {
  margin-top: -45px;
}

.space-dt--50 {
  margin-top: -50px;
}

.space-dt--55 {
  margin-top: -55px;
}

.space-dt--60 {
  margin-top: -60px;
}

.space-dt--65 {
  margin-top: -65px;
}

.space-dt--70 {
  margin-top: -70px;
}

.space-dt--75 {
  margin-top: -75px;
}

.space-dt--80 {
  margin-top: -80px;
}

.space-dt--85 {
  margin-top: -85px;
}

.space-dt--90 {
  margin-top: -90px;
}

.space-dt--95 {
  margin-top: -95px;
}

.space-dt--100 {
  margin-top: -100px;
}

.space-dt--105 {
  margin-top: -105px;
}

.space-dt--110 {
  margin-top: -110px;
}

.space-dt--115 {
  margin-top: -115px;
}

.space-dt--120 {
  margin-top: -120px;
}

.space-dt--125 {
  margin-top: -125px;
}

.space-dt--130 {
  margin-top: -130px;
}

.space-dt--135 {
  margin-top: -135px;
}

.space-dt--140 {
  margin-top: -140px;
}

.space-dt--145 {
  margin-top: -145px;
}

.space-dt--150 {
  margin-top: -150px;
}

/*-- Responsive Space Decrease top --*/
@media (min-width: 576px) {
  .space-dt-sm-0 {
    margin-top: 0px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-0 {
    margin-top: 0px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-5 {
    margin-top: -5px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-5 {
    margin-top: -5px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-10 {
    margin-top: -10px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-10 {
    margin-top: -10px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-15 {
    margin-top: -15px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-15 {
    margin-top: -15px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-20 {
    margin-top: -20px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-20 {
    margin-top: -20px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-25 {
    margin-top: -25px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-25 {
    margin-top: -25px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-30 {
    margin-top: -30px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-30 {
    margin-top: -30px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-35 {
    margin-top: -35px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-35 {
    margin-top: -35px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-40 {
    margin-top: -40px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-40 {
    margin-top: -40px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-45 {
    margin-top: -45px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-45 {
    margin-top: -45px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-50 {
    margin-top: -50px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-50 {
    margin-top: -50px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-55 {
    margin-top: -55px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-55 {
    margin-top: -55px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-60 {
    margin-top: -60px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-60 {
    margin-top: -60px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-65 {
    margin-top: -65px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-65 {
    margin-top: -65px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-70 {
    margin-top: -70px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-70 {
    margin-top: -70px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-75 {
    margin-top: -75px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-75 {
    margin-top: -75px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-80 {
    margin-top: -80px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-80 {
    margin-top: -80px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-85 {
    margin-top: -85px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-85 {
    margin-top: -85px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-90 {
    margin-top: -90px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-90 {
    margin-top: -90px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-95 {
    margin-top: -95px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-95 {
    margin-top: -95px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-100 {
    margin-top: -100px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-100 {
    margin-top: -100px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-105 {
    margin-top: -105px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-105 {
    margin-top: -105px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-110 {
    margin-top: -110px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-110 {
    margin-top: -110px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-115 {
    margin-top: -115px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-115 {
    margin-top: -115px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-120 {
    margin-top: -120px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-120 {
    margin-top: -120px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-125 {
    margin-top: -125px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-125 {
    margin-top: -125px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-130 {
    margin-top: -130px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-130 {
    margin-top: -130px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-135 {
    margin-top: -135px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-135 {
    margin-top: -135px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-140 {
    margin-top: -140px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-140 {
    margin-top: -140px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-145 {
    margin-top: -145px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-145 {
    margin-top: -145px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-150 {
    margin-top: -150px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-150 {
    margin-top: -150px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-155 {
    margin-top: -155px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-155 {
    margin-top: -155px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-160 {
    margin-top: -160px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-160 {
    margin-top: -160px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-165 {
    margin-top: -165px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-165 {
    margin-top: -165px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-170 {
    margin-top: -170px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-170 {
    margin-top: -170px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-175 {
    margin-top: -175px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-175 {
    margin-top: -175px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-180 {
    margin-top: -180px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-180 {
    margin-top: -180px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-185 {
    margin-top: -185px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-185 {
    margin-top: -185px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-190 {
    margin-top: -190px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-190 {
    margin-top: -190px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-195 {
    margin-top: -195px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-195 {
    margin-top: -195px;
  }
}

@media (min-width: 576px) {
  .space-dt-sm-200 {
    margin-top: -200px !important;
  }
}

@media (min-width: 768px) {
  .space-dt-sm-200 {
    margin-top: -200px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-0 {
    margin-top: 0px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-0 {
    margin-top: 0px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-5 {
    margin-top: -5px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-5 {
    margin-top: -5px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-10 {
    margin-top: -10px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-10 {
    margin-top: -10px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-15 {
    margin-top: -15px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-15 {
    margin-top: -15px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-20 {
    margin-top: -20px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-20 {
    margin-top: -20px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-25 {
    margin-top: -25px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-25 {
    margin-top: -25px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-30 {
    margin-top: -30px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-30 {
    margin-top: -30px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-35 {
    margin-top: -35px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-35 {
    margin-top: -35px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-40 {
    margin-top: -40px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-40 {
    margin-top: -40px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-45 {
    margin-top: -45px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-45 {
    margin-top: -45px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-50 {
    margin-top: -50px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-50 {
    margin-top: -50px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-55 {
    margin-top: -55px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-55 {
    margin-top: -55px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-60 {
    margin-top: -60px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-60 {
    margin-top: -60px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-65 {
    margin-top: -65px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-65 {
    margin-top: -65px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-70 {
    margin-top: -70px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-70 {
    margin-top: -70px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-75 {
    margin-top: -75px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-75 {
    margin-top: -75px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-80 {
    margin-top: -80px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-80 {
    margin-top: -80px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-85 {
    margin-top: -85px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-85 {
    margin-top: -85px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-90 {
    margin-top: -90px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-90 {
    margin-top: -90px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-95 {
    margin-top: -95px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-95 {
    margin-top: -95px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-100 {
    margin-top: -100px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-100 {
    margin-top: -100px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-105 {
    margin-top: -105px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-105 {
    margin-top: -105px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-110 {
    margin-top: -110px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-110 {
    margin-top: -110px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-115 {
    margin-top: -115px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-115 {
    margin-top: -115px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-120 {
    margin-top: -120px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-120 {
    margin-top: -120px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-125 {
    margin-top: -125px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-125 {
    margin-top: -125px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-130 {
    margin-top: -130px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-130 {
    margin-top: -130px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-135 {
    margin-top: -135px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-135 {
    margin-top: -135px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-140 {
    margin-top: -140px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-140 {
    margin-top: -140px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-145 {
    margin-top: -145px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-145 {
    margin-top: -145px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-150 {
    margin-top: -150px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-150 {
    margin-top: -150px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-155 {
    margin-top: -155px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-155 {
    margin-top: -155px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-160 {
    margin-top: -160px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-160 {
    margin-top: -160px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-165 {
    margin-top: -165px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-165 {
    margin-top: -165px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-170 {
    margin-top: -170px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-170 {
    margin-top: -170px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-175 {
    margin-top: -175px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-175 {
    margin-top: -175px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-180 {
    margin-top: -180px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-180 {
    margin-top: -180px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-185 {
    margin-top: -185px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-185 {
    margin-top: -185px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-190 {
    margin-top: -190px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-190 {
    margin-top: -190px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-195 {
    margin-top: -195px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-195 {
    margin-top: -195px;
  }
}

@media (min-width: 768px) {
  .space-dt-md-200 {
    margin-top: -200px !important;
  }
}

@media (min-width: 992px) {
  .space-dt-md-200 {
    margin-top: -200px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-0 {
    margin-top: 0px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-0 {
    margin-top: 0px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-5 {
    margin-top: -5px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-5 {
    margin-top: -5px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-10 {
    margin-top: -10px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-10 {
    margin-top: -10px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-15 {
    margin-top: -15px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-15 {
    margin-top: -15px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-20 {
    margin-top: -20px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-20 {
    margin-top: -20px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-25 {
    margin-top: -25px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-25 {
    margin-top: -25px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-30 {
    margin-top: -30px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-30 {
    margin-top: -30px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-35 {
    margin-top: -35px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-35 {
    margin-top: -35px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-40 {
    margin-top: -40px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-40 {
    margin-top: -40px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-45 {
    margin-top: -45px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-45 {
    margin-top: -45px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-50 {
    margin-top: -50px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-50 {
    margin-top: -50px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-55 {
    margin-top: -55px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-55 {
    margin-top: -55px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-60 {
    margin-top: -60px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-60 {
    margin-top: -60px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-65 {
    margin-top: -65px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-65 {
    margin-top: -65px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-70 {
    margin-top: -70px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-70 {
    margin-top: -70px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-75 {
    margin-top: -75px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-75 {
    margin-top: -75px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-80 {
    margin-top: -80px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-80 {
    margin-top: -80px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-85 {
    margin-top: -85px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-85 {
    margin-top: -85px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-90 {
    margin-top: -90px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-90 {
    margin-top: -90px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-95 {
    margin-top: -95px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-95 {
    margin-top: -95px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-100 {
    margin-top: -100px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-100 {
    margin-top: -100px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-105 {
    margin-top: -105px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-105 {
    margin-top: -105px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-110 {
    margin-top: -110px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-110 {
    margin-top: -110px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-115 {
    margin-top: -115px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-115 {
    margin-top: -115px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-120 {
    margin-top: -120px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-120 {
    margin-top: -120px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-125 {
    margin-top: -125px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-125 {
    margin-top: -125px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-130 {
    margin-top: -130px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-130 {
    margin-top: -130px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-135 {
    margin-top: -135px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-135 {
    margin-top: -135px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-140 {
    margin-top: -140px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-140 {
    margin-top: -140px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-145 {
    margin-top: -145px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-145 {
    margin-top: -145px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-150 {
    margin-top: -150px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-150 {
    margin-top: -150px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-155 {
    margin-top: -155px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-155 {
    margin-top: -155px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-160 {
    margin-top: -160px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-160 {
    margin-top: -160px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-165 {
    margin-top: -165px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-165 {
    margin-top: -165px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-170 {
    margin-top: -170px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-170 {
    margin-top: -170px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-175 {
    margin-top: -175px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-175 {
    margin-top: -175px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-180 {
    margin-top: -180px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-180 {
    margin-top: -180px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-185 {
    margin-top: -185px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-185 {
    margin-top: -185px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-190 {
    margin-top: -190px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-190 {
    margin-top: -190px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-195 {
    margin-top: -195px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-195 {
    margin-top: -195px;
  }
}

@media (min-width: 992px) {
  .space-dt-lg-200 {
    margin-top: -200px !important;
  }
}

@media (min-width: 1200px) {
  .space-dt-lg-200 {
    margin-top: -200px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-0 {
    margin-top: 0px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-0 {
    margin-top: 0px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-5 {
    margin-top: -5px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-5 {
    margin-top: -5px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-10 {
    margin-top: -10px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-10 {
    margin-top: -10px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-15 {
    margin-top: -15px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-15 {
    margin-top: -15px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-20 {
    margin-top: -20px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-20 {
    margin-top: -20px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-25 {
    margin-top: -25px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-25 {
    margin-top: -25px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-30 {
    margin-top: -30px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-30 {
    margin-top: -30px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-35 {
    margin-top: -35px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-35 {
    margin-top: -35px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-40 {
    margin-top: -40px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-40 {
    margin-top: -40px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-45 {
    margin-top: -45px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-45 {
    margin-top: -45px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-50 {
    margin-top: -50px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-50 {
    margin-top: -50px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-55 {
    margin-top: -55px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-55 {
    margin-top: -55px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-60 {
    margin-top: -60px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-60 {
    margin-top: -60px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-65 {
    margin-top: -65px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-65 {
    margin-top: -65px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-70 {
    margin-top: -70px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-70 {
    margin-top: -70px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-75 {
    margin-top: -75px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-75 {
    margin-top: -75px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-80 {
    margin-top: -80px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-80 {
    margin-top: -80px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-85 {
    margin-top: -85px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-85 {
    margin-top: -85px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-90 {
    margin-top: -90px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-90 {
    margin-top: -90px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-95 {
    margin-top: -95px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-95 {
    margin-top: -95px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-100 {
    margin-top: -100px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-100 {
    margin-top: -100px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-105 {
    margin-top: -105px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-105 {
    margin-top: -105px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-110 {
    margin-top: -110px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-110 {
    margin-top: -110px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-115 {
    margin-top: -115px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-115 {
    margin-top: -115px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-120 {
    margin-top: -120px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-120 {
    margin-top: -120px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-125 {
    margin-top: -125px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-125 {
    margin-top: -125px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-130 {
    margin-top: -130px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-130 {
    margin-top: -130px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-135 {
    margin-top: -135px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-135 {
    margin-top: -135px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-140 {
    margin-top: -140px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-140 {
    margin-top: -140px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-145 {
    margin-top: -145px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-145 {
    margin-top: -145px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-150 {
    margin-top: -150px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-150 {
    margin-top: -150px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-155 {
    margin-top: -155px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-155 {
    margin-top: -155px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-160 {
    margin-top: -160px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-160 {
    margin-top: -160px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-165 {
    margin-top: -165px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-165 {
    margin-top: -165px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-170 {
    margin-top: -170px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-170 {
    margin-top: -170px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-175 {
    margin-top: -175px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-175 {
    margin-top: -175px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-180 {
    margin-top: -180px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-180 {
    margin-top: -180px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-185 {
    margin-top: -185px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-185 {
    margin-top: -185px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-190 {
    margin-top: -190px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-190 {
    margin-top: -190px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-195 {
    margin-top: -195px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-195 {
    margin-top: -195px;
  }
}

@media (min-width: 1200px) {
  .space-dt-xl-200 {
    margin-top: -200px !important;
  }
}

@media (min-width: 1600px) {
  .space-dt-xl-200 {
    margin-top: -200px;
  }
}

/*-- Responsive Space Decrease bottom --*/
@media (min-width: 576px) {
  .space-db-sm-0 {
    margin-bottom: 0px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-0 {
    margin-bottom: 0px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-5 {
    margin-bottom: -5px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-5 {
    margin-bottom: -5px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-10 {
    margin-bottom: -10px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-10 {
    margin-bottom: -10px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-15 {
    margin-bottom: -15px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-15 {
    margin-bottom: -15px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-20 {
    margin-bottom: -20px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-20 {
    margin-bottom: -20px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-25 {
    margin-bottom: -25px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-25 {
    margin-bottom: -25px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-30 {
    margin-bottom: -30px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-30 {
    margin-bottom: -30px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-35 {
    margin-bottom: -35px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-35 {
    margin-bottom: -35px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-40 {
    margin-bottom: -40px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-40 {
    margin-bottom: -40px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-45 {
    margin-bottom: -45px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-45 {
    margin-bottom: -45px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-50 {
    margin-bottom: -50px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-50 {
    margin-bottom: -50px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-55 {
    margin-bottom: -55px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-55 {
    margin-bottom: -55px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-60 {
    margin-bottom: -60px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-60 {
    margin-bottom: -60px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-65 {
    margin-bottom: -65px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-65 {
    margin-bottom: -65px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-70 {
    margin-bottom: -70px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-70 {
    margin-bottom: -70px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-75 {
    margin-bottom: -75px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-75 {
    margin-bottom: -75px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-80 {
    margin-bottom: -80px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-80 {
    margin-bottom: -80px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-85 {
    margin-bottom: -85px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-85 {
    margin-bottom: -85px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-90 {
    margin-bottom: -90px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-90 {
    margin-bottom: -90px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-95 {
    margin-bottom: -95px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-95 {
    margin-bottom: -95px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-100 {
    margin-bottom: -100px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-100 {
    margin-bottom: -100px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-105 {
    margin-bottom: -105px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-105 {
    margin-bottom: -105px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-110 {
    margin-bottom: -110px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-110 {
    margin-bottom: -110px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-115 {
    margin-bottom: -115px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-115 {
    margin-bottom: -115px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-120 {
    margin-bottom: -120px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-120 {
    margin-bottom: -120px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-125 {
    margin-bottom: -125px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-125 {
    margin-bottom: -125px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-130 {
    margin-bottom: -130px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-130 {
    margin-bottom: -130px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-135 {
    margin-bottom: -135px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-135 {
    margin-bottom: -135px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-140 {
    margin-bottom: -140px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-140 {
    margin-bottom: -140px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-145 {
    margin-bottom: -145px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-145 {
    margin-bottom: -145px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-150 {
    margin-bottom: -150px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-150 {
    margin-bottom: -150px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-155 {
    margin-bottom: -155px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-155 {
    margin-bottom: -155px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-160 {
    margin-bottom: -160px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-160 {
    margin-bottom: -160px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-165 {
    margin-bottom: -165px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-165 {
    margin-bottom: -165px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-170 {
    margin-bottom: -170px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-170 {
    margin-bottom: -170px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-175 {
    margin-bottom: -175px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-175 {
    margin-bottom: -175px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-180 {
    margin-bottom: -180px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-180 {
    margin-bottom: -180px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-185 {
    margin-bottom: -185px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-185 {
    margin-bottom: -185px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-190 {
    margin-bottom: -190px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-190 {
    margin-bottom: -190px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-195 {
    margin-bottom: -195px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-195 {
    margin-bottom: -195px;
  }
}

@media (min-width: 576px) {
  .space-db-sm-200 {
    margin-bottom: -200px !important;
  }
}

@media (min-width: 768px) {
  .space-db-sm-200 {
    margin-bottom: -200px;
  }
}

@media (min-width: 768px) {
  .space-db-md-0 {
    margin-bottom: 0px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-0 {
    margin-bottom: 0px;
  }
}

@media (min-width: 768px) {
  .space-db-md-5 {
    margin-bottom: -5px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-5 {
    margin-bottom: -5px;
  }
}

@media (min-width: 768px) {
  .space-db-md-10 {
    margin-bottom: -10px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-10 {
    margin-bottom: -10px;
  }
}

@media (min-width: 768px) {
  .space-db-md-15 {
    margin-bottom: -15px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-15 {
    margin-bottom: -15px;
  }
}

@media (min-width: 768px) {
  .space-db-md-20 {
    margin-bottom: -20px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-20 {
    margin-bottom: -20px;
  }
}

@media (min-width: 768px) {
  .space-db-md-25 {
    margin-bottom: -25px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-25 {
    margin-bottom: -25px;
  }
}

@media (min-width: 768px) {
  .space-db-md-30 {
    margin-bottom: -30px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-30 {
    margin-bottom: -30px;
  }
}

@media (min-width: 768px) {
  .space-db-md-35 {
    margin-bottom: -35px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-35 {
    margin-bottom: -35px;
  }
}

@media (min-width: 768px) {
  .space-db-md-40 {
    margin-bottom: -40px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-40 {
    margin-bottom: -40px;
  }
}

@media (min-width: 768px) {
  .space-db-md-45 {
    margin-bottom: -45px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-45 {
    margin-bottom: -45px;
  }
}

@media (min-width: 768px) {
  .space-db-md-50 {
    margin-bottom: -50px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-50 {
    margin-bottom: -50px;
  }
}

@media (min-width: 768px) {
  .space-db-md-55 {
    margin-bottom: -55px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-55 {
    margin-bottom: -55px;
  }
}

@media (min-width: 768px) {
  .space-db-md-60 {
    margin-bottom: -60px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-60 {
    margin-bottom: -60px;
  }
}

@media (min-width: 768px) {
  .space-db-md-65 {
    margin-bottom: -65px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-65 {
    margin-bottom: -65px;
  }
}

@media (min-width: 768px) {
  .space-db-md-70 {
    margin-bottom: -70px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-70 {
    margin-bottom: -70px;
  }
}

@media (min-width: 768px) {
  .space-db-md-75 {
    margin-bottom: -75px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-75 {
    margin-bottom: -75px;
  }
}

@media (min-width: 768px) {
  .space-db-md-80 {
    margin-bottom: -80px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-80 {
    margin-bottom: -80px;
  }
}

@media (min-width: 768px) {
  .space-db-md-85 {
    margin-bottom: -85px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-85 {
    margin-bottom: -85px;
  }
}

@media (min-width: 768px) {
  .space-db-md-90 {
    margin-bottom: -90px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-90 {
    margin-bottom: -90px;
  }
}

@media (min-width: 768px) {
  .space-db-md-95 {
    margin-bottom: -95px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-95 {
    margin-bottom: -95px;
  }
}

@media (min-width: 768px) {
  .space-db-md-100 {
    margin-bottom: -100px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-100 {
    margin-bottom: -100px;
  }
}

@media (min-width: 768px) {
  .space-db-md-105 {
    margin-bottom: -105px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-105 {
    margin-bottom: -105px;
  }
}

@media (min-width: 768px) {
  .space-db-md-110 {
    margin-bottom: -110px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-110 {
    margin-bottom: -110px;
  }
}

@media (min-width: 768px) {
  .space-db-md-115 {
    margin-bottom: -115px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-115 {
    margin-bottom: -115px;
  }
}

@media (min-width: 768px) {
  .space-db-md-120 {
    margin-bottom: -120px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-120 {
    margin-bottom: -120px;
  }
}

@media (min-width: 768px) {
  .space-db-md-125 {
    margin-bottom: -125px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-125 {
    margin-bottom: -125px;
  }
}

@media (min-width: 768px) {
  .space-db-md-130 {
    margin-bottom: -130px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-130 {
    margin-bottom: -130px;
  }
}

@media (min-width: 768px) {
  .space-db-md-135 {
    margin-bottom: -135px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-135 {
    margin-bottom: -135px;
  }
}

@media (min-width: 768px) {
  .space-db-md-140 {
    margin-bottom: -140px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-140 {
    margin-bottom: -140px;
  }
}

@media (min-width: 768px) {
  .space-db-md-145 {
    margin-bottom: -145px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-145 {
    margin-bottom: -145px;
  }
}

@media (min-width: 768px) {
  .space-db-md-150 {
    margin-bottom: -150px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-150 {
    margin-bottom: -150px;
  }
}

@media (min-width: 768px) {
  .space-db-md-155 {
    margin-bottom: -155px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-155 {
    margin-bottom: -155px;
  }
}

@media (min-width: 768px) {
  .space-db-md-160 {
    margin-bottom: -160px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-160 {
    margin-bottom: -160px;
  }
}

@media (min-width: 768px) {
  .space-db-md-165 {
    margin-bottom: -165px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-165 {
    margin-bottom: -165px;
  }
}

@media (min-width: 768px) {
  .space-db-md-170 {
    margin-bottom: -170px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-170 {
    margin-bottom: -170px;
  }
}

@media (min-width: 768px) {
  .space-db-md-175 {
    margin-bottom: -175px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-175 {
    margin-bottom: -175px;
  }
}

@media (min-width: 768px) {
  .space-db-md-180 {
    margin-bottom: -180px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-180 {
    margin-bottom: -180px;
  }
}

@media (min-width: 768px) {
  .space-db-md-185 {
    margin-bottom: -185px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-185 {
    margin-bottom: -185px;
  }
}

@media (min-width: 768px) {
  .space-db-md-190 {
    margin-bottom: -190px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-190 {
    margin-bottom: -190px;
  }
}

@media (min-width: 768px) {
  .space-db-md-195 {
    margin-bottom: -195px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-195 {
    margin-bottom: -195px;
  }
}

@media (min-width: 768px) {
  .space-db-md-200 {
    margin-bottom: -200px !important;
  }
}

@media (min-width: 992px) {
  .space-db-md-200 {
    margin-bottom: -200px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-0 {
    margin-bottom: 0px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-0 {
    margin-bottom: 0px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-5 {
    margin-bottom: -5px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-5 {
    margin-bottom: -5px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-10 {
    margin-bottom: -10px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-10 {
    margin-bottom: -10px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-15 {
    margin-bottom: -15px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-15 {
    margin-bottom: -15px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-20 {
    margin-bottom: -20px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-20 {
    margin-bottom: -20px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-25 {
    margin-bottom: -25px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-25 {
    margin-bottom: -25px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-30 {
    margin-bottom: -30px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-30 {
    margin-bottom: -30px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-35 {
    margin-bottom: -35px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-35 {
    margin-bottom: -35px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-40 {
    margin-bottom: -40px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-40 {
    margin-bottom: -40px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-45 {
    margin-bottom: -45px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-45 {
    margin-bottom: -45px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-50 {
    margin-bottom: -50px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-50 {
    margin-bottom: -50px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-55 {
    margin-bottom: -55px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-55 {
    margin-bottom: -55px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-60 {
    margin-bottom: -60px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-60 {
    margin-bottom: -60px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-65 {
    margin-bottom: -65px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-65 {
    margin-bottom: -65px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-70 {
    margin-bottom: -70px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-70 {
    margin-bottom: -70px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-75 {
    margin-bottom: -75px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-75 {
    margin-bottom: -75px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-80 {
    margin-bottom: -80px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-80 {
    margin-bottom: -80px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-85 {
    margin-bottom: -85px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-85 {
    margin-bottom: -85px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-90 {
    margin-bottom: -90px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-90 {
    margin-bottom: -90px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-95 {
    margin-bottom: -95px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-95 {
    margin-bottom: -95px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-100 {
    margin-bottom: -100px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-100 {
    margin-bottom: -100px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-105 {
    margin-bottom: -105px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-105 {
    margin-bottom: -105px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-110 {
    margin-bottom: -110px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-110 {
    margin-bottom: -110px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-115 {
    margin-bottom: -115px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-115 {
    margin-bottom: -115px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-120 {
    margin-bottom: -120px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-120 {
    margin-bottom: -120px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-125 {
    margin-bottom: -125px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-125 {
    margin-bottom: -125px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-130 {
    margin-bottom: -130px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-130 {
    margin-bottom: -130px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-135 {
    margin-bottom: -135px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-135 {
    margin-bottom: -135px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-140 {
    margin-bottom: -140px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-140 {
    margin-bottom: -140px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-145 {
    margin-bottom: -145px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-145 {
    margin-bottom: -145px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-150 {
    margin-bottom: -150px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-150 {
    margin-bottom: -150px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-155 {
    margin-bottom: -155px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-155 {
    margin-bottom: -155px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-160 {
    margin-bottom: -160px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-160 {
    margin-bottom: -160px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-165 {
    margin-bottom: -165px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-165 {
    margin-bottom: -165px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-170 {
    margin-bottom: -170px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-170 {
    margin-bottom: -170px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-175 {
    margin-bottom: -175px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-175 {
    margin-bottom: -175px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-180 {
    margin-bottom: -180px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-180 {
    margin-bottom: -180px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-185 {
    margin-bottom: -185px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-185 {
    margin-bottom: -185px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-190 {
    margin-bottom: -190px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-190 {
    margin-bottom: -190px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-195 {
    margin-bottom: -195px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-195 {
    margin-bottom: -195px;
  }
}

@media (min-width: 992px) {
  .space-db-lg-200 {
    margin-bottom: -200px !important;
  }
}

@media (min-width: 1200px) {
  .space-db-lg-200 {
    margin-bottom: -200px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-0 {
    margin-bottom: 0px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-0 {
    margin-bottom: 0px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-5 {
    margin-bottom: -5px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-5 {
    margin-bottom: -5px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-10 {
    margin-bottom: -10px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-10 {
    margin-bottom: -10px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-15 {
    margin-bottom: -15px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-15 {
    margin-bottom: -15px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-20 {
    margin-bottom: -20px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-20 {
    margin-bottom: -20px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-25 {
    margin-bottom: -25px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-25 {
    margin-bottom: -25px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-30 {
    margin-bottom: -30px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-30 {
    margin-bottom: -30px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-35 {
    margin-bottom: -35px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-35 {
    margin-bottom: -35px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-40 {
    margin-bottom: -40px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-40 {
    margin-bottom: -40px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-45 {
    margin-bottom: -45px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-45 {
    margin-bottom: -45px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-50 {
    margin-bottom: -50px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-50 {
    margin-bottom: -50px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-55 {
    margin-bottom: -55px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-55 {
    margin-bottom: -55px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-60 {
    margin-bottom: -60px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-60 {
    margin-bottom: -60px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-65 {
    margin-bottom: -65px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-65 {
    margin-bottom: -65px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-70 {
    margin-bottom: -70px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-70 {
    margin-bottom: -70px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-75 {
    margin-bottom: -75px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-75 {
    margin-bottom: -75px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-80 {
    margin-bottom: -80px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-80 {
    margin-bottom: -80px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-85 {
    margin-bottom: -85px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-85 {
    margin-bottom: -85px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-90 {
    margin-bottom: -90px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-90 {
    margin-bottom: -90px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-95 {
    margin-bottom: -95px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-95 {
    margin-bottom: -95px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-100 {
    margin-bottom: -100px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-100 {
    margin-bottom: -100px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-105 {
    margin-bottom: -105px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-105 {
    margin-bottom: -105px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-110 {
    margin-bottom: -110px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-110 {
    margin-bottom: -110px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-115 {
    margin-bottom: -115px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-115 {
    margin-bottom: -115px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-120 {
    margin-bottom: -120px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-120 {
    margin-bottom: -120px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-125 {
    margin-bottom: -125px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-125 {
    margin-bottom: -125px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-130 {
    margin-bottom: -130px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-130 {
    margin-bottom: -130px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-135 {
    margin-bottom: -135px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-135 {
    margin-bottom: -135px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-140 {
    margin-bottom: -140px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-140 {
    margin-bottom: -140px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-145 {
    margin-bottom: -145px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-145 {
    margin-bottom: -145px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-150 {
    margin-bottom: -150px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-150 {
    margin-bottom: -150px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-155 {
    margin-bottom: -155px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-155 {
    margin-bottom: -155px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-160 {
    margin-bottom: -160px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-160 {
    margin-bottom: -160px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-165 {
    margin-bottom: -165px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-165 {
    margin-bottom: -165px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-170 {
    margin-bottom: -170px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-170 {
    margin-bottom: -170px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-175 {
    margin-bottom: -175px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-175 {
    margin-bottom: -175px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-180 {
    margin-bottom: -180px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-180 {
    margin-bottom: -180px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-185 {
    margin-bottom: -185px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-185 {
    margin-bottom: -185px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-190 {
    margin-bottom: -190px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-190 {
    margin-bottom: -190px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-195 {
    margin-bottom: -195px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-195 {
    margin-bottom: -195px;
  }
}

@media (min-width: 1200px) {
  .space-db-xl-200 {
    margin-bottom: -200px !important;
  }
}

@media (min-width: 1600px) {
  .space-db-xl-200 {
    margin-bottom: -200px;
  }
}

/*=====================================*/
/*-- :::: Paddings :::: --*/
/*=====================================*/
/*-- Padding top --*/
.pt--0 {
  padding-top: 0px !important;
}

.pt--5 {
  padding-top: 5px !important;
}

.pt--10 {
  padding-top: 10px !important;
}

.pt--15 {
  padding-top: 15px !important;
}

.pt--20 {
  padding-top: 20px !important;
}

.pt--25 {
  padding-top: 25px !important;
}

.pt--30 {
  padding-top: 30px !important;
}

.pt--35 {
  padding-top: 35px !important;
}

.pt--40 {
  padding-top: 40px !important;
}

.pt--45 {
  padding-top: 45px !important;
}

.pt--50 {
  padding-top: 50px !important;
}

.pt--55 {
  padding-top: 55px !important;
}

.pt--60 {
  padding-top: 60px !important;
}

.pt--65 {
  padding-top: 65px !important;
}

.pt--70 {
  padding-top: 70px !important;
}

.pt--75 {
  padding-top: 75px !important;
}

.pt--80 {
  padding-top: 80px !important;
}

.pt--85 {
  padding-top: 85px !important;
}

.pt--90 {
  padding-top: 90px !important;
}

.pt--95 {
  padding-top: 95px !important;
}

.pt--100 {
  padding-top: 100px !important;
}

.pt--105 {
  padding-top: 105px !important;
}

.pt--110 {
  padding-top: 110px !important;
}

.pt--115 {
  padding-top: 115px !important;
}

.pt--120 {
  padding-top: 120px !important;
}

.pt--125 {
  padding-top: 125px !important;
}

.pt--130 {
  padding-top: 130px !important;
}

.pt--135 {
  padding-top: 135px !important;
}

.pt--140 {
  padding-top: 140px !important;
}

.pt--145 {
  padding-top: 145px !important;
}

.pt--150 {
  padding-top: 150px !important;
}

.pt--155 {
  padding-top: 155px !important;
}

.pt--160 {
  padding-top: 160px !important;
}

.pt--165 {
  padding-top: 165px !important;
}

.pt--170 {
  padding-top: 170px !important;
}

.pt--175 {
  padding-top: 175px !important;
}

.pt--180 {
  padding-top: 180px !important;
}

.pt--185 {
  padding-top: 185px !important;
}

.pt--190 {
  padding-top: 190px !important;
}

.pt--195 {
  padding-top: 195px !important;
}

.pt--200 {
  padding-top: 200px !important;
}

/*-- Padding Bottom --*/
.pb--0 {
  padding-bottom: 0px !important;
}

.pb--5 {
  padding-bottom: 5px !important;
}

.pb--10 {
  padding-bottom: 10px !important;
}

.pb--15 {
  padding-bottom: 15px !important;
}

.pb--20 {
  padding-bottom: 20px !important;
}

.pb--25 {
  padding-bottom: 25px !important;
}

.pb--30 {
  padding-bottom: 30px !important;
}

.pb--35 {
  padding-bottom: 35px !important;
}

.pb--40 {
  padding-bottom: 40px !important;
}

.pb--45 {
  padding-bottom: 45px !important;
}

.pb--50 {
  padding-bottom: 50px !important;
}

.pb--55 {
  padding-bottom: 55px !important;
}

.pb--60 {
  padding-bottom: 60px !important;
}

.pb--65 {
  padding-bottom: 65px !important;
}

.pb--70 {
  padding-bottom: 70px !important;
}

.pb--75 {
  padding-bottom: 75px !important;
}

.pb--80 {
  padding-bottom: 80px !important;
}

.pb--85 {
  padding-bottom: 85px !important;
}

.pb--90 {
  padding-bottom: 90px !important;
}

.pb--95 {
  padding-bottom: 95px !important;
}

.pb--100 {
  padding-bottom: 100px !important;
}

.pb--105 {
  padding-bottom: 105px !important;
}

.pb--110 {
  padding-bottom: 110px !important;
}

.pb--115 {
  padding-bottom: 115px !important;
}

.pb--120 {
  padding-bottom: 120px !important;
}

.pb--125 {
  padding-bottom: 125px !important;
}

.pb--130 {
  padding-bottom: 130px !important;
}

.pb--135 {
  padding-bottom: 135px !important;
}

.pb--140 {
  padding-bottom: 140px !important;
}

.pb--145 {
  padding-bottom: 145px !important;
}

.pb--150 {
  padding-bottom: 150px !important;
}

.pb--155 {
  padding-bottom: 155px !important;
}

.pb--160 {
  padding-bottom: 160px !important;
}

.pb--165 {
  padding-bottom: 165px !important;
}

.pb--170 {
  padding-bottom: 170px !important;
}

.pb--175 {
  padding-bottom: 175px !important;
}

.pb--180 {
  padding-bottom: 180px !important;
}

.pb--185 {
  padding-bottom: 185px !important;
}

.pb--190 {
  padding-bottom: 190px !important;
}

.pb--195 {
  padding-bottom: 195px !important;
}

.pb--200 {
  padding-bottom: 200px !important;
}

/*-- Padding Left --*/
.pl--0 {
  padding-left: 0px !important;
}

.pl--5 {
  padding-left: 5px !important;
}

.pl--10 {
  padding-left: 10px !important;
}

.pl--15 {
  padding-left: 15px !important;
}

.pl--20 {
  padding-left: 20px !important;
}

.pl--25 {
  padding-left: 25px !important;
}

.pl--30 {
  padding-left: 30px !important;
}

.pl--35 {
  padding-left: 35px !important;
}

.pl--40 {
  padding-left: 40px !important;
}

.pl--45 {
  padding-left: 45px !important;
}

.pl--50 {
  padding-left: 50px !important;
}

.pl--55 {
  padding-left: 55px !important;
}

.pl--60 {
  padding-left: 60px !important;
}

.pl--65 {
  padding-left: 65px !important;
}

.pl--70 {
  padding-left: 70px !important;
}

.pl--75 {
  padding-left: 75px !important;
}

.pl--80 {
  padding-left: 80px !important;
}

.pl--85 {
  padding-left: 85px !important;
}

.pl--90 {
  padding-left: 90px !important;
}

.pl--95 {
  padding-left: 95px !important;
}

.pl--100 {
  padding-left: 100px !important;
}

.pl--105 {
  padding-left: 105px !important;
}

.pl--110 {
  padding-left: 110px !important;
}

.pl--115 {
  padding-left: 115px !important;
}

.pl--120 {
  padding-left: 120px !important;
}

.pl--125 {
  padding-left: 125px !important;
}

.pl--130 {
  padding-left: 130px !important;
}

.pl--135 {
  padding-left: 135px !important;
}

.pl--140 {
  padding-left: 140px !important;
}

.pl--145 {
  padding-left: 145px !important;
}

.pl--150 {
  padding-left: 150px !important;
}

.pl--155 {
  padding-left: 155px !important;
}

.pl--160 {
  padding-left: 160px !important;
}

.pl--165 {
  padding-left: 165px !important;
}

.pl--170 {
  padding-left: 170px !important;
}

.pl--175 {
  padding-left: 175px !important;
}

.pl--180 {
  padding-left: 180px !important;
}

.pl--185 {
  padding-left: 185px !important;
}

.pl--190 {
  padding-left: 190px !important;
}

.pl--195 {
  padding-left: 195px !important;
}

.pl--200 {
  padding-left: 200px !important;
}

/*-- Padding Right --*/
.pr--0 {
  padding-right: 0px !important;
}

.pr--5 {
  padding-right: 5px !important;
}

.pr--10 {
  padding-right: 10px !important;
}

.pr--15 {
  padding-right: 15px !important;
}

.pr--20 {
  padding-right: 20px !important;
}

.pr--25 {
  padding-right: 25px !important;
}

.pr--30 {
  padding-right: 30px !important;
}

.pr--35 {
  padding-right: 35px !important;
}

.pr--40 {
  padding-right: 40px !important;
}

.pr--45 {
  padding-right: 45px !important;
}

.pr--50 {
  padding-right: 50px !important;
}

.pr--55 {
  padding-right: 55px !important;
}

.pr--60 {
  padding-right: 60px !important;
}

.pr--65 {
  padding-right: 65px !important;
}

.pr--70 {
  padding-right: 70px !important;
}

.pr--75 {
  padding-right: 75px !important;
}

.pr--80 {
  padding-right: 80px !important;
}

.pr--85 {
  padding-right: 85px !important;
}

.pr--90 {
  padding-right: 90px !important;
}

.pr--95 {
  padding-right: 95px !important;
}

.pr--100 {
  padding-right: 100px !important;
}

.pr--105 {
  padding-right: 105px !important;
}

.pr--110 {
  padding-right: 110px !important;
}

.pr--115 {
  padding-right: 115px !important;
}

.pr--120 {
  padding-right: 120px !important;
}

.pr--125 {
  padding-right: 125px !important;
}

.pr--130 {
  padding-right: 130px !important;
}

.pr--135 {
  padding-right: 135px !important;
}

.pr--140 {
  padding-right: 140px !important;
}

.pr--145 {
  padding-right: 145px !important;
}

.pr--150 {
  padding-right: 150px !important;
}

.pr--155 {
  padding-right: 155px !important;
}

.pr--160 {
  padding-right: 160px !important;
}

.pr--165 {
  padding-right: 165px !important;
}

.pr--170 {
  padding-right: 170px !important;
}

.pr--175 {
  padding-right: 175px !important;
}

.pr--180 {
  padding-right: 180px !important;
}

.pr--185 {
  padding-right: 185px !important;
}

.pr--190 {
  padding-right: 190px !important;
}

.pr--195 {
  padding-right: 195px !important;
}

.pr--200 {
  padding-right: 200px !important;
}

/*-- Padding Vertical --*/
.px--0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.px--5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.px--10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.px--15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.px--20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.px--25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.px--30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.px--35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.px--40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.px--45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.px--50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.px--55 {
  padding-left: 55px !important;
  padding-right: 55px !important;
}

.px--60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.px--65 {
  padding-left: 65px !important;
  padding-right: 65px !important;
}

.px--70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.px--75 {
  padding-left: 75px !important;
  padding-right: 75px !important;
}

.px--80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.px--85 {
  padding-left: 85px !important;
  padding-right: 85px !important;
}

.px--90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

.px--95 {
  padding-left: 95px !important;
  padding-right: 95px !important;
}

.px--100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.px--105 {
  padding-left: 105px !important;
  padding-right: 105px !important;
}

.px--110 {
  padding-left: 110px !important;
  padding-right: 110px !important;
}

.px--115 {
  padding-left: 115px !important;
  padding-right: 115px !important;
}

.px--120 {
  padding-left: 120px !important;
  padding-right: 120px !important;
}

.px--125 {
  padding-left: 125px !important;
  padding-right: 125px !important;
}

.px--130 {
  padding-left: 130px !important;
  padding-right: 130px !important;
}

.px--135 {
  padding-left: 135px !important;
  padding-right: 135px !important;
}

.px--140 {
  padding-left: 140px !important;
  padding-right: 140px !important;
}

.px--145 {
  padding-left: 145px !important;
  padding-right: 145px !important;
}

.px--150 {
  padding-left: 150px !important;
  padding-right: 150px !important;
}

.px--155 {
  padding-left: 155px !important;
  padding-right: 155px !important;
}

.px--160 {
  padding-left: 160px !important;
  padding-right: 160px !important;
}

.px--165 {
  padding-left: 165px !important;
  padding-right: 165px !important;
}

.px--170 {
  padding-left: 170px !important;
  padding-right: 170px !important;
}

.px--175 {
  padding-left: 175px !important;
  padding-right: 175px !important;
}

.px--180 {
  padding-left: 180px !important;
  padding-right: 180px !important;
}

.px--185 {
  padding-left: 185px !important;
  padding-right: 185px !important;
}

.px--190 {
  padding-left: 190px !important;
  padding-right: 190px !important;
}

.px--195 {
  padding-left: 195px !important;
  padding-right: 195px !important;
}

.px--200 {
  padding-left: 200px !important;
  padding-right: 200px !important;
}

/*-- Padding Horizontal --*/
.py--0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.py--5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.py--10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.py--15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.py--20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.py--25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.py--30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.py--35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.py--40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.py--45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.py--50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.py--55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

.py--60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.py--65 {
  padding-top: 65px !important;
  padding-bottom: 65px !important;
}

.py--70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.py--75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

.py--80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.py--85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

.py--90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.py--95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}

.py--100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.py--105 {
  padding-top: 105px !important;
  padding-bottom: 105px !important;
}

.py--110 {
  padding-top: 110px !important;
  padding-bottom: 110px !important;
}

.py--115 {
  padding-top: 115px !important;
  padding-bottom: 115px !important;
}

.py--120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

.py--125 {
  padding-top: 125px !important;
  padding-bottom: 125px !important;
}

.py--130 {
  padding-top: 130px !important;
  padding-bottom: 130px !important;
}

.py--135 {
  padding-top: 135px !important;
  padding-bottom: 135px !important;
}

.py--140 {
  padding-top: 140px !important;
  padding-bottom: 140px !important;
}

.py--145 {
  padding-top: 145px !important;
  padding-bottom: 145px !important;
}

.py--150 {
  padding-top: 150px !important;
  padding-bottom: 150px !important;
}

.py--155 {
  padding-top: 155px !important;
  padding-bottom: 155px !important;
}

.py--160 {
  padding-top: 160px !important;
  padding-bottom: 160px !important;
}

.py--165 {
  padding-top: 165px !important;
  padding-bottom: 165px !important;
}

.py--170 {
  padding-top: 170px !important;
  padding-bottom: 170px !important;
}

.py--175 {
  padding-top: 175px !important;
  padding-bottom: 175px !important;
}

.py--180 {
  padding-top: 180px !important;
  padding-bottom: 180px !important;
}

.py--185 {
  padding-top: 185px !important;
  padding-bottom: 185px !important;
}

.py--190 {
  padding-top: 190px !important;
  padding-bottom: 190px !important;
}

.py--195 {
  padding-top: 195px !important;
  padding-bottom: 195px !important;
}

.py--200 {
  padding-top: 200px !important;
  padding-bottom: 200px !important;
}

/*=====================================*/
/*-- :::: Margins :::: --*/
/*=====================================*/
/*-- Margins top --*/
.mt--0 {
  margin-top: 0px !important;
}

.mt--5 {
  margin-top: 5px !important;
}

.mt--10 {
  margin-top: 10px !important;
}

.mt--15 {
  margin-top: 15px !important;
}

.mt--20 {
  margin-top: 20px !important;
}

.mt--25 {
  margin-top: 25px !important;
}

.mt--30 {
  margin-top: 30px !important;
}

.mt--35 {
  margin-top: 35px !important;
}

.mt--40 {
  margin-top: 40px !important;
}

.mt--45 {
  margin-top: 45px !important;
}

.mt--50 {
  margin-top: 50px !important;
}

.mt--55 {
  margin-top: 55px !important;
}

.mt--60 {
  margin-top: 60px !important;
}

.mt--65 {
  margin-top: 65px !important;
}

.mt--70 {
  margin-top: 70px !important;
}

.mt--75 {
  margin-top: 75px !important;
}

.mt--80 {
  margin-top: 80px !important;
}

.mt--85 {
  margin-top: 85px !important;
}

.mt--90 {
  margin-top: 90px !important;
}

.mt--95 {
  margin-top: 95px !important;
}

.mt--100 {
  margin-top: 100px !important;
}

.mt--105 {
  margin-top: 105px !important;
}

.mt--110 {
  margin-top: 110px !important;
}

.mt--115 {
  margin-top: 115px !important;
}

.mt--120 {
  margin-top: 120px !important;
}

.mt--125 {
  margin-top: 125px !important;
}

.mt--130 {
  margin-top: 130px !important;
}

.mt--135 {
  margin-top: 135px !important;
}

.mt--140 {
  margin-top: 140px !important;
}

.mt--145 {
  margin-top: 145px !important;
}

.mt--150 {
  margin-top: 150px !important;
}

.mt--155 {
  margin-top: 155px !important;
}

.mt--160 {
  margin-top: 160px !important;
}

.mt--165 {
  margin-top: 165px !important;
}

.mt--170 {
  margin-top: 170px !important;
}

.mt--175 {
  margin-top: 175px !important;
}

.mt--180 {
  margin-top: 180px !important;
}

.mt--185 {
  margin-top: 185px !important;
}

.mt--190 {
  margin-top: 190px !important;
}

.mt--195 {
  margin-top: 195px !important;
}

.mt--200 {
  margin-top: 200px !important;
}

/*-- Margin Bottom --*/
.mb--5 {
  margin-bottom: 5px !important;
}

.mb--10 {
  margin-bottom: 10px !important;
}

.mb--15 {
  margin-bottom: 15px !important;
}

.mb--20 {
  margin-bottom: 20px !important;
}

.mb--25 {
  margin-bottom: 25px !important;
}

.mb--30 {
  margin-bottom: 30px !important;
}

.mb--35 {
  margin-bottom: 35px !important;
}

.mb--40 {
  margin-bottom: 40px !important;
}

.mb--45 {
  margin-bottom: 45px !important;
}

.mb--50 {
  margin-bottom: 50px !important;
}

.mb--55 {
  margin-bottom: 55px !important;
}

.mb--60 {
  margin-bottom: 60px !important;
}

.mb--65 {
  margin-bottom: 65px !important;
}

.mb--70 {
  margin-bottom: 70px !important;
}

.mb--75 {
  margin-bottom: 75px !important;
}

.mb--80 {
  margin-bottom: 80px !important;
}

.mb--85 {
  margin-bottom: 85px !important;
}

.mb--90 {
  margin-bottom: 90px !important;
}

.mb--95 {
  margin-bottom: 95px !important;
}

.mb--100 {
  margin-bottom: 100px !important;
}

.mb--105 {
  margin-bottom: 105px !important;
}

.mb--110 {
  margin-bottom: 110px !important;
}

.mb--115 {
  margin-bottom: 115px !important;
}

.mb--120 {
  margin-bottom: 120px !important;
}

.mb--125 {
  margin-bottom: 125px !important;
}

.mb--130 {
  margin-bottom: 130px !important;
}

.mb--135 {
  margin-bottom: 135px !important;
}

.mb--140 {
  margin-bottom: 140px !important;
}

.mb--145 {
  margin-bottom: 145px !important;
}

.mb--150 {
  margin-bottom: 150px !important;
}

.mb--155 {
  margin-bottom: 155px !important;
}

.mb--160 {
  margin-bottom: 160px !important;
}

.mb--165 {
  margin-bottom: 165px !important;
}

.mb--170 {
  margin-bottom: 170px !important;
}

.mb--175 {
  margin-bottom: 175px !important;
}

.mb--180 {
  margin-bottom: 180px !important;
}

.mb--185 {
  margin-bottom: 185px !important;
}

.mb--190 {
  margin-bottom: 190px !important;
}

.mb--195 {
  margin-bottom: 195px !important;
}

.mb--200 {
  margin-bottom: 200px !important;
}

/*-- Margin Left --*/
.ml--5 {
  margin-left: 5px !important;
}

.ml--10 {
  margin-left: 10px !important;
}

.ml--15 {
  margin-left: 15px !important;
}

.ml--20 {
  margin-left: 20px !important;
}

.ml--25 {
  margin-left: 25px !important;
}

.ml--30 {
  margin-left: 30px !important;
}

.ml--35 {
  margin-left: 35px !important;
}

.ml--40 {
  margin-left: 40px !important;
}

.ml--45 {
  margin-left: 45px !important;
}

.ml--50 {
  margin-left: 50px !important;
}

.ml--55 {
  margin-left: 55px !important;
}

.ml--60 {
  margin-left: 60px !important;
}

.ml--65 {
  margin-left: 65px !important;
}

.ml--70 {
  margin-left: 70px !important;
}

.ml--75 {
  margin-left: 75px !important;
}

.ml--80 {
  margin-left: 80px !important;
}

.ml--85 {
  margin-left: 85px !important;
}

.ml--90 {
  margin-left: 90px !important;
}

.ml--95 {
  margin-left: 95px !important;
}

.ml--100 {
  margin-left: 100px !important;
}

.ml--105 {
  margin-left: 105px !important;
}

.ml--110 {
  margin-left: 110px !important;
}

.ml--115 {
  margin-left: 115px !important;
}

.ml--120 {
  margin-left: 120px !important;
}

.ml--125 {
  margin-left: 125px !important;
}

.ml--130 {
  margin-left: 130px !important;
}

.ml--135 {
  margin-left: 135px !important;
}

.ml--140 {
  margin-left: 140px !important;
}

.ml--145 {
  margin-left: 145px !important;
}

.ml--150 {
  margin-left: 150px !important;
}

.ml--155 {
  margin-left: 155px !important;
}

.ml--160 {
  margin-left: 160px !important;
}

.ml--165 {
  margin-left: 165px !important;
}

.ml--170 {
  margin-left: 170px !important;
}

.ml--175 {
  margin-left: 175px !important;
}

.ml--180 {
  margin-left: 180px !important;
}

.ml--185 {
  margin-left: 185px !important;
}

.ml--190 {
  margin-left: 190px !important;
}

.ml--195 {
  margin-left: 195px !important;
}

.ml--200 {
  margin-left: 200px !important;
}

/*-- Margin Right --*/
.mr--5 {
  margin-right: 5px !important;
}

.mr--10 {
  margin-right: 10px !important;
}

.mr--15 {
  margin-right: 15px !important;
}

.mr--20 {
  margin-right: 20px !important;
}

.mr--25 {
  margin-right: 25px !important;
}

.mr--30 {
  margin-right: 30px !important;
}

.mr--35 {
  margin-right: 35px !important;
}

.mr--40 {
  margin-right: 40px !important;
}

.mr--45 {
  margin-right: 45px !important;
}

.mr--50 {
  margin-right: 50px !important;
}

.mr--55 {
  margin-right: 55px !important;
}

.mr--60 {
  margin-right: 60px !important;
}

.mr--65 {
  margin-right: 65px !important;
}

.mr--70 {
  margin-right: 70px !important;
}

.mr--75 {
  margin-right: 75px !important;
}

.mr--80 {
  margin-right: 80px !important;
}

.mr--85 {
  margin-right: 85px !important;
}

.mr--90 {
  margin-right: 90px !important;
}

.mr--95 {
  margin-right: 95px !important;
}

.mr--100 {
  margin-right: 100px !important;
}

.mr--105 {
  margin-right: 105px !important;
}

.mr--110 {
  margin-right: 110px !important;
}

.mr--115 {
  margin-right: 115px !important;
}

.mr--120 {
  margin-right: 120px !important;
}

.mr--125 {
  margin-right: 125px !important;
}

.mr--130 {
  margin-right: 130px !important;
}

.mr--135 {
  margin-right: 135px !important;
}

.mr--140 {
  margin-right: 140px !important;
}

.mr--145 {
  margin-right: 145px !important;
}

.mr--150 {
  margin-right: 150px !important;
}

.mr--155 {
  margin-right: 155px !important;
}

.mr--160 {
  margin-right: 160px !important;
}

.mr--165 {
  margin-right: 165px !important;
}

.mr--170 {
  margin-right: 170px !important;
}

.mr--175 {
  margin-right: 175px !important;
}

.mr--180 {
  margin-right: 180px !important;
}

.mr--185 {
  margin-right: 185px !important;
}

.mr--190 {
  margin-right: 190px !important;
}

.mr--195 {
  margin-right: 195px !important;
}

.mr--200 {
  margin-right: 200px !important;
}

/*-- Margin Vertical --*/
.mx--0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.mx--5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.mx--10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mx--15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.mx--20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.mx--25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.mx--30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.mx--35 {
  margin-left: 35px !important;
  margin-right: 35px !important;
}

.mx--40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.mx--45 {
  margin-left: 45px !important;
  margin-right: 45px !important;
}

.mx--50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.mx--55 {
  margin-left: 55px !important;
  margin-right: 55px !important;
}

.mx--60 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

.mx--65 {
  margin-left: 65px !important;
  margin-right: 65px !important;
}

.mx--70 {
  margin-left: 70px !important;
  margin-right: 70px !important;
}

.mx--75 {
  margin-left: 75px !important;
  margin-right: 75px !important;
}

.mx--80 {
  margin-left: 80px !important;
  margin-right: 80px !important;
}

.mx--85 {
  margin-left: 85px !important;
  margin-right: 85px !important;
}

.mx--90 {
  margin-left: 90px !important;
  margin-right: 90px !important;
}

.mx--95 {
  margin-left: 95px !important;
  margin-right: 95px !important;
}

.mx--100 {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

.mx--105 {
  margin-left: 105px !important;
  margin-right: 105px !important;
}

.mx--110 {
  margin-left: 110px !important;
  margin-right: 110px !important;
}

.mx--115 {
  margin-left: 115px !important;
  margin-right: 115px !important;
}

.mx--120 {
  margin-left: 120px !important;
  margin-right: 120px !important;
}

.mx--125 {
  margin-left: 125px !important;
  margin-right: 125px !important;
}

.mx--130 {
  margin-left: 130px !important;
  margin-right: 130px !important;
}

.mx--135 {
  margin-left: 135px !important;
  margin-right: 135px !important;
}

.mx--140 {
  margin-left: 140px !important;
  margin-right: 140px !important;
}

.mx--145 {
  margin-left: 145px !important;
  margin-right: 145px !important;
}

.mx--150 {
  margin-left: 150px !important;
  margin-right: 150px !important;
}

.mx--155 {
  margin-left: 155px !important;
  margin-right: 155px !important;
}

.mx--160 {
  margin-left: 160px !important;
  margin-right: 160px !important;
}

.mx--165 {
  margin-left: 165px !important;
  margin-right: 165px !important;
}

.mx--170 {
  margin-left: 170px !important;
  margin-right: 170px !important;
}

.mx--175 {
  margin-left: 175px !important;
  margin-right: 175px !important;
}

.mx--180 {
  margin-left: 180px !important;
  margin-right: 180px !important;
}

.mx--185 {
  margin-left: 185px !important;
  margin-right: 185px !important;
}

.mx--190 {
  margin-left: 190px !important;
  margin-right: 190px !important;
}

.mx--195 {
  margin-left: 195px !important;
  margin-right: 195px !important;
}

.mx--200 {
  margin-left: 200px !important;
  margin-right: 200px !important;
}

/*-- Margin Horizontal --*/
.my--0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.my--5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.my--10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.my--15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.my--20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.my--25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.my--30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.my--35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.my--40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.my--45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.my--50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.my--55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}

.my--60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.my--65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}

.my--70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.my--75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}

.my--80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.my--85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important;
}

.my--90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

.my--95 {
  margin-top: 95px !important;
  margin-bottom: 95px !important;
}

.my--100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.my--105 {
  margin-top: 105px !important;
  margin-bottom: 105px !important;
}

.my--110 {
  margin-top: 110px !important;
  margin-bottom: 110px !important;
}

.my--115 {
  margin-top: 115px !important;
  margin-bottom: 115px !important;
}

.my--120 {
  margin-top: 120px !important;
  margin-bottom: 120px !important;
}

.my--125 {
  margin-top: 125px !important;
  margin-bottom: 125px !important;
}

.my--130 {
  margin-top: 130px !important;
  margin-bottom: 130px !important;
}

.my--135 {
  margin-top: 135px !important;
  margin-bottom: 135px !important;
}

.my--140 {
  margin-top: 140px !important;
  margin-bottom: 140px !important;
}

.my--145 {
  margin-top: 145px !important;
  margin-bottom: 145px !important;
}

.my--150 {
  margin-top: 150px !important;
  margin-bottom: 150px !important;
}

.my--155 {
  margin-top: 155px !important;
  margin-bottom: 155px !important;
}

.my--160 {
  margin-top: 160px !important;
  margin-bottom: 160px !important;
}

.my--165 {
  margin-top: 165px !important;
  margin-bottom: 165px !important;
}

.my--170 {
  margin-top: 170px !important;
  margin-bottom: 170px !important;
}

.my--175 {
  margin-top: 175px !important;
  margin-bottom: 175px !important;
}

.my--180 {
  margin-top: 180px !important;
  margin-bottom: 180px !important;
}

.my--185 {
  margin-top: 185px !important;
  margin-bottom: 185px !important;
}

.my--190 {
  margin-top: 190px !important;
  margin-bottom: 190px !important;
}

.my--195 {
  margin-top: 195px !important;
  margin-bottom: 195px !important;
}

.my--200 {
  margin-top: 200px !important;
  margin-bottom: 200px !important;
}

/*=====================================*/
/*-- :::: Responsive Padding :::: --*/
/*=====================================*/
/*-- Responsive Padding top --*/
@media (min-width: 576px) {
  .pt-sm--0 {
    padding-top: 0px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--0 {
    padding-top: 0px;
  }
}

@media (min-width: 576px) {
  .pt-sm--5 {
    padding-top: 5px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--5 {
    padding-top: 5px;
  }
}

@media (min-width: 576px) {
  .pt-sm--10 {
    padding-top: 10px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--10 {
    padding-top: 10px;
  }
}

@media (min-width: 576px) {
  .pt-sm--15 {
    padding-top: 15px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--15 {
    padding-top: 15px;
  }
}

@media (min-width: 576px) {
  .pt-sm--20 {
    padding-top: 20px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--20 {
    padding-top: 20px;
  }
}

@media (min-width: 576px) {
  .pt-sm--25 {
    padding-top: 25px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--25 {
    padding-top: 25px;
  }
}

@media (min-width: 576px) {
  .pt-sm--30 {
    padding-top: 30px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--30 {
    padding-top: 30px;
  }
}

@media (min-width: 576px) {
  .pt-sm--35 {
    padding-top: 35px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--35 {
    padding-top: 35px;
  }
}

@media (min-width: 576px) {
  .pt-sm--40 {
    padding-top: 40px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--40 {
    padding-top: 40px;
  }
}

@media (min-width: 576px) {
  .pt-sm--45 {
    padding-top: 45px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--45 {
    padding-top: 45px;
  }
}

@media (min-width: 576px) {
  .pt-sm--50 {
    padding-top: 50px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--50 {
    padding-top: 50px;
  }
}

@media (min-width: 576px) {
  .pt-sm--55 {
    padding-top: 55px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--55 {
    padding-top: 55px;
  }
}

@media (min-width: 576px) {
  .pt-sm--60 {
    padding-top: 60px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--60 {
    padding-top: 60px;
  }
}

@media (min-width: 576px) {
  .pt-sm--65 {
    padding-top: 65px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--65 {
    padding-top: 65px;
  }
}

@media (min-width: 576px) {
  .pt-sm--70 {
    padding-top: 70px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--70 {
    padding-top: 70px;
  }
}

@media (min-width: 576px) {
  .pt-sm--75 {
    padding-top: 75px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--75 {
    padding-top: 75px;
  }
}

@media (min-width: 576px) {
  .pt-sm--80 {
    padding-top: 80px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--80 {
    padding-top: 80px;
  }
}

@media (min-width: 576px) {
  .pt-sm--85 {
    padding-top: 85px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--85 {
    padding-top: 85px;
  }
}

@media (min-width: 576px) {
  .pt-sm--90 {
    padding-top: 90px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--90 {
    padding-top: 90px;
  }
}

@media (min-width: 576px) {
  .pt-sm--95 {
    padding-top: 95px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--95 {
    padding-top: 95px;
  }
}

@media (min-width: 576px) {
  .pt-sm--100 {
    padding-top: 100px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--100 {
    padding-top: 100px;
  }
}

@media (min-width: 576px) {
  .pt-sm--105 {
    padding-top: 105px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--105 {
    padding-top: 105px;
  }
}

@media (min-width: 576px) {
  .pt-sm--110 {
    padding-top: 110px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--110 {
    padding-top: 110px;
  }
}

@media (min-width: 576px) {
  .pt-sm--115 {
    padding-top: 115px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--115 {
    padding-top: 115px;
  }
}

@media (min-width: 576px) {
  .pt-sm--120 {
    padding-top: 120px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--120 {
    padding-top: 120px;
  }
}

@media (min-width: 576px) {
  .pt-sm--125 {
    padding-top: 125px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--125 {
    padding-top: 125px;
  }
}

@media (min-width: 576px) {
  .pt-sm--130 {
    padding-top: 130px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--130 {
    padding-top: 130px;
  }
}

@media (min-width: 576px) {
  .pt-sm--135 {
    padding-top: 135px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--135 {
    padding-top: 135px;
  }
}

@media (min-width: 576px) {
  .pt-sm--140 {
    padding-top: 140px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--140 {
    padding-top: 140px;
  }
}

@media (min-width: 576px) {
  .pt-sm--145 {
    padding-top: 145px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--145 {
    padding-top: 145px;
  }
}

@media (min-width: 576px) {
  .pt-sm--150 {
    padding-top: 150px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--150 {
    padding-top: 150px;
  }
}

@media (min-width: 576px) {
  .pt-sm--155 {
    padding-top: 155px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--155 {
    padding-top: 155px;
  }
}

@media (min-width: 576px) {
  .pt-sm--160 {
    padding-top: 160px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--160 {
    padding-top: 160px;
  }
}

@media (min-width: 576px) {
  .pt-sm--165 {
    padding-top: 165px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--165 {
    padding-top: 165px;
  }
}

@media (min-width: 576px) {
  .pt-sm--170 {
    padding-top: 170px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--170 {
    padding-top: 170px;
  }
}

@media (min-width: 576px) {
  .pt-sm--175 {
    padding-top: 175px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--175 {
    padding-top: 175px;
  }
}

@media (min-width: 576px) {
  .pt-sm--180 {
    padding-top: 180px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--180 {
    padding-top: 180px;
  }
}

@media (min-width: 576px) {
  .pt-sm--185 {
    padding-top: 185px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--185 {
    padding-top: 185px;
  }
}

@media (min-width: 576px) {
  .pt-sm--190 {
    padding-top: 190px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--190 {
    padding-top: 190px;
  }
}

@media (min-width: 576px) {
  .pt-sm--195 {
    padding-top: 195px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--195 {
    padding-top: 195px;
  }
}

@media (min-width: 576px) {
  .pt-sm--200 {
    padding-top: 200px !important;
  }
}

@media (min-width: 768px) {
  .pt-sm--200 {
    padding-top: 200px;
  }
}

@media (min-width: 768px) {
  .pt-md--0 {
    padding-top: 0px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--0 {
    padding-top: 0px;
  }
}

@media (min-width: 768px) {
  .pt-md--5 {
    padding-top: 5px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--5 {
    padding-top: 5px;
  }
}

@media (min-width: 768px) {
  .pt-md--10 {
    padding-top: 10px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--10 {
    padding-top: 10px;
  }
}

@media (min-width: 768px) {
  .pt-md--15 {
    padding-top: 15px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--15 {
    padding-top: 15px;
  }
}

@media (min-width: 768px) {
  .pt-md--20 {
    padding-top: 20px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--20 {
    padding-top: 20px;
  }
}

@media (min-width: 768px) {
  .pt-md--25 {
    padding-top: 25px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--25 {
    padding-top: 25px;
  }
}

@media (min-width: 768px) {
  .pt-md--30 {
    padding-top: 30px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--30 {
    padding-top: 30px;
  }
}

@media (min-width: 768px) {
  .pt-md--35 {
    padding-top: 35px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--35 {
    padding-top: 35px;
  }
}

@media (min-width: 768px) {
  .pt-md--40 {
    padding-top: 40px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--40 {
    padding-top: 40px;
  }
}

@media (min-width: 768px) {
  .pt-md--45 {
    padding-top: 45px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--45 {
    padding-top: 45px;
  }
}

@media (min-width: 768px) {
  .pt-md--50 {
    padding-top: 50px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--50 {
    padding-top: 50px;
  }
}

@media (min-width: 768px) {
  .pt-md--55 {
    padding-top: 55px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--55 {
    padding-top: 55px;
  }
}

@media (min-width: 768px) {
  .pt-md--60 {
    padding-top: 60px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--60 {
    padding-top: 60px;
  }
}

@media (min-width: 768px) {
  .pt-md--65 {
    padding-top: 65px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--65 {
    padding-top: 65px;
  }
}

@media (min-width: 768px) {
  .pt-md--70 {
    padding-top: 70px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--70 {
    padding-top: 70px;
  }
}

@media (min-width: 768px) {
  .pt-md--75 {
    padding-top: 75px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--75 {
    padding-top: 75px;
  }
}

@media (min-width: 768px) {
  .pt-md--80 {
    padding-top: 80px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--80 {
    padding-top: 80px;
  }
}

@media (min-width: 768px) {
  .pt-md--85 {
    padding-top: 85px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--85 {
    padding-top: 85px;
  }
}

@media (min-width: 768px) {
  .pt-md--90 {
    padding-top: 90px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--90 {
    padding-top: 90px;
  }
}

@media (min-width: 768px) {
  .pt-md--95 {
    padding-top: 95px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--95 {
    padding-top: 95px;
  }
}

@media (min-width: 768px) {
  .pt-md--100 {
    padding-top: 100px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--100 {
    padding-top: 100px;
  }
}

@media (min-width: 768px) {
  .pt-md--105 {
    padding-top: 105px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--105 {
    padding-top: 105px;
  }
}

@media (min-width: 768px) {
  .pt-md--110 {
    padding-top: 110px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--110 {
    padding-top: 110px;
  }
}

@media (min-width: 768px) {
  .pt-md--115 {
    padding-top: 115px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--115 {
    padding-top: 115px;
  }
}

@media (min-width: 768px) {
  .pt-md--120 {
    padding-top: 120px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--120 {
    padding-top: 120px;
  }
}

@media (min-width: 768px) {
  .pt-md--125 {
    padding-top: 125px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--125 {
    padding-top: 125px;
  }
}

@media (min-width: 768px) {
  .pt-md--130 {
    padding-top: 130px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--130 {
    padding-top: 130px;
  }
}

@media (min-width: 768px) {
  .pt-md--135 {
    padding-top: 135px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--135 {
    padding-top: 135px;
  }
}

@media (min-width: 768px) {
  .pt-md--140 {
    padding-top: 140px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--140 {
    padding-top: 140px;
  }
}

@media (min-width: 768px) {
  .pt-md--145 {
    padding-top: 145px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--145 {
    padding-top: 145px;
  }
}

@media (min-width: 768px) {
  .pt-md--150 {
    padding-top: 150px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--150 {
    padding-top: 150px;
  }
}

@media (min-width: 768px) {
  .pt-md--155 {
    padding-top: 155px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--155 {
    padding-top: 155px;
  }
}

@media (min-width: 768px) {
  .pt-md--160 {
    padding-top: 160px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--160 {
    padding-top: 160px;
  }
}

@media (min-width: 768px) {
  .pt-md--165 {
    padding-top: 165px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--165 {
    padding-top: 165px;
  }
}

@media (min-width: 768px) {
  .pt-md--170 {
    padding-top: 170px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--170 {
    padding-top: 170px;
  }
}

@media (min-width: 768px) {
  .pt-md--175 {
    padding-top: 175px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--175 {
    padding-top: 175px;
  }
}

@media (min-width: 768px) {
  .pt-md--180 {
    padding-top: 180px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--180 {
    padding-top: 180px;
  }
}

@media (min-width: 768px) {
  .pt-md--185 {
    padding-top: 185px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--185 {
    padding-top: 185px;
  }
}

@media (min-width: 768px) {
  .pt-md--190 {
    padding-top: 190px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--190 {
    padding-top: 190px;
  }
}

@media (min-width: 768px) {
  .pt-md--195 {
    padding-top: 195px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--195 {
    padding-top: 195px;
  }
}

@media (min-width: 768px) {
  .pt-md--200 {
    padding-top: 200px !important;
  }
}

@media (min-width: 992px) {
  .pt-md--200 {
    padding-top: 200px;
  }
}

@media (min-width: 992px) {
  .pt-lg--0 {
    padding-top: 0px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--0 {
    padding-top: 0px;
  }
}

@media (min-width: 992px) {
  .pt-lg--5 {
    padding-top: 5px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--5 {
    padding-top: 5px;
  }
}

@media (min-width: 992px) {
  .pt-lg--10 {
    padding-top: 10px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--10 {
    padding-top: 10px;
  }
}

@media (min-width: 992px) {
  .pt-lg--15 {
    padding-top: 15px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--15 {
    padding-top: 15px;
  }
}

@media (min-width: 992px) {
  .pt-lg--20 {
    padding-top: 20px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--20 {
    padding-top: 20px;
  }
}

@media (min-width: 992px) {
  .pt-lg--25 {
    padding-top: 25px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--25 {
    padding-top: 25px;
  }
}

@media (min-width: 992px) {
  .pt-lg--30 {
    padding-top: 30px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--30 {
    padding-top: 30px;
  }
}

@media (min-width: 992px) {
  .pt-lg--35 {
    padding-top: 35px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--35 {
    padding-top: 35px;
  }
}

@media (min-width: 992px) {
  .pt-lg--40 {
    padding-top: 40px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--40 {
    padding-top: 40px;
  }
}

@media (min-width: 992px) {
  .pt-lg--45 {
    padding-top: 45px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--45 {
    padding-top: 45px;
  }
}

@media (min-width: 992px) {
  .pt-lg--50 {
    padding-top: 50px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--50 {
    padding-top: 50px;
  }
}

@media (min-width: 992px) {
  .pt-lg--55 {
    padding-top: 55px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--55 {
    padding-top: 55px;
  }
}

@media (min-width: 992px) {
  .pt-lg--60 {
    padding-top: 60px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--60 {
    padding-top: 60px;
  }
}

@media (min-width: 992px) {
  .pt-lg--65 {
    padding-top: 65px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--65 {
    padding-top: 65px;
  }
}

@media (min-width: 992px) {
  .pt-lg--70 {
    padding-top: 70px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--70 {
    padding-top: 70px;
  }
}

@media (min-width: 992px) {
  .pt-lg--75 {
    padding-top: 75px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--75 {
    padding-top: 75px;
  }
}

@media (min-width: 992px) {
  .pt-lg--80 {
    padding-top: 80px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--80 {
    padding-top: 80px;
  }
}

@media (min-width: 992px) {
  .pt-lg--85 {
    padding-top: 85px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--85 {
    padding-top: 85px;
  }
}

@media (min-width: 992px) {
  .pt-lg--90 {
    padding-top: 90px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--90 {
    padding-top: 90px;
  }
}

@media (min-width: 992px) {
  .pt-lg--95 {
    padding-top: 95px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--95 {
    padding-top: 95px;
  }
}

@media (min-width: 992px) {
  .pt-lg--100 {
    padding-top: 100px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--100 {
    padding-top: 100px;
  }
}

@media (min-width: 992px) {
  .pt-lg--105 {
    padding-top: 105px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--105 {
    padding-top: 105px;
  }
}

@media (min-width: 992px) {
  .pt-lg--110 {
    padding-top: 110px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--110 {
    padding-top: 110px;
  }
}

@media (min-width: 992px) {
  .pt-lg--115 {
    padding-top: 115px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--115 {
    padding-top: 115px;
  }
}

@media (min-width: 992px) {
  .pt-lg--120 {
    padding-top: 120px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--120 {
    padding-top: 120px;
  }
}

@media (min-width: 992px) {
  .pt-lg--125 {
    padding-top: 125px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--125 {
    padding-top: 125px;
  }
}

@media (min-width: 992px) {
  .pt-lg--130 {
    padding-top: 130px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--130 {
    padding-top: 130px;
  }
}

@media (min-width: 992px) {
  .pt-lg--135 {
    padding-top: 135px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--135 {
    padding-top: 135px;
  }
}

@media (min-width: 992px) {
  .pt-lg--140 {
    padding-top: 140px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--140 {
    padding-top: 140px;
  }
}

@media (min-width: 992px) {
  .pt-lg--145 {
    padding-top: 145px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--145 {
    padding-top: 145px;
  }
}

@media (min-width: 992px) {
  .pt-lg--150 {
    padding-top: 150px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--150 {
    padding-top: 150px;
  }
}

@media (min-width: 992px) {
  .pt-lg--155 {
    padding-top: 155px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--155 {
    padding-top: 155px;
  }
}

@media (min-width: 992px) {
  .pt-lg--160 {
    padding-top: 160px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--160 {
    padding-top: 160px;
  }
}

@media (min-width: 992px) {
  .pt-lg--165 {
    padding-top: 165px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--165 {
    padding-top: 165px;
  }
}

@media (min-width: 992px) {
  .pt-lg--170 {
    padding-top: 170px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--170 {
    padding-top: 170px;
  }
}

@media (min-width: 992px) {
  .pt-lg--175 {
    padding-top: 175px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--175 {
    padding-top: 175px;
  }
}

@media (min-width: 992px) {
  .pt-lg--180 {
    padding-top: 180px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--180 {
    padding-top: 180px;
  }
}

@media (min-width: 992px) {
  .pt-lg--185 {
    padding-top: 185px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--185 {
    padding-top: 185px;
  }
}

@media (min-width: 992px) {
  .pt-lg--190 {
    padding-top: 190px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--190 {
    padding-top: 190px;
  }
}

@media (min-width: 992px) {
  .pt-lg--195 {
    padding-top: 195px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--195 {
    padding-top: 195px;
  }
}

@media (min-width: 992px) {
  .pt-lg--200 {
    padding-top: 200px !important;
  }
}

@media (min-width: 1200px) {
  .pt-lg--200 {
    padding-top: 200px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--0 {
    padding-top: 0px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--0 {
    padding-top: 0px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--5 {
    padding-top: 5px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--5 {
    padding-top: 5px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--10 {
    padding-top: 10px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--10 {
    padding-top: 10px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--15 {
    padding-top: 15px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--15 {
    padding-top: 15px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--20 {
    padding-top: 20px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--20 {
    padding-top: 20px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--25 {
    padding-top: 25px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--25 {
    padding-top: 25px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--30 {
    padding-top: 30px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--30 {
    padding-top: 30px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--35 {
    padding-top: 35px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--35 {
    padding-top: 35px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--40 {
    padding-top: 40px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--40 {
    padding-top: 40px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--45 {
    padding-top: 45px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--45 {
    padding-top: 45px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--50 {
    padding-top: 50px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--50 {
    padding-top: 50px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--55 {
    padding-top: 55px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--55 {
    padding-top: 55px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--60 {
    padding-top: 60px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--60 {
    padding-top: 60px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--65 {
    padding-top: 65px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--65 {
    padding-top: 65px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--70 {
    padding-top: 70px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--70 {
    padding-top: 70px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--75 {
    padding-top: 75px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--75 {
    padding-top: 75px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--80 {
    padding-top: 80px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--80 {
    padding-top: 80px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--85 {
    padding-top: 85px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--85 {
    padding-top: 85px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--90 {
    padding-top: 90px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--90 {
    padding-top: 90px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--95 {
    padding-top: 95px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--95 {
    padding-top: 95px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--100 {
    padding-top: 100px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--100 {
    padding-top: 100px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--105 {
    padding-top: 105px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--105 {
    padding-top: 105px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--110 {
    padding-top: 110px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--110 {
    padding-top: 110px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--115 {
    padding-top: 115px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--115 {
    padding-top: 115px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--120 {
    padding-top: 120px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--120 {
    padding-top: 120px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--125 {
    padding-top: 125px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--125 {
    padding-top: 125px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--130 {
    padding-top: 130px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--130 {
    padding-top: 130px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--135 {
    padding-top: 135px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--135 {
    padding-top: 135px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--140 {
    padding-top: 140px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--140 {
    padding-top: 140px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--145 {
    padding-top: 145px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--145 {
    padding-top: 145px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--150 {
    padding-top: 150px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--150 {
    padding-top: 150px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--155 {
    padding-top: 155px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--155 {
    padding-top: 155px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--160 {
    padding-top: 160px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--160 {
    padding-top: 160px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--165 {
    padding-top: 165px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--165 {
    padding-top: 165px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--170 {
    padding-top: 170px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--170 {
    padding-top: 170px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--175 {
    padding-top: 175px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--175 {
    padding-top: 175px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--180 {
    padding-top: 180px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--180 {
    padding-top: 180px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--185 {
    padding-top: 185px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--185 {
    padding-top: 185px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--190 {
    padding-top: 190px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--190 {
    padding-top: 190px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--195 {
    padding-top: 195px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--195 {
    padding-top: 195px;
  }
}

@media (min-width: 1200px) {
  .pt-xl--200 {
    padding-top: 200px !important;
  }
}

@media (min-width: 1600px) {
  .pt-xl--200 {
    padding-top: 200px;
  }
}

/*-- Responsive Padding bottom --*/
@media (min-width: 576px) {
  .pb-sm--0 {
    padding-bottom: 0px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--0 {
    padding-bottom: 0px;
  }
}

@media (min-width: 576px) {
  .pb-sm--5 {
    padding-bottom: 5px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--5 {
    padding-bottom: 5px;
  }
}

@media (min-width: 576px) {
  .pb-sm--10 {
    padding-bottom: 10px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--10 {
    padding-bottom: 10px;
  }
}

@media (min-width: 576px) {
  .pb-sm--15 {
    padding-bottom: 15px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--15 {
    padding-bottom: 15px;
  }
}

@media (min-width: 576px) {
  .pb-sm--20 {
    padding-bottom: 20px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--20 {
    padding-bottom: 20px;
  }
}

@media (min-width: 576px) {
  .pb-sm--25 {
    padding-bottom: 25px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--25 {
    padding-bottom: 25px;
  }
}

@media (min-width: 576px) {
  .pb-sm--30 {
    padding-bottom: 30px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--30 {
    padding-bottom: 30px;
  }
}

@media (min-width: 576px) {
  .pb-sm--35 {
    padding-bottom: 35px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--35 {
    padding-bottom: 35px;
  }
}

@media (min-width: 576px) {
  .pb-sm--40 {
    padding-bottom: 40px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--40 {
    padding-bottom: 40px;
  }
}

@media (min-width: 576px) {
  .pb-sm--45 {
    padding-bottom: 45px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--45 {
    padding-bottom: 45px;
  }
}

@media (min-width: 576px) {
  .pb-sm--50 {
    padding-bottom: 50px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--50 {
    padding-bottom: 50px;
  }
}

@media (min-width: 576px) {
  .pb-sm--55 {
    padding-bottom: 55px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--55 {
    padding-bottom: 55px;
  }
}

@media (min-width: 576px) {
  .pb-sm--60 {
    padding-bottom: 60px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--60 {
    padding-bottom: 60px;
  }
}

@media (min-width: 576px) {
  .pb-sm--65 {
    padding-bottom: 65px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--65 {
    padding-bottom: 65px;
  }
}

@media (min-width: 576px) {
  .pb-sm--70 {
    padding-bottom: 70px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--70 {
    padding-bottom: 70px;
  }
}

@media (min-width: 576px) {
  .pb-sm--75 {
    padding-bottom: 75px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--75 {
    padding-bottom: 75px;
  }
}

@media (min-width: 576px) {
  .pb-sm--80 {
    padding-bottom: 80px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--80 {
    padding-bottom: 80px;
  }
}

@media (min-width: 576px) {
  .pb-sm--85 {
    padding-bottom: 85px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--85 {
    padding-bottom: 85px;
  }
}

@media (min-width: 576px) {
  .pb-sm--90 {
    padding-bottom: 90px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--90 {
    padding-bottom: 90px;
  }
}

@media (min-width: 576px) {
  .pb-sm--95 {
    padding-bottom: 95px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--95 {
    padding-bottom: 95px;
  }
}

@media (min-width: 576px) {
  .pb-sm--100 {
    padding-bottom: 100px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--100 {
    padding-bottom: 100px;
  }
}

@media (min-width: 576px) {
  .pb-sm--105 {
    padding-bottom: 105px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--105 {
    padding-bottom: 105px;
  }
}

@media (min-width: 576px) {
  .pb-sm--110 {
    padding-bottom: 110px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--110 {
    padding-bottom: 110px;
  }
}

@media (min-width: 576px) {
  .pb-sm--115 {
    padding-bottom: 115px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--115 {
    padding-bottom: 115px;
  }
}

@media (min-width: 576px) {
  .pb-sm--120 {
    padding-bottom: 120px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--120 {
    padding-bottom: 120px;
  }
}

@media (min-width: 576px) {
  .pb-sm--125 {
    padding-bottom: 125px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--125 {
    padding-bottom: 125px;
  }
}

@media (min-width: 576px) {
  .pb-sm--130 {
    padding-bottom: 130px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--130 {
    padding-bottom: 130px;
  }
}

@media (min-width: 576px) {
  .pb-sm--135 {
    padding-bottom: 135px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--135 {
    padding-bottom: 135px;
  }
}

@media (min-width: 576px) {
  .pb-sm--140 {
    padding-bottom: 140px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--140 {
    padding-bottom: 140px;
  }
}

@media (min-width: 576px) {
  .pb-sm--145 {
    padding-bottom: 145px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--145 {
    padding-bottom: 145px;
  }
}

@media (min-width: 576px) {
  .pb-sm--150 {
    padding-bottom: 150px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--150 {
    padding-bottom: 150px;
  }
}

@media (min-width: 576px) {
  .pb-sm--155 {
    padding-bottom: 155px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--155 {
    padding-bottom: 155px;
  }
}

@media (min-width: 576px) {
  .pb-sm--160 {
    padding-bottom: 160px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--160 {
    padding-bottom: 160px;
  }
}

@media (min-width: 576px) {
  .pb-sm--165 {
    padding-bottom: 165px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--165 {
    padding-bottom: 165px;
  }
}

@media (min-width: 576px) {
  .pb-sm--170 {
    padding-bottom: 170px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--170 {
    padding-bottom: 170px;
  }
}

@media (min-width: 576px) {
  .pb-sm--175 {
    padding-bottom: 175px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--175 {
    padding-bottom: 175px;
  }
}

@media (min-width: 576px) {
  .pb-sm--180 {
    padding-bottom: 180px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--180 {
    padding-bottom: 180px;
  }
}

@media (min-width: 576px) {
  .pb-sm--185 {
    padding-bottom: 185px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--185 {
    padding-bottom: 185px;
  }
}

@media (min-width: 576px) {
  .pb-sm--190 {
    padding-bottom: 190px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--190 {
    padding-bottom: 190px;
  }
}

@media (min-width: 576px) {
  .pb-sm--195 {
    padding-bottom: 195px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--195 {
    padding-bottom: 195px;
  }
}

@media (min-width: 576px) {
  .pb-sm--200 {
    padding-bottom: 200px !important;
  }
}

@media (min-width: 768px) {
  .pb-sm--200 {
    padding-bottom: 200px;
  }
}

@media (min-width: 768px) {
  .pb-md--0 {
    padding-bottom: 0px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--0 {
    padding-bottom: 0px;
  }
}

@media (min-width: 768px) {
  .pb-md--5 {
    padding-bottom: 5px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--5 {
    padding-bottom: 5px;
  }
}

@media (min-width: 768px) {
  .pb-md--10 {
    padding-bottom: 10px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--10 {
    padding-bottom: 10px;
  }
}

@media (min-width: 768px) {
  .pb-md--15 {
    padding-bottom: 15px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--15 {
    padding-bottom: 15px;
  }
}

@media (min-width: 768px) {
  .pb-md--20 {
    padding-bottom: 20px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--20 {
    padding-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .pb-md--25 {
    padding-bottom: 25px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--25 {
    padding-bottom: 25px;
  }
}

@media (min-width: 768px) {
  .pb-md--30 {
    padding-bottom: 30px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--30 {
    padding-bottom: 30px;
  }
}

@media (min-width: 768px) {
  .pb-md--35 {
    padding-bottom: 35px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--35 {
    padding-bottom: 35px;
  }
}

@media (min-width: 768px) {
  .pb-md--40 {
    padding-bottom: 40px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--40 {
    padding-bottom: 40px;
  }
}

@media (min-width: 768px) {
  .pb-md--45 {
    padding-bottom: 45px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--45 {
    padding-bottom: 45px;
  }
}

@media (min-width: 768px) {
  .pb-md--50 {
    padding-bottom: 50px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--50 {
    padding-bottom: 50px;
  }
}

@media (min-width: 768px) {
  .pb-md--55 {
    padding-bottom: 55px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--55 {
    padding-bottom: 55px;
  }
}

@media (min-width: 768px) {
  .pb-md--60 {
    padding-bottom: 60px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--60 {
    padding-bottom: 60px;
  }
}

@media (min-width: 768px) {
  .pb-md--65 {
    padding-bottom: 65px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--65 {
    padding-bottom: 65px;
  }
}

@media (min-width: 768px) {
  .pb-md--70 {
    padding-bottom: 70px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--70 {
    padding-bottom: 70px;
  }
}

@media (min-width: 768px) {
  .pb-md--75 {
    padding-bottom: 75px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--75 {
    padding-bottom: 75px;
  }
}

@media (min-width: 768px) {
  .pb-md--80 {
    padding-bottom: 80px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--80 {
    padding-bottom: 80px;
  }
}

@media (min-width: 768px) {
  .pb-md--85 {
    padding-bottom: 85px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--85 {
    padding-bottom: 85px;
  }
}

@media (min-width: 768px) {
  .pb-md--90 {
    padding-bottom: 90px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--90 {
    padding-bottom: 90px;
  }
}

@media (min-width: 768px) {
  .pb-md--95 {
    padding-bottom: 95px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--95 {
    padding-bottom: 95px;
  }
}

@media (min-width: 768px) {
  .pb-md--100 {
    padding-bottom: 100px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--100 {
    padding-bottom: 100px;
  }
}

@media (min-width: 768px) {
  .pb-md--105 {
    padding-bottom: 105px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--105 {
    padding-bottom: 105px;
  }
}

@media (min-width: 768px) {
  .pb-md--110 {
    padding-bottom: 110px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--110 {
    padding-bottom: 110px;
  }
}

@media (min-width: 768px) {
  .pb-md--115 {
    padding-bottom: 115px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--115 {
    padding-bottom: 115px;
  }
}

@media (min-width: 768px) {
  .pb-md--120 {
    padding-bottom: 120px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--120 {
    padding-bottom: 120px;
  }
}

@media (min-width: 768px) {
  .pb-md--125 {
    padding-bottom: 125px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--125 {
    padding-bottom: 125px;
  }
}

@media (min-width: 768px) {
  .pb-md--130 {
    padding-bottom: 130px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--130 {
    padding-bottom: 130px;
  }
}

@media (min-width: 768px) {
  .pb-md--135 {
    padding-bottom: 135px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--135 {
    padding-bottom: 135px;
  }
}

@media (min-width: 768px) {
  .pb-md--140 {
    padding-bottom: 140px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--140 {
    padding-bottom: 140px;
  }
}

@media (min-width: 768px) {
  .pb-md--145 {
    padding-bottom: 145px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--145 {
    padding-bottom: 145px;
  }
}

@media (min-width: 768px) {
  .pb-md--150 {
    padding-bottom: 150px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--150 {
    padding-bottom: 150px;
  }
}

@media (min-width: 768px) {
  .pb-md--155 {
    padding-bottom: 155px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--155 {
    padding-bottom: 155px;
  }
}

@media (min-width: 768px) {
  .pb-md--160 {
    padding-bottom: 160px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--160 {
    padding-bottom: 160px;
  }
}

@media (min-width: 768px) {
  .pb-md--165 {
    padding-bottom: 165px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--165 {
    padding-bottom: 165px;
  }
}

@media (min-width: 768px) {
  .pb-md--170 {
    padding-bottom: 170px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--170 {
    padding-bottom: 170px;
  }
}

@media (min-width: 768px) {
  .pb-md--175 {
    padding-bottom: 175px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--175 {
    padding-bottom: 175px;
  }
}

@media (min-width: 768px) {
  .pb-md--180 {
    padding-bottom: 180px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--180 {
    padding-bottom: 180px;
  }
}

@media (min-width: 768px) {
  .pb-md--185 {
    padding-bottom: 185px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--185 {
    padding-bottom: 185px;
  }
}

@media (min-width: 768px) {
  .pb-md--190 {
    padding-bottom: 190px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--190 {
    padding-bottom: 190px;
  }
}

@media (min-width: 768px) {
  .pb-md--195 {
    padding-bottom: 195px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--195 {
    padding-bottom: 195px;
  }
}

@media (min-width: 768px) {
  .pb-md--200 {
    padding-bottom: 200px !important;
  }
}

@media (min-width: 992px) {
  .pb-md--200 {
    padding-bottom: 200px;
  }
}

@media (min-width: 992px) {
  .pb-lg--0 {
    padding-bottom: 0px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--0 {
    padding-bottom: 0px;
  }
}

@media (min-width: 992px) {
  .pb-lg--5 {
    padding-bottom: 5px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--5 {
    padding-bottom: 5px;
  }
}

@media (min-width: 992px) {
  .pb-lg--10 {
    padding-bottom: 10px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--10 {
    padding-bottom: 10px;
  }
}

@media (min-width: 992px) {
  .pb-lg--15 {
    padding-bottom: 15px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--15 {
    padding-bottom: 15px;
  }
}

@media (min-width: 992px) {
  .pb-lg--20 {
    padding-bottom: 20px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--20 {
    padding-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .pb-lg--25 {
    padding-bottom: 25px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--25 {
    padding-bottom: 25px;
  }
}

@media (min-width: 992px) {
  .pb-lg--30 {
    padding-bottom: 30px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--30 {
    padding-bottom: 30px;
  }
}

@media (min-width: 992px) {
  .pb-lg--35 {
    padding-bottom: 35px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--35 {
    padding-bottom: 35px;
  }
}

@media (min-width: 992px) {
  .pb-lg--40 {
    padding-bottom: 40px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--40 {
    padding-bottom: 40px;
  }
}

@media (min-width: 992px) {
  .pb-lg--45 {
    padding-bottom: 45px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--45 {
    padding-bottom: 45px;
  }
}

@media (min-width: 992px) {
  .pb-lg--50 {
    padding-bottom: 50px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--50 {
    padding-bottom: 50px;
  }
}

@media (min-width: 992px) {
  .pb-lg--55 {
    padding-bottom: 55px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--55 {
    padding-bottom: 55px;
  }
}

@media (min-width: 992px) {
  .pb-lg--60 {
    padding-bottom: 60px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--60 {
    padding-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .pb-lg--65 {
    padding-bottom: 65px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--65 {
    padding-bottom: 65px;
  }
}

@media (min-width: 992px) {
  .pb-lg--70 {
    padding-bottom: 70px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--70 {
    padding-bottom: 70px;
  }
}

@media (min-width: 992px) {
  .pb-lg--75 {
    padding-bottom: 75px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--75 {
    padding-bottom: 75px;
  }
}

@media (min-width: 992px) {
  .pb-lg--80 {
    padding-bottom: 80px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--80 {
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .pb-lg--85 {
    padding-bottom: 85px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--85 {
    padding-bottom: 85px;
  }
}

@media (min-width: 992px) {
  .pb-lg--90 {
    padding-bottom: 90px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--90 {
    padding-bottom: 90px;
  }
}

@media (min-width: 992px) {
  .pb-lg--95 {
    padding-bottom: 95px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--95 {
    padding-bottom: 95px;
  }
}

@media (min-width: 992px) {
  .pb-lg--100 {
    padding-bottom: 100px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--100 {
    padding-bottom: 100px;
  }
}

@media (min-width: 992px) {
  .pb-lg--105 {
    padding-bottom: 105px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--105 {
    padding-bottom: 105px;
  }
}

@media (min-width: 992px) {
  .pb-lg--110 {
    padding-bottom: 110px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--110 {
    padding-bottom: 110px;
  }
}

@media (min-width: 992px) {
  .pb-lg--115 {
    padding-bottom: 115px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--115 {
    padding-bottom: 115px;
  }
}

@media (min-width: 992px) {
  .pb-lg--120 {
    padding-bottom: 120px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--120 {
    padding-bottom: 120px;
  }
}

@media (min-width: 992px) {
  .pb-lg--125 {
    padding-bottom: 125px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--125 {
    padding-bottom: 125px;
  }
}

@media (min-width: 992px) {
  .pb-lg--130 {
    padding-bottom: 130px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--130 {
    padding-bottom: 130px;
  }
}

@media (min-width: 992px) {
  .pb-lg--135 {
    padding-bottom: 135px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--135 {
    padding-bottom: 135px;
  }
}

@media (min-width: 992px) {
  .pb-lg--140 {
    padding-bottom: 140px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--140 {
    padding-bottom: 140px;
  }
}

@media (min-width: 992px) {
  .pb-lg--145 {
    padding-bottom: 145px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--145 {
    padding-bottom: 145px;
  }
}

@media (min-width: 992px) {
  .pb-lg--150 {
    padding-bottom: 150px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--150 {
    padding-bottom: 150px;
  }
}

@media (min-width: 992px) {
  .pb-lg--155 {
    padding-bottom: 155px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--155 {
    padding-bottom: 155px;
  }
}

@media (min-width: 992px) {
  .pb-lg--160 {
    padding-bottom: 160px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--160 {
    padding-bottom: 160px;
  }
}

@media (min-width: 992px) {
  .pb-lg--165 {
    padding-bottom: 165px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--165 {
    padding-bottom: 165px;
  }
}

@media (min-width: 992px) {
  .pb-lg--170 {
    padding-bottom: 170px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--170 {
    padding-bottom: 170px;
  }
}

@media (min-width: 992px) {
  .pb-lg--175 {
    padding-bottom: 175px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--175 {
    padding-bottom: 175px;
  }
}

@media (min-width: 992px) {
  .pb-lg--180 {
    padding-bottom: 180px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--180 {
    padding-bottom: 180px;
  }
}

@media (min-width: 992px) {
  .pb-lg--185 {
    padding-bottom: 185px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--185 {
    padding-bottom: 185px;
  }
}

@media (min-width: 992px) {
  .pb-lg--190 {
    padding-bottom: 190px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--190 {
    padding-bottom: 190px;
  }
}

@media (min-width: 992px) {
  .pb-lg--195 {
    padding-bottom: 195px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--195 {
    padding-bottom: 195px;
  }
}

@media (min-width: 992px) {
  .pb-lg--200 {
    padding-bottom: 200px !important;
  }
}

@media (min-width: 1200px) {
  .pb-lg--200 {
    padding-bottom: 200px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--0 {
    padding-bottom: 0px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--0 {
    padding-bottom: 0px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--5 {
    padding-bottom: 5px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--5 {
    padding-bottom: 5px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--10 {
    padding-bottom: 10px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--10 {
    padding-bottom: 10px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--15 {
    padding-bottom: 15px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--15 {
    padding-bottom: 15px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--20 {
    padding-bottom: 20px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--20 {
    padding-bottom: 20px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--25 {
    padding-bottom: 25px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--25 {
    padding-bottom: 25px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--30 {
    padding-bottom: 30px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--30 {
    padding-bottom: 30px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--35 {
    padding-bottom: 35px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--35 {
    padding-bottom: 35px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--40 {
    padding-bottom: 40px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--40 {
    padding-bottom: 40px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--45 {
    padding-bottom: 45px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--45 {
    padding-bottom: 45px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--50 {
    padding-bottom: 50px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--50 {
    padding-bottom: 50px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--55 {
    padding-bottom: 55px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--55 {
    padding-bottom: 55px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--60 {
    padding-bottom: 60px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--60 {
    padding-bottom: 60px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--65 {
    padding-bottom: 65px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--65 {
    padding-bottom: 65px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--70 {
    padding-bottom: 70px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--70 {
    padding-bottom: 70px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--75 {
    padding-bottom: 75px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--75 {
    padding-bottom: 75px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--80 {
    padding-bottom: 80px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--80 {
    padding-bottom: 80px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--85 {
    padding-bottom: 85px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--85 {
    padding-bottom: 85px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--90 {
    padding-bottom: 90px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--90 {
    padding-bottom: 90px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--95 {
    padding-bottom: 95px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--95 {
    padding-bottom: 95px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--100 {
    padding-bottom: 100px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--100 {
    padding-bottom: 100px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--105 {
    padding-bottom: 105px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--105 {
    padding-bottom: 105px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--110 {
    padding-bottom: 110px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--110 {
    padding-bottom: 110px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--115 {
    padding-bottom: 115px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--115 {
    padding-bottom: 115px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--120 {
    padding-bottom: 120px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--120 {
    padding-bottom: 120px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--125 {
    padding-bottom: 125px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--125 {
    padding-bottom: 125px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--130 {
    padding-bottom: 130px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--130 {
    padding-bottom: 130px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--135 {
    padding-bottom: 135px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--135 {
    padding-bottom: 135px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--140 {
    padding-bottom: 140px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--140 {
    padding-bottom: 140px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--145 {
    padding-bottom: 145px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--145 {
    padding-bottom: 145px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--150 {
    padding-bottom: 150px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--150 {
    padding-bottom: 150px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--155 {
    padding-bottom: 155px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--155 {
    padding-bottom: 155px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--160 {
    padding-bottom: 160px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--160 {
    padding-bottom: 160px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--165 {
    padding-bottom: 165px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--165 {
    padding-bottom: 165px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--170 {
    padding-bottom: 170px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--170 {
    padding-bottom: 170px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--175 {
    padding-bottom: 175px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--175 {
    padding-bottom: 175px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--180 {
    padding-bottom: 180px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--180 {
    padding-bottom: 180px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--185 {
    padding-bottom: 185px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--185 {
    padding-bottom: 185px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--190 {
    padding-bottom: 190px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--190 {
    padding-bottom: 190px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--195 {
    padding-bottom: 195px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--195 {
    padding-bottom: 195px;
  }
}

@media (min-width: 1200px) {
  .pb-xl--200 {
    padding-bottom: 200px !important;
  }
}

@media (min-width: 1600px) {
  .pb-xl--200 {
    padding-bottom: 200px;
  }
}

/*-- Responsive Padding left --*/
@media (min-width: 576px) {
  .pl-sm--0 {
    padding-left: 0px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--0 {
    padding-left: 0px;
  }
}

@media (min-width: 576px) {
  .pl-sm--5 {
    padding-left: 5px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--5 {
    padding-left: 5px;
  }
}

@media (min-width: 576px) {
  .pl-sm--10 {
    padding-left: 10px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--10 {
    padding-left: 10px;
  }
}

@media (min-width: 576px) {
  .pl-sm--15 {
    padding-left: 15px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--15 {
    padding-left: 15px;
  }
}

@media (min-width: 576px) {
  .pl-sm--20 {
    padding-left: 20px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--20 {
    padding-left: 20px;
  }
}

@media (min-width: 576px) {
  .pl-sm--25 {
    padding-left: 25px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--25 {
    padding-left: 25px;
  }
}

@media (min-width: 576px) {
  .pl-sm--30 {
    padding-left: 30px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--30 {
    padding-left: 30px;
  }
}

@media (min-width: 576px) {
  .pl-sm--35 {
    padding-left: 35px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--35 {
    padding-left: 35px;
  }
}

@media (min-width: 576px) {
  .pl-sm--40 {
    padding-left: 40px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--40 {
    padding-left: 40px;
  }
}

@media (min-width: 576px) {
  .pl-sm--45 {
    padding-left: 45px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--45 {
    padding-left: 45px;
  }
}

@media (min-width: 576px) {
  .pl-sm--50 {
    padding-left: 50px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--50 {
    padding-left: 50px;
  }
}

@media (min-width: 576px) {
  .pl-sm--55 {
    padding-left: 55px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--55 {
    padding-left: 55px;
  }
}

@media (min-width: 576px) {
  .pl-sm--60 {
    padding-left: 60px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--60 {
    padding-left: 60px;
  }
}

@media (min-width: 576px) {
  .pl-sm--65 {
    padding-left: 65px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--65 {
    padding-left: 65px;
  }
}

@media (min-width: 576px) {
  .pl-sm--70 {
    padding-left: 70px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--70 {
    padding-left: 70px;
  }
}

@media (min-width: 576px) {
  .pl-sm--75 {
    padding-left: 75px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--75 {
    padding-left: 75px;
  }
}

@media (min-width: 576px) {
  .pl-sm--80 {
    padding-left: 80px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--80 {
    padding-left: 80px;
  }
}

@media (min-width: 576px) {
  .pl-sm--85 {
    padding-left: 85px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--85 {
    padding-left: 85px;
  }
}

@media (min-width: 576px) {
  .pl-sm--90 {
    padding-left: 90px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--90 {
    padding-left: 90px;
  }
}

@media (min-width: 576px) {
  .pl-sm--95 {
    padding-left: 95px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--95 {
    padding-left: 95px;
  }
}

@media (min-width: 576px) {
  .pl-sm--100 {
    padding-left: 100px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--100 {
    padding-left: 100px;
  }
}

@media (min-width: 576px) {
  .pl-sm--105 {
    padding-left: 105px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--105 {
    padding-left: 105px;
  }
}

@media (min-width: 576px) {
  .pl-sm--110 {
    padding-left: 110px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--110 {
    padding-left: 110px;
  }
}

@media (min-width: 576px) {
  .pl-sm--115 {
    padding-left: 115px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--115 {
    padding-left: 115px;
  }
}

@media (min-width: 576px) {
  .pl-sm--120 {
    padding-left: 120px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--120 {
    padding-left: 120px;
  }
}

@media (min-width: 576px) {
  .pl-sm--125 {
    padding-left: 125px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--125 {
    padding-left: 125px;
  }
}

@media (min-width: 576px) {
  .pl-sm--130 {
    padding-left: 130px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--130 {
    padding-left: 130px;
  }
}

@media (min-width: 576px) {
  .pl-sm--135 {
    padding-left: 135px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--135 {
    padding-left: 135px;
  }
}

@media (min-width: 576px) {
  .pl-sm--140 {
    padding-left: 140px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--140 {
    padding-left: 140px;
  }
}

@media (min-width: 576px) {
  .pl-sm--145 {
    padding-left: 145px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--145 {
    padding-left: 145px;
  }
}

@media (min-width: 576px) {
  .pl-sm--150 {
    padding-left: 150px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--150 {
    padding-left: 150px;
  }
}

@media (min-width: 576px) {
  .pl-sm--155 {
    padding-left: 155px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--155 {
    padding-left: 155px;
  }
}

@media (min-width: 576px) {
  .pl-sm--160 {
    padding-left: 160px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--160 {
    padding-left: 160px;
  }
}

@media (min-width: 576px) {
  .pl-sm--165 {
    padding-left: 165px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--165 {
    padding-left: 165px;
  }
}

@media (min-width: 576px) {
  .pl-sm--170 {
    padding-left: 170px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--170 {
    padding-left: 170px;
  }
}

@media (min-width: 576px) {
  .pl-sm--175 {
    padding-left: 175px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--175 {
    padding-left: 175px;
  }
}

@media (min-width: 576px) {
  .pl-sm--180 {
    padding-left: 180px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--180 {
    padding-left: 180px;
  }
}

@media (min-width: 576px) {
  .pl-sm--185 {
    padding-left: 185px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--185 {
    padding-left: 185px;
  }
}

@media (min-width: 576px) {
  .pl-sm--190 {
    padding-left: 190px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--190 {
    padding-left: 190px;
  }
}

@media (min-width: 576px) {
  .pl-sm--195 {
    padding-left: 195px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--195 {
    padding-left: 195px;
  }
}

@media (min-width: 576px) {
  .pl-sm--200 {
    padding-left: 200px !important;
  }
}

@media (min-width: 768px) {
  .pl-sm--200 {
    padding-left: 200px;
  }
}

@media (min-width: 768px) {
  .pl-md--0 {
    padding-left: 0px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--0 {
    padding-left: 0px;
  }
}

@media (min-width: 768px) {
  .pl-md--5 {
    padding-left: 5px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--5 {
    padding-left: 5px;
  }
}

@media (min-width: 768px) {
  .pl-md--10 {
    padding-left: 10px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--10 {
    padding-left: 10px;
  }
}

@media (min-width: 768px) {
  .pl-md--15 {
    padding-left: 15px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--15 {
    padding-left: 15px;
  }
}

@media (min-width: 768px) {
  .pl-md--20 {
    padding-left: 20px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--20 {
    padding-left: 20px;
  }
}

@media (min-width: 768px) {
  .pl-md--25 {
    padding-left: 25px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--25 {
    padding-left: 25px;
  }
}

@media (min-width: 768px) {
  .pl-md--30 {
    padding-left: 30px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--30 {
    padding-left: 30px;
  }
}

@media (min-width: 768px) {
  .pl-md--35 {
    padding-left: 35px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--35 {
    padding-left: 35px;
  }
}

@media (min-width: 768px) {
  .pl-md--40 {
    padding-left: 40px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--40 {
    padding-left: 40px;
  }
}

@media (min-width: 768px) {
  .pl-md--45 {
    padding-left: 45px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--45 {
    padding-left: 45px;
  }
}

@media (min-width: 768px) {
  .pl-md--50 {
    padding-left: 50px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--50 {
    padding-left: 50px;
  }
}

@media (min-width: 768px) {
  .pl-md--55 {
    padding-left: 55px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--55 {
    padding-left: 55px;
  }
}

@media (min-width: 768px) {
  .pl-md--60 {
    padding-left: 60px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--60 {
    padding-left: 60px;
  }
}

@media (min-width: 768px) {
  .pl-md--65 {
    padding-left: 65px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--65 {
    padding-left: 65px;
  }
}

@media (min-width: 768px) {
  .pl-md--70 {
    padding-left: 70px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--70 {
    padding-left: 70px;
  }
}

@media (min-width: 768px) {
  .pl-md--75 {
    padding-left: 75px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--75 {
    padding-left: 75px;
  }
}

@media (min-width: 768px) {
  .pl-md--80 {
    padding-left: 80px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--80 {
    padding-left: 80px;
  }
}

@media (min-width: 768px) {
  .pl-md--85 {
    padding-left: 85px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--85 {
    padding-left: 85px;
  }
}

@media (min-width: 768px) {
  .pl-md--90 {
    padding-left: 90px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--90 {
    padding-left: 90px;
  }
}

@media (min-width: 768px) {
  .pl-md--95 {
    padding-left: 95px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--95 {
    padding-left: 95px;
  }
}

@media (min-width: 768px) {
  .pl-md--100 {
    padding-left: 100px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--100 {
    padding-left: 100px;
  }
}

@media (min-width: 768px) {
  .pl-md--105 {
    padding-left: 105px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--105 {
    padding-left: 105px;
  }
}

@media (min-width: 768px) {
  .pl-md--110 {
    padding-left: 110px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--110 {
    padding-left: 110px;
  }
}

@media (min-width: 768px) {
  .pl-md--115 {
    padding-left: 115px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--115 {
    padding-left: 115px;
  }
}

@media (min-width: 768px) {
  .pl-md--120 {
    padding-left: 120px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--120 {
    padding-left: 120px;
  }
}

@media (min-width: 768px) {
  .pl-md--125 {
    padding-left: 125px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--125 {
    padding-left: 125px;
  }
}

@media (min-width: 768px) {
  .pl-md--130 {
    padding-left: 130px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--130 {
    padding-left: 130px;
  }
}

@media (min-width: 768px) {
  .pl-md--135 {
    padding-left: 135px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--135 {
    padding-left: 135px;
  }
}

@media (min-width: 768px) {
  .pl-md--140 {
    padding-left: 140px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--140 {
    padding-left: 140px;
  }
}

@media (min-width: 768px) {
  .pl-md--145 {
    padding-left: 145px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--145 {
    padding-left: 145px;
  }
}

@media (min-width: 768px) {
  .pl-md--150 {
    padding-left: 150px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--150 {
    padding-left: 150px;
  }
}

@media (min-width: 768px) {
  .pl-md--155 {
    padding-left: 155px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--155 {
    padding-left: 155px;
  }
}

@media (min-width: 768px) {
  .pl-md--160 {
    padding-left: 160px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--160 {
    padding-left: 160px;
  }
}

@media (min-width: 768px) {
  .pl-md--165 {
    padding-left: 165px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--165 {
    padding-left: 165px;
  }
}

@media (min-width: 768px) {
  .pl-md--170 {
    padding-left: 170px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--170 {
    padding-left: 170px;
  }
}

@media (min-width: 768px) {
  .pl-md--175 {
    padding-left: 175px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--175 {
    padding-left: 175px;
  }
}

@media (min-width: 768px) {
  .pl-md--180 {
    padding-left: 180px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--180 {
    padding-left: 180px;
  }
}

@media (min-width: 768px) {
  .pl-md--185 {
    padding-left: 185px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--185 {
    padding-left: 185px;
  }
}

@media (min-width: 768px) {
  .pl-md--190 {
    padding-left: 190px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--190 {
    padding-left: 190px;
  }
}

@media (min-width: 768px) {
  .pl-md--195 {
    padding-left: 195px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--195 {
    padding-left: 195px;
  }
}

@media (min-width: 768px) {
  .pl-md--200 {
    padding-left: 200px !important;
  }
}

@media (min-width: 992px) {
  .pl-md--200 {
    padding-left: 200px;
  }
}

@media (min-width: 992px) {
  .pl-lg--0 {
    padding-left: 0px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--0 {
    padding-left: 0px;
  }
}

@media (min-width: 992px) {
  .pl-lg--5 {
    padding-left: 5px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--5 {
    padding-left: 5px;
  }
}

@media (min-width: 992px) {
  .pl-lg--10 {
    padding-left: 10px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--10 {
    padding-left: 10px;
  }
}

@media (min-width: 992px) {
  .pl-lg--15 {
    padding-left: 15px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--15 {
    padding-left: 15px;
  }
}

@media (min-width: 992px) {
  .pl-lg--20 {
    padding-left: 20px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--20 {
    padding-left: 20px;
  }
}

@media (min-width: 992px) {
  .pl-lg--25 {
    padding-left: 25px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--25 {
    padding-left: 25px;
  }
}

@media (min-width: 992px) {
  .pl-lg--30 {
    padding-left: 30px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--30 {
    padding-left: 30px;
  }
}

@media (min-width: 992px) {
  .pl-lg--35 {
    padding-left: 35px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--35 {
    padding-left: 35px;
  }
}

@media (min-width: 992px) {
  .pl-lg--40 {
    padding-left: 40px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--40 {
    padding-left: 40px;
  }
}

@media (min-width: 992px) {
  .pl-lg--45 {
    padding-left: 45px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--45 {
    padding-left: 45px;
  }
}

@media (min-width: 992px) {
  .pl-lg--50 {
    padding-left: 50px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--50 {
    padding-left: 50px;
  }
}

@media (min-width: 992px) {
  .pl-lg--55 {
    padding-left: 55px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--55 {
    padding-left: 55px;
  }
}

@media (min-width: 992px) {
  .pl-lg--60 {
    padding-left: 60px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--60 {
    padding-left: 60px;
  }
}

@media (min-width: 992px) {
  .pl-lg--65 {
    padding-left: 65px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--65 {
    padding-left: 65px;
  }
}

@media (min-width: 992px) {
  .pl-lg--70 {
    padding-left: 70px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--70 {
    padding-left: 70px;
  }
}

@media (min-width: 992px) {
  .pl-lg--75 {
    padding-left: 75px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--75 {
    padding-left: 75px;
  }
}

@media (min-width: 992px) {
  .pl-lg--80 {
    padding-left: 80px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--80 {
    padding-left: 80px;
  }
}

@media (min-width: 992px) {
  .pl-lg--85 {
    padding-left: 85px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--85 {
    padding-left: 85px;
  }
}

@media (min-width: 992px) {
  .pl-lg--90 {
    padding-left: 90px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--90 {
    padding-left: 90px;
  }
}

@media (min-width: 992px) {
  .pl-lg--95 {
    padding-left: 95px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--95 {
    padding-left: 95px;
  }
}

@media (min-width: 992px) {
  .pl-lg--100 {
    padding-left: 100px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--100 {
    padding-left: 100px;
  }
}

@media (min-width: 992px) {
  .pl-lg--105 {
    padding-left: 105px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--105 {
    padding-left: 105px;
  }
}

@media (min-width: 992px) {
  .pl-lg--110 {
    padding-left: 110px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--110 {
    padding-left: 110px;
  }
}

@media (min-width: 992px) {
  .pl-lg--115 {
    padding-left: 115px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--115 {
    padding-left: 115px;
  }
}

@media (min-width: 992px) {
  .pl-lg--120 {
    padding-left: 120px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--120 {
    padding-left: 120px;
  }
}

@media (min-width: 992px) {
  .pl-lg--125 {
    padding-left: 125px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--125 {
    padding-left: 125px;
  }
}

@media (min-width: 992px) {
  .pl-lg--130 {
    padding-left: 130px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--130 {
    padding-left: 130px;
  }
}

@media (min-width: 992px) {
  .pl-lg--135 {
    padding-left: 135px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--135 {
    padding-left: 135px;
  }
}

@media (min-width: 992px) {
  .pl-lg--140 {
    padding-left: 140px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--140 {
    padding-left: 140px;
  }
}

@media (min-width: 992px) {
  .pl-lg--145 {
    padding-left: 145px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--145 {
    padding-left: 145px;
  }
}

@media (min-width: 992px) {
  .pl-lg--150 {
    padding-left: 150px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--150 {
    padding-left: 150px;
  }
}

@media (min-width: 992px) {
  .pl-lg--155 {
    padding-left: 155px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--155 {
    padding-left: 155px;
  }
}

@media (min-width: 992px) {
  .pl-lg--160 {
    padding-left: 160px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--160 {
    padding-left: 160px;
  }
}

@media (min-width: 992px) {
  .pl-lg--165 {
    padding-left: 165px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--165 {
    padding-left: 165px;
  }
}

@media (min-width: 992px) {
  .pl-lg--170 {
    padding-left: 170px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--170 {
    padding-left: 170px;
  }
}

@media (min-width: 992px) {
  .pl-lg--175 {
    padding-left: 175px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--175 {
    padding-left: 175px;
  }
}

@media (min-width: 992px) {
  .pl-lg--180 {
    padding-left: 180px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--180 {
    padding-left: 180px;
  }
}

@media (min-width: 992px) {
  .pl-lg--185 {
    padding-left: 185px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--185 {
    padding-left: 185px;
  }
}

@media (min-width: 992px) {
  .pl-lg--190 {
    padding-left: 190px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--190 {
    padding-left: 190px;
  }
}

@media (min-width: 992px) {
  .pl-lg--195 {
    padding-left: 195px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--195 {
    padding-left: 195px;
  }
}

@media (min-width: 992px) {
  .pl-lg--200 {
    padding-left: 200px !important;
  }
}

@media (min-width: 1200px) {
  .pl-lg--200 {
    padding-left: 200px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--0 {
    padding-left: 0px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--0 {
    padding-left: 0px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--5 {
    padding-left: 5px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--5 {
    padding-left: 5px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--10 {
    padding-left: 10px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--10 {
    padding-left: 10px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--15 {
    padding-left: 15px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--15 {
    padding-left: 15px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--20 {
    padding-left: 20px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--20 {
    padding-left: 20px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--25 {
    padding-left: 25px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--25 {
    padding-left: 25px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--30 {
    padding-left: 30px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--30 {
    padding-left: 30px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--35 {
    padding-left: 35px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--35 {
    padding-left: 35px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--40 {
    padding-left: 40px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--40 {
    padding-left: 40px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--45 {
    padding-left: 45px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--45 {
    padding-left: 45px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--50 {
    padding-left: 50px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--50 {
    padding-left: 50px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--55 {
    padding-left: 55px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--55 {
    padding-left: 55px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--60 {
    padding-left: 60px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--60 {
    padding-left: 60px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--65 {
    padding-left: 65px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--65 {
    padding-left: 65px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--70 {
    padding-left: 70px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--70 {
    padding-left: 70px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--75 {
    padding-left: 75px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--75 {
    padding-left: 75px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--80 {
    padding-left: 80px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--80 {
    padding-left: 80px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--85 {
    padding-left: 85px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--85 {
    padding-left: 85px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--90 {
    padding-left: 90px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--90 {
    padding-left: 90px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--95 {
    padding-left: 95px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--95 {
    padding-left: 95px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--100 {
    padding-left: 100px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--100 {
    padding-left: 100px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--105 {
    padding-left: 105px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--105 {
    padding-left: 105px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--110 {
    padding-left: 110px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--110 {
    padding-left: 110px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--115 {
    padding-left: 115px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--115 {
    padding-left: 115px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--120 {
    padding-left: 120px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--120 {
    padding-left: 120px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--125 {
    padding-left: 125px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--125 {
    padding-left: 125px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--130 {
    padding-left: 130px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--130 {
    padding-left: 130px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--135 {
    padding-left: 135px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--135 {
    padding-left: 135px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--140 {
    padding-left: 140px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--140 {
    padding-left: 140px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--145 {
    padding-left: 145px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--145 {
    padding-left: 145px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--150 {
    padding-left: 150px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--150 {
    padding-left: 150px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--155 {
    padding-left: 155px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--155 {
    padding-left: 155px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--160 {
    padding-left: 160px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--160 {
    padding-left: 160px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--165 {
    padding-left: 165px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--165 {
    padding-left: 165px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--170 {
    padding-left: 170px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--170 {
    padding-left: 170px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--175 {
    padding-left: 175px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--175 {
    padding-left: 175px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--180 {
    padding-left: 180px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--180 {
    padding-left: 180px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--185 {
    padding-left: 185px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--185 {
    padding-left: 185px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--190 {
    padding-left: 190px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--190 {
    padding-left: 190px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--195 {
    padding-left: 195px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--195 {
    padding-left: 195px;
  }
}

@media (min-width: 1200px) {
  .pl-xl--200 {
    padding-left: 200px !important;
  }
}

@media (min-width: 1600px) {
  .pl-xl--200 {
    padding-left: 200px;
  }
}

/*-- Responsive Padding Right --*/
@media (min-width: 576px) {
  .pr-sm--0 {
    padding-right: 0px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--0 {
    padding-right: 0px;
  }
}

@media (min-width: 576px) {
  .pr-sm--5 {
    padding-right: 5px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--5 {
    padding-right: 5px;
  }
}

@media (min-width: 576px) {
  .pr-sm--10 {
    padding-right: 10px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--10 {
    padding-right: 10px;
  }
}

@media (min-width: 576px) {
  .pr-sm--15 {
    padding-right: 15px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--15 {
    padding-right: 15px;
  }
}

@media (min-width: 576px) {
  .pr-sm--20 {
    padding-right: 20px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--20 {
    padding-right: 20px;
  }
}

@media (min-width: 576px) {
  .pr-sm--25 {
    padding-right: 25px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--25 {
    padding-right: 25px;
  }
}

@media (min-width: 576px) {
  .pr-sm--30 {
    padding-right: 30px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--30 {
    padding-right: 30px;
  }
}

@media (min-width: 576px) {
  .pr-sm--35 {
    padding-right: 35px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--35 {
    padding-right: 35px;
  }
}

@media (min-width: 576px) {
  .pr-sm--40 {
    padding-right: 40px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--40 {
    padding-right: 40px;
  }
}

@media (min-width: 576px) {
  .pr-sm--45 {
    padding-right: 45px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--45 {
    padding-right: 45px;
  }
}

@media (min-width: 576px) {
  .pr-sm--50 {
    padding-right: 50px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--50 {
    padding-right: 50px;
  }
}

@media (min-width: 576px) {
  .pr-sm--55 {
    padding-right: 55px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--55 {
    padding-right: 55px;
  }
}

@media (min-width: 576px) {
  .pr-sm--60 {
    padding-right: 60px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--60 {
    padding-right: 60px;
  }
}

@media (min-width: 576px) {
  .pr-sm--65 {
    padding-right: 65px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--65 {
    padding-right: 65px;
  }
}

@media (min-width: 576px) {
  .pr-sm--70 {
    padding-right: 70px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--70 {
    padding-right: 70px;
  }
}

@media (min-width: 576px) {
  .pr-sm--75 {
    padding-right: 75px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--75 {
    padding-right: 75px;
  }
}

@media (min-width: 576px) {
  .pr-sm--80 {
    padding-right: 80px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--80 {
    padding-right: 80px;
  }
}

@media (min-width: 576px) {
  .pr-sm--85 {
    padding-right: 85px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--85 {
    padding-right: 85px;
  }
}

@media (min-width: 576px) {
  .pr-sm--90 {
    padding-right: 90px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--90 {
    padding-right: 90px;
  }
}

@media (min-width: 576px) {
  .pr-sm--95 {
    padding-right: 95px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--95 {
    padding-right: 95px;
  }
}

@media (min-width: 576px) {
  .pr-sm--100 {
    padding-right: 100px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--100 {
    padding-right: 100px;
  }
}

@media (min-width: 576px) {
  .pr-sm--105 {
    padding-right: 105px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--105 {
    padding-right: 105px;
  }
}

@media (min-width: 576px) {
  .pr-sm--110 {
    padding-right: 110px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--110 {
    padding-right: 110px;
  }
}

@media (min-width: 576px) {
  .pr-sm--115 {
    padding-right: 115px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--115 {
    padding-right: 115px;
  }
}

@media (min-width: 576px) {
  .pr-sm--120 {
    padding-right: 120px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--120 {
    padding-right: 120px;
  }
}

@media (min-width: 576px) {
  .pr-sm--125 {
    padding-right: 125px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--125 {
    padding-right: 125px;
  }
}

@media (min-width: 576px) {
  .pr-sm--130 {
    padding-right: 130px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--130 {
    padding-right: 130px;
  }
}

@media (min-width: 576px) {
  .pr-sm--135 {
    padding-right: 135px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--135 {
    padding-right: 135px;
  }
}

@media (min-width: 576px) {
  .pr-sm--140 {
    padding-right: 140px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--140 {
    padding-right: 140px;
  }
}

@media (min-width: 576px) {
  .pr-sm--145 {
    padding-right: 145px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--145 {
    padding-right: 145px;
  }
}

@media (min-width: 576px) {
  .pr-sm--150 {
    padding-right: 150px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--150 {
    padding-right: 150px;
  }
}

@media (min-width: 576px) {
  .pr-sm--155 {
    padding-right: 155px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--155 {
    padding-right: 155px;
  }
}

@media (min-width: 576px) {
  .pr-sm--160 {
    padding-right: 160px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--160 {
    padding-right: 160px;
  }
}

@media (min-width: 576px) {
  .pr-sm--165 {
    padding-right: 165px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--165 {
    padding-right: 165px;
  }
}

@media (min-width: 576px) {
  .pr-sm--170 {
    padding-right: 170px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--170 {
    padding-right: 170px;
  }
}

@media (min-width: 576px) {
  .pr-sm--175 {
    padding-right: 175px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--175 {
    padding-right: 175px;
  }
}

@media (min-width: 576px) {
  .pr-sm--180 {
    padding-right: 180px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--180 {
    padding-right: 180px;
  }
}

@media (min-width: 576px) {
  .pr-sm--185 {
    padding-right: 185px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--185 {
    padding-right: 185px;
  }
}

@media (min-width: 576px) {
  .pr-sm--190 {
    padding-right: 190px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--190 {
    padding-right: 190px;
  }
}

@media (min-width: 576px) {
  .pr-sm--195 {
    padding-right: 195px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--195 {
    padding-right: 195px;
  }
}

@media (min-width: 576px) {
  .pr-sm--200 {
    padding-right: 200px !important;
  }
}

@media (min-width: 768px) {
  .pr-sm--200 {
    padding-right: 200px;
  }
}

@media (min-width: 768px) {
  .pr-md--0 {
    padding-right: 0px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--0 {
    padding-right: 0px;
  }
}

@media (min-width: 768px) {
  .pr-md--5 {
    padding-right: 5px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--5 {
    padding-right: 5px;
  }
}

@media (min-width: 768px) {
  .pr-md--10 {
    padding-right: 10px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--10 {
    padding-right: 10px;
  }
}

@media (min-width: 768px) {
  .pr-md--15 {
    padding-right: 15px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--15 {
    padding-right: 15px;
  }
}

@media (min-width: 768px) {
  .pr-md--20 {
    padding-right: 20px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--20 {
    padding-right: 20px;
  }
}

@media (min-width: 768px) {
  .pr-md--25 {
    padding-right: 25px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--25 {
    padding-right: 25px;
  }
}

@media (min-width: 768px) {
  .pr-md--30 {
    padding-right: 30px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--30 {
    padding-right: 30px;
  }
}

@media (min-width: 768px) {
  .pr-md--35 {
    padding-right: 35px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--35 {
    padding-right: 35px;
  }
}

@media (min-width: 768px) {
  .pr-md--40 {
    padding-right: 40px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--40 {
    padding-right: 40px;
  }
}

@media (min-width: 768px) {
  .pr-md--45 {
    padding-right: 45px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--45 {
    padding-right: 45px;
  }
}

@media (min-width: 768px) {
  .pr-md--50 {
    padding-right: 50px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--50 {
    padding-right: 50px;
  }
}

@media (min-width: 768px) {
  .pr-md--55 {
    padding-right: 55px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--55 {
    padding-right: 55px;
  }
}

@media (min-width: 768px) {
  .pr-md--60 {
    padding-right: 60px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--60 {
    padding-right: 60px;
  }
}

@media (min-width: 768px) {
  .pr-md--65 {
    padding-right: 65px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--65 {
    padding-right: 65px;
  }
}

@media (min-width: 768px) {
  .pr-md--70 {
    padding-right: 70px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--70 {
    padding-right: 70px;
  }
}

@media (min-width: 768px) {
  .pr-md--75 {
    padding-right: 75px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--75 {
    padding-right: 75px;
  }
}

@media (min-width: 768px) {
  .pr-md--80 {
    padding-right: 80px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--80 {
    padding-right: 80px;
  }
}

@media (min-width: 768px) {
  .pr-md--85 {
    padding-right: 85px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--85 {
    padding-right: 85px;
  }
}

@media (min-width: 768px) {
  .pr-md--90 {
    padding-right: 90px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--90 {
    padding-right: 90px;
  }
}

@media (min-width: 768px) {
  .pr-md--95 {
    padding-right: 95px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--95 {
    padding-right: 95px;
  }
}

@media (min-width: 768px) {
  .pr-md--100 {
    padding-right: 100px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--100 {
    padding-right: 100px;
  }
}

@media (min-width: 768px) {
  .pr-md--105 {
    padding-right: 105px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--105 {
    padding-right: 105px;
  }
}

@media (min-width: 768px) {
  .pr-md--110 {
    padding-right: 110px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--110 {
    padding-right: 110px;
  }
}

@media (min-width: 768px) {
  .pr-md--115 {
    padding-right: 115px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--115 {
    padding-right: 115px;
  }
}

@media (min-width: 768px) {
  .pr-md--120 {
    padding-right: 120px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--120 {
    padding-right: 120px;
  }
}

@media (min-width: 768px) {
  .pr-md--125 {
    padding-right: 125px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--125 {
    padding-right: 125px;
  }
}

@media (min-width: 768px) {
  .pr-md--130 {
    padding-right: 130px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--130 {
    padding-right: 130px;
  }
}

@media (min-width: 768px) {
  .pr-md--135 {
    padding-right: 135px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--135 {
    padding-right: 135px;
  }
}

@media (min-width: 768px) {
  .pr-md--140 {
    padding-right: 140px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--140 {
    padding-right: 140px;
  }
}

@media (min-width: 768px) {
  .pr-md--145 {
    padding-right: 145px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--145 {
    padding-right: 145px;
  }
}

@media (min-width: 768px) {
  .pr-md--150 {
    padding-right: 150px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--150 {
    padding-right: 150px;
  }
}

@media (min-width: 768px) {
  .pr-md--155 {
    padding-right: 155px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--155 {
    padding-right: 155px;
  }
}

@media (min-width: 768px) {
  .pr-md--160 {
    padding-right: 160px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--160 {
    padding-right: 160px;
  }
}

@media (min-width: 768px) {
  .pr-md--165 {
    padding-right: 165px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--165 {
    padding-right: 165px;
  }
}

@media (min-width: 768px) {
  .pr-md--170 {
    padding-right: 170px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--170 {
    padding-right: 170px;
  }
}

@media (min-width: 768px) {
  .pr-md--175 {
    padding-right: 175px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--175 {
    padding-right: 175px;
  }
}

@media (min-width: 768px) {
  .pr-md--180 {
    padding-right: 180px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--180 {
    padding-right: 180px;
  }
}

@media (min-width: 768px) {
  .pr-md--185 {
    padding-right: 185px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--185 {
    padding-right: 185px;
  }
}

@media (min-width: 768px) {
  .pr-md--190 {
    padding-right: 190px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--190 {
    padding-right: 190px;
  }
}

@media (min-width: 768px) {
  .pr-md--195 {
    padding-right: 195px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--195 {
    padding-right: 195px;
  }
}

@media (min-width: 768px) {
  .pr-md--200 {
    padding-right: 200px !important;
  }
}

@media (min-width: 992px) {
  .pr-md--200 {
    padding-right: 200px;
  }
}

@media (min-width: 992px) {
  .pr-lg--0 {
    padding-right: 0px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--0 {
    padding-right: 0px;
  }
}

@media (min-width: 992px) {
  .pr-lg--5 {
    padding-right: 5px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--5 {
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  .pr-lg--10 {
    padding-right: 10px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--10 {
    padding-right: 10px;
  }
}

@media (min-width: 992px) {
  .pr-lg--15 {
    padding-right: 15px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--15 {
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  .pr-lg--20 {
    padding-right: 20px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--20 {
    padding-right: 20px;
  }
}

@media (min-width: 992px) {
  .pr-lg--25 {
    padding-right: 25px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--25 {
    padding-right: 25px;
  }
}

@media (min-width: 992px) {
  .pr-lg--30 {
    padding-right: 30px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--30 {
    padding-right: 30px;
  }
}

@media (min-width: 992px) {
  .pr-lg--35 {
    padding-right: 35px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--35 {
    padding-right: 35px;
  }
}

@media (min-width: 992px) {
  .pr-lg--40 {
    padding-right: 40px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--40 {
    padding-right: 40px;
  }
}

@media (min-width: 992px) {
  .pr-lg--45 {
    padding-right: 45px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--45 {
    padding-right: 45px;
  }
}

@media (min-width: 992px) {
  .pr-lg--50 {
    padding-right: 50px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--50 {
    padding-right: 50px;
  }
}

@media (min-width: 992px) {
  .pr-lg--55 {
    padding-right: 55px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--55 {
    padding-right: 55px;
  }
}

@media (min-width: 992px) {
  .pr-lg--60 {
    padding-right: 60px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--60 {
    padding-right: 60px;
  }
}

@media (min-width: 992px) {
  .pr-lg--65 {
    padding-right: 65px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--65 {
    padding-right: 65px;
  }
}

@media (min-width: 992px) {
  .pr-lg--70 {
    padding-right: 70px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--70 {
    padding-right: 70px;
  }
}

@media (min-width: 992px) {
  .pr-lg--75 {
    padding-right: 75px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--75 {
    padding-right: 75px;
  }
}

@media (min-width: 992px) {
  .pr-lg--80 {
    padding-right: 80px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--80 {
    padding-right: 80px;
  }
}

@media (min-width: 992px) {
  .pr-lg--85 {
    padding-right: 85px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--85 {
    padding-right: 85px;
  }
}

@media (min-width: 992px) {
  .pr-lg--90 {
    padding-right: 90px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--90 {
    padding-right: 90px;
  }
}

@media (min-width: 992px) {
  .pr-lg--95 {
    padding-right: 95px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--95 {
    padding-right: 95px;
  }
}

@media (min-width: 992px) {
  .pr-lg--100 {
    padding-right: 100px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--100 {
    padding-right: 100px;
  }
}

@media (min-width: 992px) {
  .pr-lg--105 {
    padding-right: 105px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--105 {
    padding-right: 105px;
  }
}

@media (min-width: 992px) {
  .pr-lg--110 {
    padding-right: 110px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--110 {
    padding-right: 110px;
  }
}

@media (min-width: 992px) {
  .pr-lg--115 {
    padding-right: 115px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--115 {
    padding-right: 115px;
  }
}

@media (min-width: 992px) {
  .pr-lg--120 {
    padding-right: 120px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--120 {
    padding-right: 120px;
  }
}

@media (min-width: 992px) {
  .pr-lg--125 {
    padding-right: 125px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--125 {
    padding-right: 125px;
  }
}

@media (min-width: 992px) {
  .pr-lg--130 {
    padding-right: 130px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--130 {
    padding-right: 130px;
  }
}

@media (min-width: 992px) {
  .pr-lg--135 {
    padding-right: 135px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--135 {
    padding-right: 135px;
  }
}

@media (min-width: 992px) {
  .pr-lg--140 {
    padding-right: 140px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--140 {
    padding-right: 140px;
  }
}

@media (min-width: 992px) {
  .pr-lg--145 {
    padding-right: 145px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--145 {
    padding-right: 145px;
  }
}

@media (min-width: 992px) {
  .pr-lg--150 {
    padding-right: 150px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--150 {
    padding-right: 150px;
  }
}

@media (min-width: 992px) {
  .pr-lg--155 {
    padding-right: 155px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--155 {
    padding-right: 155px;
  }
}

@media (min-width: 992px) {
  .pr-lg--160 {
    padding-right: 160px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--160 {
    padding-right: 160px;
  }
}

@media (min-width: 992px) {
  .pr-lg--165 {
    padding-right: 165px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--165 {
    padding-right: 165px;
  }
}

@media (min-width: 992px) {
  .pr-lg--170 {
    padding-right: 170px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--170 {
    padding-right: 170px;
  }
}

@media (min-width: 992px) {
  .pr-lg--175 {
    padding-right: 175px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--175 {
    padding-right: 175px;
  }
}

@media (min-width: 992px) {
  .pr-lg--180 {
    padding-right: 180px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--180 {
    padding-right: 180px;
  }
}

@media (min-width: 992px) {
  .pr-lg--185 {
    padding-right: 185px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--185 {
    padding-right: 185px;
  }
}

@media (min-width: 992px) {
  .pr-lg--190 {
    padding-right: 190px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--190 {
    padding-right: 190px;
  }
}

@media (min-width: 992px) {
  .pr-lg--195 {
    padding-right: 195px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--195 {
    padding-right: 195px;
  }
}

@media (min-width: 992px) {
  .pr-lg--200 {
    padding-right: 200px !important;
  }
}

@media (min-width: 1200px) {
  .pr-lg--200 {
    padding-right: 200px;
  }
}

/*=====================================*/
/*-- :::: Responsive Margins :::: --*/
/*=====================================*/
/*-- Responsive Margins top --*/
@media (min-width: 576px) {
  .mt-sm--0 {
    margin-top: 0px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--0 {
    margin-top: 0px;
  }
}

@media (min-width: 576px) {
  .mt-sm--5 {
    margin-top: 5px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--5 {
    margin-top: 5px;
  }
}

@media (min-width: 576px) {
  .mt-sm--10 {
    margin-top: 10px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--10 {
    margin-top: 10px;
  }
}

@media (min-width: 576px) {
  .mt-sm--15 {
    margin-top: 15px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--15 {
    margin-top: 15px;
  }
}

@media (min-width: 576px) {
  .mt-sm--20 {
    margin-top: 20px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--20 {
    margin-top: 20px;
  }
}

@media (min-width: 576px) {
  .mt-sm--25 {
    margin-top: 25px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--25 {
    margin-top: 25px;
  }
}

@media (min-width: 576px) {
  .mt-sm--30 {
    margin-top: 30px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--30 {
    margin-top: 30px;
  }
}

@media (min-width: 576px) {
  .mt-sm--35 {
    margin-top: 35px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--35 {
    margin-top: 35px;
  }
}

@media (min-width: 576px) {
  .mt-sm--40 {
    margin-top: 40px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--40 {
    margin-top: 40px;
  }
}

@media (min-width: 576px) {
  .mt-sm--45 {
    margin-top: 45px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--45 {
    margin-top: 45px;
  }
}

@media (min-width: 576px) {
  .mt-sm--50 {
    margin-top: 50px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--50 {
    margin-top: 50px;
  }
}

@media (min-width: 576px) {
  .mt-sm--55 {
    margin-top: 55px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--55 {
    margin-top: 55px;
  }
}

@media (min-width: 576px) {
  .mt-sm--60 {
    margin-top: 60px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--60 {
    margin-top: 60px;
  }
}

@media (min-width: 576px) {
  .mt-sm--65 {
    margin-top: 65px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--65 {
    margin-top: 65px;
  }
}

@media (min-width: 576px) {
  .mt-sm--70 {
    margin-top: 70px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--70 {
    margin-top: 70px;
  }
}

@media (min-width: 576px) {
  .mt-sm--75 {
    margin-top: 75px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--75 {
    margin-top: 75px;
  }
}

@media (min-width: 576px) {
  .mt-sm--80 {
    margin-top: 80px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--80 {
    margin-top: 80px;
  }
}

@media (min-width: 576px) {
  .mt-sm--85 {
    margin-top: 85px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--85 {
    margin-top: 85px;
  }
}

@media (min-width: 576px) {
  .mt-sm--90 {
    margin-top: 90px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--90 {
    margin-top: 90px;
  }
}

@media (min-width: 576px) {
  .mt-sm--95 {
    margin-top: 95px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--95 {
    margin-top: 95px;
  }
}

@media (min-width: 576px) {
  .mt-sm--100 {
    margin-top: 100px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--100 {
    margin-top: 100px;
  }
}

@media (min-width: 576px) {
  .mt-sm--105 {
    margin-top: 105px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--105 {
    margin-top: 105px;
  }
}

@media (min-width: 576px) {
  .mt-sm--110 {
    margin-top: 110px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--110 {
    margin-top: 110px;
  }
}

@media (min-width: 576px) {
  .mt-sm--115 {
    margin-top: 115px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--115 {
    margin-top: 115px;
  }
}

@media (min-width: 576px) {
  .mt-sm--120 {
    margin-top: 120px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--120 {
    margin-top: 120px;
  }
}

@media (min-width: 576px) {
  .mt-sm--125 {
    margin-top: 125px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--125 {
    margin-top: 125px;
  }
}

@media (min-width: 576px) {
  .mt-sm--130 {
    margin-top: 130px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--130 {
    margin-top: 130px;
  }
}

@media (min-width: 576px) {
  .mt-sm--135 {
    margin-top: 135px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--135 {
    margin-top: 135px;
  }
}

@media (min-width: 576px) {
  .mt-sm--140 {
    margin-top: 140px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--140 {
    margin-top: 140px;
  }
}

@media (min-width: 576px) {
  .mt-sm--145 {
    margin-top: 145px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--145 {
    margin-top: 145px;
  }
}

@media (min-width: 576px) {
  .mt-sm--150 {
    margin-top: 150px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--150 {
    margin-top: 150px;
  }
}

@media (min-width: 576px) {
  .mt-sm--155 {
    margin-top: 155px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--155 {
    margin-top: 155px;
  }
}

@media (min-width: 576px) {
  .mt-sm--160 {
    margin-top: 160px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--160 {
    margin-top: 160px;
  }
}

@media (min-width: 576px) {
  .mt-sm--165 {
    margin-top: 165px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--165 {
    margin-top: 165px;
  }
}

@media (min-width: 576px) {
  .mt-sm--170 {
    margin-top: 170px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--170 {
    margin-top: 170px;
  }
}

@media (min-width: 576px) {
  .mt-sm--175 {
    margin-top: 175px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--175 {
    margin-top: 175px;
  }
}

@media (min-width: 576px) {
  .mt-sm--180 {
    margin-top: 180px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--180 {
    margin-top: 180px;
  }
}

@media (min-width: 576px) {
  .mt-sm--185 {
    margin-top: 185px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--185 {
    margin-top: 185px;
  }
}

@media (min-width: 576px) {
  .mt-sm--190 {
    margin-top: 190px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--190 {
    margin-top: 190px;
  }
}

@media (min-width: 576px) {
  .mt-sm--195 {
    margin-top: 195px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--195 {
    margin-top: 195px;
  }
}

@media (min-width: 576px) {
  .mt-sm--200 {
    margin-top: 200px !important;
  }
}

@media (min-width: 768px) {
  .mt-sm--200 {
    margin-top: 200px;
  }
}

@media (min-width: 768px) {
  .mt-md--0 {
    margin-top: 0px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--0 {
    margin-top: 0px;
  }
}

@media (min-width: 768px) {
  .mt-md--5 {
    margin-top: 5px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--5 {
    margin-top: 5px;
  }
}

@media (min-width: 768px) {
  .mt-md--10 {
    margin-top: 10px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--10 {
    margin-top: 10px;
  }
}

@media (min-width: 768px) {
  .mt-md--15 {
    margin-top: 15px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--15 {
    margin-top: 15px;
  }
}

@media (min-width: 768px) {
  .mt-md--20 {
    margin-top: 20px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--20 {
    margin-top: 20px;
  }
}

@media (min-width: 768px) {
  .mt-md--25 {
    margin-top: 25px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--25 {
    margin-top: 25px;
  }
}

@media (min-width: 768px) {
  .mt-md--30 {
    margin-top: 30px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--30 {
    margin-top: 30px;
  }
}

@media (min-width: 768px) {
  .mt-md--35 {
    margin-top: 35px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--35 {
    margin-top: 35px;
  }
}

@media (min-width: 768px) {
  .mt-md--40 {
    margin-top: 40px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--40 {
    margin-top: 40px;
  }
}

@media (min-width: 768px) {
  .mt-md--45 {
    margin-top: 45px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--45 {
    margin-top: 45px;
  }
}

@media (min-width: 768px) {
  .mt-md--50 {
    margin-top: 50px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--50 {
    margin-top: 50px;
  }
}

@media (min-width: 768px) {
  .mt-md--55 {
    margin-top: 55px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--55 {
    margin-top: 55px;
  }
}

@media (min-width: 768px) {
  .mt-md--60 {
    margin-top: 60px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--60 {
    margin-top: 60px;
  }
}

@media (min-width: 768px) {
  .mt-md--65 {
    margin-top: 65px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--65 {
    margin-top: 65px;
  }
}

@media (min-width: 768px) {
  .mt-md--70 {
    margin-top: 70px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--70 {
    margin-top: 70px;
  }
}

@media (min-width: 768px) {
  .mt-md--75 {
    margin-top: 75px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--75 {
    margin-top: 75px;
  }
}

@media (min-width: 768px) {
  .mt-md--80 {
    margin-top: 80px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--80 {
    margin-top: 80px;
  }
}

@media (min-width: 768px) {
  .mt-md--85 {
    margin-top: 85px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--85 {
    margin-top: 85px;
  }
}

@media (min-width: 768px) {
  .mt-md--90 {
    margin-top: 90px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--90 {
    margin-top: 90px;
  }
}

@media (min-width: 768px) {
  .mt-md--95 {
    margin-top: 95px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--95 {
    margin-top: 95px;
  }
}

@media (min-width: 768px) {
  .mt-md--100 {
    margin-top: 100px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--100 {
    margin-top: 100px;
  }
}

@media (min-width: 768px) {
  .mt-md--105 {
    margin-top: 105px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--105 {
    margin-top: 105px;
  }
}

@media (min-width: 768px) {
  .mt-md--110 {
    margin-top: 110px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--110 {
    margin-top: 110px;
  }
}

@media (min-width: 768px) {
  .mt-md--115 {
    margin-top: 115px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--115 {
    margin-top: 115px;
  }
}

@media (min-width: 768px) {
  .mt-md--120 {
    margin-top: 120px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--120 {
    margin-top: 120px;
  }
}

@media (min-width: 768px) {
  .mt-md--125 {
    margin-top: 125px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--125 {
    margin-top: 125px;
  }
}

@media (min-width: 768px) {
  .mt-md--130 {
    margin-top: 130px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--130 {
    margin-top: 130px;
  }
}

@media (min-width: 768px) {
  .mt-md--135 {
    margin-top: 135px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--135 {
    margin-top: 135px;
  }
}

@media (min-width: 768px) {
  .mt-md--140 {
    margin-top: 140px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--140 {
    margin-top: 140px;
  }
}

@media (min-width: 768px) {
  .mt-md--145 {
    margin-top: 145px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--145 {
    margin-top: 145px;
  }
}

@media (min-width: 768px) {
  .mt-md--150 {
    margin-top: 150px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--150 {
    margin-top: 150px;
  }
}

@media (min-width: 768px) {
  .mt-md--155 {
    margin-top: 155px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--155 {
    margin-top: 155px;
  }
}

@media (min-width: 768px) {
  .mt-md--160 {
    margin-top: 160px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--160 {
    margin-top: 160px;
  }
}

@media (min-width: 768px) {
  .mt-md--165 {
    margin-top: 165px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--165 {
    margin-top: 165px;
  }
}

@media (min-width: 768px) {
  .mt-md--170 {
    margin-top: 170px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--170 {
    margin-top: 170px;
  }
}

@media (min-width: 768px) {
  .mt-md--175 {
    margin-top: 175px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--175 {
    margin-top: 175px;
  }
}

@media (min-width: 768px) {
  .mt-md--180 {
    margin-top: 180px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--180 {
    margin-top: 180px;
  }
}

@media (min-width: 768px) {
  .mt-md--185 {
    margin-top: 185px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--185 {
    margin-top: 185px;
  }
}

@media (min-width: 768px) {
  .mt-md--190 {
    margin-top: 190px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--190 {
    margin-top: 190px;
  }
}

@media (min-width: 768px) {
  .mt-md--195 {
    margin-top: 195px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--195 {
    margin-top: 195px;
  }
}

@media (min-width: 768px) {
  .mt-md--200 {
    margin-top: 200px !important;
  }
}

@media (min-width: 992px) {
  .mt-md--200 {
    margin-top: 200px;
  }
}

@media (min-width: 992px) {
  .mt-lg--0 {
    margin-top: 0px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--0 {
    margin-top: 0px;
  }
}

@media (min-width: 992px) {
  .mt-lg--5 {
    margin-top: 5px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--5 {
    margin-top: 5px;
  }
}

@media (min-width: 992px) {
  .mt-lg--10 {
    margin-top: 10px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--10 {
    margin-top: 10px;
  }
}

@media (min-width: 992px) {
  .mt-lg--15 {
    margin-top: 15px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--15 {
    margin-top: 15px;
  }
}

@media (min-width: 992px) {
  .mt-lg--20 {
    margin-top: 20px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--20 {
    margin-top: 20px;
  }
}

@media (min-width: 992px) {
  .mt-lg--25 {
    margin-top: 25px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--25 {
    margin-top: 25px;
  }
}

@media (min-width: 992px) {
  .mt-lg--30 {
    margin-top: 30px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--30 {
    margin-top: 30px;
  }
}

@media (min-width: 992px) {
  .mt-lg--35 {
    margin-top: 35px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--35 {
    margin-top: 35px;
  }
}

@media (min-width: 992px) {
  .mt-lg--40 {
    margin-top: 40px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--40 {
    margin-top: 40px;
  }
}

@media (min-width: 992px) {
  .mt-lg--45 {
    margin-top: 45px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--45 {
    margin-top: 45px;
  }
}

@media (min-width: 992px) {
  .mt-lg--50 {
    margin-top: 50px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--50 {
    margin-top: 50px;
  }
}

@media (min-width: 992px) {
  .mt-lg--55 {
    margin-top: 55px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--55 {
    margin-top: 55px;
  }
}

@media (min-width: 992px) {
  .mt-lg--60 {
    margin-top: 60px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--60 {
    margin-top: 60px;
  }
}

@media (min-width: 992px) {
  .mt-lg--65 {
    margin-top: 65px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--65 {
    margin-top: 65px;
  }
}

@media (min-width: 992px) {
  .mt-lg--70 {
    margin-top: 70px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--70 {
    margin-top: 70px;
  }
}

@media (min-width: 992px) {
  .mt-lg--75 {
    margin-top: 75px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--75 {
    margin-top: 75px;
  }
}

@media (min-width: 992px) {
  .mt-lg--80 {
    margin-top: 80px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--80 {
    margin-top: 80px;
  }
}

@media (min-width: 992px) {
  .mt-lg--85 {
    margin-top: 85px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--85 {
    margin-top: 85px;
  }
}

@media (min-width: 992px) {
  .mt-lg--90 {
    margin-top: 90px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--90 {
    margin-top: 90px;
  }
}

@media (min-width: 992px) {
  .mt-lg--95 {
    margin-top: 95px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--95 {
    margin-top: 95px;
  }
}

@media (min-width: 992px) {
  .mt-lg--100 {
    margin-top: 100px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--100 {
    margin-top: 100px;
  }
}

@media (min-width: 992px) {
  .mt-lg--105 {
    margin-top: 105px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--105 {
    margin-top: 105px;
  }
}

@media (min-width: 992px) {
  .mt-lg--110 {
    margin-top: 110px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--110 {
    margin-top: 110px;
  }
}

@media (min-width: 992px) {
  .mt-lg--115 {
    margin-top: 115px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--115 {
    margin-top: 115px;
  }
}

@media (min-width: 992px) {
  .mt-lg--120 {
    margin-top: 120px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--120 {
    margin-top: 120px;
  }
}

@media (min-width: 992px) {
  .mt-lg--125 {
    margin-top: 125px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--125 {
    margin-top: 125px;
  }
}

@media (min-width: 992px) {
  .mt-lg--130 {
    margin-top: 130px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--130 {
    margin-top: 130px;
  }
}

@media (min-width: 992px) {
  .mt-lg--135 {
    margin-top: 135px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--135 {
    margin-top: 135px;
  }
}

@media (min-width: 992px) {
  .mt-lg--140 {
    margin-top: 140px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--140 {
    margin-top: 140px;
  }
}

@media (min-width: 992px) {
  .mt-lg--145 {
    margin-top: 145px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--145 {
    margin-top: 145px;
  }
}

@media (min-width: 992px) {
  .mt-lg--150 {
    margin-top: 150px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--150 {
    margin-top: 150px;
  }
}

@media (min-width: 992px) {
  .mt-lg--155 {
    margin-top: 155px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--155 {
    margin-top: 155px;
  }
}

@media (min-width: 992px) {
  .mt-lg--160 {
    margin-top: 160px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--160 {
    margin-top: 160px;
  }
}

@media (min-width: 992px) {
  .mt-lg--165 {
    margin-top: 165px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--165 {
    margin-top: 165px;
  }
}

@media (min-width: 992px) {
  .mt-lg--170 {
    margin-top: 170px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--170 {
    margin-top: 170px;
  }
}

@media (min-width: 992px) {
  .mt-lg--175 {
    margin-top: 175px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--175 {
    margin-top: 175px;
  }
}

@media (min-width: 992px) {
  .mt-lg--180 {
    margin-top: 180px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--180 {
    margin-top: 180px;
  }
}

@media (min-width: 992px) {
  .mt-lg--185 {
    margin-top: 185px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--185 {
    margin-top: 185px;
  }
}

@media (min-width: 992px) {
  .mt-lg--190 {
    margin-top: 190px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--190 {
    margin-top: 190px;
  }
}

@media (min-width: 992px) {
  .mt-lg--195 {
    margin-top: 195px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--195 {
    margin-top: 195px;
  }
}

@media (min-width: 992px) {
  .mt-lg--200 {
    margin-top: 200px !important;
  }
}

@media (min-width: 1200px) {
  .mt-lg--200 {
    margin-top: 200px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--0 {
    margin-top: 0px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--0 {
    margin-top: 0px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--5 {
    margin-top: 5px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--5 {
    margin-top: 5px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--10 {
    margin-top: 10px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--10 {
    margin-top: 10px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--15 {
    margin-top: 15px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--15 {
    margin-top: 15px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--20 {
    margin-top: 20px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--20 {
    margin-top: 20px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--25 {
    margin-top: 25px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--25 {
    margin-top: 25px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--30 {
    margin-top: 30px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--30 {
    margin-top: 30px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--35 {
    margin-top: 35px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--35 {
    margin-top: 35px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--40 {
    margin-top: 40px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--40 {
    margin-top: 40px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--45 {
    margin-top: 45px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--45 {
    margin-top: 45px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--50 {
    margin-top: 50px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--50 {
    margin-top: 50px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--55 {
    margin-top: 55px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--55 {
    margin-top: 55px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--60 {
    margin-top: 60px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--60 {
    margin-top: 60px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--65 {
    margin-top: 65px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--65 {
    margin-top: 65px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--70 {
    margin-top: 70px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--70 {
    margin-top: 70px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--75 {
    margin-top: 75px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--75 {
    margin-top: 75px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--80 {
    margin-top: 80px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--80 {
    margin-top: 80px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--85 {
    margin-top: 85px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--85 {
    margin-top: 85px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--90 {
    margin-top: 90px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--90 {
    margin-top: 90px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--95 {
    margin-top: 95px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--95 {
    margin-top: 95px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--100 {
    margin-top: 100px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--100 {
    margin-top: 100px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--105 {
    margin-top: 105px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--105 {
    margin-top: 105px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--110 {
    margin-top: 110px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--110 {
    margin-top: 110px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--115 {
    margin-top: 115px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--115 {
    margin-top: 115px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--120 {
    margin-top: 120px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--120 {
    margin-top: 120px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--125 {
    margin-top: 125px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--125 {
    margin-top: 125px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--130 {
    margin-top: 130px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--130 {
    margin-top: 130px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--135 {
    margin-top: 135px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--135 {
    margin-top: 135px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--140 {
    margin-top: 140px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--140 {
    margin-top: 140px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--145 {
    margin-top: 145px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--145 {
    margin-top: 145px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--150 {
    margin-top: 150px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--150 {
    margin-top: 150px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--155 {
    margin-top: 155px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--155 {
    margin-top: 155px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--160 {
    margin-top: 160px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--160 {
    margin-top: 160px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--165 {
    margin-top: 165px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--165 {
    margin-top: 165px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--170 {
    margin-top: 170px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--170 {
    margin-top: 170px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--175 {
    margin-top: 175px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--175 {
    margin-top: 175px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--180 {
    margin-top: 180px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--180 {
    margin-top: 180px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--185 {
    margin-top: 185px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--185 {
    margin-top: 185px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--190 {
    margin-top: 190px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--190 {
    margin-top: 190px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--195 {
    margin-top: 195px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--195 {
    margin-top: 195px;
  }
}

@media (min-width: 1200px) {
  .mt-xl--200 {
    margin-top: 200px !important;
  }
}

@media (min-width: 1600px) {
  .mt-xl--200 {
    margin-top: 200px;
  }
}

/*-- Responsive Margins bottom --*/
@media (min-width: 576px) {
  .mb-sm--0 {
    margin-bottom: 0px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--0 {
    margin-bottom: 0px;
  }
}

@media (min-width: 576px) {
  .mb-sm--5 {
    margin-bottom: 5px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--5 {
    margin-bottom: 5px;
  }
}

@media (min-width: 576px) {
  .mb-sm--10 {
    margin-bottom: 10px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--10 {
    margin-bottom: 10px;
  }
}

@media (min-width: 576px) {
  .mb-sm--15 {
    margin-bottom: 15px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--15 {
    margin-bottom: 15px;
  }
}

@media (min-width: 576px) {
  .mb-sm--20 {
    margin-bottom: 20px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--20 {
    margin-bottom: 20px;
  }
}

@media (min-width: 576px) {
  .mb-sm--25 {
    margin-bottom: 25px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--25 {
    margin-bottom: 25px;
  }
}

@media (min-width: 576px) {
  .mb-sm--30 {
    margin-bottom: 30px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--30 {
    margin-bottom: 30px;
  }
}

@media (min-width: 576px) {
  .mb-sm--35 {
    margin-bottom: 35px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--35 {
    margin-bottom: 35px;
  }
}

@media (min-width: 576px) {
  .mb-sm--40 {
    margin-bottom: 40px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--40 {
    margin-bottom: 40px;
  }
}

@media (min-width: 576px) {
  .mb-sm--45 {
    margin-bottom: 45px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--45 {
    margin-bottom: 45px;
  }
}

@media (min-width: 576px) {
  .mb-sm--50 {
    margin-bottom: 50px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--50 {
    margin-bottom: 50px;
  }
}

@media (min-width: 576px) {
  .mb-sm--55 {
    margin-bottom: 55px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--55 {
    margin-bottom: 55px;
  }
}

@media (min-width: 576px) {
  .mb-sm--60 {
    margin-bottom: 60px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--60 {
    margin-bottom: 60px;
  }
}

@media (min-width: 576px) {
  .mb-sm--65 {
    margin-bottom: 65px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--65 {
    margin-bottom: 65px;
  }
}

@media (min-width: 576px) {
  .mb-sm--70 {
    margin-bottom: 70px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--70 {
    margin-bottom: 70px;
  }
}

@media (min-width: 576px) {
  .mb-sm--75 {
    margin-bottom: 75px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--75 {
    margin-bottom: 75px;
  }
}

@media (min-width: 576px) {
  .mb-sm--80 {
    margin-bottom: 80px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--80 {
    margin-bottom: 80px;
  }
}

@media (min-width: 576px) {
  .mb-sm--85 {
    margin-bottom: 85px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--85 {
    margin-bottom: 85px;
  }
}

@media (min-width: 576px) {
  .mb-sm--90 {
    margin-bottom: 90px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--90 {
    margin-bottom: 90px;
  }
}

@media (min-width: 576px) {
  .mb-sm--95 {
    margin-bottom: 95px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--95 {
    margin-bottom: 95px;
  }
}

@media (min-width: 576px) {
  .mb-sm--100 {
    margin-bottom: 100px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--100 {
    margin-bottom: 100px;
  }
}

@media (min-width: 576px) {
  .mb-sm--105 {
    margin-bottom: 105px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--105 {
    margin-bottom: 105px;
  }
}

@media (min-width: 576px) {
  .mb-sm--110 {
    margin-bottom: 110px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--110 {
    margin-bottom: 110px;
  }
}

@media (min-width: 576px) {
  .mb-sm--115 {
    margin-bottom: 115px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--115 {
    margin-bottom: 115px;
  }
}

@media (min-width: 576px) {
  .mb-sm--120 {
    margin-bottom: 120px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--120 {
    margin-bottom: 120px;
  }
}

@media (min-width: 576px) {
  .mb-sm--125 {
    margin-bottom: 125px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--125 {
    margin-bottom: 125px;
  }
}

@media (min-width: 576px) {
  .mb-sm--130 {
    margin-bottom: 130px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--130 {
    margin-bottom: 130px;
  }
}

@media (min-width: 576px) {
  .mb-sm--135 {
    margin-bottom: 135px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--135 {
    margin-bottom: 135px;
  }
}

@media (min-width: 576px) {
  .mb-sm--140 {
    margin-bottom: 140px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--140 {
    margin-bottom: 140px;
  }
}

@media (min-width: 576px) {
  .mb-sm--145 {
    margin-bottom: 145px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--145 {
    margin-bottom: 145px;
  }
}

@media (min-width: 576px) {
  .mb-sm--150 {
    margin-bottom: 150px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--150 {
    margin-bottom: 150px;
  }
}

@media (min-width: 576px) {
  .mb-sm--155 {
    margin-bottom: 155px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--155 {
    margin-bottom: 155px;
  }
}

@media (min-width: 576px) {
  .mb-sm--160 {
    margin-bottom: 160px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--160 {
    margin-bottom: 160px;
  }
}

@media (min-width: 576px) {
  .mb-sm--165 {
    margin-bottom: 165px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--165 {
    margin-bottom: 165px;
  }
}

@media (min-width: 576px) {
  .mb-sm--170 {
    margin-bottom: 170px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--170 {
    margin-bottom: 170px;
  }
}

@media (min-width: 576px) {
  .mb-sm--175 {
    margin-bottom: 175px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--175 {
    margin-bottom: 175px;
  }
}

@media (min-width: 576px) {
  .mb-sm--180 {
    margin-bottom: 180px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--180 {
    margin-bottom: 180px;
  }
}

@media (min-width: 576px) {
  .mb-sm--185 {
    margin-bottom: 185px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--185 {
    margin-bottom: 185px;
  }
}

@media (min-width: 576px) {
  .mb-sm--190 {
    margin-bottom: 190px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--190 {
    margin-bottom: 190px;
  }
}

@media (min-width: 576px) {
  .mb-sm--195 {
    margin-bottom: 195px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--195 {
    margin-bottom: 195px;
  }
}

@media (min-width: 576px) {
  .mb-sm--200 {
    margin-bottom: 200px !important;
  }
}

@media (min-width: 768px) {
  .mb-sm--200 {
    margin-bottom: 200px;
  }
}

@media (min-width: 768px) {
  .mb-md--0 {
    margin-bottom: 0px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--0 {
    margin-bottom: 0px;
  }
}

@media (min-width: 768px) {
  .mb-md--5 {
    margin-bottom: 5px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--5 {
    margin-bottom: 5px;
  }
}

@media (min-width: 768px) {
  .mb-md--10 {
    margin-bottom: 10px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--10 {
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) {
  .mb-md--15 {
    margin-bottom: 15px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--15 {
    margin-bottom: 15px;
  }
}

@media (min-width: 768px) {
  .mb-md--20 {
    margin-bottom: 20px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--20 {
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .mb-md--25 {
    margin-bottom: 25px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--25 {
    margin-bottom: 25px;
  }
}

@media (min-width: 768px) {
  .mb-md--30 {
    margin-bottom: 30px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--30 {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) {
  .mb-md--35 {
    margin-bottom: 35px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--35 {
    margin-bottom: 35px;
  }
}

@media (min-width: 768px) {
  .mb-md--40 {
    margin-bottom: 40px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--40 {
    margin-bottom: 40px;
  }
}

@media (min-width: 768px) {
  .mb-md--45 {
    margin-bottom: 45px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--45 {
    margin-bottom: 45px;
  }
}

@media (min-width: 768px) {
  .mb-md--50 {
    margin-bottom: 50px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--50 {
    margin-bottom: 50px;
  }
}

@media (min-width: 768px) {
  .mb-md--55 {
    margin-bottom: 55px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--55 {
    margin-bottom: 55px;
  }
}

@media (min-width: 768px) {
  .mb-md--60 {
    margin-bottom: 60px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--60 {
    margin-bottom: 60px;
  }
}

@media (min-width: 768px) {
  .mb-md--65 {
    margin-bottom: 65px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--65 {
    margin-bottom: 65px;
  }
}

@media (min-width: 768px) {
  .mb-md--70 {
    margin-bottom: 70px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--70 {
    margin-bottom: 70px;
  }
}

@media (min-width: 768px) {
  .mb-md--75 {
    margin-bottom: 75px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--75 {
    margin-bottom: 75px;
  }
}

@media (min-width: 768px) {
  .mb-md--80 {
    margin-bottom: 80px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--80 {
    margin-bottom: 80px;
  }
}

@media (min-width: 768px) {
  .mb-md--85 {
    margin-bottom: 85px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--85 {
    margin-bottom: 85px;
  }
}

@media (min-width: 768px) {
  .mb-md--90 {
    margin-bottom: 90px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--90 {
    margin-bottom: 90px;
  }
}

@media (min-width: 768px) {
  .mb-md--95 {
    margin-bottom: 95px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--95 {
    margin-bottom: 95px;
  }
}

@media (min-width: 768px) {
  .mb-md--100 {
    margin-bottom: 100px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--100 {
    margin-bottom: 100px;
  }
}

@media (min-width: 768px) {
  .mb-md--105 {
    margin-bottom: 105px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--105 {
    margin-bottom: 105px;
  }
}

@media (min-width: 768px) {
  .mb-md--110 {
    margin-bottom: 110px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--110 {
    margin-bottom: 110px;
  }
}

@media (min-width: 768px) {
  .mb-md--115 {
    margin-bottom: 115px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--115 {
    margin-bottom: 115px;
  }
}

@media (min-width: 768px) {
  .mb-md--120 {
    margin-bottom: 120px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--120 {
    margin-bottom: 120px;
  }
}

@media (min-width: 768px) {
  .mb-md--125 {
    margin-bottom: 125px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--125 {
    margin-bottom: 125px;
  }
}

@media (min-width: 768px) {
  .mb-md--130 {
    margin-bottom: 130px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--130 {
    margin-bottom: 130px;
  }
}

@media (min-width: 768px) {
  .mb-md--135 {
    margin-bottom: 135px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--135 {
    margin-bottom: 135px;
  }
}

@media (min-width: 768px) {
  .mb-md--140 {
    margin-bottom: 140px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--140 {
    margin-bottom: 140px;
  }
}

@media (min-width: 768px) {
  .mb-md--145 {
    margin-bottom: 145px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--145 {
    margin-bottom: 145px;
  }
}

@media (min-width: 768px) {
  .mb-md--150 {
    margin-bottom: 150px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--150 {
    margin-bottom: 150px;
  }
}

@media (min-width: 768px) {
  .mb-md--155 {
    margin-bottom: 155px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--155 {
    margin-bottom: 155px;
  }
}

@media (min-width: 768px) {
  .mb-md--160 {
    margin-bottom: 160px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--160 {
    margin-bottom: 160px;
  }
}

@media (min-width: 768px) {
  .mb-md--165 {
    margin-bottom: 165px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--165 {
    margin-bottom: 165px;
  }
}

@media (min-width: 768px) {
  .mb-md--170 {
    margin-bottom: 170px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--170 {
    margin-bottom: 170px;
  }
}

@media (min-width: 768px) {
  .mb-md--175 {
    margin-bottom: 175px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--175 {
    margin-bottom: 175px;
  }
}

@media (min-width: 768px) {
  .mb-md--180 {
    margin-bottom: 180px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--180 {
    margin-bottom: 180px;
  }
}

@media (min-width: 768px) {
  .mb-md--185 {
    margin-bottom: 185px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--185 {
    margin-bottom: 185px;
  }
}

@media (min-width: 768px) {
  .mb-md--190 {
    margin-bottom: 190px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--190 {
    margin-bottom: 190px;
  }
}

@media (min-width: 768px) {
  .mb-md--195 {
    margin-bottom: 195px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--195 {
    margin-bottom: 195px;
  }
}

@media (min-width: 768px) {
  .mb-md--200 {
    margin-bottom: 200px !important;
  }
}

@media (min-width: 992px) {
  .mb-md--200 {
    margin-bottom: 200px;
  }
}

@media (min-width: 992px) {
  .mb-lg--0 {
    margin-bottom: 0px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--0 {
    margin-bottom: 0px;
  }
}

@media (min-width: 992px) {
  .mb-lg--5 {
    margin-bottom: 5px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--5 {
    margin-bottom: 5px;
  }
}

@media (min-width: 992px) {
  .mb-lg--10 {
    margin-bottom: 10px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--10 {
    margin-bottom: 10px;
  }
}

@media (min-width: 992px) {
  .mb-lg--15 {
    margin-bottom: 15px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--15 {
    margin-bottom: 15px;
  }
}

@media (min-width: 992px) {
  .mb-lg--20 {
    margin-bottom: 20px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--20 {
    margin-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .mb-lg--25 {
    margin-bottom: 25px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--25 {
    margin-bottom: 25px;
  }
}

@media (min-width: 992px) {
  .mb-lg--30 {
    margin-bottom: 30px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--30 {
    margin-bottom: 30px;
  }
}

@media (min-width: 992px) {
  .mb-lg--35 {
    margin-bottom: 35px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--35 {
    margin-bottom: 35px;
  }
}

@media (min-width: 992px) {
  .mb-lg--40 {
    margin-bottom: 40px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--40 {
    margin-bottom: 40px;
  }
}

@media (min-width: 992px) {
  .mb-lg--45 {
    margin-bottom: 45px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--45 {
    margin-bottom: 45px;
  }
}

@media (min-width: 992px) {
  .mb-lg--50 {
    margin-bottom: 50px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--50 {
    margin-bottom: 50px;
  }
}

@media (min-width: 992px) {
  .mb-lg--55 {
    margin-bottom: 55px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--55 {
    margin-bottom: 55px;
  }
}

@media (min-width: 992px) {
  .mb-lg--60 {
    margin-bottom: 60px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--60 {
    margin-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .mb-lg--65 {
    margin-bottom: 65px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--65 {
    margin-bottom: 65px;
  }
}

@media (min-width: 992px) {
  .mb-lg--70 {
    margin-bottom: 70px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--70 {
    margin-bottom: 70px;
  }
}

@media (min-width: 992px) {
  .mb-lg--75 {
    margin-bottom: 75px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--75 {
    margin-bottom: 75px;
  }
}

@media (min-width: 992px) {
  .mb-lg--80 {
    margin-bottom: 80px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--80 {
    margin-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .mb-lg--85 {
    margin-bottom: 85px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--85 {
    margin-bottom: 85px;
  }
}

@media (min-width: 992px) {
  .mb-lg--90 {
    margin-bottom: 90px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--90 {
    margin-bottom: 90px;
  }
}

@media (min-width: 992px) {
  .mb-lg--95 {
    margin-bottom: 95px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--95 {
    margin-bottom: 95px;
  }
}

@media (min-width: 992px) {
  .mb-lg--100 {
    margin-bottom: 100px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--100 {
    margin-bottom: 100px;
  }
}

@media (min-width: 992px) {
  .mb-lg--105 {
    margin-bottom: 105px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--105 {
    margin-bottom: 105px;
  }
}

@media (min-width: 992px) {
  .mb-lg--110 {
    margin-bottom: 110px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--110 {
    margin-bottom: 110px;
  }
}

@media (min-width: 992px) {
  .mb-lg--115 {
    margin-bottom: 115px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--115 {
    margin-bottom: 115px;
  }
}

@media (min-width: 992px) {
  .mb-lg--120 {
    margin-bottom: 120px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--120 {
    margin-bottom: 120px;
  }
}

@media (min-width: 992px) {
  .mb-lg--125 {
    margin-bottom: 125px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--125 {
    margin-bottom: 125px;
  }
}

@media (min-width: 992px) {
  .mb-lg--130 {
    margin-bottom: 130px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--130 {
    margin-bottom: 130px;
  }
}

@media (min-width: 992px) {
  .mb-lg--135 {
    margin-bottom: 135px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--135 {
    margin-bottom: 135px;
  }
}

@media (min-width: 992px) {
  .mb-lg--140 {
    margin-bottom: 140px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--140 {
    margin-bottom: 140px;
  }
}

@media (min-width: 992px) {
  .mb-lg--145 {
    margin-bottom: 145px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--145 {
    margin-bottom: 145px;
  }
}

@media (min-width: 992px) {
  .mb-lg--150 {
    margin-bottom: 150px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--150 {
    margin-bottom: 150px;
  }
}

@media (min-width: 992px) {
  .mb-lg--155 {
    margin-bottom: 155px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--155 {
    margin-bottom: 155px;
  }
}

@media (min-width: 992px) {
  .mb-lg--160 {
    margin-bottom: 160px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--160 {
    margin-bottom: 160px;
  }
}

@media (min-width: 992px) {
  .mb-lg--165 {
    margin-bottom: 165px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--165 {
    margin-bottom: 165px;
  }
}

@media (min-width: 992px) {
  .mb-lg--170 {
    margin-bottom: 170px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--170 {
    margin-bottom: 170px;
  }
}

@media (min-width: 992px) {
  .mb-lg--175 {
    margin-bottom: 175px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--175 {
    margin-bottom: 175px;
  }
}

@media (min-width: 992px) {
  .mb-lg--180 {
    margin-bottom: 180px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--180 {
    margin-bottom: 180px;
  }
}

@media (min-width: 992px) {
  .mb-lg--185 {
    margin-bottom: 185px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--185 {
    margin-bottom: 185px;
  }
}

@media (min-width: 992px) {
  .mb-lg--190 {
    margin-bottom: 190px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--190 {
    margin-bottom: 190px;
  }
}

@media (min-width: 992px) {
  .mb-lg--195 {
    margin-bottom: 195px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--195 {
    margin-bottom: 195px;
  }
}

@media (min-width: 992px) {
  .mb-lg--200 {
    margin-bottom: 200px !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg--200 {
    margin-bottom: 200px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--0 {
    margin-bottom: 0px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--0 {
    margin-bottom: 0px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--5 {
    margin-bottom: 5px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--5 {
    margin-bottom: 5px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--10 {
    margin-bottom: 10px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--10 {
    margin-bottom: 10px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--15 {
    margin-bottom: 15px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--15 {
    margin-bottom: 15px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--20 {
    margin-bottom: 20px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--20 {
    margin-bottom: 20px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--25 {
    margin-bottom: 25px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--25 {
    margin-bottom: 25px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--30 {
    margin-bottom: 30px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--30 {
    margin-bottom: 30px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--35 {
    margin-bottom: 35px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--35 {
    margin-bottom: 35px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--40 {
    margin-bottom: 40px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--40 {
    margin-bottom: 40px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--45 {
    margin-bottom: 45px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--45 {
    margin-bottom: 45px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--50 {
    margin-bottom: 50px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--50 {
    margin-bottom: 50px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--55 {
    margin-bottom: 55px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--55 {
    margin-bottom: 55px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--60 {
    margin-bottom: 60px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--60 {
    margin-bottom: 60px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--65 {
    margin-bottom: 65px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--65 {
    margin-bottom: 65px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--70 {
    margin-bottom: 70px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--70 {
    margin-bottom: 70px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--75 {
    margin-bottom: 75px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--75 {
    margin-bottom: 75px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--80 {
    margin-bottom: 80px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--80 {
    margin-bottom: 80px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--85 {
    margin-bottom: 85px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--85 {
    margin-bottom: 85px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--90 {
    margin-bottom: 90px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--90 {
    margin-bottom: 90px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--95 {
    margin-bottom: 95px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--95 {
    margin-bottom: 95px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--100 {
    margin-bottom: 100px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--100 {
    margin-bottom: 100px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--105 {
    margin-bottom: 105px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--105 {
    margin-bottom: 105px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--110 {
    margin-bottom: 110px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--110 {
    margin-bottom: 110px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--115 {
    margin-bottom: 115px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--115 {
    margin-bottom: 115px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--120 {
    margin-bottom: 120px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--120 {
    margin-bottom: 120px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--125 {
    margin-bottom: 125px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--125 {
    margin-bottom: 125px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--130 {
    margin-bottom: 130px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--130 {
    margin-bottom: 130px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--135 {
    margin-bottom: 135px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--135 {
    margin-bottom: 135px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--140 {
    margin-bottom: 140px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--140 {
    margin-bottom: 140px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--145 {
    margin-bottom: 145px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--145 {
    margin-bottom: 145px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--150 {
    margin-bottom: 150px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--150 {
    margin-bottom: 150px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--155 {
    margin-bottom: 155px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--155 {
    margin-bottom: 155px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--160 {
    margin-bottom: 160px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--160 {
    margin-bottom: 160px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--165 {
    margin-bottom: 165px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--165 {
    margin-bottom: 165px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--170 {
    margin-bottom: 170px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--170 {
    margin-bottom: 170px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--175 {
    margin-bottom: 175px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--175 {
    margin-bottom: 175px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--180 {
    margin-bottom: 180px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--180 {
    margin-bottom: 180px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--185 {
    margin-bottom: 185px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--185 {
    margin-bottom: 185px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--190 {
    margin-bottom: 190px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--190 {
    margin-bottom: 190px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--195 {
    margin-bottom: 195px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--195 {
    margin-bottom: 195px;
  }
}

@media (min-width: 1200px) {
  .mb-xl--200 {
    margin-bottom: 200px !important;
  }
}

@media (min-width: 1600px) {
  .mb-xl--200 {
    margin-bottom: 200px;
  }
}

/*-- Responsive Margins left --*/
@media (min-width: 576px) {
  .ml-sm--0 {
    margin-left: 0px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--0 {
    margin-left: 0px;
  }
}

@media (min-width: 576px) {
  .ml-sm--5 {
    margin-left: 5px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--5 {
    margin-left: 5px;
  }
}

@media (min-width: 576px) {
  .ml-sm--10 {
    margin-left: 10px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--10 {
    margin-left: 10px;
  }
}

@media (min-width: 576px) {
  .ml-sm--15 {
    margin-left: 15px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--15 {
    margin-left: 15px;
  }
}

@media (min-width: 576px) {
  .ml-sm--20 {
    margin-left: 20px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--20 {
    margin-left: 20px;
  }
}

@media (min-width: 576px) {
  .ml-sm--25 {
    margin-left: 25px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--25 {
    margin-left: 25px;
  }
}

@media (min-width: 576px) {
  .ml-sm--30 {
    margin-left: 30px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--30 {
    margin-left: 30px;
  }
}

@media (min-width: 576px) {
  .ml-sm--35 {
    margin-left: 35px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--35 {
    margin-left: 35px;
  }
}

@media (min-width: 576px) {
  .ml-sm--40 {
    margin-left: 40px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--40 {
    margin-left: 40px;
  }
}

@media (min-width: 576px) {
  .ml-sm--45 {
    margin-left: 45px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--45 {
    margin-left: 45px;
  }
}

@media (min-width: 576px) {
  .ml-sm--50 {
    margin-left: 50px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--50 {
    margin-left: 50px;
  }
}

@media (min-width: 576px) {
  .ml-sm--55 {
    margin-left: 55px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--55 {
    margin-left: 55px;
  }
}

@media (min-width: 576px) {
  .ml-sm--60 {
    margin-left: 60px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--60 {
    margin-left: 60px;
  }
}

@media (min-width: 576px) {
  .ml-sm--65 {
    margin-left: 65px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--65 {
    margin-left: 65px;
  }
}

@media (min-width: 576px) {
  .ml-sm--70 {
    margin-left: 70px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--70 {
    margin-left: 70px;
  }
}

@media (min-width: 576px) {
  .ml-sm--75 {
    margin-left: 75px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--75 {
    margin-left: 75px;
  }
}

@media (min-width: 576px) {
  .ml-sm--80 {
    margin-left: 80px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--80 {
    margin-left: 80px;
  }
}

@media (min-width: 576px) {
  .ml-sm--85 {
    margin-left: 85px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--85 {
    margin-left: 85px;
  }
}

@media (min-width: 576px) {
  .ml-sm--90 {
    margin-left: 90px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--90 {
    margin-left: 90px;
  }
}

@media (min-width: 576px) {
  .ml-sm--95 {
    margin-left: 95px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--95 {
    margin-left: 95px;
  }
}

@media (min-width: 576px) {
  .ml-sm--100 {
    margin-left: 100px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--100 {
    margin-left: 100px;
  }
}

@media (min-width: 576px) {
  .ml-sm--105 {
    margin-left: 105px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--105 {
    margin-left: 105px;
  }
}

@media (min-width: 576px) {
  .ml-sm--110 {
    margin-left: 110px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--110 {
    margin-left: 110px;
  }
}

@media (min-width: 576px) {
  .ml-sm--115 {
    margin-left: 115px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--115 {
    margin-left: 115px;
  }
}

@media (min-width: 576px) {
  .ml-sm--120 {
    margin-left: 120px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--120 {
    margin-left: 120px;
  }
}

@media (min-width: 576px) {
  .ml-sm--125 {
    margin-left: 125px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--125 {
    margin-left: 125px;
  }
}

@media (min-width: 576px) {
  .ml-sm--130 {
    margin-left: 130px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--130 {
    margin-left: 130px;
  }
}

@media (min-width: 576px) {
  .ml-sm--135 {
    margin-left: 135px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--135 {
    margin-left: 135px;
  }
}

@media (min-width: 576px) {
  .ml-sm--140 {
    margin-left: 140px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--140 {
    margin-left: 140px;
  }
}

@media (min-width: 576px) {
  .ml-sm--145 {
    margin-left: 145px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--145 {
    margin-left: 145px;
  }
}

@media (min-width: 576px) {
  .ml-sm--150 {
    margin-left: 150px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--150 {
    margin-left: 150px;
  }
}

@media (min-width: 576px) {
  .ml-sm--155 {
    margin-left: 155px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--155 {
    margin-left: 155px;
  }
}

@media (min-width: 576px) {
  .ml-sm--160 {
    margin-left: 160px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--160 {
    margin-left: 160px;
  }
}

@media (min-width: 576px) {
  .ml-sm--165 {
    margin-left: 165px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--165 {
    margin-left: 165px;
  }
}

@media (min-width: 576px) {
  .ml-sm--170 {
    margin-left: 170px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--170 {
    margin-left: 170px;
  }
}

@media (min-width: 576px) {
  .ml-sm--175 {
    margin-left: 175px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--175 {
    margin-left: 175px;
  }
}

@media (min-width: 576px) {
  .ml-sm--180 {
    margin-left: 180px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--180 {
    margin-left: 180px;
  }
}

@media (min-width: 576px) {
  .ml-sm--185 {
    margin-left: 185px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--185 {
    margin-left: 185px;
  }
}

@media (min-width: 576px) {
  .ml-sm--190 {
    margin-left: 190px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--190 {
    margin-left: 190px;
  }
}

@media (min-width: 576px) {
  .ml-sm--195 {
    margin-left: 195px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--195 {
    margin-left: 195px;
  }
}

@media (min-width: 576px) {
  .ml-sm--200 {
    margin-left: 200px !important;
  }
}

@media (min-width: 768px) {
  .ml-sm--200 {
    margin-left: 200px;
  }
}

@media (min-width: 768px) {
  .ml-md--0 {
    margin-left: 0px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--0 {
    margin-left: 0px;
  }
}

@media (min-width: 768px) {
  .ml-md--5 {
    margin-left: 5px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--5 {
    margin-left: 5px;
  }
}

@media (min-width: 768px) {
  .ml-md--10 {
    margin-left: 10px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--10 {
    margin-left: 10px;
  }
}

@media (min-width: 768px) {
  .ml-md--15 {
    margin-left: 15px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--15 {
    margin-left: 15px;
  }
}

@media (min-width: 768px) {
  .ml-md--20 {
    margin-left: 20px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--20 {
    margin-left: 20px;
  }
}

@media (min-width: 768px) {
  .ml-md--25 {
    margin-left: 25px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--25 {
    margin-left: 25px;
  }
}

@media (min-width: 768px) {
  .ml-md--30 {
    margin-left: 30px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--30 {
    margin-left: 30px;
  }
}

@media (min-width: 768px) {
  .ml-md--35 {
    margin-left: 35px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--35 {
    margin-left: 35px;
  }
}

@media (min-width: 768px) {
  .ml-md--40 {
    margin-left: 40px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--40 {
    margin-left: 40px;
  }
}

@media (min-width: 768px) {
  .ml-md--45 {
    margin-left: 45px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--45 {
    margin-left: 45px;
  }
}

@media (min-width: 768px) {
  .ml-md--50 {
    margin-left: 50px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--50 {
    margin-left: 50px;
  }
}

@media (min-width: 768px) {
  .ml-md--55 {
    margin-left: 55px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--55 {
    margin-left: 55px;
  }
}

@media (min-width: 768px) {
  .ml-md--60 {
    margin-left: 60px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--60 {
    margin-left: 60px;
  }
}

@media (min-width: 768px) {
  .ml-md--65 {
    margin-left: 65px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--65 {
    margin-left: 65px;
  }
}

@media (min-width: 768px) {
  .ml-md--70 {
    margin-left: 70px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--70 {
    margin-left: 70px;
  }
}

@media (min-width: 768px) {
  .ml-md--75 {
    margin-left: 75px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--75 {
    margin-left: 75px;
  }
}

@media (min-width: 768px) {
  .ml-md--80 {
    margin-left: 80px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--80 {
    margin-left: 80px;
  }
}

@media (min-width: 768px) {
  .ml-md--85 {
    margin-left: 85px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--85 {
    margin-left: 85px;
  }
}

@media (min-width: 768px) {
  .ml-md--90 {
    margin-left: 90px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--90 {
    margin-left: 90px;
  }
}

@media (min-width: 768px) {
  .ml-md--95 {
    margin-left: 95px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--95 {
    margin-left: 95px;
  }
}

@media (min-width: 768px) {
  .ml-md--100 {
    margin-left: 100px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--100 {
    margin-left: 100px;
  }
}

@media (min-width: 768px) {
  .ml-md--105 {
    margin-left: 105px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--105 {
    margin-left: 105px;
  }
}

@media (min-width: 768px) {
  .ml-md--110 {
    margin-left: 110px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--110 {
    margin-left: 110px;
  }
}

@media (min-width: 768px) {
  .ml-md--115 {
    margin-left: 115px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--115 {
    margin-left: 115px;
  }
}

@media (min-width: 768px) {
  .ml-md--120 {
    margin-left: 120px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--120 {
    margin-left: 120px;
  }
}

@media (min-width: 768px) {
  .ml-md--125 {
    margin-left: 125px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--125 {
    margin-left: 125px;
  }
}

@media (min-width: 768px) {
  .ml-md--130 {
    margin-left: 130px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--130 {
    margin-left: 130px;
  }
}

@media (min-width: 768px) {
  .ml-md--135 {
    margin-left: 135px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--135 {
    margin-left: 135px;
  }
}

@media (min-width: 768px) {
  .ml-md--140 {
    margin-left: 140px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--140 {
    margin-left: 140px;
  }
}

@media (min-width: 768px) {
  .ml-md--145 {
    margin-left: 145px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--145 {
    margin-left: 145px;
  }
}

@media (min-width: 768px) {
  .ml-md--150 {
    margin-left: 150px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--150 {
    margin-left: 150px;
  }
}

@media (min-width: 768px) {
  .ml-md--155 {
    margin-left: 155px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--155 {
    margin-left: 155px;
  }
}

@media (min-width: 768px) {
  .ml-md--160 {
    margin-left: 160px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--160 {
    margin-left: 160px;
  }
}

@media (min-width: 768px) {
  .ml-md--165 {
    margin-left: 165px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--165 {
    margin-left: 165px;
  }
}

@media (min-width: 768px) {
  .ml-md--170 {
    margin-left: 170px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--170 {
    margin-left: 170px;
  }
}

@media (min-width: 768px) {
  .ml-md--175 {
    margin-left: 175px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--175 {
    margin-left: 175px;
  }
}

@media (min-width: 768px) {
  .ml-md--180 {
    margin-left: 180px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--180 {
    margin-left: 180px;
  }
}

@media (min-width: 768px) {
  .ml-md--185 {
    margin-left: 185px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--185 {
    margin-left: 185px;
  }
}

@media (min-width: 768px) {
  .ml-md--190 {
    margin-left: 190px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--190 {
    margin-left: 190px;
  }
}

@media (min-width: 768px) {
  .ml-md--195 {
    margin-left: 195px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--195 {
    margin-left: 195px;
  }
}

@media (min-width: 768px) {
  .ml-md--200 {
    margin-left: 200px !important;
  }
}

@media (min-width: 992px) {
  .ml-md--200 {
    margin-left: 200px;
  }
}

@media (min-width: 992px) {
  .ml-lg--0 {
    margin-left: 0px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--0 {
    margin-left: 0px;
  }
}

@media (min-width: 992px) {
  .ml-lg--5 {
    margin-left: 5px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--5 {
    margin-left: 5px;
  }
}

@media (min-width: 992px) {
  .ml-lg--10 {
    margin-left: 10px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--10 {
    margin-left: 10px;
  }
}

@media (min-width: 992px) {
  .ml-lg--15 {
    margin-left: 15px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--15 {
    margin-left: 15px;
  }
}

@media (min-width: 992px) {
  .ml-lg--20 {
    margin-left: 20px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--20 {
    margin-left: 20px;
  }
}

@media (min-width: 992px) {
  .ml-lg--25 {
    margin-left: 25px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--25 {
    margin-left: 25px;
  }
}

@media (min-width: 992px) {
  .ml-lg--30 {
    margin-left: 30px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--30 {
    margin-left: 30px;
  }
}

@media (min-width: 992px) {
  .ml-lg--35 {
    margin-left: 35px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--35 {
    margin-left: 35px;
  }
}

@media (min-width: 992px) {
  .ml-lg--40 {
    margin-left: 40px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--40 {
    margin-left: 40px;
  }
}

@media (min-width: 992px) {
  .ml-lg--45 {
    margin-left: 45px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--45 {
    margin-left: 45px;
  }
}

@media (min-width: 992px) {
  .ml-lg--50 {
    margin-left: 50px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--50 {
    margin-left: 50px;
  }
}

@media (min-width: 992px) {
  .ml-lg--55 {
    margin-left: 55px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--55 {
    margin-left: 55px;
  }
}

@media (min-width: 992px) {
  .ml-lg--60 {
    margin-left: 60px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--60 {
    margin-left: 60px;
  }
}

@media (min-width: 992px) {
  .ml-lg--65 {
    margin-left: 65px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--65 {
    margin-left: 65px;
  }
}

@media (min-width: 992px) {
  .ml-lg--70 {
    margin-left: 70px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--70 {
    margin-left: 70px;
  }
}

@media (min-width: 992px) {
  .ml-lg--75 {
    margin-left: 75px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--75 {
    margin-left: 75px;
  }
}

@media (min-width: 992px) {
  .ml-lg--80 {
    margin-left: 80px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--80 {
    margin-left: 80px;
  }
}

@media (min-width: 992px) {
  .ml-lg--85 {
    margin-left: 85px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--85 {
    margin-left: 85px;
  }
}

@media (min-width: 992px) {
  .ml-lg--90 {
    margin-left: 90px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--90 {
    margin-left: 90px;
  }
}

@media (min-width: 992px) {
  .ml-lg--95 {
    margin-left: 95px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--95 {
    margin-left: 95px;
  }
}

@media (min-width: 992px) {
  .ml-lg--100 {
    margin-left: 100px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--100 {
    margin-left: 100px;
  }
}

@media (min-width: 992px) {
  .ml-lg--105 {
    margin-left: 105px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--105 {
    margin-left: 105px;
  }
}

@media (min-width: 992px) {
  .ml-lg--110 {
    margin-left: 110px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--110 {
    margin-left: 110px;
  }
}

@media (min-width: 992px) {
  .ml-lg--115 {
    margin-left: 115px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--115 {
    margin-left: 115px;
  }
}

@media (min-width: 992px) {
  .ml-lg--120 {
    margin-left: 120px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--120 {
    margin-left: 120px;
  }
}

@media (min-width: 992px) {
  .ml-lg--125 {
    margin-left: 125px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--125 {
    margin-left: 125px;
  }
}

@media (min-width: 992px) {
  .ml-lg--130 {
    margin-left: 130px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--130 {
    margin-left: 130px;
  }
}

@media (min-width: 992px) {
  .ml-lg--135 {
    margin-left: 135px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--135 {
    margin-left: 135px;
  }
}

@media (min-width: 992px) {
  .ml-lg--140 {
    margin-left: 140px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--140 {
    margin-left: 140px;
  }
}

@media (min-width: 992px) {
  .ml-lg--145 {
    margin-left: 145px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--145 {
    margin-left: 145px;
  }
}

@media (min-width: 992px) {
  .ml-lg--150 {
    margin-left: 150px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--150 {
    margin-left: 150px;
  }
}

@media (min-width: 992px) {
  .ml-lg--155 {
    margin-left: 155px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--155 {
    margin-left: 155px;
  }
}

@media (min-width: 992px) {
  .ml-lg--160 {
    margin-left: 160px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--160 {
    margin-left: 160px;
  }
}

@media (min-width: 992px) {
  .ml-lg--165 {
    margin-left: 165px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--165 {
    margin-left: 165px;
  }
}

@media (min-width: 992px) {
  .ml-lg--170 {
    margin-left: 170px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--170 {
    margin-left: 170px;
  }
}

@media (min-width: 992px) {
  .ml-lg--175 {
    margin-left: 175px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--175 {
    margin-left: 175px;
  }
}

@media (min-width: 992px) {
  .ml-lg--180 {
    margin-left: 180px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--180 {
    margin-left: 180px;
  }
}

@media (min-width: 992px) {
  .ml-lg--185 {
    margin-left: 185px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--185 {
    margin-left: 185px;
  }
}

@media (min-width: 992px) {
  .ml-lg--190 {
    margin-left: 190px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--190 {
    margin-left: 190px;
  }
}

@media (min-width: 992px) {
  .ml-lg--195 {
    margin-left: 195px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--195 {
    margin-left: 195px;
  }
}

@media (min-width: 992px) {
  .ml-lg--200 {
    margin-left: 200px !important;
  }
}

@media (min-width: 1200px) {
  .ml-lg--200 {
    margin-left: 200px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--0 {
    margin-left: 0px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--0 {
    margin-left: 0px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--5 {
    margin-left: 5px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--5 {
    margin-left: 5px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--10 {
    margin-left: 10px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--10 {
    margin-left: 10px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--15 {
    margin-left: 15px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--15 {
    margin-left: 15px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--20 {
    margin-left: 20px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--20 {
    margin-left: 20px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--25 {
    margin-left: 25px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--25 {
    margin-left: 25px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--30 {
    margin-left: 30px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--30 {
    margin-left: 30px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--35 {
    margin-left: 35px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--35 {
    margin-left: 35px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--40 {
    margin-left: 40px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--40 {
    margin-left: 40px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--45 {
    margin-left: 45px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--45 {
    margin-left: 45px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--50 {
    margin-left: 50px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--50 {
    margin-left: 50px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--55 {
    margin-left: 55px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--55 {
    margin-left: 55px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--60 {
    margin-left: 60px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--60 {
    margin-left: 60px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--65 {
    margin-left: 65px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--65 {
    margin-left: 65px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--70 {
    margin-left: 70px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--70 {
    margin-left: 70px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--75 {
    margin-left: 75px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--75 {
    margin-left: 75px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--80 {
    margin-left: 80px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--80 {
    margin-left: 80px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--85 {
    margin-left: 85px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--85 {
    margin-left: 85px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--90 {
    margin-left: 90px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--90 {
    margin-left: 90px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--95 {
    margin-left: 95px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--95 {
    margin-left: 95px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--100 {
    margin-left: 100px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--100 {
    margin-left: 100px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--105 {
    margin-left: 105px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--105 {
    margin-left: 105px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--110 {
    margin-left: 110px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--110 {
    margin-left: 110px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--115 {
    margin-left: 115px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--115 {
    margin-left: 115px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--120 {
    margin-left: 120px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--120 {
    margin-left: 120px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--125 {
    margin-left: 125px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--125 {
    margin-left: 125px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--130 {
    margin-left: 130px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--130 {
    margin-left: 130px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--135 {
    margin-left: 135px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--135 {
    margin-left: 135px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--140 {
    margin-left: 140px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--140 {
    margin-left: 140px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--145 {
    margin-left: 145px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--145 {
    margin-left: 145px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--150 {
    margin-left: 150px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--150 {
    margin-left: 150px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--155 {
    margin-left: 155px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--155 {
    margin-left: 155px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--160 {
    margin-left: 160px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--160 {
    margin-left: 160px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--165 {
    margin-left: 165px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--165 {
    margin-left: 165px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--170 {
    margin-left: 170px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--170 {
    margin-left: 170px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--175 {
    margin-left: 175px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--175 {
    margin-left: 175px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--180 {
    margin-left: 180px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--180 {
    margin-left: 180px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--185 {
    margin-left: 185px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--185 {
    margin-left: 185px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--190 {
    margin-left: 190px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--190 {
    margin-left: 190px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--195 {
    margin-left: 195px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--195 {
    margin-left: 195px;
  }
}

@media (min-width: 1200px) {
  .ml-xl--200 {
    margin-left: 200px !important;
  }
}

@media (min-width: 1600px) {
  .ml-xl--200 {
    margin-left: 200px;
  }
}

/*-- Responsive Margins Right --*/
@media (min-width: 576px) {
  .mr-sm--0 {
    margin-right: 0px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--0 {
    margin-right: 0px;
  }
}

@media (min-width: 576px) {
  .mr-sm--5 {
    margin-right: 5px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--5 {
    margin-right: 5px;
  }
}

@media (min-width: 576px) {
  .mr-sm--10 {
    margin-right: 10px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--10 {
    margin-right: 10px;
  }
}

@media (min-width: 576px) {
  .mr-sm--15 {
    margin-right: 15px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--15 {
    margin-right: 15px;
  }
}

@media (min-width: 576px) {
  .mr-sm--20 {
    margin-right: 20px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--20 {
    margin-right: 20px;
  }
}

@media (min-width: 576px) {
  .mr-sm--25 {
    margin-right: 25px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--25 {
    margin-right: 25px;
  }
}

@media (min-width: 576px) {
  .mr-sm--30 {
    margin-right: 30px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--30 {
    margin-right: 30px;
  }
}

@media (min-width: 576px) {
  .mr-sm--35 {
    margin-right: 35px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--35 {
    margin-right: 35px;
  }
}

@media (min-width: 576px) {
  .mr-sm--40 {
    margin-right: 40px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--40 {
    margin-right: 40px;
  }
}

@media (min-width: 576px) {
  .mr-sm--45 {
    margin-right: 45px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--45 {
    margin-right: 45px;
  }
}

@media (min-width: 576px) {
  .mr-sm--50 {
    margin-right: 50px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--50 {
    margin-right: 50px;
  }
}

@media (min-width: 576px) {
  .mr-sm--55 {
    margin-right: 55px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--55 {
    margin-right: 55px;
  }
}

@media (min-width: 576px) {
  .mr-sm--60 {
    margin-right: 60px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--60 {
    margin-right: 60px;
  }
}

@media (min-width: 576px) {
  .mr-sm--65 {
    margin-right: 65px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--65 {
    margin-right: 65px;
  }
}

@media (min-width: 576px) {
  .mr-sm--70 {
    margin-right: 70px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--70 {
    margin-right: 70px;
  }
}

@media (min-width: 576px) {
  .mr-sm--75 {
    margin-right: 75px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--75 {
    margin-right: 75px;
  }
}

@media (min-width: 576px) {
  .mr-sm--80 {
    margin-right: 80px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--80 {
    margin-right: 80px;
  }
}

@media (min-width: 576px) {
  .mr-sm--85 {
    margin-right: 85px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--85 {
    margin-right: 85px;
  }
}

@media (min-width: 576px) {
  .mr-sm--90 {
    margin-right: 90px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--90 {
    margin-right: 90px;
  }
}

@media (min-width: 576px) {
  .mr-sm--95 {
    margin-right: 95px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--95 {
    margin-right: 95px;
  }
}

@media (min-width: 576px) {
  .mr-sm--100 {
    margin-right: 100px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--100 {
    margin-right: 100px;
  }
}

@media (min-width: 576px) {
  .mr-sm--105 {
    margin-right: 105px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--105 {
    margin-right: 105px;
  }
}

@media (min-width: 576px) {
  .mr-sm--110 {
    margin-right: 110px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--110 {
    margin-right: 110px;
  }
}

@media (min-width: 576px) {
  .mr-sm--115 {
    margin-right: 115px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--115 {
    margin-right: 115px;
  }
}

@media (min-width: 576px) {
  .mr-sm--120 {
    margin-right: 120px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--120 {
    margin-right: 120px;
  }
}

@media (min-width: 576px) {
  .mr-sm--125 {
    margin-right: 125px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--125 {
    margin-right: 125px;
  }
}

@media (min-width: 576px) {
  .mr-sm--130 {
    margin-right: 130px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--130 {
    margin-right: 130px;
  }
}

@media (min-width: 576px) {
  .mr-sm--135 {
    margin-right: 135px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--135 {
    margin-right: 135px;
  }
}

@media (min-width: 576px) {
  .mr-sm--140 {
    margin-right: 140px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--140 {
    margin-right: 140px;
  }
}

@media (min-width: 576px) {
  .mr-sm--145 {
    margin-right: 145px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--145 {
    margin-right: 145px;
  }
}

@media (min-width: 576px) {
  .mr-sm--150 {
    margin-right: 150px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--150 {
    margin-right: 150px;
  }
}

@media (min-width: 576px) {
  .mr-sm--155 {
    margin-right: 155px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--155 {
    margin-right: 155px;
  }
}

@media (min-width: 576px) {
  .mr-sm--160 {
    margin-right: 160px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--160 {
    margin-right: 160px;
  }
}

@media (min-width: 576px) {
  .mr-sm--165 {
    margin-right: 165px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--165 {
    margin-right: 165px;
  }
}

@media (min-width: 576px) {
  .mr-sm--170 {
    margin-right: 170px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--170 {
    margin-right: 170px;
  }
}

@media (min-width: 576px) {
  .mr-sm--175 {
    margin-right: 175px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--175 {
    margin-right: 175px;
  }
}

@media (min-width: 576px) {
  .mr-sm--180 {
    margin-right: 180px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--180 {
    margin-right: 180px;
  }
}

@media (min-width: 576px) {
  .mr-sm--185 {
    margin-right: 185px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--185 {
    margin-right: 185px;
  }
}

@media (min-width: 576px) {
  .mr-sm--190 {
    margin-right: 190px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--190 {
    margin-right: 190px;
  }
}

@media (min-width: 576px) {
  .mr-sm--195 {
    margin-right: 195px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--195 {
    margin-right: 195px;
  }
}

@media (min-width: 576px) {
  .mr-sm--200 {
    margin-right: 200px !important;
  }
}

@media (min-width: 768px) {
  .mr-sm--200 {
    margin-right: 200px;
  }
}

@media (min-width: 768px) {
  .mr-md--0 {
    margin-right: 0px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--0 {
    margin-right: 0px;
  }
}

@media (min-width: 768px) {
  .mr-md--5 {
    margin-right: 5px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--5 {
    margin-right: 5px;
  }
}

@media (min-width: 768px) {
  .mr-md--10 {
    margin-right: 10px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--10 {
    margin-right: 10px;
  }
}

@media (min-width: 768px) {
  .mr-md--15 {
    margin-right: 15px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--15 {
    margin-right: 15px;
  }
}

@media (min-width: 768px) {
  .mr-md--20 {
    margin-right: 20px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--20 {
    margin-right: 20px;
  }
}

@media (min-width: 768px) {
  .mr-md--25 {
    margin-right: 25px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--25 {
    margin-right: 25px;
  }
}

@media (min-width: 768px) {
  .mr-md--30 {
    margin-right: 30px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--30 {
    margin-right: 30px;
  }
}

@media (min-width: 768px) {
  .mr-md--35 {
    margin-right: 35px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--35 {
    margin-right: 35px;
  }
}

@media (min-width: 768px) {
  .mr-md--40 {
    margin-right: 40px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--40 {
    margin-right: 40px;
  }
}

@media (min-width: 768px) {
  .mr-md--45 {
    margin-right: 45px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--45 {
    margin-right: 45px;
  }
}

@media (min-width: 768px) {
  .mr-md--50 {
    margin-right: 50px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--50 {
    margin-right: 50px;
  }
}

@media (min-width: 768px) {
  .mr-md--55 {
    margin-right: 55px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--55 {
    margin-right: 55px;
  }
}

@media (min-width: 768px) {
  .mr-md--60 {
    margin-right: 60px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--60 {
    margin-right: 60px;
  }
}

@media (min-width: 768px) {
  .mr-md--65 {
    margin-right: 65px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--65 {
    margin-right: 65px;
  }
}

@media (min-width: 768px) {
  .mr-md--70 {
    margin-right: 70px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--70 {
    margin-right: 70px;
  }
}

@media (min-width: 768px) {
  .mr-md--75 {
    margin-right: 75px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--75 {
    margin-right: 75px;
  }
}

@media (min-width: 768px) {
  .mr-md--80 {
    margin-right: 80px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--80 {
    margin-right: 80px;
  }
}

@media (min-width: 768px) {
  .mr-md--85 {
    margin-right: 85px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--85 {
    margin-right: 85px;
  }
}

@media (min-width: 768px) {
  .mr-md--90 {
    margin-right: 90px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--90 {
    margin-right: 90px;
  }
}

@media (min-width: 768px) {
  .mr-md--95 {
    margin-right: 95px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--95 {
    margin-right: 95px;
  }
}

@media (min-width: 768px) {
  .mr-md--100 {
    margin-right: 100px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--100 {
    margin-right: 100px;
  }
}

@media (min-width: 768px) {
  .mr-md--105 {
    margin-right: 105px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--105 {
    margin-right: 105px;
  }
}

@media (min-width: 768px) {
  .mr-md--110 {
    margin-right: 110px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--110 {
    margin-right: 110px;
  }
}

@media (min-width: 768px) {
  .mr-md--115 {
    margin-right: 115px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--115 {
    margin-right: 115px;
  }
}

@media (min-width: 768px) {
  .mr-md--120 {
    margin-right: 120px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--120 {
    margin-right: 120px;
  }
}

@media (min-width: 768px) {
  .mr-md--125 {
    margin-right: 125px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--125 {
    margin-right: 125px;
  }
}

@media (min-width: 768px) {
  .mr-md--130 {
    margin-right: 130px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--130 {
    margin-right: 130px;
  }
}

@media (min-width: 768px) {
  .mr-md--135 {
    margin-right: 135px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--135 {
    margin-right: 135px;
  }
}

@media (min-width: 768px) {
  .mr-md--140 {
    margin-right: 140px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--140 {
    margin-right: 140px;
  }
}

@media (min-width: 768px) {
  .mr-md--145 {
    margin-right: 145px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--145 {
    margin-right: 145px;
  }
}

@media (min-width: 768px) {
  .mr-md--150 {
    margin-right: 150px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--150 {
    margin-right: 150px;
  }
}

@media (min-width: 768px) {
  .mr-md--155 {
    margin-right: 155px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--155 {
    margin-right: 155px;
  }
}

@media (min-width: 768px) {
  .mr-md--160 {
    margin-right: 160px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--160 {
    margin-right: 160px;
  }
}

@media (min-width: 768px) {
  .mr-md--165 {
    margin-right: 165px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--165 {
    margin-right: 165px;
  }
}

@media (min-width: 768px) {
  .mr-md--170 {
    margin-right: 170px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--170 {
    margin-right: 170px;
  }
}

@media (min-width: 768px) {
  .mr-md--175 {
    margin-right: 175px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--175 {
    margin-right: 175px;
  }
}

@media (min-width: 768px) {
  .mr-md--180 {
    margin-right: 180px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--180 {
    margin-right: 180px;
  }
}

@media (min-width: 768px) {
  .mr-md--185 {
    margin-right: 185px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--185 {
    margin-right: 185px;
  }
}

@media (min-width: 768px) {
  .mr-md--190 {
    margin-right: 190px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--190 {
    margin-right: 190px;
  }
}

@media (min-width: 768px) {
  .mr-md--195 {
    margin-right: 195px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--195 {
    margin-right: 195px;
  }
}

@media (min-width: 768px) {
  .mr-md--200 {
    margin-right: 200px !important;
  }
}

@media (min-width: 992px) {
  .mr-md--200 {
    margin-right: 200px;
  }
}

@media (min-width: 992px) {
  .mr-lg--0 {
    margin-right: 0px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--0 {
    margin-right: 0px;
  }
}

@media (min-width: 992px) {
  .mr-lg--5 {
    margin-right: 5px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--5 {
    margin-right: 5px;
  }
}

@media (min-width: 992px) {
  .mr-lg--10 {
    margin-right: 10px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--10 {
    margin-right: 10px;
  }
}

@media (min-width: 992px) {
  .mr-lg--15 {
    margin-right: 15px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--15 {
    margin-right: 15px;
  }
}

@media (min-width: 992px) {
  .mr-lg--20 {
    margin-right: 20px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--20 {
    margin-right: 20px;
  }
}

@media (min-width: 992px) {
  .mr-lg--25 {
    margin-right: 25px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--25 {
    margin-right: 25px;
  }
}

@media (min-width: 992px) {
  .mr-lg--30 {
    margin-right: 30px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--30 {
    margin-right: 30px;
  }
}

@media (min-width: 992px) {
  .mr-lg--35 {
    margin-right: 35px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--35 {
    margin-right: 35px;
  }
}

@media (min-width: 992px) {
  .mr-lg--40 {
    margin-right: 40px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--40 {
    margin-right: 40px;
  }
}

@media (min-width: 992px) {
  .mr-lg--45 {
    margin-right: 45px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--45 {
    margin-right: 45px;
  }
}

@media (min-width: 992px) {
  .mr-lg--50 {
    margin-right: 50px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--50 {
    margin-right: 50px;
  }
}

@media (min-width: 992px) {
  .mr-lg--55 {
    margin-right: 55px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--55 {
    margin-right: 55px;
  }
}

@media (min-width: 992px) {
  .mr-lg--60 {
    margin-right: 60px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--60 {
    margin-right: 60px;
  }
}

@media (min-width: 992px) {
  .mr-lg--65 {
    margin-right: 65px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--65 {
    margin-right: 65px;
  }
}

@media (min-width: 992px) {
  .mr-lg--70 {
    margin-right: 70px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--70 {
    margin-right: 70px;
  }
}

@media (min-width: 992px) {
  .mr-lg--75 {
    margin-right: 75px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--75 {
    margin-right: 75px;
  }
}

@media (min-width: 992px) {
  .mr-lg--80 {
    margin-right: 80px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--80 {
    margin-right: 80px;
  }
}

@media (min-width: 992px) {
  .mr-lg--85 {
    margin-right: 85px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--85 {
    margin-right: 85px;
  }
}

@media (min-width: 992px) {
  .mr-lg--90 {
    margin-right: 90px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--90 {
    margin-right: 90px;
  }
}

@media (min-width: 992px) {
  .mr-lg--95 {
    margin-right: 95px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--95 {
    margin-right: 95px;
  }
}

@media (min-width: 992px) {
  .mr-lg--100 {
    margin-right: 100px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--100 {
    margin-right: 100px;
  }
}

@media (min-width: 992px) {
  .mr-lg--105 {
    margin-right: 105px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--105 {
    margin-right: 105px;
  }
}

@media (min-width: 992px) {
  .mr-lg--110 {
    margin-right: 110px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--110 {
    margin-right: 110px;
  }
}

@media (min-width: 992px) {
  .mr-lg--115 {
    margin-right: 115px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--115 {
    margin-right: 115px;
  }
}

@media (min-width: 992px) {
  .mr-lg--120 {
    margin-right: 120px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--120 {
    margin-right: 120px;
  }
}

@media (min-width: 992px) {
  .mr-lg--125 {
    margin-right: 125px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--125 {
    margin-right: 125px;
  }
}

@media (min-width: 992px) {
  .mr-lg--130 {
    margin-right: 130px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--130 {
    margin-right: 130px;
  }
}

@media (min-width: 992px) {
  .mr-lg--135 {
    margin-right: 135px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--135 {
    margin-right: 135px;
  }
}

@media (min-width: 992px) {
  .mr-lg--140 {
    margin-right: 140px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--140 {
    margin-right: 140px;
  }
}

@media (min-width: 992px) {
  .mr-lg--145 {
    margin-right: 145px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--145 {
    margin-right: 145px;
  }
}

@media (min-width: 992px) {
  .mr-lg--150 {
    margin-right: 150px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--150 {
    margin-right: 150px;
  }
}

@media (min-width: 992px) {
  .mr-lg--155 {
    margin-right: 155px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--155 {
    margin-right: 155px;
  }
}

@media (min-width: 992px) {
  .mr-lg--160 {
    margin-right: 160px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--160 {
    margin-right: 160px;
  }
}

@media (min-width: 992px) {
  .mr-lg--165 {
    margin-right: 165px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--165 {
    margin-right: 165px;
  }
}

@media (min-width: 992px) {
  .mr-lg--170 {
    margin-right: 170px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--170 {
    margin-right: 170px;
  }
}

@media (min-width: 992px) {
  .mr-lg--175 {
    margin-right: 175px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--175 {
    margin-right: 175px;
  }
}

@media (min-width: 992px) {
  .mr-lg--180 {
    margin-right: 180px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--180 {
    margin-right: 180px;
  }
}

@media (min-width: 992px) {
  .mr-lg--185 {
    margin-right: 185px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--185 {
    margin-right: 185px;
  }
}

@media (min-width: 992px) {
  .mr-lg--190 {
    margin-right: 190px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--190 {
    margin-right: 190px;
  }
}

@media (min-width: 992px) {
  .mr-lg--195 {
    margin-right: 195px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--195 {
    margin-right: 195px;
  }
}

@media (min-width: 992px) {
  .mr-lg--200 {
    margin-right: 200px !important;
  }
}

@media (min-width: 1200px) {
  .mr-lg--200 {
    margin-right: 200px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--0 {
    margin-right: 0px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--0 {
    margin-right: 0px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--5 {
    margin-right: 5px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--5 {
    margin-right: 5px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--10 {
    margin-right: 10px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--10 {
    margin-right: 10px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--15 {
    margin-right: 15px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--15 {
    margin-right: 15px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--20 {
    margin-right: 20px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--20 {
    margin-right: 20px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--25 {
    margin-right: 25px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--25 {
    margin-right: 25px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--30 {
    margin-right: 30px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--30 {
    margin-right: 30px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--35 {
    margin-right: 35px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--35 {
    margin-right: 35px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--40 {
    margin-right: 40px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--40 {
    margin-right: 40px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--45 {
    margin-right: 45px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--45 {
    margin-right: 45px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--50 {
    margin-right: 50px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--50 {
    margin-right: 50px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--55 {
    margin-right: 55px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--55 {
    margin-right: 55px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--60 {
    margin-right: 60px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--60 {
    margin-right: 60px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--65 {
    margin-right: 65px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--65 {
    margin-right: 65px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--70 {
    margin-right: 70px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--70 {
    margin-right: 70px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--75 {
    margin-right: 75px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--75 {
    margin-right: 75px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--80 {
    margin-right: 80px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--80 {
    margin-right: 80px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--85 {
    margin-right: 85px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--85 {
    margin-right: 85px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--90 {
    margin-right: 90px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--90 {
    margin-right: 90px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--95 {
    margin-right: 95px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--95 {
    margin-right: 95px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--100 {
    margin-right: 100px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--100 {
    margin-right: 100px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--105 {
    margin-right: 105px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--105 {
    margin-right: 105px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--110 {
    margin-right: 110px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--110 {
    margin-right: 110px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--115 {
    margin-right: 115px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--115 {
    margin-right: 115px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--120 {
    margin-right: 120px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--120 {
    margin-right: 120px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--125 {
    margin-right: 125px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--125 {
    margin-right: 125px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--130 {
    margin-right: 130px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--130 {
    margin-right: 130px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--135 {
    margin-right: 135px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--135 {
    margin-right: 135px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--140 {
    margin-right: 140px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--140 {
    margin-right: 140px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--145 {
    margin-right: 145px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--145 {
    margin-right: 145px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--150 {
    margin-right: 150px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--150 {
    margin-right: 150px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--155 {
    margin-right: 155px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--155 {
    margin-right: 155px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--160 {
    margin-right: 160px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--160 {
    margin-right: 160px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--165 {
    margin-right: 165px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--165 {
    margin-right: 165px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--170 {
    margin-right: 170px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--170 {
    margin-right: 170px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--175 {
    margin-right: 175px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--175 {
    margin-right: 175px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--180 {
    margin-right: 180px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--180 {
    margin-right: 180px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--185 {
    margin-right: 185px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--185 {
    margin-right: 185px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--190 {
    margin-right: 190px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--190 {
    margin-right: 190px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--195 {
    margin-right: 195px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--195 {
    margin-right: 195px;
  }
}

@media (min-width: 1200px) {
  .mr-xl--200 {
    margin-right: 200px !important;
  }
}

@media (min-width: 1600px) {
  .mr-xl--200 {
    margin-right: 200px;
  }
}

/*==== 0.Common -------*/
.mb--30 {
  margin-bottom: 30px !important;
}

.mb-d--30 {
  margin-bottom: -30px !important;
}

@media (min-width: 992px) {
  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0 !important;
  }
}

@media (min-width: 480px) {
  .col-xs-6 {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
}

.section-title h2, .section-title .title {
  color: #19191b;
  font-family: "Sofia Pro", sans-serif;
  font-weight: 700;
  letter-spacing: -2.5px;
  font-size: 40px;
  line-height: 54px;
  margin-bottom: 15px;
}

@media (min-width: 480px) {
  .section-title h2, .section-title .title {
    font-size: 48px;
    line-height: 58px;
  }
}

@media (min-width: 576px) {
  .section-title h2, .section-title .title {
    font-size: 50px;
    line-height: 62px;
  }
}

@media (min-width: 992px) {
  .section-title h2, .section-title .title {
    font-size: 60px;
    line-height: 70px;
  }
}

.section-title p {
  color: #696871;
  font-family: "Sofia Pro", sans-serif;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

.title {
  color: #19191b;
}

p {
  color: #696871;
}

@-webkit-keyframes spin-reverse {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

@keyframes spin-reverse {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg) translate(-50%);
            transform: rotate(0deg) translate(-50%);
    -webkit-transform-origin: center;
            transform-origin: center;
  }
  to {
    -webkit-transform: rotate(360deg) translate(-50%);
            transform: rotate(360deg) translate(-50%);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg) translate(-50%);
            transform: rotate(0deg) translate(-50%);
    -webkit-transform-origin: center;
            transform-origin: center;
  }
  to {
    -webkit-transform: rotate(360deg) translate(-50%);
            transform: rotate(360deg) translate(-50%);
  }
}

.mb-d-30 {
  margin-bottom: -30px;
}

/* Bounce To Left */
.hvr-bounce-to-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  position: relative;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  z-index: 1;
}

.hvr-bounce-to-left:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.hvr-bounce-to-left:hover, .hvr-bounce-to-left:focus, .hvr-bounce-to-left:active {
  color: #000 !important;
}

.hvr-bounce-to-left:hover:before, .hvr-bounce-to-left:focus:before, .hvr-bounce-to-left:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.z-index-1 {
  z-index: 1;
}

.btn-height--45 {
  height: 45px !important;
}

.btn-width--141 {
  width: 141px !important;
}

.btn-header {
  font-size: 16px !important;
  width: 141px !important;
  height: 45px !important;
}

.btn--primary, .btn--red {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  width: 200px;
  height: 60px;
  border-radius: 5px;
  border: 1px solid #C31A12;
  background: #f04037;
  color: #FFFFFF;
  font-family: "CircularStd", sans-serif;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.66px;
}

.btn--primary.hvr-bounce-to-left::before, .btn--red.hvr-bounce-to-left::before {
  background: #F9544B;
  color: #fff;
}

.btn--primary.hvr-bounce-to-left:hover::before, .btn--red.hvr-bounce-to-left:hover::before {
  background: #F9544B;
  color: #fff;
}

.btn--primary.hvr-bounce-to-left:hover, .btn--primary.hvr-bounce-to-left:focus, .btn--primary.hvr-bounce-to-left:active, .btn--red.hvr-bounce-to-left:hover, .btn--red.hvr-bounce-to-left:focus, .btn--red.hvr-bounce-to-left:active {
  color: #fff !important;
}

.btn--yellow {
  border: 1px solid #f0c931;
  background-color: #fedc5a;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  width: 200px;
  height: 60px;
  border-radius: 5px;
  font-family: "CircularStd", sans-serif;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.66px;
  color: rgba(0, 0, 0, 0.79);
}

.btn--yellow.hvr-bounce-to-left::before {
  background: rgba(254, 221, 90, 0.69);
  color: rgba(0, 0, 0, 0.79);
}

.btn--yellow.hvr-bounce-to-left:hover::before {
  background: rgba(254, 221, 90, 0.69);
  color: rgba(0, 0, 0, 0.79);
}

.btn--yellow.hvr-bounce-to-left:hover, .btn--yellow.hvr-bounce-to-left:focus, .btn--yellow.hvr-bounce-to-left:active {
  color: rgba(0, 0, 0, 0.79) !important;
}

.position-lg-absolute {
  position: static !important;
}

@media (min-width: 992px) {
  .position-lg-absolute {
    position: absolute !important;
  }
}

/*==== 1.Header -------*/
.site-navbar {
  padding: 10px 0 10px 0;
}

@media (min-width: 992px) {
  .header-btns {
    margin-left: 10px;
  }
}

@media (min-width: 992px) {
  .main-menu {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
}

@media (min-width: 992px) {
  .main-menu > li > .nav-link {
    color: #353638 !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding-top: 18px !important;
    padding-bottom: 18px !important;
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
}

.main-menu > li > .nav-link:hover {
  color: #f04037 !important;
}

@media (min-width: 992px) {
  .main-menu .nav-item.dropdown {
    position: relative;
    z-index: 99;
  }
}

@media (min-width: 992px) {
  .main-menu .nav-item.dropdown:hover > .menu-dropdown {
    top: 90%;
    opacity: 1;
    pointer-events: visible;
  }
}

@media (min-width: 992px) {
  .offcanvas-active.navbar-expand-lg .btn-close-off-canvas {
    display: none;
  }
}

@media (min-width: 320px) and (max-width: 992px) {
  .offcanvas-active.navbar-expand-lg .navbar-collapse {
    display: block;
    position: fixed;
    top: 0;
    background: #fff;
    left: -100%;
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
    -webkit-transition: left .4s;
    transition: left .4s;
    z-index: 9999;
    box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
    padding-top: 50px;
    width: 250px;
  }
}

@media (min-width: 320px) and (max-width: 992px) and (min-width: 380px) {
  .offcanvas-active.navbar-expand-lg .navbar-collapse {
    width: 300px;
  }
}

@media (min-width: 320px) and (max-width: 992px) and (min-width: 576px) {
  .offcanvas-active.navbar-expand-lg .navbar-collapse {
    width: 350px;
  }
}

@media (min-width: 320px) and (max-width: 992px) {
  .offcanvas-active.navbar-expand-lg .navbar-collapse.show {
    left: 0%;
  }
  .offcanvas-active.navbar-expand-lg .navbar-collapse.show ~ .btn-close-off-canvas .icon-burger {
    display: none;
  }
  .offcanvas-active.navbar-expand-lg .navbar-collapse.show ~ .btn-close-off-canvas .icon-close {
    display: block;
  }
  .offcanvas-active.navbar-expand-lg .navbar-collapse.collapsing {
    -webkit-transition: height 0s;
    transition: height 0s;
    height: 100%;
  }
  .offcanvas-active.navbar-expand-lg .btn-close-off-canvas .icon-burger {
    display: block;
  }
  .offcanvas-active.navbar-expand-lg .btn-close-off-canvas .icon-close {
    display: none;
  }
  .offcanvas-active.navbar-expand-lg .main-menu > li {
    padding-bottom: 13px;
    margin-bottom: 13px;
    border-bottom: 1px solid #ECECEC;
  }
  .offcanvas-active.navbar-expand-lg .main-menu > li > .nav-link {
    padding: 0;
  }
  .offcanvas-active.navbar-expand-lg .main-menu > li .menu-dropdown {
    border: 0;
    border-radius: 0;
    min-width: auto;
    padding: 0;
  }
  .offcanvas-active.navbar-expand-lg .main-menu > li .menu-dropdown > li {
    padding-top: 13px;
    margin-top: 13px;
    border-top: 1px solid #ECECEC;
    padding-left: 20px;
  }
  .offcanvas-active.navbar-expand-lg .main-menu > li .menu-dropdown > li.single-item h3 {
    font-size: 15px;
    margin-bottom: 5px;
    font-weight: 600;
    line-height: 1;
  }
  .offcanvas-active.navbar-expand-lg .main-menu > li .menu-dropdown > li.single-item p {
    font-size: 13px;
  }
  .offcanvas-active.navbar-expand-lg .main-menu > li .menu-dropdown > li.single-item a:hover {
    color: inherit;
  }
  .offcanvas-active.navbar-expand-lg .main-menu > li:last-child {
    border-bottom-color: transparent;
  }
  .offcanvas-active.navbar-expand-lg .main-menu li i {
    margin-left: 8px;
    position: relative;
    top: 3px;
  }
  .offcanvas-active.navbar-expand-lg .main-menu li:hover > a {
    color: #f04037;
  }
  .offcanvas-active.navbar-expand-lg .main-menu a {
    display: block;
  }
}

.toggler-white {
  color: #fff !important;
  border-color: #fff !important;
}

.sticky-header {
  position: absolute !important;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 999;
}

@media (min-width: 992px) {
  .sticky-header {
    position: fixed !important;
    -webkit-transition: .4s;
    transition: .4s;
  }
  .sticky-header.scrolling {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transition: .4s;
    transition: .4s;
  }
  .sticky-header.reveal-header {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
    z-index: 9999;
  }
}

.landing-1 .sticky-header.reveal-header {
  background: #fff;
}

.landing-2 .sticky-header.reveal-header {
  background: #fff;
}

.landing-3 .sticky-header.reveal-header {
  background: #fff;
}

.landing-3.dark-theme .sticky-header.reveal-header {
  background: #19191b;
}

.landing-4 .sticky-header.reveal-header {
  background: #5454d4;
}

.landing-5 .sticky-header.reveal-header {
  background: radial-gradient(circle 961px at 49.94% 0%, #504a93 0%, #413e65 100%);
}

.landing-6 .sticky-header.reveal-header {
  background: #fff;
}

.landing-7 .sticky-header.reveal-header {
  background: #fff;
}

.landing-8 .sticky-header.reveal-header {
  background: #19191b;
}

/*==== 2.Menu -------*/
.main-menu > .dropdown-toggle {
  -webkit-transition: .4s;
  transition: .4s;
}

@media (min-width: 992px) {
  .main-menu .nav-item.dropdown:hover > .dropdown-toggle:after {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.main-menu .drop-menu-item.dropdown > .dropdown-toggle {
  position: relative;
  display: block;
}

.main-menu .drop-menu-item.dropdown > .dropdown-toggle:after {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  position: absolute;
  top: 9px;
  right: 0;
  -webkit-transition: .4s;
  transition: .4s;
}

@media (min-width: 992px) {
  .main-menu .drop-menu-item.dropdown:hover > .dropdown-toggle:after {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@media (min-width: 992px) {
  .menu-dropdown {
    top: 110%;
    position: absolute;
    min-width: 227px;
    max-width: 227px;
    box-shadow: 0 52px 54px rgba(65, 62, 101, 0.3);
    border-radius: 8px;
    border: 1px solid #E5E5E5;
    background-color: #FFFFFF;
    padding: 15px 0px;
    z-index: 99;
    opacity: 0;
    -webkit-transition: opacity .4s,top .4s;
    transition: opacity .4s,top .4s;
    pointer-events: none;
    left: -90%;
    border-radius: 0 0 10px 10px;
    border: 1px solid #eae9f2;
    background-color: #ffffff;
    display: block;
    border-top: 3px solid #5454d4;
  }
}

.menu-dropdown > .drop-menu-item {
  color: #19191b;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.menu-dropdown > .drop-menu-item:hover > a {
  color: #5454d4;
}

.menu-dropdown > .drop-menu-item.dropdown {
  position: relative;
}

@media (min-width: 992px) {
  .menu-dropdown > .drop-menu-item.dropdown:hover > .menu-dropdown {
    top: 10px;
    opacity: 1;
    pointer-events: visible;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.menu-dropdown > .drop-menu-item.dropdown > .menu-dropdown {
  border-top-color: #f04037;
}

@media (min-width: 992px) {
  .menu-dropdown > .drop-menu-item.dropdown > .menu-dropdown {
    top: 10px;
    left: 0%;
    opacity: 0;
    -webkit-transform: translateX(-110%);
            transform: translateX(-110%);
    -webkit-transition: .4s;
    transition: .4s;
    pointer-events: none;
  }
}

@media (min-width: 380px) {
  .menu-dropdown > .drop-menu-item.dropdown > .menu-dropdown > .drop-menu-item {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 992px) {
  .menu-dropdown > .drop-menu-item.dropdown > .menu-dropdown > .drop-menu-item {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.menu-dropdown.dropdown-right {
  left: auto;
  right: -90%;
}

/*==== 3.Landing 1 -------*/
.landing-1 {
  /*>>>>>>>> Hero area styling <<<<<<<<<*/
  /*>>>>>>>> Brand area styling <<<<<<<<<*/
  /*>>>>>>>> Feature area styling <<<<<<<<<*/
  /*>>>>>>>> Content area styling <<<<<<<<<*/
  /*>>>>>>>> Testimonial area styling <<<<<<<<<*/
}

.landing-1 .btn-height--45 {
  height: 45px !important;
}

.landing-1 .btn-width--141 {
  width: 141px !important;
}

.landing-1 .btn-header {
  font-size: 16px !important;
  width: 141px !important;
  height: 45px !important;
}

.landing-1 .btn--primary, .landing-1 .btn--red {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  width: 200px;
  height: 60px;
  border-radius: 5px;
  border: 1px solid #c31a12;
  background: #f04037;
  color: #ffffff;
  font-family: "CircularStd", sans-serif;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.66px;
}

.landing-1 .btn--primary.hvr-bounce-to-left::before, .landing-1 .btn--red.hvr-bounce-to-left::before {
  background: #F9544B;
  color: #fff;
}

.landing-1 .btn--primary.hvr-bounce-to-left:hover::before, .landing-1 .btn--red.hvr-bounce-to-left:hover::before {
  background: #F9544B;
  color: #fff;
}

.landing-1 .btn--primary.hvr-bounce-to-left:hover, .landing-1 .btn--primary.hvr-bounce-to-left:focus, .landing-1 .btn--primary.hvr-bounce-to-left:active, .landing-1 .btn--red.hvr-bounce-to-left:hover, .landing-1 .btn--red.hvr-bounce-to-left:focus, .landing-1 .btn--red.hvr-bounce-to-left:active {
  color: #fff !important;
}

.landing-1 .btn--secondary {
  width: 216px;
  height: 60px;
  border-radius: 5px;
  border: 1px solid #eae9f2;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  color: #ffffff;
  font-family: "CircularStd", sans-serif;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.66px;
  background-image: linear-gradient(to bottom right, #4454A3, #5FBAD8);
}

.landing-1 .image-card {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  box-shadow: 0 32px 34px rgba(28, 27, 32, 0.2);
  border-radius: 8px;
  padding-top: 18px;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 18px;
  max-width: 305px;
  min-width: 305px;
}

.landing-1 .image-card .card-icon {
  min-width: 31px;
  max-width: 31px;
  min-height: 31px;
  max-height: 31px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  border-radius: 500px;
  margin-right: 15px;
}

.landing-1 .image-card .card-icon i {
  font-size: 16px;
  color: #fff;
}

.landing-1 .image-card p {
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  margin-bottom: 0;
  text-align: left;
  line-height: 20px;
}

.landing-1 .image-card.card-blue {
  background-image: linear-gradient(to bottom right, #4454A3, #5FBAD8);
}

.landing-1 .image-card.card-blue .card-icon {
  background-color: #fedc5a;
}

.landing-1 .image-card.card-yellow {
  background-color: #fedc5a;
}
.landing-1 .image-card.card-red {
  background-color: #f43c3c;
}

.landing-1 .image-card.card-yellow .card-icon {
  background-color: #5454d4;
}
.landing-1 .image-card.card-red .card-icon {
  background-color: #000;
}

.landing-1 .image-card.card-yellow p {
  color: #19191b;
}
.landing-1 .image-card.card-red p {
  color: #fff;
}

.landing-1 .image-card-2 {
  display: -webkit-box;
  display: flex;
  box-shadow: 0 32px 34px rgba(28, 27, 32, 0.2);
  border-radius: 10px;
  padding-top: 18px;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 18px;
  max-width: 305px;
  min-width: 305px;
}

.landing-1 .image-card-2 .card-icon {
  background-color: #5454d4;
  min-width: 31px;
  max-width: 31px;
  min-height: 31px;
  max-height: 31px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  border-radius: 500px;
  margin-right: 15px;
  margin-top: 8px;
}

.landing-1 .image-card-2 .card-icon i {
  font-size: 16px;
  color: #fff;
}

.landing-1 .image-card-2 .card-text {
  padding-right: 40px;
}

.landing-1 .image-card-2 .card-text .date {
  opacity: 0.48;
  color: #19191b;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: -0.44px;
}

.landing-1 .image-card-2 .card-text p {
  color: #19191b;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  margin-bottom: 0;
}

.landing-1 .image-card-2.card-yellow {
  background-color: #fedc5a;
}

.landing-1 .image-card-2.card-red {
  background-color: #f04037;
}

.landing-1 .image-card-2.card-red .date {
  color: #fff;
}

.landing-1 .image-card-2.card-red p {
  color: #fff;
}

.landing-1 .header-btns {
  margin-right: 10px;
}

@media (min-width: 480px) {
  .landing-1 .header-btns {
    margin-right: 15px;
  }
}

@media (min-width: 992px) {
  .landing-1 .header-btns {
    margin-right: 0;
  }
}

.landing-1 .header-btns a {
  font-size: 14px !important;
  width: 120px !important;
  height: 34px !important;
}

@media (min-width: 370px) {
  .landing-1 .header-btns a {
    font-size: 16px !important;
    width: 141px !important;
    height: 45px !important;
  }
}

.landing-1 .hero-area {
  padding-top: 120px;
}

@media (min-width: 768px) {
  .landing-1 .hero-area {
    padding-top: 150px;
  }
}

.landing-1 .hero-shape {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.landing-1 .hero-content {
  padding-right: 60px;
}

.landing-1 .hero-content .title {
  color: #19191b;
  font-family: "CircularStd", sans-serif;
  font-weight: 700;
  letter-spacing: -2.81px;
  font-size: 50px;
  line-height: 56px;
  margin-bottom: 30px;
}

@media (min-width: 576px) {
  .landing-1 .hero-content .title {
    font-size: 66px;
    line-height: 70px;
  }
}

@media (min-width: 992px) {
  .landing-1 .hero-content .title {
    font-size: 76px;
    line-height: 84px;
  }
}

@media (min-width: 1200px) {
  .landing-1 .hero-content .title {
    font-size: 80px;
    line-height: 84px;
  }
}

.landing-1 .hero-content p {
  color: #696871;
  font-family: "CircularStd", sans-serif;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

.landing-1 .hero-content .hero-btn {
  margin-top: 34px;
}

.landing-1 .hero-content .video-link {
  color: #5454d4;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  margin-top: 12px;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
}

.landing-1 .hero-content .video-link i {
  margin-right: 6px;
}

.landing-1 .hero-img-group {
  position: relative;
  text-align: right;
}

.landing-1 .hero-img-group .img-main {
  text-align: center;
}

@media (min-width: 992px) {
  .landing-1 .hero-img-group .img-main {
    text-align: right;
  }
}

.landing-1 .hero-img-group .img-main img {
  max-width: 80%;
}

@media (min-width: 400px) {
  .landing-1 .hero-img-group .img-main img {
    max-width: 50%;
  }
}

@media (min-width: 576px) {
  .landing-1 .hero-img-group .img-main img {
    max-width: 60%;
  }
}

@media (min-width: 992px) {
  .landing-1 .hero-img-group .img-main img {
    max-width: 100%;
  }
}

.landing-1 .hero-img-group .abs-img {
  position: absolute;
}

.landing-1 .hero-img-group .abs-img.img-1 {
  top: 10%;
  left: 0;
}

@media (min-width: 768px) {
  .landing-1 .hero-img-group .abs-img.img-1 {
    left: -13%;
  }
}

@media (min-width: 992px) {
  .landing-1 .hero-img-group .abs-img.img-1 {
    left: 2%;
  }
}

.landing-1 .hero-img-group .abs-img.img-2 {
  bottom: 12%;
  right: 0;
}

@media (min-width: 576px) {
  .landing-1 .hero-img-group .abs-img.img-2 {
    right: -13%;
  }
}

@media (min-width: 1100px) {
  .landing-1 .hero-img-group .abs-img.img-2 {
    right: -23%;
  }
}

@media (min-width: 1200px) {
  .landing-1 .hero-img-group .abs-img.img-2 {
    right: -13%;
  }
}

@media (min-width: 1366px) {
  .landing-1 .hero-img-group .abs-img.img-2 {
    right: -28%;
  }
}

@media (min-width: 1500px) {
  .landing-1 .hero-img-group .abs-img.img-2 {
    right: -43%;
  }
}

.landing-1 .divider-line {
  width: 100%;
  height: 100%;
  position: relative;
}

.landing-1 .divider-line::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background-color: #eae9f2;
  top: 50%;
  left: 0;
}

.landing-1 .brand-title-wrapper {
  position: relative;
  margin-bottom: 30px;
}

.landing-1 .brand-title-wrapper:after {
  content: "";
  height: 1px;
  position: absolute;
  right: 0;
  top: 50%;
  width: 42%;
  background: #EAE9F2;
  margin-top: 0.5px;
  display: none;
}

@media (min-width: 480px) {
  .landing-1 .brand-title-wrapper:after {
    display: block;
    width: 32%;
  }
}

@media (min-width: 576px) {
  .landing-1 .brand-title-wrapper:after {
    width: 32%;
  }
}

@media (min-width: 768px) {
  .landing-1 .brand-title-wrapper:after {
    width: 42%;
  }
}

@media (min-width: 992px) {
  .landing-1 .brand-title-wrapper:after {
    width: 56%;
  }
}

@media (min-width: 1200px) {
  .landing-1 .brand-title-wrapper:after {
    width: 62%;
  }
}

@media (min-width: 1480px) {
  .landing-1 .brand-title-wrapper:after {
    width: 59%;
  }
}

@media (min-width: 1800px) {
  .landing-1 .brand-title-wrapper:after {
    width: 56%;
  }
}

.landing-1 .brand-title-wrapper .brand-title {
  color: #696871;
  font-family: "CircularStd", sans-serif;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.56px;
  line-height: 28px;
}

.landing-1 .brand-logos {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  flex-wrap: wrap;
  margin: 0 -30px;
  margin-top: 20px;
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .landing-1 .brand-logos {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
}

.landing-1 .brand-logos .single-brand {
  padding-top: 15px;
  padding-bottom: 15px;
  margin-right: 30px;
  margin-left: 30px;
  opacity: .7;
  -webkit-transition: .4s;
  transition: .4s;
}

.landing-1 .brand-logos .single-brand:hover {
  opacity: 1;
}

.landing-1 .feature-section {
  background: #27abdf;
  position: relative;
}

.landing-1 .feature-section .shape-1 {
  position: absolute;
  top: 0;
  right: 0px;
}

.landing-1 .feature-section .shape-2 {
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
}

.landing-1 .feature-section .shape-2 img {
  width: 100%;
}

.landing-1 .feature-section .feature-content .title {
  color: #fff;
  margin-bottom: 25px;
}

.landing-1 .feature-section .feature-content p {
  color: rgba(255, 255, 255, 0.7);
}

.landing-1 .feature-section .feature-content .content-btn {
  margin-top: 35px;
}

.landing-1 .feature-card {
  padding: 25px 30px 12px;
  border-radius: 10px;
  background-color: #ffffff;
  -webkit-transition: .4s;
  transition: .4s;
}

.landing-1 .feature-card .card-icon {
  width: 69px;
  height: 69px;
  border-radius: 50%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  font-size: 24px;
  margin-bottom: 22px;
}

.landing-1 .feature-card .card-icon.red {
  background-color: rgba(240, 64, 55, 0.1);
  color: #f04037;
}

.landing-1 .feature-card .card-icon.yellow {
  background-color: rgba(254, 220, 90, 0.1);
  color: #fedc5a;
}

.landing-1 .feature-card .card-icon.blue {
  background-color: rgba(84, 84, 212, 0.1);
  color: #5454d4;
}

.landing-1 .feature-card .card-icon.ash {
  background-color: rgba(65, 62, 101, 0.1);
  color: #413e65;
}

.landing-1 .feature-card .card-text .title {
  color: #19191b;
  font-family: "Sofia Pro", sans-serif;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.75px;
  margin-bottom: 16px;
}

.landing-1 .feature-card .card-text p {
  color: #696871;
  font-family: "Sofia Pro", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.landing-1 .feature-card:hover {
  box-shadow: 0 52px 54px rgba(65, 62, 101, 0.3);
}

.landing-1 .content-text .title {
  margin-bottom: 25px;
}

.landing-1 .content-text .content-btn {
  margin-top: 35px;
}

.landing-1 .content-widget {
  border-radius: 10px;
  border: 1px solid #eae9f2;
  background-color: #ffffff;
  padding: 20px;
  min-width: 100%;
  width: 100%;
  display: -webkit-inline-box;
  display: inline-flex;
}

@media (min-width: 768px) {
  .landing-1 .content-widget {
    width: 360px;
    min-width: 360px;
  }
}

.landing-1 .content-widget .widget-icon {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  margin-right: 20px;
}

.landing-1 .content-widget .widget-icon.red {
  color: #f04037;
  background-color: rgba(240, 64, 55, 0.1);
}

.landing-1 .content-widget .widget-icon.yellow {
  background-color: rgba(254, 220, 90, 0.1);
  color: #fedc5a;
}

.landing-1 .content-widget .widget-icon.blue {
  background-color: rgba(84, 84, 212, 0.1);
  color: #5454d4;
}

.landing-1 .content-widget .widget-icon.ash {
  background-color: rgba(65, 62, 101, 0.1);
  color: #413e65;
}

.landing-1 .content-widget .widget-text .title {
  color: #19191b;
  font-family: "CircularStd", sans-serif;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -0.66px;
  margin-bottom: 0;
}

.landing-1 .content-widget .widget-text .sub-title {
  color: #696871;
  font-family: "CircularStd", sans-serif;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
}

.landing-1 .content-section2 {
  background-color: #f7f7fb;
  position: relative;
}

.landing-1 .content-section2 .shape-1 {
  position: absolute;
  top: 0;
  left: -100px;
}

.landing-1 .content-img-group img {
  max-width: none;
  width: 100%;
}

.landing-1 .content-img-group-1 {
  position: relative;
  margin-bottom: 30px;
}

@media (min-width: 400px) {
  .landing-1 .content-img-group-1 {
    margin-left: 50px;
  }
}

@media (min-width: 768px) {
  .landing-1 .content-img-group-1 {
    margin-bottom: 0px;
    margin-left: 50px;
  }
}

@media (min-width: 1450px) {
  .landing-1 .content-img-group-1 .img-1 {
    margin-left: 30px;
  }
}

.landing-1 .content-img-group-1 .abs-img {
  position: absolute;
}

.landing-1 .content-img-group-1 .abs-img.img-2 {
  position: absolute;
  top: 52%;
  left: -30px;
  z-index: 2;
}

@media (min-width: 400px) {
  .landing-1 .content-img-group-1 .abs-img.img-2 {
    left: -50px;
  }
}

.landing-1 .content-img-group-1 .abs-img.img-2 .image-card-2 {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

@media (min-width: 400px) {
  .landing-1 .content-img-group-1 .abs-img.img-2 .image-card-2 {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media (min-width: 768px) {
  .landing-1 .content-img-group-1 .abs-img.img-2 {
    left: -14%;
  }
}

@media (min-width: 1450px) {
  .landing-1 .content-img-group-1 .abs-img.img-2 {
    left: -3%;
  }
}

.landing-1 .content-img-group-1 .abs-img.img-3 {
  position: absolute;
  top: 7%;
  right: -8%;
}

@media (min-width: 992px) {
  .landing-1 .content-img-group-1 .abs-img.img-3 {
    max-width: 80%;
    right: 3%;
  }
}

@media (min-width: 1200px) {
  .landing-1 .content-img-group-1 .abs-img.img-3 {
    right: 15%;
  }
}

@media (min-width: 1450px) {
  .landing-1 .content-img-group-1 .abs-img.img-3 {
    max-width: 100%;
    right: 3%;
  }
}

.landing-1 .content-img-group-2 {
  position: relative;
  margin-bottom: 30px;
}

@media (min-width: 400px) {
  .landing-1 .content-img-group-2 {
    margin-left: 50px;
  }
}

@media (min-width: 768px) {
  .landing-1 .content-img-group-2 {
    margin-bottom: 0px;
    margin-left: 50px;
  }
}

@media (min-width: 1450px) {
  .landing-1 .content-img-group-2 .img-1 {
    margin-left: -48px;
  }
}

.landing-1 .content-img-group-2 .abs-img {
  position: absolute;
}

.landing-1 .content-img-group-2 .abs-img.img-2 {
  position: absolute;
  top: 20%;
  left: -14%;
  z-index: 2;
}

@media (min-width: 1450px) {
  .landing-1 .content-img-group-2 .abs-img.img-2 {
    left: -33%;
  }
}

.landing-1 .content-img-group-2 .abs-img.img-2 .image-card-2 {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

@media (min-width: 400px) {
  .landing-1 .content-img-group-2 .abs-img.img-2 .image-card-2 {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.landing-1 .content-img-group-2 .abs-img.img-3 {
  position: absolute;
  top: 10%;
  left: -8%;
}

@media (min-width: 768px) {
  .landing-1 .content-img-group-2 .abs-img.img-3 {
    top: 6%;
    left: -2%;
  }
}

@media (min-width: 992px) {
  .landing-1 .content-img-group-2 .abs-img.img-3 {
    max-width: 80%;
    left: 0%;
  }
}

@media (min-width: 1450px) {
  .landing-1 .content-img-group-2 .abs-img.img-3 {
    max-width: 100%;
    left: -13%;
    top: 3%;
  }
}

.landing-1 .testimonial-section .section-title {
  margin-bottom: 60px;
}

@media (min-width: 992px) {
  .landing-1 .testimonial-section .section-title {
    margin-bottom: 100px;
  }
}

.landing-1 .testimonial-card {
  border-radius: 10px;
  border: 1px solid #eae9f2;
  background-color: #ffffff;
  padding: 25px 25px 16px;
}

.landing-1 .testimonial-card .card-top-area {
  display: -webkit-box;
  display: flex;
  margin-bottom: 5px;
}

.landing-1 .testimonial-card .card-top-area .customer-img {
  flex-basis: 16%;
  margin-right: 6px;
}

.landing-1 .testimonial-card .card-top-area .customer-identity {
  flex-basis: 68%;
}

.landing-1 .testimonial-card .card-top-area .customer-identity .name {
  color: #19191b;
  font-family: "CircularStd", sans-serif;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -0.66px;
  line-height: 28px;
}

.landing-1 .testimonial-card .card-top-area .customer-identity .company {
  color: #696871;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.landing-1 .testimonial-card .card-top-area .review-media {
  flex-basis: 16%;
  text-align: right;
}

.landing-1 .testimonial-card .card-top-area .review-media i {
  font-size: 27px;
  color: #5454d4;
}

.landing-1 .testimonial-card .review-text {
  color: #19191b;
  font-family: "CircularStd", sans-serif;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

.landing-1 .testimonial-card .review-text .secondary-text {
  color: #5454d4;
  font-family: "CircularStd", sans-serif;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

.landing-1 .testimonial-link {
  color: #5454D4;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
}

.landing-1 .testimonial-link i {
  margin-right: 15px;
}

.landing-1 .testimonial-link span {
  font-size: inherit !important;
}

.landing-1 .cta-section {
  background-image: linear-gradient(to bottom right, #4454A3, #5FBAD8);
  position: relative;
}

.landing-1 .cta-section .shape-1 {
  position: absolute;
  top: 0;
  left: 0px;
}

.landing-1 .cta-section .shape-2 {
  position: absolute;
  top: 0;
  right: -270px;
}

.landing-1 .cta-section .section-title .title {
  color: #FFF;
  margin-bottom: 35px;
}

.landing-1 .cta-section .section-title p {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 40px;
}

.landing-1 .cta-form .form-control {
  border-radius: 10px;
  background-color: #ffffff;
  height: 71px;
  margin-bottom: 15px;
  color: #696871;
  font-family: "CircularStd", sans-serif;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.56px;
}

@media (min-width: 768px) {
  .landing-1 .cta-form .form-control {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.landing-1 .cta-form .submit-btn {
  width: 100%;
}

.landing-1 .footer-section {
  background-color: #19191b;
}

.landing-1 .footer-section .footer-top {
  padding: 80px 0 60px;
}

.landing-1 .footer-title {
  color: #ffffff;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 25px;
}

.landing-1 .footer-list a {
  color: rgba(255, 255, 255, 0.7);
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 38px;
}

.landing-1 .copyright-area {
  border-top: 1px solid #2F2F31;
  padding: 15px 0;
}

.landing-1 .copyright-area p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  font-weight: 300;
  letter-spacing: -0.41px;
  line-height: 38px;
  font-family: "CircularStd", sans-serif;
}

.landing-1 .copyright-area .copyright-text {
  margin-bottom: 0;
}

.landing-1 .copyright-area .social-icons {
  display: -webkit-inline-box;
  display: inline-flex;
}

.landing-1 .copyright-area .social-icons li a {
  color: #FFFFFF;
  font-size: 16px;
  -webkit-transition: .4s;
  transition: .4s;
  padding: 0 3px;
  margin: 0 2.5px;
}

.landing-1 .copyright-area .social-icons li a:hover {
  color: #5454d4;
}

/*==== 4.Landing 2 -------*/
.landing-2 {
  /*>>>>>>>> Header area styling <<<<<<<<<*/
  /*>>>>>>>> Feature area styling <<<<<<<<<*/
  /*>>>>>>>> Content area styling <<<<<<<<<*/
  /*>>>>>>>> Content Section 2 area styling <<<<<<<<<*/
  /*>>>>>>>> Testimonial Section styling <<<<<<<<<*/
  /*>>>>>>>> CTA Section styling <<<<<<<<<*/
  /*>>>>>>>> Footer area styling <<<<<<<<<*/
}

.landing-2 .btn-height--45 {
  height: 45px !important;
}

.landing-2 .btn-width--141 {
  width: 141px !important;
}

.landing-2 .btn-header {
  font-size: 16px !important;
  width: 141px !important;
  height: 45px !important;
}

.landing-2 .btn--primary, .landing-2 .btn--red {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  width: 200px;
  height: 60px;
  border-radius: 5px;
  border: 1px solid #c31a12;
  background: #f04037;
  color: #ffffff;
  font-family: "CircularStd", sans-serif;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.66px;
}

.landing-2 .btn--primary.hvr-bounce-to-left::before, .landing-2 .btn--red.hvr-bounce-to-left::before {
  background: #F9544B;
  color: #fff;
}

.landing-2 .btn--primary.hvr-bounce-to-left:hover::before, .landing-2 .btn--red.hvr-bounce-to-left:hover::before {
  background: #F9544B;
  color: #fff;
}

.landing-2 .btn--primary.hvr-bounce-to-left:hover, .landing-2 .btn--primary.hvr-bounce-to-left:focus, .landing-2 .btn--primary.hvr-bounce-to-left:active, .landing-2 .btn--red.hvr-bounce-to-left:hover, .landing-2 .btn--red.hvr-bounce-to-left:focus, .landing-2 .btn--red.hvr-bounce-to-left:active {
  color: #fff !important;
}

.landing-2 .btn--secondary {
  width: 216px;
  height: 60px;
  border-radius: 5px;
  border: 1px solid #eae9f2;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  color: #ffffff;
  font-family: "CircularStd", sans-serif;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.66px;
  background-color: #413e65;
}

.landing-2 .site-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.landing-2 .hero-area {
  background: url(../image/png/l2-hero-bg.png) no-repeat;
  background-size: cover;
  background-position: bottom left;
}

@media (min-width: 992px) {
  .landing-2 .hero-area {
    background-position: center;
  }
}

.landing-2 .hero-content .title {
  color: #19191b;
  font-family: "CircularStd", sans-serif;
  font-weight: 700;
  letter-spacing: -2.81px;
  font-size: 50px;
  line-height: 56px;
  margin-bottom: 28px;
}

@media (min-width: 576px) {
  .landing-2 .hero-content .title {
    font-size: 66px;
    line-height: 70px;
  }
}

@media (min-width: 992px) {
  .landing-2 .hero-content .title {
    font-size: 76px;
    line-height: 84px;
  }
}

@media (min-width: 1200px) {
  .landing-2 .hero-content .title {
    font-size: 80px;
    line-height: 84px;
  }
}

.landing-2 .hero-content p {
  color: #696871;
  font-family: "CircularStd", sans-serif;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

.landing-2 .hero-content .hero-btn {
  margin-top: 38px;
}

.landing-2 .hero-content .video-link {
  color: #413e65;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  margin-top: 12px;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
}

.landing-2 .hero-content .video-link i {
  margin-right: 6px;
}

@media (min-width: 1200px) {
  .landing-2 .hero-content {
    padding-right: 20px;
  }
}

.landing-2 .hero-img-group {
  position: relative;
  height: 100%;
  width: 100%;
}

.landing-2 .hero-img-group .abs-img {
  position: absolute;
}

.landing-2 .hero-img-group .abs-img.img-1 {
  top: -11%;
  left: -13%;
}

@media (min-width: 992px) {
  .landing-2 .hero-img-group .abs-img.img-1 {
    left: 2%;
  }
}

.landing-2 .hero-img-group .abs-img.img-1 img {
  box-shadow: 0 52px 54px rgba(65, 62, 101, 0.3);
  border: 10px solid #FFFFFF;
  background-color: #FFFFFF;
  border-radius: 500px;
}

.landing-2 .hero-img-group .abs-img.img-2 {
  right: -13%;
  top: 54%;
}

@media (min-width: 1100px) {
  .landing-2 .hero-img-group .abs-img.img-2 {
    right: -23%;
  }
}

@media (min-width: 1200px) {
  .landing-2 .hero-img-group .abs-img.img-2 {
    right: -13%;
  }
}

@media (min-width: 1366px) {
  .landing-2 .hero-img-group .abs-img.img-2 {
    right: -28%;
  }
}

@media (min-width: 1500px) {
  .landing-2 .hero-img-group .abs-img.img-2 {
    right: -43%;
  }
}

.landing-2 .hero-form {
  position: relative;
}

.landing-2 .hero-form .input-group {
  max-width: 539px;
  height: 71px;
  border-radius: 10px;
  border: 1px solid #EAE9F2;
  background-color: #FFFFFF;
  position: relative;
}

.landing-2 .hero-form .input-group i {
  position: absolute;
  top: 27px;
  left: 27px;
  font-size: 19px;
  color: #cdccd9;
  margin-top: -2px;
}

.landing-2 .hero-form .input-group input {
  padding-left: 63px;
  padding-right: 15px;
  width: 100%;
  border: 0;
  border-radius: 10px;
}

@media (min-width: 500px) {
  .landing-2 .hero-form .input-group input {
    padding-right: 200px;
  }
}

.landing-2 .hero-form .input-group input:focus {
  box-shadow: none;
  outline: none;
}

.landing-2 .hero-form .input-group input::-webkit-input-placeholder {
  color: #696871;
  font-weight: 400;
}

.landing-2 .hero-form .input-group input::-moz-placeholder {
  color: #696871;
  font-weight: 400;
}

.landing-2 .hero-form .input-group input:-ms-input-placeholder {
  color: #696871;
  font-weight: 400;
}

.landing-2 .hero-form .input-group input::-ms-input-placeholder {
  color: #696871;
  font-weight: 400;
}

.landing-2 .hero-form .input-group input::placeholder {
  color: #696871;
  font-weight: 400;
}

.landing-2 .hero-form button {
  margin-top: 15px;
}

@media (min-width: 500px) {
  .landing-2 .hero-form button {
    position: absolute;
    margin-top: 0px;
    top: 5px;
    right: 16px;
    margin-right: 5px;
  }
}

.landing-2 .feature-section {
  background: #413e65;
  position: relative;
}

.landing-2 .feature-section .feature-content .title {
  color: #fff;
  margin-bottom: 25px;
}

.landing-2 .feature-section .feature-content p {
  font-size: 21px;
  color: rgba(255, 255, 255, 0.7);
}

.landing-2 .feature-section .feature-content .content-btn {
  margin-top: 35px;
}

.landing-2 .feature-widget {
  border-radius: 10px;
  border: 1px solid #eae9f2;
  background-color: #ffffff;
  padding: 30px;
  min-width: 100%;
  width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
}

@media (min-width: 768px) {
  .landing-2 .feature-widget {
    width: 399px;
    min-width: 389px;
  }
}

.landing-2 .feature-widget .widget-icon {
  flex-basis: auto;
  width: 69px;
  height: 69px;
  border-radius: 500px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  font-size: 24px;
  margin-right: 20px;
}

.landing-2 .feature-widget .widget-icon.red {
  color: #f04037;
  background-color: rgba(240, 64, 55, 0.1);
}

.landing-2 .feature-widget .widget-icon.yellow {
  background-color: rgba(254, 220, 90, 0.1);
  color: #fedc5a;
}

.landing-2 .feature-widget .widget-icon.blue {
  background-color: rgba(84, 84, 212, 0.1);
  color: #413e65;
}

.landing-2 .feature-widget .widget-icon.ash {
  background-color: rgba(65, 62, 101, 0.1);
  color: #413e65;
}

.landing-2 .feature-widget .widget-text {
  flex-basis: calc(100% - 100px);
}

.landing-2 .feature-widget .widget-text .title {
  color: #19191b;
  font-family: "CircularStd", sans-serif;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.75px;
  margin-bottom: 16px;
}

.landing-2 .feature-widget .widget-text .sub-title {
  color: #696871;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.landing-2 .content-section1 .content-text {
  padding-top: 80px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .landing-2 .content-section1 .content-text {
    padding-top: 80px;
    padding-bottom: 50px;
  }
}

@media (min-width: 992px) {
  .landing-2 .content-section1 .content-text {
    padding-top: 190px;
    padding-bottom: 180px;
  }
}

.landing-2 .content-text .title {
  margin-bottom: 25px;
}

.landing-2 .content-text .content-btn {
  margin-top: 35px;
}

.landing-2 .content-section1 {
  position: relative;
}

.landing-2 .content-section1 .content1-img-group {
  position: absolute;
  top: -120px;
  right: -260px;
  z-index: -1;
}

@media (min-width: 1200px) {
  .landing-2 .content-section1 .content1-img-group {
    right: -200px;
  }
}

@media (min-width: 1366px) {
  .landing-2 .content-section1 .content1-img-group {
    right: -145px;
  }
}

@media (min-width: 1600px) {
  .landing-2 .content-section1 .content1-img-group {
    right: 0px;
  }
}

@media (min-width: 375px) {
  .landing-2 .content-section1 .img-half img {
    max-width: 80%;
  }
}

@media (min-width: 480px) {
  .landing-2 .content-section1 .img-half img {
    max-width: 70%;
  }
}

@media (min-width: 576px) {
  .landing-2 .content-section1 .img-half img {
    max-width: 100%;
  }
}

.landing-2 .content-section1 .abs-img.round-img {
  position: absolute;
  left: 20px;
  bottom: 10%;
}

@media (min-width: 480px) {
  .landing-2 .content-section1 .abs-img.round-img {
    bottom: 15%;
  }
}

@media (min-width: 992px) {
  .landing-2 .content-section1 .abs-img.round-img {
    top: 55%;
    left: -95px;
    bottom: auto;
  }
}

.landing-2 .content-section1 .abs-img.round-img img {
  box-shadow: 0 52px 54px rgba(65, 62, 101, 0.3);
  border-radius: 500px;
  border: 10px solid #ffffff;
  background-color: #ffffff;
  max-width: 50%;
}

@media (min-width: 576px) {
  .landing-2 .content-section1 .abs-img.round-img img {
    max-width: 70%;
  }
}

@media (min-width: 768px) {
  .landing-2 .content-section1 .abs-img.round-img img {
    max-width: 80%;
  }
}

@media (min-width: 992px) {
  .landing-2 .content-section1 .abs-img.round-img img {
    max-width: 100%;
  }
}

.landing-2 .content-img-group {
  position: relative;
  width: 100%;
  height: 100%;
}

.landing-2 .content-img-group .round-img {
  position: absolute;
  top: 50%;
  left: 0%;
}

.landing-2 .content-img-group-1 {
  position: relative;
}

.landing-2 .content-img-group-1 .img-1 img {
  box-shadow: 0 52px 54px rgba(65, 62, 101, 0.3);
  border-radius: 10px;
}

@media (min-width: 1450px) {
  .landing-2 .content-img-group-1 .img-1 {
    margin-left: 69px;
  }
}

.landing-2 .content-img-group-1 .abs-img {
  position: absolute;
}

.landing-2 .content-img-group-1 .abs-img.img-2 {
  position: absolute;
  top: 52%;
  left: -3%;
  z-index: 2;
  box-shadow: 0 52px 54px rgba(65, 62, 101, 0.3);
  border: 10px solid #ffffff;
  background-color: #ffffff;
  border-radius: 500px;
  max-width: 50%;
}

@media (min-width: 768px) {
  .landing-2 .content-img-group-1 .abs-img.img-2 {
    max-width: 100%;
  }
}

@media (min-width: 1450px) {
  .landing-2 .content-img-group-1 .abs-img.img-2 {
    left: -3%;
  }
}

.landing-2 .content-img-group-2 {
  position: relative;
}

.landing-2 .content-img-group-2 .abs-img {
  position: absolute;
}

.landing-2 .content-img-group-2 .abs-img.img-2 {
  right: 0%;
  position: absolute;
  top: 21%;
  z-index: 2;
  box-shadow: 0 52px 54px rgba(65, 62, 101, 0.3);
  border: 10px solid #ffffff;
  background-color: #ffffff;
  border-radius: 500px;
  max-width: 50%;
}

@media (min-width: 768px) {
  .landing-2 .content-img-group-2 .abs-img.img-2 {
    max-width: 100%;
    right: 15%;
  }
}

.landing-2 .content-section2 {
  background: #f7f7fb;
}

.landing-2 .content-widget {
  border-radius: 10px;
  border: 1px solid #EAE9F2;
  background-color: #FFFFFF;
  padding: 20px;
  min-width: 100%;
  width: 100%;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-transition: .4s;
  transition: .4s;
}

@media (min-width: 1200px) {
  .landing-2 .content-widget {
    width: 360px;
    min-width: 360px;
  }
}

.landing-2 .content-widget .widget-icon {
  min-width: 55px;
  max-width: 55px;
  min-height: 55px;
  max-height: 55px;
  border-radius: 50%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  margin-right: 20px;
}

.landing-2 .content-widget .widget-icon.red {
  color: #f04037;
  background-color: rgba(240, 64, 55, 0.1);
}

.landing-2 .content-widget .widget-icon.yellow {
  background-color: rgba(254, 220, 90, 0.1);
  color: #FEDC5A;
}

.landing-2 .content-widget .widget-icon.blue {
  background-color: rgba(84, 84, 212, 0.1);
  color: #413e65;
}

.landing-2 .content-widget .widget-icon.ash {
  background-color: rgba(65, 62, 101, 0.1);
  color: #413E65;
}

.landing-2 .content-widget .widget-icon.green {
  background-color: rgba(119, 191, 65, 0.1);
  color: #77BF41;
}

.landing-2 .content-widget .widget-text .title {
  color: #19191b;
  font-family: "CircularStd", sans-serif;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -0.66px;
  margin-bottom: 0;
}

.landing-2 .content-widget .widget-text .sub-title {
  color: #696871;
  font-family: "CircularStd", sans-serif;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
}

.landing-2 .content-widget:hover {
  box-shadow: 0 52px 54px rgba(65, 62, 101, 0.3);
}

.landing-2 .testimonial-section {
  background-color: #f7f7fb;
}

.landing-2 .testimonial-slider .slick-arrow {
  display: none !important;
}

.landing-2 .testimonial-slider .slick-dots {
  margin-top: 60px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}

.landing-2 .testimonial-slider .slick-dots li {
  font-size: 0;
  width: 17px;
  height: 8px;
  border-radius: 4px;
  background-color: #eae9f2;
  margin-left: 5px;
  margin-right: 5px;
  -webkit-transition: .5s;
  transition: .5s;
}

.landing-2 .testimonial-slider .slick-dots li.slick-active {
  width: 45px;
  height: 8px;
  border-radius: 4px;
  background: #5454d4;
}

.landing-2 .testimonial-card {
  max-width: 468px;
  border-radius: 10px;
  border: 1px solid #eae9f2;
  background-color: #ffffff;
  padding: 25px 28px;
  margin: 0 auto;
}

.landing-2 .testimonial-card .card-footer {
  display: -webkit-box;
  display: flex;
  background: transparent;
  border-top: none;
  padding: 0;
  margin-top: 28px;
}

.landing-2 .testimonial-card .card-footer .customer-img {
  margin-right: 12px;
}

.landing-2 .testimonial-card .card-footer .customer-identity .name {
  color: #19191b;
  font-family: "CircularStd", sans-serif;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -0.66px;
  line-height: 28px;
}

.landing-2 .testimonial-card .card-footer .customer-identity .company {
  color: #696871;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.landing-2 .testimonial-card .card-footer .customer-identity :last-child {
  margin-bottom: 0;
}

.landing-2 .testimonial-card .review-text {
  color: #19191b;
  font-family: "CircularStd", sans-serif;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

.landing-2 .cta-section {
  background: #413e65;
}

.landing-2 .cta-section .section-title .title {
  color: #fff;
  margin-bottom: 30px;
}

.landing-2 .cta-section .section-title p {
  color: rgba(255, 255, 255, 0.7);
}

.landing-2 .cta-form {
  position: relative;
  margin-top: 45px;
}

.landing-2 .cta-form .input-group {
  max-width: 445px;
  height: 70px;
  border-radius: 10px;
  border: 1px solid #EAE9F2;
  background-color: #FFFFFF;
  position: relative;
}

.landing-2 .cta-form .input-group i {
  position: absolute;
  top: 24px;
  left: 22px;
  font-size: 20px;
}

.landing-2 .cta-form .input-group input {
  padding-left: 63px;
  padding-right: 63px;
  width: 100%;
  border: 0;
  border-radius: 10px;
  color: #696871;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.56px;
  text-align: center;
}

.landing-2 .cta-form .input-group input:focus {
  box-shadow: none;
  outline: none;
}

.landing-2 .cta-form .input-group input::-webkit-input-placeholder {
  color: #696871;
  font-weight: 400;
  text-align: center;
}

.landing-2 .cta-form .input-group input::-moz-placeholder {
  color: #696871;
  font-weight: 400;
  text-align: center;
}

.landing-2 .cta-form .input-group input:-ms-input-placeholder {
  color: #696871;
  font-weight: 400;
  text-align: center;
}

.landing-2 .cta-form .input-group input::-ms-input-placeholder {
  color: #696871;
  font-weight: 400;
  text-align: center;
}

.landing-2 .cta-form .input-group input::placeholder {
  color: #696871;
  font-weight: 400;
  text-align: center;
}

.landing-2 .cta-form button {
  margin-top: 15px;
  max-width: 445px;
  width: 100%;
}

.landing-2 .footer-section p {
  color: #696871;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.landing-2 .footer-title {
  color: #19191B;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 25px;
}

.landing-2 .footer-list a {
  color: #19191B;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 38px;
}

@media (min-width: 768px) {
  .landing-2 .footer-list a:hover {
    color: #5454d4;
  }
}

.landing-2 .footer-list.contact-list a {
  color: #5454D4;
}

.landing-2 .social-icons {
  display: -webkit-inline-box;
  display: inline-flex;
  margin-left: -5px;
  margin-right: -5px;
  padding-top: 6px;
}

.landing-2 .social-icons li a {
  color: #FFFFFF;
  background: #696871;
  width: 30px;
  height: 30px;
  font-size: 16px;
  -webkit-transition: .4s;
  transition: .4s;
  padding: 0 3px;
  margin: 0 5px;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  border-radius: 500px;
}

@media (min-width: 768px) {
  .landing-2 .social-icons li a:hover {
    background: #f04037;
  }
}

/*==== 5.Landing 3 -------*/
.landing-3 {
  /*>>>>>>>> Header area styling <<<<<<<<<*/
  /*>>>>>>>> Hero area styling <<<<<<<<<*/
  /*>>>>>>>> Feature area styling <<<<<<<<<*/
  /*>>>>>>>> Content area styling <<<<<<<<<*/
  /*>>>>>>>> Content Section 2 area styling <<<<<<<<<*/
  /*>>>>>>>> FAQ Section styling <<<<<<<<<*/
  /*>>>>>>>> CTA Section styling <<<<<<<<<*/
  /*>>>>>>>> Footer area styling <<<<<<<<<*/
}

.landing-3.dark-theme .cta-section {
  background: #1e1e20;
}

.landing-3.dark-theme .navbar-toggler.btn-close-off-canvas {
  color: #fff;
  border-color: #fff;
  border: none;
}

.navbar-toggler {
  border:none;
}

.landing-3 .btn-height--45 {
  height: 45px !important;
}

.landing-3 .btn-width--141 {
  width: 141px !important;
}

.landing-3 .btn-header {
  font-size: 16px !important;
  width: 141px !important;
  height: 45px !important;
}

.landing-3 .btn--primary, .landing-3 .btn--red {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  width: 200px;
  height: 60px;
  border-radius: 5px;
  border: 1px solid #c31a12;
  background: #f04037;
  color: #ffffff;
  font-family: "CircularStd", sans-serif;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.66px;
}

.landing-3 .btn--primary.hvr-bounce-to-left::before, .landing-3 .btn--red.hvr-bounce-to-left::before {
  background: #F9544B;
  color: #fff;
}

.landing-3 .btn--primary.hvr-bounce-to-left:hover::before, .landing-3 .btn--red.hvr-bounce-to-left:hover::before {
  background: #F9544B;
  color: #fff;
}

.landing-3 .btn--primary.hvr-bounce-to-left:hover, .landing-3 .btn--primary.hvr-bounce-to-left:focus, .landing-3 .btn--primary.hvr-bounce-to-left:active, .landing-3 .btn--red.hvr-bounce-to-left:hover, .landing-3 .btn--red.hvr-bounce-to-left:focus, .landing-3 .btn--red.hvr-bounce-to-left:active {
  color: #fff !important;
}

.landing-3 .btn--secondary {
  width: 216px;
  height: 60px;
  border-radius: 5px;
  border: 1px solid #eae9f2;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  color: #ffffff;
  font-family: "CircularStd", sans-serif;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.66px;
  background-color: #5454d4;
}

.landing-3 .main-menu .menu-dropdown .single-item a p {
  color: #19191b !important;
}

.landing-3 .header-btn a {
  font-size: 14px;
  max-width: 120px;
  height: 34px;
  display: none;
}

@media (min-width: 370px) {
  .landing-3 .header-btn a {
    display: -webkit-inline-box;
    display: inline-flex;
    max-width: 120px;
    height: 34px;
  }
}

@media (min-width: 480px) {
  .landing-3 .header-btn a {
    font-size: 21px;
    max-width: 220px;
    height: 60px;
  }
}

.landing-3 .hero-content .title {
  color: #19191b;
  font-family: "CircularStd", sans-serif;
  font-weight: 700;
  letter-spacing: -2.81px;
  font-size: 50px;
  line-height: 56px;
  margin-bottom: 28px;
}

@media (min-width: 576px) {
  .landing-3 .hero-content .title {
    font-size: 66px;
    line-height: 70px;
  }
}

@media (min-width: 992px) {
  .landing-3 .hero-content .title {
    font-size: 76px;
    line-height: 84px;
  }
}

@media (min-width: 1200px) {
  .landing-3 .hero-content .title {
    font-size: 80px;
    line-height: 84px;
  }
}

.landing-3 .hero-content p {
  color: #696871;
  font-family: "CircularStd", sans-serif;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

@media (min-width: 992px) {
  .landing-3 .hero-content {
    padding-right: 60px;
  }
}

@media (min-width: 1200px) {
  .landing-3 .hero-content {
    padding-right: 80px;
  }
}

.landing-3 .hero-section-shape {
  position: absolute;
  left: 0;
  -webkit-transform: translateX(-63%) translateY(50%);
          transform: translateX(-63%) translateY(50%);
  bottom: 0;
}

.landing-3 .hero-img-group .abs-img {
  position: absolute;
  z-index: -1;
}

.landing-3 .hero-img-group .abs-img.img-1 {
  top: 0;
  left: -10%;
}

.landing-3 .hero-img-group .abs-img.img-2 {
  bottom: 0%;
  right: 0%;
}

.landing-3 .form-shape {
  position: absolute;
  bottom: -58px;
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  z-index: -1;
}

@media (min-width: 768px) {
  .landing-3 .form-shape {
    left: 13%;
  }
}

@media (min-width: 992px) {
  .landing-3 .form-shape {
    position: absolute;
    bottom: 17px;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    z-index: -1;
    left: 0;
  }
}

@media (min-width: 1200px) {
  .landing-3 .form-shape {
    position: absolute;
    bottom: -58px;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    z-index: -1;
  }
}

.landing-3 .hero-form {
  padding: 30px;
  box-shadow: 0 42px 54px rgba(0, 0, 0, 0.79);
  border-radius: 10px;
  background-color: #ffffff;
  /* For IE10 */
}

.landing-3 .hero-form .form-group {
  margin-bottom: 20px;
  height: 70px;
}

.landing-3 .hero-form .form-group.from-group--with-label {
  position: relative;
}

.landing-3 .hero-form .form-group.from-group--with-label .form-label {
  color: #696871;
  font-size: 18px;
  font-weight: 300;
  background: #fff;
  top: 50%;
  padding: 0 10px;
  left: 15px;
  margin-bottom: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: absolute;
  -webkit-transition: .4s;
  transition: .4s;
  pointer-events: none;
}

.landing-3 .hero-form .form-group.from-group--with-label input:focus ~ label {
  top: 0px;
  left: 15px;
}

.landing-3 .hero-form input {
  width: 100%;
  height: 100%;
  padding: 0 16px;
  border-radius: 10px;
  border: 1px solid #eae9f2;
  background-color: #ffffff;
  color: #19191b;
  font-size: 21px;
  font-weight: 300;
}

.landing-3 .hero-form input::-webkit-input-placeholder {
  color: #696871;
}

.landing-3 .hero-form input::-moz-placeholder {
  color: #696871;
}

.landing-3 .hero-form input:-ms-input-placeholder {
  color: #696871;
}

.landing-3 .hero-form input::-ms-input-placeholder {
  color: #696871;
}

.landing-3 .hero-form input::placeholder {
  color: #696871;
}

.landing-3 .hero-form .nice-select:after {
  position: relative;
  content: "\ea1a";
  font-family: 'avasta';
  font-size: 16px;
  top: 0;
  -webkit-transform: rotate(0);
          transform: rotate(0);
  border: none;
  width: auto;
  height: auto;
  -webkit-transform-origin: center;
          transform-origin: center;
}

.landing-3 .hero-form .nice-select.open:after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.landing-3 .hero-form select::-ms-expand {
  display: none;
}

.landing-3 .hero-form .nice-select {
  height: 100%;
  border-radius: 10px;
  border: 1px solid #eae9f2;
  background-color: #ffffff;
  width: 100%;
  float: none;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  background: none !important;
}

.landing-3 .hero-form .nice-select span {
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.56px;
  color: #696871;
  display: block;
  width: 100%;
}

.landing-3 .hero-form .nice-select:focus {
  box-shadow: none;
  outline: #5454d4;
}

.landing-3 .hero-form .nice-select .list {
  width: 100%;
  margin-top: 0;
}

.landing-3 .hero-form .nice-select .list li {
  line-height: 45px;
  list-style: none;
  min-height: 45px;
}

.landing-3 .hero-form .hero-btn {
  margin-top: 20px;
}

.landing-3 .hero-form .hero-btn button {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.landing-3 .feature-section .video-image {
  position: relative;
  box-shadow: 0 42px 54px rgba(0, 0, 0, 0.31);
  border-radius: 10px;
}

.landing-3 .feature-section .video-image a {
  font-size: 22px;
  background-color: #5454D4;
  border-radius: 500px;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  color: #fff;
  width: 64px;
  height: 64px;
}

@media (min-width: 576px) {
  .landing-3 .feature-section .video-image a {
    width: 100px;
    height: 100px;
  }
}

@media (min-width: 768px) {
  .landing-3 .feature-section .video-image a {
    width: 124px;
    height: 124px;
  }
}

.landing-3 .feature-widget {
  text-align: center;
  padding-left: 25px;
  padding-right: 25px;
}

@media (min-width: 1200px) {
  .landing-3 .feature-widget {
    padding-left: 35px;
    padding-right: 35px;
  }
}

.landing-3 .feature-widget .widget-icon {
  min-width: 69px;
  max-width: 69px;
  min-height: 69px;
  max-height: 69px;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  border-radius: 500px;
  margin-bottom: 17px;
}

.landing-3 .feature-widget .widget-icon.bg-red {
  background-color: rgba(240, 64, 55, 0.1);
}

.landing-3 .feature-widget .widget-icon.bg-blue {
  background-color: rgba(84, 84, 212, 0.1);
}

.landing-3 .feature-widget .widget-icon.bg-yellow {
  background-color: rgba(254, 220, 90, 0.1);
}

.landing-3 .feature-widget .widget-text .title {
  display: block;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.75px;
  margin-bottom: 14px;
}

.landing-3 .feature-widget .widget-text p {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.landing-3 .feature-widget .widget-text p:last-child {
  margin-bottom: 0;
}

.landing-3 .content-text .title {
  margin-bottom: 33px;
}

.landing-3 .content-text .content-btn {
  margin-top: 40px;
}

@media (min-width: 1200px) {
  .landing-3 .content-text {
    padding-right: 20px;
  }
}

.landing-3 .content-badge {
  height: 32px;
  border-radius: 5px;
  background-color: #5454d4;
  color: #fff;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: -0.41px;
  margin-bottom: 30px;
}

.landing-3 .content-img-group .abs-img {
  position: absolute;
}

.landing-3 .content-img-group .img-2 {
  position: absolute;
  top: -70px;
  left: -20px;
  z-index: 2;
}

.landing-3 .content-img-group .img-2 img {
  opacity: 0.5;
}

.landing-3 .content3-section {
  border-bottom: 1px solid #242427;
}

.landing-3 .content1-section {
  border-top: 1px solid #242427;
}

.landing-3 .content2-section {
  padding-top: 50px;
  padding-bottom: 50px;
  background: #1e1e20;
}

@media (min-width: 768px) {
  .landing-3 .content2-section {
    padding-top: 110px;
    padding-bottom: 150px;
  }
}

.landing-3 .content2-section .section-title p {
  padding-top: 20px;
}

.landing-3 .content2-section .content-img {
  max-height: 515px;
  box-shadow: 0 42px 54px rgba(0, 0, 0, 0.31);
}

.landing-3 .content2-section .content-img-mobile {
  box-shadow: 0 42px 54px rgba(0, 0, 0, 0.31);
}

.landing-3 .faq-section .faq-link {
  margin-top: 85px;
  text-align: center;
}

.landing-3 .faq-section .faq-link p {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
  margin-bottom: 0;
}

.landing-3 .faq-section .faq-link a {
  color: #f04037;
}

.landing-3 .single-faq .title {
  font-size: 24px;
  font-weight: 900;
  letter-spacing: -0.75px;
  margin-bottom: 30px;
}

.landing-3 .single-faq p {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.landing-3 .single-faq p:last-child {
  margin-bottom: 0;
}

.landing-3 .shape-holder {
  position: relative;
  z-index: 1;
}

.landing-3 .cta-section {
  padding-top: 90px;
  padding-bottom: 85px;
}

@media (min-width: 992px) {
  .landing-3 .cta-section {
    padding-top: 120px;
    padding-bottom: 115px;
  }
}

@media (min-width: 1200px) {
  .landing-3 .cta-section {
    padding-top: 170px;
    padding-bottom: 165px;
  }
}

.landing-3 .cta-section .cta-shape {
  position: absolute;
}

.landing-3 .cta-section .cta-shape-1 {
  display: none;
}

@media (min-width: 768px) {
  .landing-3 .cta-section .cta-shape-1 {
    display: block;
    top: 4%;
    right: -13%;
  }
}

@media (min-width: 1600px) {
  .landing-3 .cta-section .cta-shape-1 {
    right: -2%;
    right: -2%;
  }
}

.landing-3 .cta-section .cta-shape-2 {
  bottom: 0;
  left: -5%;
}

.landing-3 .footer-section {
  padding: 60px 0 25px;
}

.landing-3 .footer-section p {
  color: #696871;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.landing-3 .footer-section p:last-child {
  margin-bottom: 0;
}

.landing-3 .footer-title {
  color: #19191B;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 25px;
}

.landing-3 .footer-list a {
  color: #19191B;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 1;
}

@media (min-width: 768px) {
  .landing-3 .footer-list a:hover {
    color: #5454d4;
  }
}

.landing-3 .footer-list.list-inline {
  display: -webkit-inline-box;
  display: inline-flex;
}

@media (min-width: 1200px) {
  .landing-3 .footer-list.list-inline {
    margin-left: 90px;
  }
}

.landing-3 .footer-list.list-inline li {
  padding: 0 15px;
}

.dark-theme {
  background: #19191b;
}

.dark-theme .navbar-light .navbar-nav .nav-link:focus, .dark-theme .navbar-light .navbar-nav .nav-link:hover {
  color: #f04037 !important;
}

.dark-theme .section-title h2, .dark-theme .section-title .title, .dark-theme .title {
  color: #ffffff !important;
}

@media (min-width: 992px) {
  .dark-theme .main-menu > li > .nav-link {
    color: #ffffff !important;
  }
}

.dark-theme p, .dark-theme .footer-list a {
  color: rgba(255, 255, 255, 0.7) !important;
}

/*==== 6.Landing 4 -------*/
.landing-4 {
  /*>>>>>>>> Header area styling <<<<<<<<<*/
  /*>>>>>>>> Hero area styling <<<<<<<<<*/
  /*>>>>>>>> Content area styling <<<<<<<<<*/
  /*>>>>>>>> Content Section 2 area styling <<<<<<<<<*/
  /*>>>>>>>> Testimonial styling <<<<<<<<<*/
  /*>>>>>>>> CTA Section styling <<<<<<<<<*/
  /*>>>>>>>> Footer area styling <<<<<<<<<*/
}

.landing-4 .site-header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}

.landing-4 .btn-close-off-canvas.white-toggler {
  color: #fff;
  border-color: #fff;
}

@media (min-width: 992px) {
  .landing-4 .main-menu > li > .nav-link {
    color: rgba(255, 255, 255, 0.7) !important;
    font-weight: 300;
  }
}

@media (min-width: 992px) {
  .landing-4 .menu-dropdown {
    border-top: 3px solid #fedc5a;
  }
}

.landing-4 .header-cart .cart {
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}

.landing-4 .header-cart .cart-count {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 23px;
  background-color: #fedc5a;
  color: rgba(0, 0, 0, 0.79);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  margin-left: 13px;
}

.landing-4 .hero-area {
  background: #5454d4;
}

.landing-4 .hero-area .hero-image {
  max-width: 60%;
}

@media (min-width: 576px) {
  .landing-4 .hero-area .hero-image {
    max-width: 50%;
  }
}

@media (min-width: 768px) {
  .landing-4 .hero-area .hero-image {
    max-width: 100%;
  }
}

.landing-4 .hero-area .product-widget-block {
  max-width: 322px;
  min-width: 322px;
}

.landing-4 .hero-area .product-widget-block .price-box {
  border-radius: 10px;
  background-color: #6565e9;
  padding-left: 25px;
  padding-right: 20px;
  padding-top: 13px;
  padding-bottom: 10px;
}

.landing-4 .hero-area .product-widget-block .price-box .product-price-n-offer {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: end;
          align-items: flex-end;
  margin-bottom: 4px;
}

.landing-4 .hero-area .product-widget-block .price-box .price {
  color: #fff;
  font-size: 60px;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 1;
}

.landing-4 .hero-area .product-widget-block .price-box .offer-badge {
  min-height: 38px;
  border-radius: 5px;
  background-color: #77bf41;
  padding-left: 7px;
  padding-right: 7px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 0;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  position: relative;
  top: -5px;
  margin-left: 15px;
}

.landing-4 .hero-area .product-widget-block .price-box p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.93px;
  margin-bottom: 0;
}

.landing-4 .hero-area .product-widget-block .pro-qty {
  display: -webkit-box;
  display: flex;
  margin-top: 20px;
}

.landing-4 .hero-area .product-widget-block .pro-qty .count-input-block {
  min-width: 90px;
  max-width: 90px;
  height: 60px;
  display: -webkit-box;
  display: flex;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  margin-right: 20px;
}

.landing-4 .hero-area .product-widget-block .pro-qty .count-input-block input {
  height: 100%;
  width: 100%;
  padding-right: 27px;
}

.landing-4 .hero-area .product-widget-block .pro-qty .count-input-block .count-input-btns {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  position: absolute;
  right: 0;
}

.landing-4 .hero-area .product-widget-block .pro-qty .count-input-block .count-input-btns .count-btn {
  min-width: 27px;
  max-width: 27px;
  min-height: 29.5px;
  max-height: 29.5px;
  background: #f7f7fb;
  border-bottom: 1px solid #fff;
}

.landing-4 .hero-area .product-widget-block .pro-qty .count-input-block .count-input-btns .count-btn:last-child {
  border-bottom: 0;
}

.landing-4 .hero-content .title {
  color: #fff;
  font-family: "CircularStd", sans-serif;
  font-weight: 700;
  letter-spacing: -2.81px;
  font-size: 50px;
  line-height: 56px;
  margin-bottom: 23px;
}

@media (min-width: 576px) {
  .landing-4 .hero-content .title {
    font-size: 66px;
    line-height: 70px;
  }
}

@media (min-width: 992px) {
  .landing-4 .hero-content .title {
    font-size: 60px;
    line-height: 70px;
  }
}

.landing-4 .hero-content p {
  color: rgba(255, 255, 255, 0.7);
  font-family: "CircularStd", sans-serif;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

@media (min-width: 992px) {
  .landing-4 .hero-content {
    padding-right: 60px;
  }
}

@media (min-width: 1200px) {
  .landing-4 .hero-content {
    padding-right: 80px;
  }
}

@media (min-width: 992px) {
  .landing-4 .content-section1 .content-img img {
    max-width: 118%;
    position: relative;
    left: -18%;
  }
}

.landing-4 .content-section2 {
  background: #413e65;
}

.landing-4 .content-text .title {
  margin-bottom: 20px;
}

.landing-4 .content-text .content-btn {
  margin-top: 40px;
}

@media (min-width: 1200px) {
  .landing-4 .content-text {
    padding-right: 20px;
  }
}

.landing-4 .content-widget {
  border-radius: 10px;
  border: 1px solid #eae9f2;
  background-color: #ffffff;
  padding: 20px;
  min-width: 100%;
  width: 100%;
  display: -webkit-inline-box;
  display: inline-flex;
}

@media (min-width: 768px) {
  .landing-4 .content-widget {
    width: 360px;
    min-width: 360px;
  }
}

.landing-4 .content-widget .widget-icon {
  min-width: 55px;
  max-width: 55px;
  min-height: 55px;
  max-height: 55px;
  border-radius: 50%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  margin-right: 20px;
}

.landing-4 .content-widget .widget-icon.red {
  color: #f04037;
  background-color: rgba(240, 64, 55, 0.1);
}

.landing-4 .content-widget .widget-icon.yellow {
  background-color: rgba(254, 220, 90, 0.1);
  color: #fedc5a;
}

.landing-4 .content-widget .widget-icon.blue {
  background-color: rgba(84, 84, 212, 0.1);
  color: #5454d4;
}

.landing-4 .content-widget .widget-icon.green {
  background-color: rgba(119, 191, 65, 0.1);
  color: #77bf41;
}

.landing-4 .content-widget .widget-icon.ash {
  background-color: rgba(65, 62, 101, 0.1);
  color: #413e65;
}

.landing-4 .content-widget .widget-text .title {
  color: #19191b;
  font-family: "CircularStd", sans-serif;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -0.66px;
  margin-bottom: 0;
}

.landing-4 .content-widget .widget-text .sub-title {
  color: #696871;
  font-family: "CircularStd", sans-serif;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
}

.landing-4 .content-section3 {
  padding-top: 50px;
  padding-bottom: 70px;
}

@media (min-width: 992px) {
  .landing-4 .content-section3 {
    padding-top: 105px;
    padding-bottom: 170px;
  }
}

.landing-4 .content-section3 .content-img {
  border-radius: 5px;
  overflow: hidden;
}

.landing-4 .content-section3 .content-img.img-big {
  box-shadow: 0 52px 54px rgba(65, 62, 101, 0.3);
}

.landing-4 .content-section4 {
  background: #5454d4;
  position: relative;
  overflow: hidden;
  padding-top: 73px;
  padding-bottom: 73px;
}

@media (min-width: 992px) {
  .landing-4 .content-section4 {
    padding-top: 73px;
    padding-bottom: 73px;
  }
}

@media (min-width: 1200px) {
  .landing-4 .content-section4 {
    padding-top: 135px;
    padding-bottom: 145px;
  }
}

.landing-4 .content-section4 .c4-shape-image {
  position: absolute;
  top: 0;
  right: -5%;
}

@media (min-width: 1920px) {
  .landing-4 .content-section4 .c4-shape-image {
    right: 0;
  }
}

.landing-4 .content-section4 .content-text .title {
  color: #fff;
}

.landing-4 .content-section4 .content-text p {
  color: #fff;
}

.landing-4 .content-section2 {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .landing-4 .content-section2 {
    padding-top: 110px;
    padding-bottom: 150px;
  }
}

.landing-4 .content-section2 .content-widget {
  padding-right: 18px;
  padding-left: 18px;
}

@media (min-width: 768px) {
  .landing-4 .content-section2 .content-widget {
    width: 100%;
    min-width: 100%;
  }
}

.landing-4 .content-section2 .content-widget .widget-icon {
  margin-right: 18px;
}

.landing-4 .content2-section .section-title p {
  padding-top: 20px;
}

.landing-4 .content2-section .content-img {
  max-height: 515px;
  box-shadow: 0 42px 54px rgba(0, 0, 0, 0.31);
}

.landing-4 .content2-section .content-img-mobile {
  box-shadow: 0 42px 54px rgba(0, 0, 0, 0.31);
}

.landing-4 .testimonial-slider .slick-arrow {
  display: none !important;
}

.landing-4 .testimonial-slider .slick-dots {
  margin-top: 60px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}

.landing-4 .testimonial-slider .slick-dots li {
  font-size: 0;
  width: 17px;
  height: 8px;
  border-radius: 4px;
  background-color: #eae9f2;
  margin-left: 5px;
  margin-right: 5px;
  -webkit-transition: .5s;
  transition: .5s;
}

.landing-4 .testimonial-slider .slick-dots li.slick-active {
  width: 45px;
  height: 8px;
  border-radius: 4px;
  background: #5454d4;
}

.landing-4 .testimonial-card {
  max-width: 468px;
  border-radius: 10px;
  border: 1px solid #eae9f2;
  background-color: #ffffff;
  padding: 25px 28px;
  margin: 0 auto;
}

.landing-4 .testimonial-card .card-footer {
  display: -webkit-box;
  display: flex;
  background: transparent;
  border-top: none;
  padding: 0;
  margin-top: 28px;
}

.landing-4 .testimonial-card .card-footer .customer-img {
  margin-right: 12px;
}

.landing-4 .testimonial-card .card-footer .customer-identity .name {
  color: #19191b;
  font-family: "CircularStd", sans-serif;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -0.66px;
  line-height: 28px;
}

.landing-4 .testimonial-card .card-footer .customer-identity .company {
  color: #696871;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.landing-4 .testimonial-card .card-footer .customer-identity :last-child {
  margin-bottom: 0;
}

.landing-4 .testimonial-card .review-text {
  color: #19191b;
  font-family: "CircularStd", sans-serif;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

.landing-4 .cta-section {
  padding-top: 90px;
  padding-bottom: 85px;
  background-color: #5454d4;
  color: #fff;
}

@media (min-width: 992px) {
  .landing-4 .cta-section {
    padding-top: 120px;
    padding-bottom: 115px;
  }
}

@media (min-width: 1200px) {
  .landing-4 .cta-section {
    padding-top: 170px;
    padding-bottom: 165px;
  }
}

.landing-4 .cta-section h2 {
  color: inherit;
}

.landing-4 .footer-section {
  padding: 60px 0 25px;
}

.landing-4 .footer-section p {
  color: #19191b;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.landing-4 .footer-section p:last-child {
  margin-bottom: 0;
}

.landing-4 .footer-title {
  color: #19191B;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 25px;
}

.landing-4 .footer-list a {
  color: #19191B;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 1;
}

@media (min-width: 768px) {
  .landing-4 .footer-list a:hover {
    color: #5454d4;
  }
}

.landing-4 .footer-list.list-inline {
  display: -webkit-inline-box;
  display: inline-flex;
}

@media (min-width: 1200px) {
  .landing-4 .footer-list.list-inline {
    margin-left: 90px;
  }
}

.landing-4 .footer-list.list-inline li {
  padding: 0 15px;
}

/*==== 7.Landing 5 -------*/
.landing-5 {
  background-color: #f7f7fb;
  /*>>>>>>>> Header area styling <<<<<<<<<*/
  /*>>>>>>>> Hero area styling <<<<<<<<<*/
  /*>>>>>>>> Feature section styling <<<<<<<<<*/
  /*>>>>>>>> Brand section styling <<<<<<<<<*/
  /*>>>>>>>> Content area styling <<<<<<<<<*/
  /*>>>>>>>> Content Section 2 area styling <<<<<<<<<*/
  /*>>>>>>>> Testimonial styling <<<<<<<<<*/
  /*>>>>>>>> Fact Section styling <<<<<<<<<*/
  /*>>>>>>>> Pricing section styling <<<<<<<<<*/
  /*>>>>>>>> Faq Section styling <<<<<<<<<*/
  /*>>>>>>>> CTA Section styling <<<<<<<<<*/
  /*>>>>>>>> Footer area styling <<<<<<<<<*/
}

.landing-5 .section-title .title {
  color: #19191b;
  font-weight: 700;
  letter-spacing: -1.88px;
  font-size: 48px;
  line-height: 50px;
}

@media (min-width: 768px) {
  .landing-5 .section-title .title {
    font-size: 60px;
    line-height: 70px;
  }
}

.landing-5 .section-title p {
  color: #696871;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

.landing-5 .btn--primary {
  width: 252px;
  height: 60px;
  border-radius: 5px;
  border: 1px solid #f0544c;
  background-color: #f0544c;
  color: #fff;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-transform: perspective(1px) translateZ(0);
          transform: perspective(1px) translateZ(0);
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.landing-5 .btn--primary:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.landing-5 .btn--primary:hover:before, .landing-5 .btn--primary:focus:before, .landing-5 .btn--primary:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.landing-5 .site-header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}

@media (min-width: 992px) {
  .landing-5 .main-menu > li > .nav-link {
    color: #f7f7fb !important;
    font-weight: 300;
  }
  .landing-5 .main-menu > li > .nav-link:hover {
    color: #f04037 !important;
  }
}

@media (min-width: 992px) {
  .landing-5 .menu-dropdown {
    border-top: 3px solid #fedc5a;
  }
}

.landing-5 .header-button a {
  font-size: 14px;
  max-width: 110px;
  height: 34px;
}

@media (min-width: 480px) {
  .landing-5 .header-button a {
    font-size: 16px;
    max-width: 140px;
    height: 45px;
  }
}

.landing-5 .hero-area {
  background-image: radial-gradient(circle 961px at 49.94% 0%, #504a93 0%, #413e65 100%);
  padding-top: 120px;
  padding-bottom: 80px;
}

@media (min-width: 768px) {
  .landing-5 .hero-area {
    padding-top: 120px;
    padding-bottom: 150px;
  }
}

@media (min-width: 992px) {
  .landing-5 .hero-area {
    padding-top: 150px;
    padding-bottom: 235px;
  }
}

@media (min-width: 768px) {
  .landing-5 .hero-content {
    padding-top: 40px;
  }
}

@media (min-width: 992px) {
  .landing-5 .hero-content {
    padding-top: 118px;
  }
}

.landing-5 .hero-content .title {
  color: #fff;
  font-family: "CircularStd", sans-serif;
  font-weight: 700;
  letter-spacing: -2.81px;
  font-size: 50px;
  line-height: 56px;
  margin-bottom: 23px;
}

@media (min-width: 576px) {
  .landing-5 .hero-content .title {
    font-size: 66px;
    line-height: 70px;
  }
}

@media (min-width: 992px) {
  .landing-5 .hero-content .title {
    font-size: 76px;
    line-height: 84px;
  }
}

@media (min-width: 1200px) {
  .landing-5 .hero-content .title {
    margin-bottom: 33px;
    font-size: 80px;
    line-height: 84px;
  }
}

.landing-5 .hero-content p {
  color: rgba(255, 255, 255, 0.7);
  font-family: "CircularStd", sans-serif;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

@media (min-width: 992px) {
  .landing-5 .hero-content {
    padding-right: 60px;
  }
}

@media (min-width: 1200px) {
  .landing-5 .hero-content {
    padding-right: 80px;
  }
}

@media (min-width: 768px) {
  .landing-5 .hero-image {
    position: absolute;
    top: 0;
    max-width: 100%;
    min-width: 100%;
    right: 0;
    -webkit-transform: translateX(25%);
            transform: translateX(25%);
  }
}

.landing-5 .hero-btn {
  padding-top: 25px;
}

.landing-5 .hero-btn .button-bottom-text {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 300;
  letter-spacing: -0.44px;
  margin-top: 15px;
  display: block;
}

.landing-5 .feature-section {
  padding-top: 47px;
  padding-bottom: 23px;
  border-bottom: 1px solid #eae9f2;
  background: #fff;
}

@media (min-width: 768px) {
  .landing-5 .feature-section {
    padding-top: 87px;
    padding-bottom: 63px;
  }
}

.landing-5 .feature-widget .widget-icon {
  margin-bottom: 48px;
}

.landing-5 .feature-widget .widget-texts .title {
  color: #19191b;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.75px;
  padding-bottom: 13px;
}

.landing-5 .feature-widget .widget-texts p {
  color: #696871;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.landing-5 .feature-widget .widget-texts p:last-child {
  margin-bottom: 0;
}

.landing-5 .brand-section {
  background: #fff;
}

.landing-5 .brand-logos {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  flex-wrap: wrap;
  margin: 0 -30px;
  padding-top: 25px;
  padding-bottom: 30px;
}

@media (min-width: 992px) {
  .landing-5 .brand-logos {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
}

.landing-5 .brand-logos .single-brand {
  padding-top: 15px;
  padding-bottom: 15px;
  margin-right: 30px;
  margin-left: 30px;
  -webkit-transition: .4s;
  transition: .4s;
  opacity: .6;
}

.landing-5 .content-section {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .landing-5 .content-section {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}

@media (min-width: 992px) {
  .landing-5 .content-section {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}

@media (min-width: 1200px) {
  .landing-5 .content-section {
    padding-top: 230px;
    padding-bottom: 230px;
  }
}

.landing-5 .content-text .title {
  color: #19191b;
  font-weight: 700;
  letter-spacing: -1.88px;
  padding-bottom: 20px;
  font-size: 40px;
  line-height: 50px;
}

@media (min-width: 576px) {
  .landing-5 .content-text .title {
    font-size: 60px;
    line-height: 70px;
  }
}

.landing-5 .content-text p {
  color: #696871;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

.landing-5 .content-text .content-list {
  padding-top: 15px;
}

@media (min-width: 576px) {
  .landing-5 .content-text .content-list {
    padding-top: 35px;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
}

@media (min-width: 992px) {
  .landing-5 .content-text .content-list {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
}

.landing-5 .content-text .content-list li {
  color: #19191b;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.66px;
  line-height: 50px;
  display: -webkit-box;
  display: flex;
  margin-bottom: 5px;
}

@media (min-width: 576px) {
  .landing-5 .content-text .content-list li:nth-child(odd) {
    padding-right: 50px;
  }
}

@media (min-width: 992px) {
  .landing-5 .content-text .content-list li:nth-child(odd) {
    padding-right: 50px;
  }
}

.landing-5 .content-text .content-list li:before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: inline-block;
  font-size: 13px;
  width: 30px;
  height: 30px;
  background-color: rgba(84, 84, 212, 0.1);
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  border-radius: 500px;
  color: #5454d4;
  position: relative;
  top: 9px;
  margin-right: 13px;
}

.landing-5 .content-text .content-widget-wrapper {
  padding-top: 15px;
}

.landing-5 .content-text .content-widget-wrapper .content-widget {
  margin-top: 15px;
}

.landing-5 .content-image {
  margin-top: 50px;
}

.landing-5 .content-image img {
  box-shadow: 0 12px 84px rgba(29, 41, 63, 0.12);
  border-radius: 10px;
}

@media (min-width: 992px) {
  .landing-5 .content-image {
    margin-top: 0px;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translate(55%, -50%);
            transform: translate(55%, -50%);
  }
}

@media (min-width: 1200px) {
  .landing-5 .content-image {
    -webkit-transform: translate(41%, -50%);
            transform: translate(41%, -50%);
  }
}

@media (min-width: 1500px) {
  .landing-5 .content-image {
    -webkit-transform: translate(35%, -50%);
            transform: translate(35%, -50%);
  }
}

@media (min-width: 1600px) {
  .landing-5 .content-image {
    -webkit-transform: translate(30%, -50%);
            transform: translate(30%, -50%);
  }
}

@media (min-width: 1900px) {
  .landing-5 .content-image {
    -webkit-transform: translate(15%, -50%);
            transform: translate(15%, -50%);
  }
}

.landing-5 .content-image img {
  width: 100%;
}

.landing-5 .content-section-2 {
  background: #fff;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .landing-5 .content-section-2 {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.landing-5 .content-image-2 {
  padding-left: 50px;
}

.landing-5 .content-image-2 img {
  -webkit-transform: rotate(10deg);
          transform: rotate(10deg);
  box-shadow: 0 52px 54px rgba(65, 62, 101, 0.3);
  border-radius: 10px;
  max-width: 70%;
}

@media (min-width: 400px) {
  .landing-5 .content-image-2 img {
    max-width: 50%;
  }
}

@media (min-width: 576px) {
  .landing-5 .content-image-2 img {
    max-width: 40%;
  }
}

@media (min-width: 768px) {
  .landing-5 .content-image-2 img {
    max-width: 33%;
  }
}

@media (min-width: 992px) {
  .landing-5 .content-image-2 img {
    margin-left: 20px;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .landing-5 .content-image-2 img {
    margin-left: 50px;
  }
}

.landing-5 .content-widget {
  display: -webkit-box;
  display: flex;
  max-width: 415px;
}

.landing-5 .content-widget .widget-icon {
  min-width: 55px;
  max-width: 55px;
  margin-top: 5px;
  margin-right: 13px;
}

.landing-5 .content-widget .widget-text .widget-title {
  color: #19191b;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -0.66px;
  margin-bottom: 13px;
}

.landing-5 .content-widget .widget-text p {
  color: #696871;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.landing-5 .content-section2 {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .landing-5 .content-section2 {
    padding-top: 110px;
    padding-bottom: 150px;
  }
}

.landing-5 .content-section2 .content-widget {
  padding-right: 18px;
  padding-left: 18px;
}

@media (min-width: 768px) {
  .landing-5 .content-section2 .content-widget {
    width: 100%;
    min-width: 100%;
  }
}

.landing-5 .content-section2 .content-widget .widget-icon {
  margin-right: 18px;
}

.landing-5 .content2-section .section-title p {
  padding-top: 20px;
}

.landing-5 .content2-section .content-img {
  max-height: 515px;
  box-shadow: 0 42px 54px rgba(0, 0, 0, 0.31);
}

.landing-5 .content2-section .content-img-mobile {
  box-shadow: 0 42px 54px rgba(0, 0, 0, 0.31);
}

.landing-5 .testimonial-section {
  background-color: #f7f7fb;
  padding-bottom: 60px;
  padding-top: 20px;
}

@media (min-width: 768px) {
  .landing-5 .testimonial-section {
    padding-top: 120px;
  }
}

@media (min-width: 992px) {
  .landing-5 .testimonial-slider-l5 .single-slide {
    margin: 60px 60px 100px 60px;
  }
}

.landing-5 .testimonial-slider-l5 .slick-arrow {
  position: absolute;
  top: 50%;
  font-size: 0;
  height: 65px;
  display: -webkit-box;
  display: flex;
  width: 65px;
  background: #5454d4;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 500px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 999;
  margin-top: -20px;
}

.landing-5 .testimonial-slider-l5 .slick-arrow:hover {
  background-color: #ffffff;
}

.landing-5 .testimonial-slider-l5 .slick-arrow:hover:after {
  color: #5454d4;
}

.landing-5 .testimonial-slider-l5 .slick-arrow:after {
  position: absolute;
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 20px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: #fff;
  -webkit-transition: .4s;
  transition: .4s;
}

.landing-5 .testimonial-slider-l5 .slick-arrow.slick-next {
  right: 0;
  left: auto;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
}

@media (min-width: 992px) {
  .landing-5 .testimonial-slider-l5 .slick-arrow.slick-next {
    margin-right: 60px;
  }
}

.landing-5 .testimonial-slider-l5 .slick-arrow.slick-next:after {
  content: "\f061";
}

@media (min-width: 992px) {
  .landing-5 .testimonial-slider-l5 .slick-arrow.slick-prev {
    margin-left: 60px;
  }
}

.landing-5 .testimonial-slider-l5 .slick-arrow.slick-prev:after {
  content: "\f060";
}

.landing-5 .testimonial-card {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  background: #fff;
  box-shadow: 0 52px 54px rgba(65, 62, 101, 0.1);
  border-radius: 10px;
  padding: 20px 20px 10px 20px;
}

@media (min-width: 576px) {
  .landing-5 .testimonial-card {
    padding: 35px 35px 20px 35px;
  }
}

@media (min-width: 768px) {
  .landing-5 .testimonial-card {
    padding: 10px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}

.landing-5 .testimonial-card .testimonial-image {
  border-radius: 10px;
  overflow: hidden;
}

@media (min-width: 576px) {
  .landing-5 .testimonial-card .testimonial-image {
    min-width: 315px;
  }
}

.landing-5 .testimonial-card .testimonial-image img {
  margin: 0 auto;
  max-width: unset;
  width: 100%;
}

@media (min-width: 576px) {
  .landing-5 .testimonial-card .testimonial-image img {
    max-width: 100%;
    width: auto;
  }
}

.landing-5 .testimonial-card .testmonial-texts {
  text-align: center;
}

@media (min-width: 768px) {
  .landing-5 .testimonial-card .testmonial-texts {
    padding-right: 30px;
  }
}

.landing-5 .testimonial-card .testmonial-texts .testimonial-text-inner {
  padding-top: 35px;
  margin: 0 auto;
  padding-bottom: 30px;
}

@media (min-width: 576px) {
  .landing-5 .testimonial-card .testmonial-texts .testimonial-text-inner {
    max-width: 435px;
  }
}

@media (min-width: 768px) {
  .landing-5 .testimonial-card .testmonial-texts .testimonial-text-inner {
    max-width: 505px;
    padding-left: 30px;
  }
}

@media (min-width: 992px) {
  .landing-5 .testimonial-card .testmonial-texts .testimonial-text-inner {
    padding-top: 65px;
    padding-left: 100px;
    padding-bottom: 15px;
  }
}

@media (min-width: 992px) {
  .landing-5 .testimonial-card .testmonial-texts .testimonial-text-inner {
    padding-top: 65px;
    padding-left: 100px;
  }
}

.landing-5 .testimonial-card .testmonial-texts .quote-shape {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  margin-bottom: 30px;
}

.landing-5 .testimonial-card .testmonial-texts p {
  color: #19191b;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
  padding-top: 10px;
}

.landing-5 .testimonial-card .testmonial-texts .username {
  color: #19191b;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -0.66px;
  line-height: 28px;
}

.landing-5 .testimonial-card .testmonial-texts .rank {
  color: #696871;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.landing-5 .fact-section {
  border-bottom: 1px solid #ededf4;
  padding-bottom: 30px;
}

@media (min-width: 768px) {
  .landing-5 .fact-section {
    padding-bottom: 70px;
  }
}

.landing-5 .single-fact {
  text-align: center;
}

.landing-5 .single-fact .title {
  color: #5454d4;
  font-size: 60px;
  font-weight: 700;
  letter-spacing: -1.88px;
  line-height: 70px;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.landing-5 .single-fact p {
  color: #696871;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 35px;
}

.landing-5 .pricing-section {
  position: relative;
  padding-top: 90px;
}

.landing-5 .pricing-section:before {
  content: "";
  left: 0;
  bottom: 0;
  height: 30%;
  width: 100%;
  position: absolute;
  background: #19191b;
}

.landing-5 .pricing-wrapper.monthly-active .pricing-card .price-block .price:before {
  display: inline-block;
  content: attr(data-monthly);
}

.landing-5 .pricing-wrapper.yearly-active .pricing-card .price-block .price:before {
  display: inline-block;
  content: attr(data-yearly);
}

.landing-5 .pricing-wrapper .pricing-btn {
  display: -webkit-inline-box;
  display: inline-flex;
  position: relative;
  margin-top: 55px;
}

@media (min-width: 470px) {
  .landing-5 .pricing-wrapper .pricing-btn {
    margin-top: 35px;
  }
}

.landing-5 .pricing-wrapper .pricing-btn .period {
  color: #696871;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.5 6px;
  line-height: 28px;
}

.landing-5 .pricing-wrapper .pricing-btn .offer-badge {
  position: absolute;
  width: 85px;
  height: 29px;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: -0.41px;
  border-radius: 5px;
  background-color: #f04037;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  top: 0;
  right: 0;
  -webkit-transform: translateX(0%) translateY(-100%);
          transform: translateX(0%) translateY(-100%);
  margin-right: 0;
  margin-top: -10px;
}

@media (min-width: 470px) {
  .landing-5 .pricing-wrapper .pricing-btn .offer-badge {
    margin-top: 0px;
    top: 0;
    right: -25px;
    -webkit-transform: translateX(100%) translateY(0%);
            transform: translateX(100%) translateY(0%);
    margin-right: 10px;
  }
}

.landing-5 .pricing-wrapper .pricing-btn .toggle-btn {
  width: 72px;
  height: 33px;
  border-radius: 17px;
  background-color: #eae9f2;
  position: relative;
  margin: 0 15px;
}

.landing-5 .pricing-wrapper .pricing-btn .toggle-btn span {
  width: 21px;
  height: 21px;
  background-color: #ffffff;
  position: absolute;
  left: 0;
  margin-left: 6px;
  top: 0;
  margin-top: 6px;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 500px;
  pointer-events: none;
}

.landing-5 .pricing-wrapper .pricing-btn .toggle-btn.clicked {
  background: #5454d4;
}

.landing-5 .pricing-wrapper .pricing-btn .toggle-btn.clicked span {
  left: calc(100% - 33px);
}

.landing-5 .pricing-card {
  box-shadow: 0 52px 54px rgba(65, 62, 101, 0.1);
  border-radius: 10px;
  background-color: #fff;
  padding-top: 30px;
}

.landing-5 .pricing-card .price-content {
  padding-bottom: 30px;
}

.landing-5 .pricing-card .price-content .small-title {
  color: #5454d4;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.landing-5 .pricing-card .price-block {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: end;
          align-items: flex-end;
  -webkit-box-pack: center;
          justify-content: center;
}

.landing-5 .pricing-card .price-block span {
  color: #19191b;
  font-size: 30px;
  font-weight: 300;
  letter-spacing: -0.52px;
  line-height: 56px;
  margin-bottom: 5px;
}

.landing-5 .pricing-card .price-block .price {
  font-size: 80px;
  letter-spacing: -1.38px;
  color: #19191b;
}

.landing-5 .pricing-card .price-block .price:before {
  display: inline-block;
  content: attr(data-active);
}

.landing-5 .pricing-card .price-bottom-text {
  color: #696871;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.56px;
  line-height: 28px;
  margin-top: 3px;
}

.landing-5 .pricing-card .card-list {
  max-width: 315px;
  margin: 30px auto 0;
}

.landing-5 .pricing-card .card-list li {
  color: #19191b;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 50px;
  display: -webkit-box;
  display: flex;
}

.landing-5 .pricing-card .card-list li:before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: inline-block;
  font-size: 13px;
  width: 30px;
  height: 30px;
  background-color: rgba(84, 84, 212, 0.1);
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  border-radius: 500px;
  color: #5454d4;
  position: relative;
  top: 9px;
  margin-right: 13px;
}

.landing-5 .pricing-card .price-btn {
  padding-top: 18px;
  padding-bottom: 22px;
  border-radius: 0 0 10px 10px;
  background-color: #5454d4;
  color: #fff;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.66px;
  display: block;
  position: relative;
  z-index: 1;
}

.landing-5 .pricing-card .price-btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.landing-5 .pricing-card .price-btn:hover:before, .landing-5 .pricing-card .price-btn:focus:before, .landing-5 .pricing-card .price-btn:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.landing-5 .faq-section {
  background: #19191b;
  padding-top: 95px;
}

.landing-5 .faq-section .single-faq {
  padding-left: 45px;
  position: relative;
}

.landing-5 .faq-section .single-faq:before {
  content: "\f061";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  min-width: 30px;
  min-height: 30px;
  max-height: 30px;
  font-size: 15px;
  background-color: #5454d4;
  color: #fff;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  border-radius: 500px;
  position: absolute;
  left: 0;
}

.landing-5 .faq-section .single-faq .faq-title {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.75px;
  margin-bottom: 10px;
}

.landing-5 .faq-section .single-faq p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.landing-5 .faq-section .single-faq p:last-child {
  margin-bottom: 0;
}

.landing-5 .cta-section {
  background-color: #19191b;
  position: relative;
  padding-top: 40px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .landing-5 .cta-section {
    padding-top: 90px;
    padding-bottom: 118px;
  }
}

.landing-5 .cta-section .section-title .title {
  color: #FFF;
  margin-bottom: 22px;
}

.landing-5 .cta-section .section-title p {
  color: #696871;
  margin-bottom: 35px;
}

.landing-5 .cta-section .cta-btn {
  padding-top: 5px;
}

.landing-5 .cta-section .cta-btn .btn {
  width: 250px;
  height: 60px;
  border-radius: 10px;
  border: 1px solid #c31a12;
  background-color: #f04037;
  color: #fff;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.66px;
  overflow: hidden;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  margin: 0 auto;
}

.landing-5 .cta-section .cta-btn .btn:hover {
  border-color: #fff;
}

.landing-5 .cta-section .cta-btn .btn-bottom-text {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 300;
  letter-spacing: -0.44px;
  padding-top: 10px;
  display: block;
}

.landing-5 .footer-section {
  background: #fff;
}

.landing-5 .footer-section p {
  color: #696871;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.landing-5 .footer-title {
  color: #19191B;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 25px;
}

.landing-5 .footer-list a {
  color: #19191B;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 38px;
}

@media (min-width: 768px) {
  .landing-5 .footer-list a:hover {
    color: #5454d4;
  }
}

.landing-5 .footer-list.contact-list a {
  color: #5454D4;
}

.landing-5 .social-icons {
  display: -webkit-inline-box;
  display: inline-flex;
  margin-left: -5px;
  margin-right: -5px;
  padding-top: 6px;
}

.landing-5 .social-icons li a {
  color: #FFFFFF;
  background: #696871;
  width: 30px;
  height: 30px;
  font-size: 16px;
  -webkit-transition: .4s;
  transition: .4s;
  padding: 0 3px;
  margin: 0 5px;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  border-radius: 500px;
}

@media (min-width: 768px) {
  .landing-5 .social-icons li a:hover {
    background: #f04037;
  }
}

/*==== 8.Landing 6 -------*/
.landing-6 {
  background-color: #f6f6f8;
  /*>>>>>>>> Header area styling <<<<<<<<<*/
  /*>>>>>>>> Hero area styling <<<<<<<<<*/
  /*>>>>>>>> Hero area styling <<<<<<<<<*/
  /*>>>>>>>> Feature section styling <<<<<<<<<*/
  /*>>>>>>>> Testimonial styling (chapters covered) <<<<<<<<<*/
  /*>>>>>>>> Feature styling (chapters covered) <<<<<<<<<*/
  /*>>>>>>>> Content Section2 styling <<<<<<<<<*/
  /*>>>>>>>> Offer Section styling <<<<<<<<<*/
  /*>>>>>>>> Fact Section styling <<<<<<<<<*/
  /*>>>>>>>> Fact Section styling <<<<<<<<<*/
  /*////////////////// 
    Review
//////////////////*/
  /*>>>>>>>> About Author section styling <<<<<<<<<*/
  /*>>>>>>>> Pricing section styling <<<<<<<<<*/
  /*>>>>>>>> Faq Section styling <<<<<<<<<*/
  /*>>>>>>>> CTA Section styling <<<<<<<<<*/
  /*>>>>>>>> Footer area styling <<<<<<<<<*/
}

.landing-6 .bg-gray {
  background-color: #F2F2F3;
}

.landing-6 .section-title .title {
  color: #19191b;
  font-weight: 700;
  letter-spacing: -1.88px;
  font-size: 48px;
  line-height: 50px;
}

@media (min-width: 768px) {
  .landing-6 .section-title .title {
    font-size: 60px;
    line-height: 70px;
  }
}

.landing-6 .section-title p {
  color: #696871;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

.landing-6 .section-title.white .title {
  color: #fff;
}

.landing-6 .section-title.white p {
  color: rgba(255, 255, 255, 0.7);
}

.landing-6 .btn--primary {
  width: 252px;
  height: 60px;
  border-radius: 5px;
  border: 1px solid #f0544c;
  background-color: #f0544c;
  color: #fff;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-transform: perspective(1px) translateZ(0);
          transform: perspective(1px) translateZ(0);
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.landing-6 .btn--primary:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.landing-6 .btn--primary:hover:before, .landing-6 .btn--primary:focus:before, .landing-6 .btn--primary:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.landing-6 .site-header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}

.landing-6 .btn-close-off-canvas.white-toggler {
  color: #fff;
  border-color: #fff;
}

@media (min-width: 992px) {
  .landing-6 .main-menu > li > .nav-link {
    color: #19191b !important;
    font-weight: 300;
  }
  .landing-6 .main-menu > li > .nav-link:hover {
    color: #5454d4 !important;
  }
}

@media (min-width: 992px) {
  .landing-6 .menu-dropdown {
    border-top: 3px solid #fedc5a;
  }
}

.landing-6 .header-btn a {
  font-size: 14px;
  max-width: 110px;
  height: 34px;
}

@media (min-width: 480px) {
  .landing-6 .header-btn a {
    font-size: 16px;
    max-width: 140px;
    height: 45px;
  }
}

.landing-6 .hero-area {
  background: #f6f6f8;
  padding-top: 120px;
  padding-bottom: 30px;
}

@media (min-width: 768px) {
  .landing-6 .hero-area {
    padding-top: 120px;
    padding-bottom: 30px;
  }
}

@media (min-width: 992px) {
  .landing-6 .hero-area {
    padding-top: 180px;
    padding-bottom: 30px;
  }
}

.landing-6 .hero-content {
  color: #19191b;
}

@media (min-width: 992px) {
  .landing-6 .hero-content {
    padding-left: 60px;
  }
}

@media (min-width: 1200px) {
  .landing-6 .hero-content {
    padding-left: 70px;
  }
}

.landing-6 .hero-content .title {
  color: inherit;
  font-family: "CircularStd", sans-serif;
  font-weight: 700;
  letter-spacing: -2.81px;
  font-size: 50px;
  line-height: 56px;
  margin-bottom: 23px;
}

@media (min-width: 576px) {
  .landing-6 .hero-content .title {
    font-size: 55px;
    line-height: 63px;
  }
}

@media (min-width: 992px) {
  .landing-6 .hero-content .title {
    font-size: 60px;
    line-height: 70px;
  }
}

.landing-6 .hero-content p {
  color: inherit;
  font-family: "CircularStd", sans-serif;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

.landing-6 .hero-image {
  margin-left: -55px;
  margin-bottom: 30px;
}

@media (min-width: 400px) {
  .landing-6 .hero-image {
    max-width: 80%;
    margin-left: -35px;
  }
}

@media (min-width: 576px) {
  .landing-6 .hero-image {
    max-width: unset;
    margin-left: -35px;
    position: relative;
    left: -35px;
  }
}

@media (min-width: 768px) {
  .landing-6 .hero-image {
    margin-left: -85px;
    position: relative;
    left: -35px;
  }
}

@media (min-width: 992px) {
  .landing-6 .hero-image {
    margin-bottom: 0px;
    left: 0;
    margin-left: -156px;
  }
}

@media (min-width: 1200px) {
  .landing-6 .hero-image {
    margin-left: -90px;
  }
}

.landing-6 .hero-list {
  padding-top: 10px;
}

.landing-6 .hero-list li {
  position: relative;
  color: #19191b;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.66px;
  display: -webkit-box;
  display: flex;
  margin-bottom: 13px;
}

.landing-6 .hero-list li:before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 11px;
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  background-color: rgba(84, 84, 212, 0.1);
  display: -webkit-box;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 500px;
  color: #5454d4;
  position: relative;
  top: 3px;
  margin-right: 13px;
}

.landing-6 .hero-btn {
  padding-top: 25px;
}

.landing-6 .hero-btn .btn {
  min-width: 325px;
  width: auto;
  padding-left: 30px;
  padding-right: 30px;
}

.landing-6 .hero-btn .button-bottom-text {
  color: #413e65;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.44px;
  margin-top: 15px;
  display: block;
}

.landing-6 .hero-btn .button-bottom-text .link {
  color: #5454d4;
}

.landing-6 .author-section {
  border-bottom: 1px solid #eae9f2;
  padding-top: 17px;
  padding-bottom: 45px;
}

@media (min-width: 768px) {
  .landing-6 .author-section {
    padding-top: 90px;
    padding-bottom: 85px;
  }
}

.landing-6 .author-widget {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
}

@media (min-width: 576px) {
  .landing-6 .author-widget {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}

@media (min-width: 992px) {
  .landing-6 .author-widget {
    padding-left: 90px;
    padding-right: 90px;
  }
}

.landing-6 .author-widget .widget-image {
  min-width: 111px;
  max-width: 111px;
  height: 111px;
  border-radius: 500px;
  overflow: hidden;
}

.landing-6 .author-widget .widget-text {
  padding-top: 20px;
}

@media (min-width: 576px) {
  .landing-6 .author-widget .widget-text {
    padding-left: 30px;
    padding-top: 0px;
  }
}

.landing-6 .author-widget .widget-text .author-name {
  color: #19191b;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -0.66px;
  line-height: 28px;
}

.landing-6 .author-widget .widget-text p {
  color: #19191b;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
  font-style: italic;
  margin-bottom: 13px;
}

.landing-6 .feature-section {
  padding-top: 47px;
  padding-bottom: 23px;
  border-bottom: 1px solid #eae9f2;
  background: #fff;
}

@media (min-width: 768px) {
  .landing-6 .feature-section {
    padding-top: 87px;
    padding-bottom: 63px;
  }
}

.landing-6 .feature-widget .widget-icon {
  margin-bottom: 48px;
}

.landing-6 .feature-widget .widget-texts .title {
  color: #19191b;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.75px;
  padding-bottom: 13px;
}

.landing-6 .feature-widget .widget-texts p {
  color: #696871;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.landing-6 .feature-widget .widget-texts p:last-child {
  margin-bottom: 0;
}

.landing-6 .testimonial-section {
  background-color: #f6f6f8;
  padding-top: 50px;
}

@media (min-width: 768px) {
  .landing-6 .testimonial-section {
    padding-top: 80px;
  }
}

.landing-6 .testimonial-slider-l6 .slick-list {
  margin: 0 -20px;
}

.landing-6 .testimonial-slider-l6 .single-slide {
  margin: 60px 20px 45px;
}

.landing-6 .testimonial-slider-l6 .slick-arrow {
  position: absolute;
  top: 50%;
  font-size: 0;
  height: 65px;
  display: -webkit-box;
  display: flex;
  width: 65px;
  background: #5454d4;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 500px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 99;
}

.landing-6 .testimonial-slider-l6 .slick-arrow:hover {
  background-color: #ffffff;
}

.landing-6 .testimonial-slider-l6 .slick-arrow:hover:after {
  color: #5454d4;
}

.landing-6 .testimonial-slider-l6 .slick-arrow:after {
  position: absolute;
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 20px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: #fff;
  -webkit-transition: .4s;
  transition: .4s;
  margin-left: 0;
}

.landing-6 .testimonial-slider-l6 .slick-arrow.slick-next {
  right: 0;
  left: auto;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
}

.landing-6 .testimonial-slider-l6 .slick-arrow.slick-next:after {
  content: "\f061";
}

.landing-6 .testimonial-slider-l6 .slick-arrow.slick-prev:after {
  content: "\f060";
}

.landing-6 .testimonial-card {
  display: -webkit-box;
  display: flex;
  background: #fff;
  box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
  border-radius: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (min-width: 576px) {
  .landing-6 .testimonial-card {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}

@media (min-width: 768px) {
  .landing-6 .testimonial-card {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 992px) {
  .landing-6 .testimonial-card {
    padding-right: 100px;
    padding-left: 100px;
  }
}

@media (min-width: 1200px) {
  .landing-6 .testimonial-card {
    padding-right: 120px;
    padding-left: 120px;
  }
}

@media (min-width: 400px) {
  .landing-6 .testimonial-card .testimonial-image {
    min-width: 215px;
  }
}

.landing-6 .testimonial-card .testimonial-image img {
  margin: 0 auto;
  max-width: unset;
  width: 100%;
}

@media (min-width: 400px) {
  .landing-6 .testimonial-card .testimonial-image img {
    max-width: 100%;
    width: auto;
  }
}

.landing-6 .testimonial-card .testimonial-number-block {
  min-width: 215px;
  height: 213px;
  border-radius: 8px;
  background-color: #eae9f2;
  text-align: center;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .landing-6 .testimonial-card .testimonial-number-block {
    margin-left: 0;
  }
}

.landing-6 .testimonial-card .testimonial-number-block span {
  color: #413e65;
  font-size: 140px;
  font-weight: 900;
  letter-spacing: -4.38px;
}

.landing-6 .testimonial-card .testmonial-texts {
  text-align: center;
  padding-top: 30px;
}

@media (min-width: 768px) {
  .landing-6 .testimonial-card .testmonial-texts {
    text-align: left;
    padding-left: 50px;
    padding-right: 0px;
    padding-top: 0px;
  }
}

@media (min-width: 992px) {
  .landing-6 .testimonial-card .testmonial-texts {
    padding-left: 90px;
  }
}

@media (min-width: 1200px) {
  .landing-6 .testimonial-card .testmonial-texts {
    padding-left: 120px;
  }
}

.landing-6 .testimonial-card .testmonial-texts .title-top {
  color: #5454d4;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
  margin-bottom: 10px;
}

.landing-6 .testimonial-card .testmonial-texts .title-top {
  color: #5454d4;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.landing-6 .testimonial-card .testmonial-texts .testmonial-title {
  color: #19191b;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.75px;
  padding-bottom: 30px;
}

.landing-6 .testimonial-card .testmonial-texts p {
  color: #19191b;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

.landing-6 .testimonial-card .testmonial-texts p:last-child {
  margin-bottom: 0;
}

.landing-6 .feature-section {
  background: #f6f6f8;
}

.landing-6 .content-section2 {
  background-color: #f2f2f3;
  padding-top: 43px;
  padding-bottom: 23px;
}

@media (min-width: 992px) {
  .landing-6 .content-section2 {
    padding-top: 100px;
    padding-bottom: 80px;
  }
}

.landing-6 .content-text .title {
  margin-bottom: 20px;
}

.landing-6 .content-text .content-btn {
  margin-top: 40px;
}

@media (min-width: 1200px) {
  .landing-6 .content-text {
    padding-right: 20px;
  }
}

.landing-6 .content-widget {
  border-radius: 10px;
  border: 1px solid #eae9f2;
  background-color: #ffffff;
  padding: 20px;
  min-width: 100%;
  width: 100%;
  min-height: 89px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}

@media (min-width: 1200px) {
  .landing-6 .content-widget {
    width: 360px;
    min-width: 360px;
  }
}

.landing-6 .content-widget .widget-icon {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  border-radius: 50%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  margin-right: 20px;
  color: #fff;
  background-color: #5454d4;
}

.landing-6 .content-widget .widget-text .title {
  color: #19191b;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.66px;
  margin-bottom: 0;
}

.landing-6 .content-widget .widget-text .sub-title {
  color: #19191b;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
}

.landing-6 .book-offer-wrapper {
  padding-right: 50px;
}

.landing-6 .book-widget {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
}

.landing-6 .book-widget .widget-text {
  padding-top: 20px;
  padding-left: 30px;
  padding-left: 50px;
}

.landing-6 .book-widget .w-title {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.75px;
  padding-bottom: 15px;
}

.landing-6 .book-widget p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.56px;
  line-height: 28px;
}

.landing-6 .book-widget p:last-child {
  margin-bottom: 0;
}

.landing-6 .book-widget .widget-btn {
  margin-left: 45px;
  margin-top: 30px;
}

@media (min-width: 992px) {
  .landing-6 .book-widget .widget-btn {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.landing-6 .book-offer-section {
  background: #f2f2f3;
}

.landing-6 .book-offer-wrapper {
  box-shadow: 0 52px 54px rgba(65, 62, 101, 0.3);
  border-radius: 10px;
  background-color: #413e65;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 100px;
}

.landing-6 .fact-section {
  border-bottom: 1px solid #ededf4;
  background: #f2f2f3;
}

@media (min-width: 768px) {
  .landing-6 .fact-section {
    padding-bottom: 70px;
  }
}

.landing-6 .single-fact {
  text-align: center;
}

.landing-6 .single-fact .title {
  color: #5454d4;
  font-size: 60px;
  font-weight: 700;
  letter-spacing: -1.88px;
  line-height: 70px;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.landing-6 .single-fact p {
  color: #696871;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 35px;
}

.landing-6 .review-section {
  border-bottom: 1px solid #EAE9F2;
  padding: 45px 0;
}

@media (min-width: 768px) {
  .landing-6 .review-section {
    padding: 95px 0;
  }
}

.landing-6 .review-card {
  margin-bottom: 30px;
  display: -webkit-box;
  display: flex;
  width: 100%;
  min-height: 403px;
  border-radius: 5px;
  background-color: #FFFFFF;
  padding: 35px 30px 30px 35px;
  -webkit-transition: .4s;
  transition: .4s;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
}

.landing-6 .review-card:before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
  content: "";
  -webkit-transition: .4s;
  transition: .4s;
}

.landing-6 .review-card .review-star {
  margin-bottom: 25px;
}

.landing-6 .review-card .card-content p {
  font-style: italic;
  color: #19191B;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

.landing-6 .review-card .card-id {
  display: -webkit-box;
  display: flex;
  margin-top: auto;
  -webkit-box-align: center;
          align-items: center;
}

.landing-6 .review-card .card-id .id-img {
  width: 45px;
  height: 45px;
  border-radius: 500px;
  overflow: hidden;
  text-align: center;
}

.landing-6 .review-card .card-id .id-content {
  margin-left: 22px;
}

.landing-6 .review-card .card-id .id-content h6 {
  color: #1F1F1F;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.34px;
  line-height: 28px;
  margin-bottom: -5px;
}

.landing-6 .review-card .card-id .id-content span {
  color: #666666;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 28px;
}

.landing-6 .review-card:hover:before {
  box-shadow: -1px 6px 4px rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.04);
}

.landing-6 .more-review-link {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  text-decoration: underline;
  text-align: center;
}

.landing-6 .more-review-link a {
  color: #5454d4;
}

.landing-6 .more-review-link a:hover {
  color: #5454d4;
  text-decoration: underline;
}

.landing-6 .about-author-section {
  padding-top: 40px;
  padding-bottom: 35px;
}

@media (min-width: 768px) {
  .landing-6 .about-author-section {
    padding-top: 90px;
    padding-bottom: 85px;
  }
}

.landing-6 .about-author-section .section-title {
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .landing-6 .about-author-section .section-title {
    margin-bottom: 80px;
  }
}

.landing-6 .about-author-section .section-title h2 {
  letter-spacing: -1.88px;
}

.landing-6 .author-image {
  min-width: 271px;
  height: 271px;
  border-radius: 500px;
  overflow: hidden;
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .landing-6 .author-image {
    margin-bottom: 0px;
  }
}

.landing-6 .author-text {
  margin-top: 30px;
}

@media (min-width: 768px) {
  .landing-6 .author-text {
    margin-top: 0px;
  }
}

.landing-6 .author-text .title {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.75px;
  color: #19191B;
  margin-bottom: 30px;
}

.landing-6 .author-text p {
  color: #696871;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
  margin-bottom: 30px;
}

.landing-6 .author-text p:last-child {
  margin-bottom: 0px;
}

.landing-6 .author-text p .link {
  font-size: 16px;
  font-weight: 300;
  color: #19191B;
  text-decoration: underline;
}

.landing-6 .pricing-section {
  position: relative;
  background-color: #413e65;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .landing-6 .pricing-section {
    padding-top: 100px;
    padding-bottom: 90px;
  }
}

.landing-6 .pricing-card {
  background: #fff;
  padding-top: 45px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  border-radius: 10px;
  min-height: 441px;
}

.landing-6 .pricing-card .title-top {
  color: #5454d4;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
  margin-bottom: 21px;
}

.landing-6 .pricing-card .title-bottom {
  color: #696871;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.56px;
  line-height: 28px;
  padding-top: 5px;
}

.landing-6 .pricing-card .pricing-title {
  color: #1d293f;
  font-size: 60px;
  font-weight: 700;
  letter-spacing: -1.03px;
  line-height: 56px;
}

.landing-6 .pricing-card p {
  color: #19191b;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
  margin-top: 20px;
}

.landing-6 .pricing-card p:last-child {
  margin-bottom: 0;
}

.landing-6 .pricing-card .form-group {
  margin-bottom: 0;
  padding-top: 25px;
}

.landing-6 .pricing-card .form-group .form-control {
  border-radius: 10px;
  height: 60px;
  border: 1px solid #eae9f2;
  background-color: #f7f7fb;
  color: #696871;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.56px;
  text-align: center;
  margin-bottom: 15px;
}

.landing-6 .pricing-card .form-group .icon-input {
  position: relative;
}

.landing-6 .pricing-card .form-group .icon-input i {
  color: #cdccd9;
  position: absolute;
  height: 100%;
  top: 0;
  padding-left: 15px;
  padding-right: 15px;
  width: 55px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}

.landing-6 .pricing-card .form-group .card-btn {
  margin-top: 0;
}

.landing-6 .pricing-card .card-btn {
  height: 60px;
  border-radius: 5px;
  color: #fff;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.66px;
  width: 100%;
  -webkit-transform: perspective(1px) translateZ(0);
          transform: perspective(1px) translateZ(0);
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.landing-6 .pricing-card .card-btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.landing-6 .pricing-card .card-btn:hover:before, .landing-6 .pricing-card .card-btn:focus:before, .landing-6 .pricing-card .card-btn:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.landing-6 .pricing-card .card-btn.btn--blue {
  border: 1px solid #3d3dad;
  background-color: #5454d4;
}

.landing-6 .pricing-card .card-btn.btn--orange {
  border: 1px solid #c31a12;
  background-color: #f0544c;
}

.landing-6 .pricing-card.premium {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
}

.landing-6 .pricing-card.premium .card-btn {
  margin-top: auto;
}

.landing-6 .faq-section {
  background-color: #413e65;
  padding-bottom: 25px;
}

@media (min-width: 768px) {
  .landing-6 .faq-section {
    padding-bottom: 75px;
  }
}

.landing-6 .faq-section .single-faq {
  padding-left: 55px;
  position: relative;
}

.landing-6 .faq-section .single-faq:before {
  content: "\f061";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  min-width: 30px;
  min-height: 30px;
  max-height: 30px;
  font-size: 15px;
  background-color: #5454d4;
  color: #fff;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  border-radius: 500px;
  position: absolute;
  left: 0;
}

.landing-6 .faq-section .single-faq .faq-title {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.75px;
  margin-bottom: 10px;
}

.landing-6 .faq-section .single-faq p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.landing-6 .faq-section .single-faq p:last-child {
  margin-bottom: 0;
}

.landing-6 .cta-section {
  padding-top: 40px;
  padding-bottom: 35px;
  background-color: #F6F6F8;
}

@media (min-width: 768px) {
  .landing-6 .cta-section {
    padding-top: 95px;
    padding-bottom: 68px;
  }
}

.landing-6 .cta-section h2 {
  color: inherit;
}

.landing-6 .cta-section .cta-btn {
  width: 100%;
}

@media (min-width: 992px) {
  .landing-6 .cta-section .cta-btn {
    width: auto;
  }
}

.landing-6 .cta-section .cta-btn .btn--primary {
  width: 100%;
}

@media (min-width: 992px) {
  .landing-6 .cta-section .cta-btn .btn--primary {
    width: 220px;
  }
}

.landing-6 .footer-section {
  padding: 45px 0 10px;
  background-color: #F6F6F8;
  border-top: 1px solid #EAE9F2;
}

.landing-6 .footer-section p {
  color: #19191B;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.landing-6 .footer-section p:last-child {
  margin-bottom: 0;
}

.landing-6 .footer-list a {
  color: #19191B;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 1;
}

@media (min-width: 768px) {
  .landing-6 .footer-list a:hover {
    color: #5454d4;
  }
}

.landing-6 .footer-list.list-inline {
  display: -webkit-inline-box;
  display: inline-flex;
}

@media (min-width: 1200px) {
  .landing-6 .footer-list.list-inline {
    margin-left: 90px;
  }
}

.landing-6 .footer-list.list-inline li {
  padding: 0 15px;
}

/*==== 9.Landing 7 -------*/
.landing-7 {
  background: #fcfdfe;
  /*------> Review Area <------*/
  /*------> Video Area <------*/
  /*------> Content Area <------*/
  /*>>>>>>>> CTA area styling <<<<<<<<<*/
  /*>>>>>>>> Footer area styling <<<<<<<<<*/
}

.landing-7 .header-btns {
  margin-right: 10px;
}

@media (min-width: 480px) {
  .landing-7 .header-btns {
    margin-right: 15px;
  }
}

@media (min-width: 992px) {
  .landing-7 .header-btns {
    margin-right: 0;
  }
}

.landing-7 .header-btns a {
  font-size: 14px !important;
  width: 120px !important;
  height: 34px !important;
}

@media (min-width: 370px) {
  .landing-7 .header-btns a {
    font-size: 16px !important;
    width: 141px !important;
    height: 45px !important;
  }
}

.landing-7 .btn--primary {
  width: 252px;
  height: 60px;
  border-radius: 5px;
  border: 1px solid #c31a12;
  background-color: #f04037;
  color: #fff;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-transform: perspective(1px) translateZ(0);
          transform: perspective(1px) translateZ(0);
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.landing-7 .btn--primary:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.landing-7 .btn--primary:hover:before, .landing-7 .btn--primary:focus:before, .landing-7 .btn--primary:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.landing-7 .hero-area {
  padding-top: 100px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .landing-7 .hero-area {
    padding-top: 130px;
    padding-bottom: 60px;
  }
}

.landing-7 .hero-image img {
  max-width: 80%;
}

@media (min-width: 400px) {
  .landing-7 .hero-image img {
    max-width: 50%;
  }
}

@media (min-width: 576px) {
  .landing-7 .hero-image img {
    max-width: 100%;
  }
}

.landing-7 .hero-content .title {
  color: #19191b;
  font-family: "CircularStd", sans-serif;
  font-weight: 700;
  letter-spacing: -2.81px;
  font-size: 50px;
  line-height: 56px;
  margin-bottom: 28px;
}

@media (min-width: 768px) {
  .landing-7 .hero-content .title {
    font-size: 60px;
    line-height: 70px;
  }
}

.landing-7 .hero-content p {
  color: #19191b;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

.landing-7 .hero-content .hero-btn {
  margin-top: 38px;
}

.landing-7 .hero-content .video-link {
  color: #413e65;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  margin-top: 12px;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
}

.landing-7 .hero-content .video-link i {
  margin-right: 6px;
}

@media (min-width: 1200px) {
  .landing-7 .hero-content {
    padding-right: 20px;
  }
}

.landing-7 .hero-form {
  position: relative;
  max-width: 480px;
}

.landing-7 .hero-form .input-group {
  width: 100%;
  height: 71px;
  border-radius: 10px;
  border: 1px solid #EAE9F2;
  background-color: #FFFFFF;
  position: relative;
}

.landing-7 .hero-form .input-group i {
  position: absolute;
  top: 27px;
  left: 27px;
  font-size: 19px;
  color: #cdccd9;
  margin-top: -2px;
}

.landing-7 .hero-form .input-group input {
  padding-left: 63px;
  padding-right: 15px;
  width: 100%;
  border: 0;
  border-radius: 10px;
}

@media (min-width: 500px) {
  .landing-7 .hero-form .input-group input {
    padding-right: 140px;
  }
}

.landing-7 .hero-form .input-group input:focus {
  box-shadow: none;
  outline: none;
}

.landing-7 .hero-form .input-group input::-webkit-input-placeholder {
  color: #696871;
  font-weight: 400;
}

.landing-7 .hero-form .input-group input::-moz-placeholder {
  color: #696871;
  font-weight: 400;
}

.landing-7 .hero-form .input-group input:-ms-input-placeholder {
  color: #696871;
  font-weight: 400;
}

.landing-7 .hero-form .input-group input::-ms-input-placeholder {
  color: #696871;
  font-weight: 400;
}

.landing-7 .hero-form .input-group input::placeholder {
  color: #696871;
  font-weight: 400;
}

.landing-7 .hero-form button {
  margin-top: 15px;
  width: 200px;
}

@media (min-width: 768px) {
  .landing-7 .hero-form button {
    max-width: 140px;
    font-size: 18px;
  }
}

@media (min-width: 992px) {
  .landing-7 .hero-form button {
    max-width: 140px;
    font-size: 19px;
  }
}

@media (min-width: 500px) {
  .landing-7 .hero-form button {
    position: absolute;
    margin-top: 0px;
    top: 5px;
    right: 0px;
    margin-right: 5px;
  }
}

.landing-7 .review-area {
  background-color: #5454d4;
  padding-top: 50px;
  padding-bottom: 20px;
}

@media (min-width: 992px) {
  .landing-7 .review-area {
    padding-top: 115px;
    padding-bottom: 90px;
  }
}

.landing-7 .single-review .review-star {
  margin-bottom: 17px;
}

.landing-7 .single-review .review-star i {
  font-size: 21px;
  color: #fedc5a;
}

.landing-7 .single-review p {
  color: #fff;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
  font-style: italic;
}

.landing-7 .single-review .user-name {
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.landing-7 .video-area {
  position: relative;
}

.landing-7 .video-area .video-image {
  width: 100%;
}

.landing-7 .video-area .play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.landing-7 .video-area .play-button img {
  max-width: 80%;
}

@media (min-width: 480px) {
  .landing-7 .video-area .play-button img {
    max-width: 60%;
  }
}

@media (min-width: 576px) {
  .landing-7 .video-area .play-button img {
    max-width: 60%;
  }
}

@media (min-width: 768px) {
  .landing-7 .video-area .play-button img {
    max-width: 100%;
  }
}

.landing-7 .content-section {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .landing-7 .content-section {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

@media (min-width: 992px) {
  .landing-7 .content-section {
    padding-bottom: 60px;
  }
}

.landing-7 .content-section-2 {
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .landing-7 .content-section-2 {
    padding-bottom: 120px;
  }
}

.landing-7 .content-section-3 {
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .landing-7 .content-section-3 {
    padding-bottom: 120px;
  }
}

.landing-7 .content-text .title {
  color: #19191b;
  font-weight: 700;
  letter-spacing: -1.88px;
  padding-bottom: 20px;
  font-size: 40px;
  line-height: 50px;
}

@media (min-width: 576px) {
  .landing-7 .content-text .title {
    font-size: 60px;
    line-height: 70px;
  }
}

.landing-7 .content-text p {
  color: #696871;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

.landing-7 .content-text .content-widget {
  counter-reset: widget-counter;
}

.landing-7 .content-text .content-widget .single-widget {
  padding-left: 70px;
  position: relative;
  max-width: 411px;
  margin-top: 50px;
}

.landing-7 .content-text .content-widget .single-widget:before {
  counter-increment: widget-counter;
  content: counter(widget-counter);
  position: absolute;
  left: 0;
  width: 49px;
  height: 49px;
  background-color: #5454d4;
  border-radius: 500px;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.75px;
}

.landing-7 .content-text .content-widget .single-widget .w-title {
  color: #19191b;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -0.66px;
  margin-bottom: 15px;
}

.landing-7 .content-text .content-widget .single-widget p {
  color: #696871;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.landing-7 .content-text .content-widget .single-widget p:last-child {
  margin-bottom: 0;
}

.landing-7 .content-btn .btn--primary {
  width: 200px;
}

@media (min-width: 992px) {
  .landing-7 .content-image {
    text-align: left;
  }
}

.landing-7 .content-image img {
  -webkit-transform: rotate(3deg);
          transform: rotate(3deg);
  border-radius: 10px;
  max-width: 70%;
}

@media (min-width: 400px) {
  .landing-7 .content-image img {
    max-width: 50%;
  }
}

@media (min-width: 576px) {
  .landing-7 .content-image img {
    max-width: 40%;
  }
}

@media (min-width: 768px) {
  .landing-7 .content-image img {
    max-width: 33%;
  }
}

@media (min-width: 992px) {
  .landing-7 .content-image img {
    margin-left: 20px;
    max-width: 100%;
  }
}

.landing-7 .content-image-group {
  display: -webkit-box;
  display: flex;
  margin-right: -15px;
}

.landing-7 .content-image-group .single-image {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
}

.landing-7 .content-image-group .single-image:nth-child(2) {
  padding-top: 43px;
}

.landing-7 .content-image-group .single-image img {
  padding-bottom: 15px;
  padding-right: 15px;
}

@media (min-width: 992px) {
  .landing-7 .content-image-3 {
    text-align: left;
  }
}

@media (min-width: 1200px) {
  .landing-7 .content-image-3 {
    padding-left: 50px;
  }
}

.landing-7 .content-image-3 img {
  -webkit-transform: rotate(-3deg);
          transform: rotate(-3deg);
  border-radius: 10px;
  max-width: 70%;
}

@media (min-width: 400px) {
  .landing-7 .content-image-3 img {
    max-width: 50%;
  }
}

@media (min-width: 576px) {
  .landing-7 .content-image-3 img {
    max-width: 40%;
  }
}

@media (min-width: 768px) {
  .landing-7 .content-image-3 img {
    max-width: 33%;
  }
}

@media (min-width: 992px) {
  .landing-7 .content-image-3 img {
    margin-left: 20px;
    max-width: 100%;
  }
}

.landing-7 .cta-section {
  background: #fcfdfe;
}

.landing-7 .cta-content {
  padding-top: 45px;
  padding-bottom: 45px;
}

@media (min-width: 768px) {
  .landing-7 .cta-content {
    padding-top: 90px;
    padding-bottom: 100px;
  }
}

.landing-7 .cta-content {
  text-align: center;
}

.landing-7 .cta-content h2 {
  color: #19191b;
  font-size: 50px;
  font-weight: 700;
  letter-spacing: -1.88px;
  line-height: 55px;
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .landing-7 .cta-content h2 {
    font-size: 60px;
    line-height: 70px;
  }
}

.landing-7 .cta-content p {
  color: #696871;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

.landing-7 .cta-content .cta-btns {
  margin-top: 35px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
}

.landing-7 .cta-content .cta-btns .apple-store {
  padding: 0 9px;
  -webkit-transition: .4s;
  transition: .4s;
}

.landing-7 .cta-content .cta-btns .apple-store:hover {
  -webkit-transform: translateY(-8px);
          transform: translateY(-8px);
}

.landing-7 .cta-content .cta-btns .google-play {
  padding: 0 9px;
  -webkit-transition: .4s;
  transition: .4s;
}

.landing-7 .cta-content .cta-btns .google-play:hover {
  -webkit-transform: translateY(-8px);
          transform: translateY(-8px);
}

.landing-7 .footer-section {
  padding: 45px 0 10px;
  background-color: #fcfdfe;
  border-top: 1px solid #EAE9F2;
}

.landing-7 .footer-section p {
  color: #19191B;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.landing-7 .footer-section p:last-child {
  margin-bottom: 0;
}

.landing-7 .footer-list a {
  color: #19191B;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 1;
}

@media (min-width: 768px) {
  .landing-7 .footer-list a:hover {
    color: #5454d4;
  }
}

.landing-7 .footer-list.list-inline {
  display: -webkit-inline-box;
  display: inline-flex;
}

@media (min-width: 1200px) {
  .landing-7 .footer-list.list-inline {
    margin-left: 90px;
  }
}

.landing-7 .footer-list.list-inline li {
  padding: 0 15px;
}

/*==== 10.Landing 8 -------*/
.landing-8 {
  background: #19191b;
  /*>>>>>>>> Fact Section styling <<<<<<<<<*/
  /*>>>>>>>> Services Section styling <<<<<<<<<*/
  /*>>>>>>>> Case Study Section styling <<<<<<<<<*/
  /*>>>>>>>> Testimonial Section styling <<<<<<<<<*/
  /*>>>>>>>> Contact Section styling <<<<<<<<<*/
  /*>>>>>>>> Footer Section styling <<<<<<<<<*/
}

.landing-8 .text--primary {
  color: #5454d4;
}

.landing-8 .btn--primary {
  border-radius: 5px;
  border: none;
  background-color: #f04037;
  color: #fff;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-transform: perspective(1px) translateZ(0);
          transform: perspective(1px) translateZ(0);
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 200px;
  height: 60px;
  font-size: 21px;
}

.landing-8 .btn--primary:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.landing-8 .btn--primary:hover:before, .landing-8 .btn--primary:focus:before, .landing-8 .btn--primary:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.landing-8 .btn-outlined--white {
  color: #fff;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.66px;
  border-radius: 5px;
  border: 2px solid rgba(255, 255, 255, 0.7);
  background-color: #19191b;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  width: 222px;
  height: 60px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  -webkit-transition: .4s;
  transition: .4s;
}

.landing-8 .btn-outlined--white:before {
  content: "";
  background: #fff;
  height: 100%;
  width: 100%;
  top: 0;
  left: 100%;
  position: absolute;
  z-index: -1;
  -webkit-transition: .4s;
  transition: .4s;
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
}

.landing-8 .btn-outlined--white:hover {
  color: #19191b;
  border-color: #fff;
}

.landing-8 .btn-outlined--white:hover:before {
  left: 0;
}

.landing-8 .header-btns {
  margin-right: 10px;
}

@media (min-width: 480px) {
  .landing-8 .header-btns {
    margin-right: 15px;
  }
}

@media (min-width: 992px) {
  .landing-8 .header-btns {
    margin-right: 0;
  }
}

.landing-8 .header-btns a {
  font-size: 14px !important;
  width: 120px !important;
  height: 34px !important;
}

@media (min-width: 370px) {
  .landing-8 .header-btns a {
    font-size: 16px !important;
    width: 141px !important;
    height: 45px !important;
  }
}

.landing-8 .main-menu > li > .nav-link {
  font-weight: 300;
}

.landing-8 .hero-area {
  padding-top: 100px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .landing-8 .hero-area {
    padding-top: 200px;
    padding-bottom: 60px;
  }
}

.landing-8 .hero-image img {
  max-width: 80%;
}

@media (min-width: 400px) {
  .landing-8 .hero-image img {
    max-width: 50%;
  }
}

@media (min-width: 576px) {
  .landing-8 .hero-image img {
    max-width: 100%;
  }
}

.landing-8 .hero-content .title {
  color: #fff;
  font-family: "CircularStd", sans-serif;
  font-weight: 700;
  letter-spacing: -2.81px;
  font-size: 50px;
  line-height: 56px;
  margin-bottom: 33px;
}

@media (min-width: 768px) {
  .landing-8 .hero-content .title {
    font-size: 60px;
    line-height: 70px;
  }
}

@media (min-width: 992px) {
  .landing-8 .hero-content .title {
    font-size: 70px;
    line-height: 80px;
  }
}

@media (min-width: 1200px) {
  .landing-8 .hero-content .title {
    font-size: 80px;
    letter-spacing: -2.5px;
    line-height: 84px;
  }
}

.landing-8 .hero-content .title-top {
  color: #fff;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
  margin-bottom: 25px;
}

.landing-8 .hero-content .hero-btn {
  margin-top: 38px;
}

.landing-8 .hero-content .video-link {
  color: #413e65;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  margin-top: 12px;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
}

.landing-8 .hero-content .video-link i {
  margin-right: 6px;
}

@media (min-width: 1200px) {
  .landing-8 .hero-content {
    padding-right: 20px;
  }
}

.landing-8 .fact-section {
  border-bottom: 1px solid #2d2d30;
}

@media (min-width: 768px) {
  .landing-8 .fact-section {
    padding-top: 110px;
    padding-bottom: 70px;
  }
}

.landing-8 .single-fact {
  text-align: left;
}

@media (min-width: 1200px) {
  .landing-8 .single-fact {
    padding-right: 45px;
  }
}

.landing-8 .single-fact .title {
  color: #5454d4;
  font-size: 60px;
  font-weight: 700;
  letter-spacing: -1.88px;
  line-height: 70px;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.landing-8 .single-fact p {
  color: #696871;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 35px;
}

.landing-8 .service-section {
  padding-top: 40px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .landing-8 .service-section {
    padding-top: 100px;
    padding-bottom: 150px;
  }
}

.landing-8 .service-card {
  border-radius: 10px;
  border: 1px solid #2d2d30;
  background-color: #19191b;
  -webkit-transition: .4s;
  transition: .4s;
  min-height: 89px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 28px;
  padding-right: 55px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  position: relative;
}

.landing-8 .service-card:hover {
  box-shadow: 0 52px 54px rgba(25, 25, 27, 0.3);
  border-radius: 10px;
  background-color: #5454d4;
}

.landing-8 .service-card:hover i {
  right: -3px;
  opacity: 1;
}

.landing-8 .service-card .card-title {
  color: #ffffff;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.66px;
  margin-bottom: 0;
}

.landing-8 .service-card i {
  font-size: 30px;
  color: #fff;
  position: absolute;
  right: 0;
  margin-right: 30px;
  opacity: 0;
  -webkit-transition: .4s;
  transition: .4s;
}

.landing-8 .case-card {
  -webkit-transition: .4s;
  transition: .4s;
}

.landing-8 .case-card:hover {
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
}

.landing-8 .case-card:hover .c-card-img .cc-btn {
  opacity: 1;
  margin-top: 0px;
}

.landing-8 .case-card:hover .c-card-img:before {
  opacity: 1;
}

.landing-8 .case-card.white-color .c-card-text {
  background: #fff;
}

.landing-8 .case-card.white-color .c-card-text .shape svg path {
  fill: #fff !important;
}

.landing-8 .case-card.white-color .c-card-text .title-top {
  color: #696871;
}

.landing-8 .case-card.white-color .c-card-text .c-title {
  color: #19191b;
}

.landing-8 .case-card.white-color .c-card-text p {
  color: #696871 !important;
}

.landing-8 .case-card .c-card-img {
  border-radius: 8px 8px 0 0;
  position: relative;
  z-index: 1;
}

.landing-8 .case-card .c-card-img .cc-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%);
  margin-top: 20px;
  opacity: 0;
  -webkit-transition: .4s opacity,.4s margin-top;
  transition: .4s opacity,.4s margin-top;
  width: 150px;
  height: 40px;
  font-size: 16px;
}

@media (min-width: 480px) {
  .landing-8 .case-card .c-card-img .cc-btn {
    width: 180px;
    height: 50px;
    font-size: 18px;
  }
}

@media (min-width: 576px) {
  .landing-8 .case-card .c-card-img .cc-btn {
    width: 200px;
    height: 60px;
    font-size: 21px;
  }
}

.landing-8 .case-card .c-card-img:before {
  border-radius: 8px 8px 0 0;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  background: rgba(25, 25, 27, 0.4);
  opacity: 0;
  -webkit-transition: .4s;
  transition: .4s;
}

.landing-8 .case-card .c-card-text {
  background: #5454d4;
  position: relative;
  z-index: 1;
  -webkit-transition: .4s;
  transition: .4s;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-top: 23px;
  padding-bottom: 21px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 480px) {
  .landing-8 .case-card .c-card-text {
    padding-top: 43px;
    padding-bottom: 41px;
    padding-left: 38px;
    padding-right: 38px;
  }
}

.landing-8 .case-card .c-card-text .shape {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 2;
  bottom: 98%;
}

@media (min-width: 480px) {
  .landing-8 .case-card .c-card-text .shape {
    bottom: 99%;
  }
}

@media (min-width: 992px) {
  .landing-8 .case-card .c-card-text .shape {
    bottom: 99%;
  }
}

@media (min-width: 1200px) {
  .landing-8 .case-card .c-card-text .shape {
    bottom: 100%;
  }
}

.landing-8 .case-card .c-card-text .shape svg {
  width: 100%;
}

.landing-8 .case-card .c-card-text .shape svg path {
  fill: #5454d4;
  -webkit-transition: .4s;
  transition: .4s;
}

.landing-8 .case-card .c-card-text .title-top {
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
  margin-bottom: 22px;
}

.landing-8 .case-card .c-card-text .c-title {
  color: #fff;
  letter-spacing: -1.06px;
  margin-bottom: 22px;
  font-size: 28px;
  font-weight: 700;
}

@media (min-width: 480px) {
  .landing-8 .case-card .c-card-text .c-title {
    font-size: 34px;
    font-weight: 700;
  }
}

.landing-8 .case-card .c-card-text p {
  color: rgba(255, 255, 255, 0.7) !important;
  font-weight: 300;
  letter-spacing: -0.66px;
  font-size: 17px;
  line-height: 24px;
}

@media (min-width: 480px) {
  .landing-8 .case-card .c-card-text p {
    font-size: 21px;
    line-height: 39px;
  }
}

.landing-8 .case-card .c-card-text p:last-child {
  margin-bottom: 0;
}

.landing-8 .case-btn {
  padding-top: 30px;
}

@media (min-width: 768px) {
  .landing-8 .case-btn {
    padding-top: 70px;
  }
}

.landing-8 .testimonial-section {
  padding-top: 80px;
}

@media (min-width: 768px) {
  .landing-8 .testimonial-section {
    padding-top: 155px;
  }
}

.landing-8 .single-testimonial {
  text-align: center;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
}

.landing-8 .single-testimonial .brand-logo {
  margin-bottom: 33px;
}

.landing-8 .single-testimonial p {
  color: #fff;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

.landing-8 .single-testimonial .user-block {
  text-align: center;
  padding-top: 22px;
  margin-top: auto;
}

.landing-8 .single-testimonial .user-block .user-image {
  max-width: 67px;
  max-height: 67px;
  display: inline-block;
  margin-bottom: 19px;
}

.landing-8 .single-testimonial .user-block .user-text .user-title {
  color: #fff;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -0.66px;
  line-height: 28px;
}

.landing-8 .single-testimonial .user-block .user-text .user-rank {
  color: #696871;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.landing-8 .contact-section {
  position: relative;
  z-index: 1;
}

@media (min-width: 768px) {
  .landing-8 .contact-section {
    margin-top: 150px;
  }
}

.landing-8 .contact-section .contact-shape {
  position: absolute;
  width: 150%;
  bottom: 48%;
  left: -5%;
}

@media (min-width: 576px) {
  .landing-8 .contact-section .contact-shape {
    bottom: 48%;
  }
}

@media (min-width: 768px) {
  .landing-8 .contact-section .contact-shape {
    bottom: 48%;
  }
}

@media (min-width: 992px) {
  .landing-8 .contact-section .contact-shape {
    bottom: 47%;
  }
}

.landing-8 .contact-section .contact-shape img {
  max-width: unset;
  width: 100%;
}

.landing-8 .contact-section:after {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 49%;
  content: "";
  width: 100%;
  background: #5454d4;
  z-index: -1;
}

.landing-8 .contact-section .form-title-block {
  margin-bottom: 3px;
}

.landing-8 .contact-section .form-title-block .f-title {
  color: #19191b;
  font-size: 60px;
  font-weight: 700;
  letter-spacing: -1.88px;
  line-height: 70px;
}

.landing-8 .contact-section .form-title-block p {
  color: #696871 !important;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}

.landing-8 .contact-form {
  box-shadow: 0 20px 61px rgba(65, 62, 101, 0.14);
  border-radius: 10px;
  background-color: #f7f7fb;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
  padding-top: 30px;
}

@media (min-width: 576px) {
  .landing-8 .contact-form {
    padding-left: 55px;
    padding-right: 55px;
    padding-bottom: 55px;
    padding-top: 60px;
  }
}

.landing-8 .contact-form .form-title-block {
  text-align: center;
  padding-bottom: 10px;
}

.landing-8 .contact-form .form-title-block .f-title {
  color: #19191b;
  font-size: 60px;
  font-weight: 700;
  letter-spacing: -1.88px;
  line-height: 70px;
}

.landing-8 .contact-form .form-title-block p {
  color: #696871 !important;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
}

.landing-8 .contact-form .form-control {
  height: 73px;
  border-radius: 10px;
  border: 1px solid #eae9f2;
  background-color: #ffffff;
  color: #9c9aaa;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.56px;
  margin-bottom: 17px;
  padding-left: 25px;
}

.landing-8 .contact-form textarea {
  min-height: 115px;
  padding-top: 28px;
}

.landing-8 .contact-form .agree-block {
  display: -webkit-box;
  display: flex;
  margin-bottom: 20px;
  padding-top: 10px;
}

.landing-8 .contact-form .agree-block #agree-check {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
}

.landing-8 .contact-form .agree-block #agree-check ~ label {
  position: relative;
  min-width: 19px;
  max-height: 19px;
  min-height: 19px;
  border-radius: 3px;
  border: 1.5px solid #9c9aaa;
  margin-right: 15px;
}

.landing-8 .contact-form .agree-block #agree-check ~ label:before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 8px;
  height: 100%;
  width: 100%;
  position: absolute;
  text-align: center;
  opacity: 0;
}

.landing-8 .contact-form .agree-block #agree-check:checked ~ label {
  border-color: #5454d4;
  background: #5454d4;
}

.landing-8 .contact-form .agree-block #agree-check:checked ~ label::before {
  color: #fff;
  opacity: 1;
}

.landing-8 .contact-form .agree-block p {
  margin-bottom: 0;
  color: #696871 !important;
}

.landing-8 .contact-form .agree-block p a {
  color: #5454d4;
}

.landing-8 .footer-section {
  background-color: #5454d4;
}

@media (min-width: 992px) {
  .landing-8 .footer-section {
    padding-top: 70px;
  }
}

.landing-8 .footer-section .footer-top {
  padding: 55px 0 0px;
}

@media (min-width: 768px) {
  .landing-8 .footer-section .footer-top {
    padding: 80px 0 60px;
  }
}

.landing-8 .footer-newsletter .newsletter-title {
  color: #fff;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: -0.75px;
  margin-bottom: 25px;
}

.landing-8 .footer-newsletter .btn {
  width: 150px;
  height: 45px;
  border-radius: 5px;
  border: 2px solid rgba(255, 255, 255, 0.7);
  background: transparent;
  font-size: 16px;
  font-weight: 500;
}

.landing-8 .footer-newsletter .btn:hover {
  border-color: #fff;
}

.landing-8 .footer-contact-block {
  padding-top: 40px;
}

.landing-8 .footer-contact-block .label {
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 31px;
}

.landing-8 .footer-contact-block .contact-mail {
  color: #fff;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -0.66px;
}

.landing-8 .footer-title {
  color: rgba(255, 255, 255, 0.7);
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 25px;
}

.landing-8 .footer-list a {
  color: white !important;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 38px;
}

.landing-8 .copyright-area {
  border-top: 1px solid #6a6ae5;
  padding: 40px 0;
}

@media (min-width: 768px) {
  .landing-8 .copyright-area {
    margin-top: 60px;
  }
}

.landing-8 .copyright-area .footer-brand {
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  .landing-8 .copyright-area .footer-brand {
    margin-bottom: 0;
  }
}

.landing-8 .copyright-area p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  font-weight: 300;
  letter-spacing: -0.41px;
  line-height: 38px;
  font-family: "CircularStd", sans-serif;
}

.landing-8 .copyright-area .social-icons {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  margin: auto -12px;
  height: 100%;
}

.landing-8 .copyright-area .social-icons li a {
  color: #f7f7fb;
  font-size: 23px;
  -webkit-transition: .4s;
  transition: .4s;
  padding: 0 3px;
  margin: 0 12px;
  transition: .4s;
}

@media (min-width: 992px) {
  .landing-8 .copyright-area .social-icons li a:hover {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
  }
}

/*==== 11.Contact -------*/
.sign-page {
  background-color: #f7f8fa;
}

.contact-wrapper {
  position: relative;
}

.contact-wrapper.contact-bg:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .contact-wrapper.contact-bg:after {
    width: 30%;
  }
}

@media (min-width: 992px) {
  .contact-wrapper.contact-bg:after {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .contact-wrapper.contact-bg:after {
    width: 60%;
  }
}

@media (min-width: 1366px) {
  .contact-wrapper.contact-bg:after {
    width: calc(100% - 665px);
  }
}

.contact-wrapper.contact-bg-1:after {
  /* background-image: url(../image/png/contact-page.png.html); */
}

.contact-wrapper.contact-bg-2:after {
  /* background-image: url(../image/png/contact-page-2.png.html); */
}

.contact-wrapper .main-block {
  min-height: 100vh;
}

.contact-wrapper .main-block .omega-form {
  min-height: calc(100vh - 72px);
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}

.contact-wrapper .main-block .omega-form form {
  padding: 53px 58px 50px;
  box-shadow: 0 20px 61px rgba(65, 62, 101, 0.14);
  border-radius: 10px;
  background-color: #ffffff;
  width: 100%;
}

.contact-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .contact-container {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .contact-container {
    margin-left: 0;
    max-width: 70%;
  }
}

@media (min-width: 992px) {
  .contact-container {
    max-width: 50%;
  }
}

@media (min-width: 1200px) {
  .contact-container {
    max-width: 40%;
  }
}

@media (min-width: 1366px) {
  .contact-container {
    min-width: 665px;
    max-width: 665px;
  }
}

.contact-container .main-block {
  max-width: 413px;
  margin: 0 auto;
}

.omega-form .form-title .title {
  color: #19191b;
  font-family: "CircularStd", sans-serif;
  font-size: 60px;
  font-weight: 700;
  letter-spacing: -1.03px;
  line-height: 56px;
  margin-bottom: 15px;
}

.omega-form .form-title p {
  color: #696871;
  font-family: "CircularStd", sans-serif;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

.omega-form .form-control {
  padding-left: 28px;
  margin-bottom: 17px;
  color: #9c9aaa;
  font-family: "CircularStd", sans-serif;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.56px;
  height: 73px;
  border-radius: 10px;
  border: 1px solid #eae9f2;
  background-color: #ffffff;
}

.omega-form .form-control::-webkit-input-placeholder {
  color: #9c9aaa;
}

.omega-form .form-control::-moz-placeholder {
  color: #9c9aaa;
}

.omega-form .form-control:-ms-input-placeholder {
  color: #9c9aaa;
}

.omega-form .form-control::-ms-input-placeholder {
  color: #9c9aaa;
}

.omega-form .form-control::placeholder {
  color: #9c9aaa;
}

.omega-form .input-with-icon {
  position: relative;
}

.omega-form .input-with-icon .form-control {
  padding-left: 50px;
}

@media (min-width: 576px) {
  .omega-form .input-with-icon .form-control {
    padding-left: 70px;
  }
}

.omega-form .input-with-icon .input-icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 26px;
  line-height: 1;
  font-size: 20px;
}

.omega-form .forget-block {
  position: relative;
}

.omega-form .forget-block .forget-link {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 15px;
  color: #5454d4;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
}

.omega-form .form-check-label {
  padding-top: 5px;
  margin-bottom: 20px;
}

.omega-form .form-check-label label {
  display: -webkit-box;
  display: flex;
}

.omega-form .form-check-label label input:checked ~ .checkbox::before {
  content: "\f14a";
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  color: #5454d4;
}

.omega-form .form-check-label label .checkbox {
  position: relative;
  line-height: 1;
  top: -2px;
  margin-right: 8px;
  min-height: 20px;
  min-width: 20px;
  cursor: pointer;
}

.omega-form .form-check-label label .checkbox:before {
  content: "\f0c8";
  font-family: "Font Awesome 5 free";
  font-size: 19px;
  color: #E2E4E8;
}

.omega-form .form-check-label label .checkbox.color-blue:before {
  color: #f04037;
}

.omega-form .form-check-label label p {
  color: #696871;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 0;
}

.omega-form .form-check-label label p a {
  color: #5454d4;
}

.omega-form .button-block {
  margin-bottom: 0px;
}

.omega-form .button-block .form-btn {
  height: 60px;
  border-radius: 10px;
  background-color: #f04037;
  width: 100%;
  color: #ffffff;
  font-family: "CircularStd", sans-serif;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.66px;
}

.omega-form .button-block .sign-up-text {
  color: #696871;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  margin-top: 12px;
  margin-bottom: 0;
}

.omega-form .button-block .sign-up-text a {
  color: #5454d4;
  margin-left: 4px;
}

.sign-page.bg-img {
  position: relative;
}

.sign-page.bg-img::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
}

.sign-page.bg-img .main-block .omega-form form {
  box-shadow: 0 52px 54px rgba(65, 62, 101, 0.25);
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.44);
  color: #fff !important;
}

.sign-page.bg-img .omega-form .form-title .title {
  color: #fff;
}

.sign-page.bg-img .omega-form .form-title p {
  color: rgba(255, 255, 255, 0.7);
}

.sign-page.bg-img .omega-form .form-check-label label p {
  color: rgba(255, 255, 255, 0.7);
}

.sign-page.bg-img .omega-form .form-check-label label p a {
  color: #fedc5a;
}

.sign-page.bg-img .omega-form .button-block .sign-up-text {
  color: rgba(255, 255, 255, 0.7);
}

.sign-page.bg-img .omega-form .button-block .sign-up-text a {
  color: #fedc5a;
}

.frgt-pass-img {
  background: url(../image/png/forget-pass-bg.png) no-repeat center;
  background-size: cover;
}

.frgt-pass-img.bg-img:before {
  opacity: 0.2;
}

.sign-in-img {
  background: url(../image/png/sign-in-bg.png) no-repeat center;
  background-size: cover;
}

.sign-up-img {
  background: url(../image/png/sign-up-bg.png) no-repeat center;
  background-size: cover;
}

.sign-up-img.bg-img:before {
  opacity: 0.2;
}

/*==== 12.About -------*/
.about-page {
  /*>>>>>>>> content area styling <<<<<<<<<*/
  /*>>>>>>>> Team area styling <<<<<<<<<*/
  /*>>>>>>>> Footer area styling <<<<<<<<<*/
}

.about-page .section-title p {
  margin-top: 28px;
}

.about-page .site-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.about-page .inner-hero-area {
  padding-top: 95px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .about-page .inner-hero-area {
    padding-top: 190px;
    padding-bottom: 180px;
  }
}

.about-page .inner-hero-area.bg-gradient {
  background-image: linear-gradient(147deg, rgba(141, 141, 236, 0.17) 0%, rgba(84, 84, 212, 0) 100%);
}

.about-page .inner-hero-area .inner-hero-text .title {
  color: #19191b;
  font-weight: 700;
  letter-spacing: -2.5px;
  line-height: 1;
  font-size: 34px;
  line-height: 46px;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .about-page .inner-hero-area .inner-hero-text .title {
    margin-bottom: 32px;
    font-size: 80px;
    line-height: 84px;
  }
}

.about-page .inner-hero-area .inner-hero-text p {
  color: #696871;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

.about-page .content-area {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .about-page .content-area {
    padding-top: 120px;
    padding-bottom: 110px;
  }
}

.about-page .content-area .section-title {
  margin-bottom: 65px;
}

@media (min-width: 992px) {
  .about-page .content-area .section-title {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
}

.about-page .content-area .section-title .title {
  color: #19191b;
  font-weight: 700;
  letter-spacing: -2.5px;
  font-size: 34px;
  line-height: 46px;
}

@media (min-width: 768px) {
  .about-page .content-area .section-title .title {
    font-size: 80px;
    line-height: 84px;
  }
}

@media (min-width: 992px) {
  .about-page .content-area .section-title .title {
    margin-right: 40px;
    max-width: 46%;
  }
}

.about-page .content-area .section-title p {
  color: #696871;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

@media (min-width: 992px) {
  .about-page .content-area .section-title p {
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .about-page .content-area .section-title .right-side {
    max-width: 40%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: end;
            align-items: flex-end;
  }
}

.about-page .content-area .section-title .right-side p:last-child {
  margin-bottom: 0;
}

.about-page .content-area .content-img {
  max-height: 515px;
}

.about-page .team-widget {
  text-align: center;
}

.about-page .team-widget .widget-image {
  width: 160px;
  height: 160px;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  margin-bottom: 29px;
}

.about-page .team-widget .widget-text .title {
  color: #19191b;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.75px;
  line-height: 1;
}

.about-page .team-widget .widget-text .subtitle {
  padding-top: 9px;
  color: #696871;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 1;
}

.about-page .footer-section {
  background-color: #19191B;
}

.about-page .footer-section .footer-top {
  padding: 80px 0 60px;
}

.about-page .footer-title {
  color: #FFFFFF;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 25px;
}

.about-page .footer-list a {
  color: rgba(255, 255, 255, 0.7);
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 38px;
}

.about-page .copyright-area {
  border-top: 1px solid #2F2F31;
  padding: 15px 0;
}

.about-page .copyright-area p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  font-weight: 300;
  letter-spacing: -0.41px;
  line-height: 38px;
  font-family: "CircularStd", sans-serif;
}

.about-page .copyright-area .copyright-text {
  margin-bottom: 0;
}

.about-page .copyright-area .social-icons {
  display: -webkit-inline-box;
  display: inline-flex;
}

.about-page .copyright-area .social-icons li a {
  color: #FFFFFF;
  font-size: 16px;
  -webkit-transition: .4s;
  transition: .4s;
  padding: 0 3px;
  margin: 0 2.5px;
}

.about-page .copyright-area .social-icons li a:hover {
  color: #5454d4;
}

.about-page .cta-section {
  background-color: #19191b;
  position: relative;
}

.about-page .cta-section .shape-1 {
  position: absolute;
  top: 0;
  left: 0px;
}

.about-page .cta-section .shape-2 {
  position: absolute;
  top: 0;
  right: -270px;
}

.about-page .cta-section .section-title .title {
  color: #FFF;
  margin-bottom: 35px;
}

.about-page .cta-section .section-title p {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 40px;
}

.about-page .cta-section .cta-btn {
  padding-top: 5px;
}

.about-page .cta-section .cta-btn .btn {
  width: 250px;
  height: 60px;
  border-radius: 10px;
  border: 1px solid #c31a12;
  background-color: #f04037;
  color: #fff;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.66px;
  overflow: hidden;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}

.about-page .footer-section {
  background-color: #19191b;
}

.about-page .footer-section .footer-top {
  padding: 80px 0 60px;
}

.about-page .footer-title {
  color: #ffffff;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 25px;
}

.about-page .footer-list a {
  color: rgba(255, 255, 255, 0.7);
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 38px;
}

.about-page .copyright-area {
  border-top: 1px solid #2F2F31;
  padding: 15px 0;
}

.about-page .copyright-area p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  font-weight: 300;
  letter-spacing: -0.41px;
  line-height: 38px;
  font-family: "CircularStd", sans-serif;
}

.about-page .copyright-area .copyright-text {
  margin-bottom: 0;
}

.about-page .copyright-area .social-icons {
  display: -webkit-inline-box;
  display: inline-flex;
}

.about-page .copyright-area .social-icons li a {
  color: #FFFFFF;
  font-size: 16px;
  -webkit-transition: .4s;
  transition: .4s;
  padding: 0 3px;
  margin: 0 2.5px;
}

.about-page .copyright-area .social-icons li a:hover {
  color: #5454d4;
}

/*==== 13.Career Details -------*/
.career-details-page .section-title h2 {
  color: #19191b;
  font-size: 48px;
  font-weight: 700;
  letter-spacing: -0.83px;
  line-height: 56px;
}

.career-details-page .section-title p {
  color: #7C8087;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  padding-top: 15px;
}

.career-details-page .section-title p + p {
  padding-top: 0;
}

.career-details-page .section-title p:last-child {
  margin-bottom: 0;
}

.career-details-page .footer-section {
  background-color: #19191B;
}

.career-details-page .footer-section .footer-top {
  padding: 80px 0 60px;
}

.career-details-page .footer-title {
  color: #FFFFFF;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 25px;
}

.career-details-page .footer-list a {
  color: rgba(255, 255, 255, 0.7);
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 38px;
}

.career-details-page .copyright-area {
  border-top: 1px solid #2F2F31;
  padding: 15px 0;
}

.career-details-page .copyright-area p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  font-weight: 300;
  letter-spacing: -0.41px;
  line-height: 38px;
  font-family: "CircularStd", sans-serif;
}

.career-details-page .copyright-area .copyright-text {
  margin-bottom: 0;
}

.career-details-page .copyright-area .social-icons {
  display: -webkit-inline-box;
  display: inline-flex;
}

.career-details-page .copyright-area .social-icons li a {
  color: #FFFFFF;
  font-size: 16px;
  -webkit-transition: .4s;
  transition: .4s;
  padding: 0 3px;
  margin: 0 2.5px;
}

.career-details-page .copyright-area .social-icons li a:hover {
  color: #5454d4;
}

.job-details-content {
  padding-top: 35px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .job-details-content {
    padding-top: 65px;
    padding-bottom: 100px;
  }
}

.job-details-content .block-title {
  color: #19191b;
  font-size: 21px;
  font-weight: 700;
  line-height: 34px;
}

.job-details-content .skill-list li {
  color: #696871;
  list-style: none;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  padding-top: 12.5px;
  padding-bottom: 12.5px;
}

.job-details-content .skill-list li:last-child {
  padding-bottom: 0;
}

.job-details-content .skill-list li:first-child {
  padding-top: 0;
}

.job-details-content .skill-list li:before {
  display: inline-block;
  content: "";
  width: 9px;
  height: 9px;
  background-color: #19191b;
  border-radius: 500px;
  margin-right: 12px;
}

.job-details-content p {
  color: #696871;
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  margin-bottom: 15px;
}

.job-details-content p:last-child {
  margin-bottom: 0;
}

.job-details-content .job-top-header {
  max-width: 275px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  margin: 0 auto;
  padding-bottom: 30px;
}

.job-details-content .job-top-header .location {
  color: #8C97AC;
  font-size: 16px;
  font-weight: 300;
  margin-right: 5px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  margin-right: 10px;
}

.job-details-content .job-top-header .badge {
  height: 30px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 300;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  padding-left: 13px;
  padding-right: 13px;
  width: 76px;
}

.job-details-content .job-top-header .badge.green {
  background-color: rgba(15, 188, 73, 0.1);
  color: #0FBC49;
}

.job-details-content .job-top-header .badge.orange {
  background-color: rgba(245, 166, 35, 0.15);
  color: #F5A623;
}

.job-details-content .job-top-header .badge.blue {
  background-color: rgba(16, 66, 253, 0.15);
  color: #1042FD;
}

.job-details-content .job-top-header .badge.purple {
  background-color: rgba(84, 84, 212, 0.15);
  color: #5454d4;
}

.job-details-content .contant-block-1 .main-title {
  color: #19191b;
  font-weight: 700;
  letter-spacing: -1px;
  margin-bottom: 75px;
  font-size: 34px;
  line-height: 46px;
}

@media (min-width: 768px) {
  .job-details-content .contant-block-1 .main-title {
    font-size: 80px;
    line-height: 85px;
  }
}

.job-details-content .apply-btn .btn--primary {
  min-width: 250px;
  height: 60px;
  border-radius: 10px;
  border: 1px solid #C31A12;
  background-color: #f04037;
  overflow: hidden;
}

/*==== 14.Career Page -------*/
.career-page {
  /*>>>>>>>> JOB area styling <<<<<<<<<*/
  /*>>>>>>>> feature area styling <<<<<<<<<*/
  /* Ripple Out */
  /*>>>>>>>> Video area styling <<<<<<<<<*/
  /*>>>>>>>> Footer area styling <<<<<<<<<*/
}

.career-page .section-title p {
  margin-top: 28px;
}

.career-page .site-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.career-page .inner-hero-area {
  padding-top: 95px;
  padding-bottom: 35px;
}

@media (min-width: 768px) {
  .career-page .inner-hero-area {
    padding-top: 190px;
    padding-bottom: 180px;
  }
}

.career-page .inner-hero-area.bg-gradient {
  background-image: linear-gradient(147deg, rgba(141, 141, 236, 0.17) 0%, rgba(84, 84, 212, 0) 100%);
}

.career-page .inner-hero-area .inner-hero-text .title {
  color: #19191b;
  font-weight: 700;
  letter-spacing: -2.5px;
  line-height: 1;
  font-size: 34px;
  line-height: 46px;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .career-page .inner-hero-area .inner-hero-text .title {
    margin-bottom: 32px;
    font-size: 80px;
    line-height: 84px;
  }
}

.career-page .inner-hero-area .inner-hero-text p {
  color: #696871;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

.career-page .job-section {
  background: #f7f7fb;
  padding-top: 50px;
  padding-bottom: 65px;
}

@media (min-width: 768px) {
  .career-page .job-section {
    padding-top: 100px;
    padding-bottom: 130px;
  }
}

.career-page .card-job {
  min-height: 350px;
  box-shadow: 0 2px 4px rgba(14, 86, 124, 0.17);
  background-color: #ffffff;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: justify;
          justify-content: space-between;
  padding: 35px;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 10px;
}

.career-page .card-job .location {
  color: #8c97ac;
  font-size: 16px;
  font-weight: 300;
  margin-right: 5px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}

.career-page .card-job .card-footer {
  background: transparent;
  border: 0;
  padding: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}

.career-page .card-job .card-footer .client-details {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
}

.career-page .card-job .card-footer .client-details .company-title {
  color: #1d293f;
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
}

.career-page .card-job .card-footer .client-details span {
  line-height: 1;
  color: #8c97ac;
  font-size: 13px;
  font-weight: 300;
}

.career-page .card-job .badge {
  height: 30px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 300;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  padding-left: 13px;
  padding-right: 13px;
}

.career-page .card-job .badge.green {
  background-color: rgba(119, 191, 65, 0.1);
  color: #77bf41;
}

.career-page .card-job .badge.red {
  background-color: rgba(240, 64, 55, 0.15);
  color: #f04037;
}

.career-page .card-job .badge.blue {
  background-color: rgba(84, 84, 212, 0.15);
  color: #5454d4;
}

.career-page .card-job:hover {
  box-shadow: 0 32px 84px rgba(14, 86, 124, 0.17);
}

.career-page .card-job.top-only {
  -webkit-box-pack: start;
          justify-content: flex-start;
  min-height: 100%;
}

@media (min-width: 768px) {
  .career-page .feature-section {
    padding-bottom: 40px;
  }
}

@-webkit-keyframes ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}

@keyframes ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}

.career-page .circle-bg-red {
  background: #f04037;
}

.career-page .circle-bg-red.outer-circle {
  position: relative;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  background: rgba(240, 64, 55, 0.06);
}

.career-page .circle-bg-red.outer-circle:before {
  content: '';
  position: absolute;
  border: rgba(240, 64, 55, 0.08) solid 6px;
  border-radius: 500px;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  -webkit-animation: ripple-out 1.2s linear 1s infinite;
          animation: ripple-out 1.2s linear 1s infinite;
}

.career-page .circle-bg-green {
  background: #77bf41;
}

.career-page .circle-bg-green.outer-circle {
  position: relative;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  background: rgba(119, 191, 65, 0.06);
}

.career-page .circle-bg-green.outer-circle:before {
  content: '';
  position: absolute;
  border: rgba(119, 191, 65, 0.08) solid 6px;
  border-radius: 500px;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  -webkit-animation: ripple-out 1.2s linear 1s infinite;
          animation: ripple-out 1.2s linear 1s infinite;
}

.career-page .circle-bg-purple {
  background: #a665fb;
}

.career-page .circle-bg-purple.outer-circle {
  background: rgba(166, 101, 251, 0.06);
  position: relative;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
}

.career-page .circle-bg-purple.outer-circle:before {
  content: '';
  position: absolute;
  border: rgba(166, 101, 251, 0.08) solid 6px;
  border-radius: 500px;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  -webkit-animation: ripple-out 1.2s linear 1s infinite;
          animation: ripple-out 1.2s linear 1s infinite;
  opacity: 1.6;
}

.career-page .circle-bg-ash {
  background: #413e65;
}

.career-page .circle-bg-ash.outer-circle {
  background: rgba(65, 62, 101, 0.06);
  position: relative;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
}

.career-page .circle-bg-ash.outer-circle:before {
  content: '';
  position: absolute;
  border: rgba(65, 62, 101, 0.08) solid 6px;
  border-radius: 500px;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  -webkit-animation: ripple-out 1.2s linear 1s infinite;
          animation: ripple-out 1.2s linear 1s infinite;
  opacity: 1.6;
}

.career-page .circle-bg-blue {
  background: #5454d4;
}

.career-page .circle-bg-blue.outer-circle {
  background: rgba(84, 84, 212, 0.06);
  position: relative;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
}

.career-page .circle-bg-blue.outer-circle:before {
  content: '';
  position: absolute;
  border: rgba(84, 84, 212, 0.08) solid 6px;
  border-radius: 500px;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  -webkit-animation: ripple-out 1.2s linear 1s infinite;
          animation: ripple-out 1.2s linear 1s infinite;
  opacity: 1.6;
}

.career-page .circle-bg-yellow {
  background: #fedc5a;
}

.career-page .circle-bg-yellow.outer-circle {
  background: rgba(254, 220, 90, 0.06);
  position: relative;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
}

.career-page .circle-bg-yellow.outer-circle:before {
  content: '';
  position: absolute;
  border: rgba(254, 220, 90, 0.08) solid 6px;
  border-radius: 500px;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  -webkit-animation: ripple-out 1.2s linear 1s infinite;
          animation: ripple-out 1.2s linear 1s infinite;
  opacity: 1.6;
}

.career-page .feature--widget {
  display: -webkit-box;
  display: flex;
}

@media (min-width: 992px) {
  .career-page .feature--widget {
    margin-bottom: 0;
  }
}

.career-page .feature--widget .widget__icon {
  position: relative;
  min-width: 62px;
  min-height: 62px;
  max-width: 62px;
  max-height: 62px;
  -webkit-box-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  margin-bottom: 35px;
  margin-right: 25px;
}

.career-page .feature--widget .widget__icon .inner-circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}

.career-page .feature--widget .widget__icon .outer-circle {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  position: absolute;
}

.career-page .feature--widget .widget__body {
  text-align: left;
}

.career-page .feature--widget .widget__body p {
  color: #696871;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.career-page .feature--widget .widget__heading {
  color: #19191b;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.career-page .video-area {
  padding-top: 40px;
}

@media (min-width: 768px) {
  .career-page .video-area {
    padding-top: 120px;
  }
}

@media (min-width: 768px) {
  .career-page .video-area .section-title {
    margin-bottom: 65px;
  }
}

@media (min-width: 992px) {
  .career-page .video-area .section-title {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
}

.career-page .video-area .section-title .title {
  color: #19191b;
  font-weight: 700;
  letter-spacing: -2.5px;
  font-size: 34px;
  line-height: 46px;
}

@media (min-width: 768px) {
  .career-page .video-area .section-title .title {
    font-size: 80px;
    line-height: 84px;
  }
}

@media (min-width: 992px) {
  .career-page .video-area .section-title .title {
    margin-right: 40px;
    max-width: 46%;
  }
}

.career-page .video-area .section-title p {
  color: #696871;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

@media (min-width: 992px) {
  .career-page .video-area .section-title .right-side {
    max-width: 40%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: end;
            align-items: flex-end;
  }
}

.career-page .video-area .section-title .right-side p:last-child {
  margin-bottom: 0;
}

.career-page .video-area .video-image {
  position: relative;
  border-radius: 10px;
}

.career-page .video-area .video-image a {
  font-size: 22px;
  background-color: #5454D4;
  border-radius: 500px;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  color: #fff;
  width: 64px;
  height: 64px;
}

@media (min-width: 576px) {
  .career-page .video-area .video-image a {
    width: 100px;
    height: 100px;
  }
}

@media (min-width: 768px) {
  .career-page .video-area .video-image a {
    width: 124px;
    height: 124px;
  }
}

.career-page .footer-section {
  background-color: #19191B;
}

.career-page .footer-section .footer-top {
  padding: 80px 0 60px;
}

.career-page .footer-title {
  color: #FFFFFF;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 25px;
}

.career-page .footer-list a {
  color: rgba(255, 255, 255, 0.7);
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 38px;
}

.career-page .copyright-area {
  border-top: 1px solid #2F2F31;
  padding: 15px 0;
}

.career-page .copyright-area p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  font-weight: 300;
  letter-spacing: -0.41px;
  line-height: 38px;
  font-family: "CircularStd", sans-serif;
}

.career-page .copyright-area .copyright-text {
  margin-bottom: 0;
}

.career-page .copyright-area .social-icons {
  display: -webkit-inline-box;
  display: inline-flex;
}

.career-page .copyright-area .social-icons li a {
  color: #FFFFFF;
  font-size: 16px;
  -webkit-transition: .4s;
  transition: .4s;
  padding: 0 3px;
  margin: 0 2.5px;
}

.career-page .copyright-area .social-icons li a:hover {
  color: #5454d4;
}

/*==== 15.Case Study -------*/
.case-study-page .page-banner {
  padding-top: 25px;
}

@media (min-width: 768px) {
  .case-study-page .page-banner {
    padding-top: 100px;
    padding-bottom: 80px;
  }
}

.case-study-page .page-banner .banner-content .title {
  color: #19191b;
  font-weight: 700;
  letter-spacing: -2.5px;
  padding-bottom: 22px;
  font-size: 34px;
  line-height: 46px;
}

@media (min-width: 768px) {
  .case-study-page .page-banner .banner-content .title {
    font-size: 80px;
    line-height: 84px;
  }
}

.case-study-page .page-banner .banner-content p {
  color: #696871;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
  margin-top: 5px;
}

.case-study-page .case-study-card {
  -webkit-transition: .4s;
  transition: .4s;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.case-study-page .case-study-card .card-img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  overflow: hidden;
  position: relative;
}

.case-study-page .case-study-card .card-img > img {
  width: 100%;
}

@media (min-width: 576px) {
  .case-study-page .case-study-card .card-img > img {
    width: auto;
  }
}

.case-study-page .case-study-card .card-img .brand-img {
  position: absolute;
  bottom: 28px;
  left: 30px;
}

.case-study-page .case-study-card .card-content {
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 35px;
  padding-right: 35px;
  border-left: 1px solid #eae9f2;
  border-right: 1px solid #eae9f2;
  border-bottom: 1px solid #eae9f2;
  background-color: #ffffff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.case-study-page .case-study-card .card-content .title {
  color: #19191b;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -0.66px;
  margin-bottom: 13px;
}

.case-study-page .case-study-card .card-content p {
  color: #767581;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 26px;
}

.case-study-page .case-study-card .card-content p:last-child {
  margin-bottom: 0;
}

.case-study-page .case-study-card:hover {
  box-shadow: 0 52px 54px rgba(65, 62, 101, 0.3);
}

.case-study-page .case-study-card-2 {
  -webkit-transition: .4s;
  transition: .4s;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

@media (min-width: 480px) {
  .case-study-page .case-study-card-2 {
    display: -webkit-box;
    display: flex;
  }
}

.case-study-page .case-study-card-2 .card-img {
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

@media (min-width: 768px) {
  .case-study-page .case-study-card-2 .card-img {
    max-width: 210px;
  }
}

.case-study-page .case-study-card-2 .card-img > img {
  width: 100%;
}

@media (min-width: 576px) {
  .case-study-page .case-study-card-2 .card-img > img {
    width: auto;
  }
}

.case-study-page .case-study-card-2 .card-content {
  padding-top: 30px;
}

@media (min-width: 480px) {
  .case-study-page .case-study-card-2 .card-content {
    padding-left: 30px;
    padding-top: 0px;
  }
}

.case-study-page .case-study-card-2 .card-content .brand-img {
  margin-bottom: 22px;
}

.case-study-page .case-study-card-2 .card-content .title {
  color: #19191b;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -0.66px;
  margin-bottom: 13px;
}

.case-study-page .case-study-card-2 .card-content p {
  color: #767581;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 26px;
}

.case-study-page .case-study-card-2 .card-content p:last-child {
  margin-bottom: 0;
}

.case-study-page .cta-section {
  background-color: #19191b;
  position: relative;
}

@media (min-width: 1200px) {
  .case-study-page .cta-section {
    padding-top: 150px;
    padding-bottom: 80px;
  }
}

.case-study-page .cta-section .shape-1 {
  position: absolute;
  top: 0;
  left: 0px;
}

.case-study-page .cta-section .shape-2 {
  position: absolute;
  top: 0;
  right: -270px;
}

.case-study-page .cta-section .section-title .title {
  color: #FFF;
  margin-bottom: 35px;
}

.case-study-page .cta-section .section-title p {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 40px;
}

.case-study-page .cta-section .cta-btn {
  padding-top: 5px;
}

.case-study-page .cta-section .cta-btn .btn {
  width: 250px;
  height: 60px;
  border-radius: 10px;
  border: 1px solid #c31a12;
  background-color: #f04037;
  color: #fff;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.66px;
  overflow: hidden;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}

.case-study-page .footer-section {
  background-color: #19191b;
}

.case-study-page .footer-section .footer-top {
  padding: 80px 0 60px;
}

.case-study-page .footer-title {
  color: #ffffff;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 25px;
}

.case-study-page .footer-list a {
  color: rgba(255, 255, 255, 0.7);
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 38px;
}

.case-study-page .copyright-area {
  border-top: 1px solid #2F2F31;
  padding: 15px 0;
}

.case-study-page .copyright-area p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  font-weight: 300;
  letter-spacing: -0.41px;
  line-height: 38px;
  font-family: "CircularStd", sans-serif;
}

.case-study-page .copyright-area .copyright-text {
  margin-bottom: 0;
}

.case-study-page .copyright-area .social-icons {
  display: -webkit-inline-box;
  display: inline-flex;
}

.case-study-page .copyright-area .social-icons li a {
  color: #FFFFFF;
  font-size: 16px;
  -webkit-transition: .4s;
  transition: .4s;
  padding: 0 3px;
  margin: 0 2.5px;
}

.case-study-page .copyright-area .social-icons li a:hover {
  color: #5454d4;
}

.case-study-page.case-study-details .case-study-details-section {
  border-bottom: 1px solid #eae9f2;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .case-study-page.case-study-details .case-study-details-section {
    padding-bottom: 120px;
  }
}

.case-study-page.case-study-details .details-text-block .title {
  color: #19191b;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -0.66px;
  line-height: 28px;
  padding-bottom: 22px;
}

.case-study-page.case-study-details .details-text-block p {
  color: #696871;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.case-study-page.case-study-details .details-text-block p:last-child {
  margin-bottom: 0;
}

.case-study-page.case-study-details .details-text-block .details-list li {
  color: #696871;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 28px;
  margin-left: 20px;
  margin-bottom: 17px;
  display: -webkit-box;
  display: flex;
}

.case-study-page.case-study-details .details-text-block .details-list li:last-child {
  margin-bottom: 0;
}

.case-study-page.case-study-details .details-text-block .details-list li::before {
  content: "";
  min-width: 10px;
  max-width: 10px;
  min-height: 10px;
  max-height: 10px;
  background-color: #19191b;
  display: block;
  border-radius: 500px;
  margin-right: 15px;
  margin-top: 10px;
}

.case-study-page.case-study-details .details-text-block .btn {
  min-width: 250px;
  min-height: 60px;
  border-radius: 10px;
  border: 1px solid #c31a12;
  background-color: #f04037;
  overflow: hidden;
}

/*==== 16.Pricing Pages -------*/
.pricing-page {
  /*>>>>>>>> Pricing Section <<<<<<<<<*/
  /*>>>>>>>> Feature Section <<<<<<<<<*/
  /*>>>>>>>> FAQ Section styling <<<<<<<<<*/
  /*>>>>>>>> Footer area styling <<<<<<<<<*/
}

.pricing-page .section-title p {
  margin-top: 28px;
}

.pricing-page .site-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.pricing-page .inner-hero-area {
  padding-top: 95px;
  padding-bottom: 90px;
}

@media (min-width: 768px) {
  .pricing-page .inner-hero-area {
    padding-top: 190px;
    padding-bottom: 180px;
  }
}

.pricing-page .inner-hero-area.bg-gradient {
  background-image: linear-gradient(147deg, rgba(141, 141, 236, 0.17) 0%, rgba(84, 84, 212, 0) 100%);
}

.pricing-page .inner-hero-area .inner-hero-text .title {
  color: #19191b;
  font-weight: 700;
  letter-spacing: -2.5px;
  line-height: 1;
  margin-bottom: 32px;
  font-size: 34px;
  line-height: 46px;
}

@media (min-width: 768px) {
  .pricing-page .inner-hero-area .inner-hero-text .title {
    font-size: 80px;
  }
}

.pricing-page .inner-hero-area .inner-hero-text p {
  color: #696871;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

.pricing-page .pricing-area .section-title h2 {
  color: #19191b;
  font-size: 60px;
  font-weight: 700;
  letter-spacing: -1.88px;
  line-height: 70px;
}

.pricing-page .pricing-btns {
  border-radius: 10px;
  padding: 5px;
  background-color: #f7f7fb;
  display: -webkit-inline-box;
  display: inline-flex;
  max-height: 65px;
}

.pricing-page .pricing-btns a {
  border-radius: 10px 0 0 10px;
  width: 160px;
  height: 55px;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  color: #19191b;
}

.pricing-page .pricing-btns a + a {
  border-radius: 0 10px 10px 0;
}

.pricing-page .pricing-btns a.active {
  background-color: #5454d4;
  color: #fff;
}

.pricing-page .pricing-card {
  border-radius: 10px;
  border: 1px solid #eae9f2;
  background-color: #fff;
  text-align: center;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
  padding-top: 32px;
}

.pricing-page .pricing-card .small-title {
  color: #696871;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.56px;
  line-height: 28px;
  margin-bottom: 22px;
}

.pricing-page .pricing-card .price {
  color: #1d293f;
  font-size: 60px;
  font-weight: 700;
  letter-spacing: -1.03px;
  line-height: 56px;
  color: #1d293f;
  margin-bottom: 30px;
}

.pricing-page .pricing-card .price .time {
  font-size: 28px;
  font-weight: 300;
  letter-spacing: -1.03px;
  line-height: 1;
}

.pricing-page .pricing-card .pricing-list {
  color: #696871;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 1.6;
}

.pricing-page .pricing-card .pricing-list li {
  margin-bottom: 14px;
}

.pricing-page .pricing-card .pricing-btn {
  padding-top: 30px;
}

.pricing-page .pricing-card .pricing-btn .btn {
  width: 100%;
  max-width: 305px;
  border-radius: 10px;
  border: 1px solid #eae9f2;
  background-color: #ffffff;
  color: #5454d4;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.66px;
  min-height: 60px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  margin: 0 auto;
}

.pricing-page .yearly-active .yearly {
  display: block;
}

.pricing-page .yearly-active .monthly {
  display: none;
}

.pricing-page .monthly-active .monthly {
  display: block;
}

.pricing-page .monthly-active .yearly {
  display: none;
}

.pricing-page .feature-area .section-title h2 {
  color: #19191b;
  font-size: 60px;
  font-weight: 700;
  letter-spacing: -1.88px;
  line-height: 84px;
}

.pricing-page .feature-widget {
  text-align: center;
  padding-left: 25px;
  padding-right: 25px;
}

@media (min-width: 1200px) {
  .pricing-page .feature-widget {
    padding-left: 35px;
    padding-right: 35px;
  }
}

.pricing-page .feature-widget .widget-icon {
  min-width: 69px;
  max-width: 69px;
  min-height: 69px;
  max-height: 69px;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  border-radius: 500px;
  margin-bottom: 17px;
}

.pricing-page .feature-widget .widget-icon.bg-red {
  background-color: rgba(240, 64, 55, 0.1);
}

.pricing-page .feature-widget .widget-icon.bg-blue {
  background-color: rgba(84, 84, 212, 0.1);
}

.pricing-page .feature-widget .widget-icon.bg-yellow {
  background-color: rgba(254, 220, 90, 0.1);
}

.pricing-page .feature-widget .widget-text .title {
  display: block;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.75px;
  margin-bottom: 14px;
}

.pricing-page .feature-widget .widget-text p {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.pricing-page .feature-widget .widget-text p:last-child {
  margin-bottom: 0;
}

.pricing-page .faq-section .section-title p {
  padding-top: 15px;
}

.pricing-page .faq-section .faq-link {
  margin-top: 85px;
  text-align: center;
}

.pricing-page .faq-section .faq-link p {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
  margin-bottom: 0;
}

.pricing-page .faq-section .faq-link a {
  color: #f04037;
}

.pricing-page .single-faq .title {
  font-size: 24px;
  font-weight: 900;
  letter-spacing: -0.75px;
  margin-bottom: 30px;
}

.pricing-page .single-faq p {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.pricing-page .single-faq p:last-child {
  margin-bottom: 0;
}

.pricing-page .footer-section {
  background-color: #19191b;
}

.pricing-page .footer-section .footer-top {
  padding: 80px 0 60px;
}

.pricing-page .footer-title {
  color: #ffffff;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 25px;
}

.pricing-page .footer-list a {
  color: rgba(255, 255, 255, 0.7);
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 38px;
}

.pricing-page .copyright-area {
  border-top: 1px solid #2F2F31;
  padding: 15px 0;
}

.pricing-page .copyright-area p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  font-weight: 300;
  letter-spacing: -0.41px;
  line-height: 38px;
  font-family: "CircularStd", sans-serif;
}

.pricing-page .copyright-area .copyright-text {
  margin-bottom: 0;
}

.pricing-page .copyright-area .social-icons {
  display: -webkit-inline-box;
  display: inline-flex;
}

.pricing-page .copyright-area .social-icons li a {
  color: #FFFFFF;
  font-size: 16px;
  -webkit-transition: .4s;
  transition: .4s;
  padding: 0 3px;
  margin: 0 2.5px;
}

.pricing-page .copyright-area .social-icons li a:hover {
  color: #5454d4;
}

.pricing-page-2 .site-header {
  position: relative;
}

.pricing-page-2 .page-banner {
  padding-top: 50px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .pricing-page-2 .page-banner {
    padding-top: 100px;
    padding-bottom: 80px;
  }
}

.pricing-page-2 .page-banner .banner-content .title {
  color: #19191b;
  font-weight: 700;
  letter-spacing: -2.5px;
  font-size: 34px;
  line-height: 46px;
}

@media (min-width: 768px) {
  .pricing-page-2 .page-banner .banner-content .title {
    font-size: 80px;
    line-height: 84px;
  }
}

.pricing-page-2 .page-banner .banner-content p {
  color: #696871;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
  margin-top: 5px;
}

.pricing-page-2 .pricing-table {
  overflow: auto;
  padding-bottom: 30px;
}

.pricing-page-2 .pricing-table table {
  display: block;
}

.pricing-page-2 .pricing-table table thead {
  display: block;
}

.pricing-page-2 .pricing-table table thead tr {
  display: block;
}

.pricing-page-2 .pricing-table table thead tr .sin-title-box {
  padding: 0 20px;
  height: 60px;
  color: #19191b;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.56px;
}

.pricing-page-2 .pricing-table table tbody {
  display: block;
}

.pricing-page-2 .pricing-table table tbody tr {
  display: block;
}

.pricing-page-2 .pricing-table table .title-col {
  min-width: 290px;
}

.pricing-page-2 .pricing-table table .basic-col {
  min-width: 195px;
}

.pricing-page-2 .pricing-table table .standard-col {
  min-width: 195px;
}

.pricing-page-2 .pricing-table table .premium-col {
  min-width: 195px;
}

.pricing-page-2 .pricing-table table .ent-col {
  min-width: 195px;
}

.pricing-page-2 .pricing-table table .colored-row {
  background: #f7f7fb;
  border-top: 2px solid #fff;
}

.pricing-page-2 .pricing-table table .colored-row:first-child {
  border-top: 0;
}

.pricing-page-2 .pricing-table table .colored-row:first-child .sin-box:before {
  height: 50%;
  top: auto;
  bottom: 0;
}

.pricing-page-2 .pricing-table table .colored-row .sin-box {
  position: relative;
  padding: 0 20px;
  height: 65px;
  color: #696871;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.56px;
}

.pricing-page-2 .pricing-table table .colored-row .sin-box:before {
  content: "";
  background: #fff;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  width: 1px;
}

.pricing-page-2 .pricing-table table .colored-row .sin-box.title-col {
  color: #19191b;
  font-weight: 700;
}

.pricing-page-2 .pricing-table table .colored-row .sin-box .table-icon {
  font-size: 20px;
}

.pricing-page-2 .pricing-table table .colored-row .sin-box .table-icon.neg {
  color: #f04037;
}

.pricing-page-2 .pricing-table table .colored-row .sin-box .table-icon.pos {
  color: #67d216;
}

.pricing-page-2 .pricing-table table .price-widget-block {
  padding-left: 22px;
  margin-top: 50px;
}

.pricing-page-2 .pricing-table table .price-widget-block .title {
  color: #19191b;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -0.66px;
  padding-bottom: 5px;
}

.pricing-page-2 .pricing-table table .price-widget-block .subtitle {
  color: #9c9aaa;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: -0.46px;
  padding-bottom: 26px;
}

.pricing-page-2 .pricing-table table .price-widget-block .btn {
  width: 167px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #eae9f2;
  background-color: #ffffff;
  color: #5454d4;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.56px;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}

.pricing-page-2 .pricing-table table .price-widget-block .btn:hover {
  border: 1px solid #c31a12;
  background-color: #f04037;
  color: #fff;
}

/*==== 17.Pricing Pages -------*/
.blog-page {
  /*>>>>>>>> BLog Post (Regular) styling <<<<<<<<<*/
  /*>>>>>>>> CTA Section styling <<<<<<<<<*/
  /*>>>>>>>> Footer area styling <<<<<<<<<*/
}

.blog-page .section-title p {
  margin-top: 28px;
}

.blog-page .page-banner {
  padding-top: 30px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .blog-page .page-banner {
    padding-top: 100px;
    padding-bottom: 80px;
  }
}

.blog-page .page-banner .banner-content .title {
  color: #19191b;
  font-weight: 700;
  letter-spacing: -2.5px;
  margin-bottom: 15px;
  font-size: 34px;
  line-height: 46px;
}

@media (min-width: 768px) {
  .blog-page .page-banner .banner-content .title {
    margin-bottom: 30px;
    font-size: 80px;
    line-height: 84px;
  }
}

.blog-page .page-banner .banner-content p {
  color: #696871;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
  margin-top: 5px;
}

.blog-page .omega-page-sidebar .single-sidebar {
  background-color: #27abdf;
  border-radius: 10px;
  border: 1px solid #eae9f2;
  margin-bottom: 33px;
}

.blog-page .omega-page-sidebar .single-sidebar .sidebar-title {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.75px;
  line-height: 32px;
}

.blog-page .omega-page-sidebar .single-sidebar .sidebar-search {
  min-height: 71px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  padding-left: 23px;
}

.blog-page .omega-page-sidebar .single-sidebar .sidebar-search button {
  font-size: 19px;
  color: rgba(255, 255, 255, 0.7);
  height: 100%;
  margin-top: 9px;
}

.blog-page .omega-page-sidebar .single-sidebar .sidebar-search .form-control {
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 26px;
  border: 0;
  background: transparent;
}

.blog-page .omega-page-sidebar .single-sidebar .sidebar-search .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.blog-page .omega-page-sidebar .single-sidebar .sidebar-search .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.blog-page .omega-page-sidebar .single-sidebar .sidebar-search .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.blog-page .omega-page-sidebar .single-sidebar .sidebar-search .form-control::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.blog-page .omega-page-sidebar .single-sidebar .sidebar-search .form-control::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.blog-page .omega-page-sidebar .single-sidebar.post-block {
  padding: 25px;
}

.blog-page .omega-page-sidebar .sidebar-post-block {
  border-bottom: 1px solid #524f73;
  padding-bottom: 13px;
  padding-top: 20px;
}

.blog-page .omega-page-sidebar .sidebar-post-block:last-child {
  padding-bottom: 0px;
  border-bottom: 0;
}

.blog-page .omega-page-sidebar .sidebar-post-block .title {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 26px;
}

.blog-page .omega-page-sidebar .sidebar-post-block .title a:hover {
  opacity: .9;
  color: #fff;
}

.blog-page .omega-page-sidebar .sidebar-post-block .date {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 300;
  letter-spacing: -0.44px;
  line-height: 26px;
  margin-top: 4px;
}

.blog-page .omega-page-sidebar .category-sidebar-list li {
  margin-bottom: 13px;
}

.blog-page .omega-page-sidebar .category-sidebar-list li:last-child {
  margin-bottom: 0;
}

.blog-page .omega-page-sidebar .category-sidebar-list li a {
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 22px;
}

.blog-page .omega-page-sidebar .category-sidebar-list li a span {
  color: rgba(255, 255, 255, 0.7);
}

.blog-page .blog-post-area {
  padding-bottom: 20px;
}

@media (min-width: 768px) {
  .blog-page .blog-post-area {
    padding-bottom: 140px;
  }
}

.blog-page .blog-card {
  border-radius: 0 0 10px 10px;
  -webkit-transition: .4s;
  transition: .4s;
}

.blog-page .blog-card_img {
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}

.blog-page .blog-card_img img {
  width: 100%;
  height: 240px;
  object-fit: cover;
}

.blog-page .blog-card_texts {
  border: 1px solid #eae9f2;
  width: 100%;
  border-top: 0;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 33px;
  padding-right: 33px;
  border-radius: 0 0 10px 10px;
}

.blog-page .blog-card_texts .post-date {
  color: #767581;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 26px;
  padding-bottom: 17px;
}

.blog-page .blog-card_texts .post-title {
  color: #19191b;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -0.66px;
  line-height: 32px;
  padding-bottom: 8px;
}

.blog-page .blog-card_texts p {
  color: #767581;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 26px;
  margin-bottom: 15px;
}

.blog-page .blog-card_texts .link-to-more {
  color: #f04037;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 28px;
  margin-top: 6px;
}

.blog-page .blog-card:hover {
  box-shadow: 0 52px 54px rgba(65, 62, 101, 0.3);
}

.blog-page .blog-card--list {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
}

.blog-page .post-image img {
  width: 100%;
  border-radius: 20px;
}

@media (min-width: 768px) {
  .blog-page .blog-card--list {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}

.blog-page .blog-card--list .blog-card_img {
  border-radius: 10px 10px 0 0;
}

@media (min-width: 768px) {
  .blog-page .blog-card--list .blog-card_img {
    border-radius: 10px 0 0  10px;
    width: 100%;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .blog-page .blog-card--list .blog-card_img {
    min-width: 350px;
    max-width: 350px;
  }
}

@media (min-width: 768px) and (min-width: 992px) {
  .blog-page .blog-card--list .blog-card_img {
    min-width: 265px;
  }
}

@media (min-width: 768px) and (min-width: 1200px) {
  .blog-page .blog-card--list .blog-card_img {
    min-width: 350px;
    max-width: 350px;
  }
}

.blog-page .blog-card--list .blog-card_img img {
  width: 100%;
}

@media (min-width: 992px) {
  .blog-page .blog-card--list .blog-card_img img {
    max-width: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}

@media (min-width: 768px) {
  .blog-page .blog-card--list .blog-card_texts {
    border-radius: 0px 10px 10px 0;
    border-top: 1px solid  #eae9f2;
    border-left: 1px solid  #eae9f2;
  }
}

.blog-page .omega-blog-pagination .pagination-list {
  display: -webkit-box;
  display: flex;
}

.blog-page .omega-blog-pagination .pagination-list li {
  width: 38px;
  height: 38px;
  border-radius: 5px;
  border: 1px solid #eae9f2;
  background-color: #ffffff;
  margin: 0 5px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
}

.blog-page .omega-blog-pagination .pagination-list li.exerpt {
  -webkit-box-align: end;
          align-items: flex-end;
}

.blog-page .omega-blog-pagination .pagination-list li a {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  border-radius: 5px;
}

.blog-page .omega-blog-pagination .pagination-list li:hover {
  border: 1px solid #5454d4;
  background-color: #5454d4;
  color: #fff;
}

.blog-page .omega-blog-pagination .pagination-list li:hover a {
  color: inherit;
}

.blog-page .shape-holder {
  position: relative;
  z-index: 1;
}

.blog-page .cta-section {
  background-color: #19191b;
  position: relative;
}

.blog-page .cta-section .shape-1 {
  position: absolute;
  top: 0;
  left: 0px;
}

.blog-page .cta-section .shape-2 {
  position: absolute;
  top: 0;
  right: -270px;
}

.blog-page .cta-section .section-title .title {
  color: #FFF;
  margin-bottom: 35px;
}

.blog-page .cta-section .section-title p {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 40px;
}

.blog-page .cta-section .cta-btn {
  padding-top: 5px;
}

.blog-page .cta-section .cta-btn .btn {
  width: 250px;
  height: 60px;
  border-radius: 10px;
  border: 1px solid #c31a12;
  background-color: #f04037;
  color: #fff;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.66px;
  overflow: hidden;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}

.blog-page .footer-section {
  background-color: #19191b;
}

.blog-page .footer-section .footer-top {
  padding: 80px 0 60px;
}

.blog-page .footer-title {
  color: #ffffff;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 25px;
}

.blog-page .footer-list a {
  color: rgba(255, 255, 255, 0.7);
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 38px;
}

.blog-page .copyright-area {
  border-top: 1px solid #2F2F31;
  padding: 15px 0;
}

.blog-page .copyright-area p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  font-weight: 300;
  letter-spacing: -0.41px;
  line-height: 38px;
  font-family: "CircularStd", sans-serif;
}

.blog-page .copyright-area .copyright-text {
  margin-bottom: 0;
}

.blog-page .copyright-area .social-icons {
  display: -webkit-inline-box;
  display: inline-flex;
}

.blog-page .copyright-area .social-icons li a {
  color: #FFFFFF;
  font-size: 16px;
  -webkit-transition: .4s;
  transition: .4s;
  padding: 0 3px;
  margin: 0 2.5px;
}

.blog-page .copyright-area .social-icons li a:hover {
  color: #5454d4;
}

.blog-page.blog-details .page-banner {
  padding-top: 30px;
  padding-bottom: 10px;
}

@media (min-width: 768px) {
  .blog-page.blog-details .page-banner {
    padding-top: 100px;
    padding-bottom: 80px;
  }
}

.blog-page.blog-details .page-banner .banner-content .title {
  color: #19191b;
  font-weight: 700;
  letter-spacing: -1.88px;
  margin-bottom: 15px;
  font-size: 40px;
  line-height: 50px;
}

@media (min-width: 768px) {
  .blog-page.blog-details .page-banner .banner-content .title {
    font-size: 60px;
    line-height: 70px;
  }
}

.blog-page.blog-details .page-banner .banner-content p {
  color: #696871;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

.blog-page.blog-details .page-banner .banner-content p .bullet {
  width: 10px;
  height: 10px;
  background-color: #5454d4;
  border-radius: 500px;
  margin: 0 18px;
}

.blog-page.blog-details .page-banner .banner-content p a:hover {
  color: #f04037;
}

.blog-page.blog-details .blog-post-details .article-title {
  color: #19191b;
  font-size: 24px;
  font-weight: 700;
}

.blog-page.blog-details .blog-post-details p {
  color: #696871;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.56px;
  line-height: 30px;
  padding-bottom: 10px;
}

.blog-page.blog-details .blog-post-details .quoteed-text {
  display: -webkit-box;
  display: flex;
}

.blog-page.blog-details .blog-post-details .quoteed-text:before {
  content: url(../image/png/quote-icon.png);
  display: inline-block;
  min-width: 28px;
  max-width: 28px;
  margin-top: 8px;
  margin-right: 23px;
}

.blog-page.blog-details .blog-post-details .quoteed-text p {
  color: #696871;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 34px;
}

.blog-page.blog-details .blog-post-details .related-post-tags {
  display: -webkit-box;
  display: flex;
}

.blog-page.blog-details .blog-post-details .related-post-tags a {
  background: #eae9f2;
  color: #696871;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  border-radius: 5px;
  line-height: 1;
  padding: 15px;
}

.blog-page.blog-details .blog-post-details .related-post-tags li {
  margin: 0 12px 10px;
}

.blog-page.blog-details .blog-post-details .related-post-tags li:first-child {
  margin-left: 0;
}

.blog-page.blog-details .reply-section {
  background-color: #f7f7fb;
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .blog-page.blog-details .reply-section {
    padding-top: 110px;
    padding-bottom: 120px;
  }
}

.blog-page.blog-details .reply-section .reply-block-title {
  color: #19191b;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.75px;
  line-height: 32px;
}

.blog-page.blog-details .reply-form .form-control {
  box-shadow: 0 2px 4px rgba(14, 86, 124, 0.17);
  border-radius: 10px;
  background-color: #ffffff;
  border: 0;
  color: #9c9aaa;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.56px;
  line-height: 30px;
  margin-bottom: 20px;
}

.blog-page.blog-details .reply-form input {
  height: 60px;
  padding-left: 30px;
}

.blog-page.blog-details .reply-form textarea {
  min-height: 171px;
  padding-top: 20px;
  padding-left: 30px;
}

.blog-page.blog-details .reply-form .btn {
  width: 259px;
  height: 60px;
  border-radius: 10px;
  overflow: hidden;
}

.blog-page.blog-details .comments .single-comment {
  box-shadow: 0 2px 4px rgba(14, 86, 124, 0.17);
  border-radius: 10px;
  background-color: #ffffff;
  padding: 25px;
  margin-bottom: 30px;
}

.blog-page.blog-details .comments .single-comment:last-child {
  margin-bottom: 0;
}

.blog-page.blog-details .comments .single-comment p {
  color: #696871;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.56px;
  line-height: 30px;
}

.blog-page.blog-details .comments .single-comment .user-small-card {
  display: -webkit-box;
  display: flex;
  margin-bottom: 5px;
}

.blog-page.blog-details .comments .single-comment .user-small-card .user-img {
  min-width: 54px;
  max-width: 54px;
  max-height: 54px;
  min-height: 54px;
  margin-right: 15px;
}

.blog-page.blog-details .comments .single-comment .user-small-card .user-identity .name {
  color: #19191b;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -0.66px;
  line-height: 28px;
}

.blog-page.blog-details .comments .single-comment .user-small-card .user-identity .date {
  color: #696871;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

/*==== 18.Contact Pages -------*/
.contact-page .site-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.contact-page .inner-hero-area {
  padding-top: 100px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .contact-page .inner-hero-area {
    padding-top: 190px;
    padding-bottom: 180px;
  }
}

.contact-page .inner-hero-area.bg-gradient {
  background-image: linear-gradient(147deg, rgba(141, 141, 236, 0.17) 0%, rgba(84, 84, 212, 0) 100%);
}

.contact-page .inner-hero-area .inner-hero-text .title {
  color: #19191b;
  font-weight: 700;
  letter-spacing: -2.5px;
  line-height: 1;
  margin-bottom: 15px;
  font-size: 34px;
  line-height: 46px;
}

@media (min-width: 768px) {
  .contact-page .inner-hero-area .inner-hero-text .title {
    margin-bottom: 32px;
    font-size: 80px;
  }
}

.contact-page .inner-hero-area .inner-hero-text p {
  color: #696871;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

.contact-page .page-banner {
  padding-top: 20px;
  padding-bottom: 30px;
}

@media (min-width: 768px) {
  .contact-page .page-banner {
    padding-top: 100px;
    padding-bottom: 80px;
  }
}

.contact-page .page-banner .banner-content .title {
  color: #19191b;
  font-weight: 700;
  letter-spacing: -2.5px;
  font-size: 34px;
  line-height: 46px;
  padding-bottom: 12;
}

@media (min-width: 768px) {
  .contact-page .page-banner .banner-content .title {
    font-size: 80px;
    line-height: 84px;
    padding-bottom: 22px;
  }
}

.contact-page .page-banner .banner-content p {
  color: #696871;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
  margin-top: 5px;
}

.contact-page .section-title {
  margin-bottom: 50px;
}

@media (min-width: 992px) {
  .contact-page .section-title {
    margin-bottom: 80px;
  }
}

.contact-page .section-title h2 {
  color: #19191b;
  font-size: 60px;
  font-weight: 700;
  letter-spacing: -1.88px;
  line-height: 70px;
}

.contact-page .omega-contact-form .form-group {
  margin-bottom: 30px;
}

.contact-page .omega-contact-form .form-group .form-control {
  margin-bottom: 0;
}

.contact-page .omega-contact-form .form-group label {
  color: #19191b;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.56px;
  line-height: 1;
  margin-bottom: 20px;
}

.contact-page .omega-contact-form .form-title {
  color: #19191b;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.75px;
}

.contact-page .omega-contact-form .form-control {
  border-radius: 10px;
  border: 1px solid #eae9f2;
  background-color: #ffffff;
  color: #9c9aaa;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.56px;
  margin-bottom: 17px;
  text-indent: 25px;
}

.contact-page .omega-contact-form input {
  min-height: 73px;
}

.contact-page .omega-contact-form textarea {
  min-height: 154px;
  max-height: 154px;
  padding-top: 20px;
}

.contact-page .omega-contact-form .btn {
  width: 100%;
  max-height: 60px;
  min-height: 60px;
}

.contact-page .omega-contact-form .btn.w-small {
  max-width: 255px;
}

.contact-page .contact-widget-wrapper {
  border-radius: 10px;
  background-color: #f7f7fb;
  padding-top: 80px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
}

@media (min-width: 992px) {
  .contact-page .contact-widget-wrapper {
    padding-left: 140px;
    padding-right: 150px;
  }
}

@media (min-width: 1200px) {
  .contact-page .contact-widget-wrapper {
    padding-left: 150px;
    padding-right: 150px;
  }
}

.contact-page .contact-widget-block .title {
  color: #19191b;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.75px;
  line-height: 1;
}

.contact-page .contact-widget-block p {
  color: #696871;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
  margin-bottom: 0;
}

.contact-page .contact-form-title--big .form-title {
  color: #19191b;
  font-weight: 700;
  letter-spacing: -2.5px;
  font-size: 34px;
  line-height: 46px;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .contact-page .contact-form-title--big .form-title {
    font-size: 80px;
    line-height: 84px;
    margin-bottom: 30px;
  }
}

.contact-page .contact-form-title--big p {
  color: #696871;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

.contact-page .contact-map {
  width: 100%;
  height: 500px;
  margin-bottom: 50px;
}

@media (min-width: 992px) {
  .contact-page .contact-map {
    margin-top: 0px;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 47%;
  }
}

.contact-page .footer-section {
  background-color: #19191b;
}

.contact-page .footer-section .footer-top {
  padding: 80px 0 60px;
}

.contact-page .footer-title {
  color: #ffffff;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 25px;
}

.contact-page .footer-list a {
  color: rgba(255, 255, 255, 0.7);
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 38px;
}

.contact-page .copyright-area {
  border-top: 1px solid #2F2F31;
  padding: 15px 0;
}

.contact-page .copyright-area p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  font-weight: 300;
  letter-spacing: -0.41px;
  line-height: 38px;
  font-family: "CircularStd", sans-serif;
}

.contact-page .copyright-area .copyright-text {
  margin-bottom: 0;
}

.contact-page .copyright-area .social-icons {
  display: -webkit-inline-box;
  display: inline-flex;
}

.contact-page .copyright-area .social-icons li a {
  color: #FFFFFF;
  font-size: 16px;
  -webkit-transition: .4s;
  transition: .4s;
  padding: 0 3px;
  margin: 0 2.5px;
}

.contact-page .copyright-area .social-icons li a:hover {
  color: #5454d4;
}

/*==== 19.Thank You -------*/
.thank-page {
  /*>>>>>>>> Footer area styling <<<<<<<<<*/
}

.thank-page .thank-page-main {
  padding-top: 55px;
  padding-bottom: 70px;
}

@media (min-width: 768px) {
  .thank-page .thank-page-main {
    padding-top: 110px;
    padding-bottom: 140px;
  }
}

.thank-page .thank-page-main .contents {
  text-align: center;
}

.thank-page .thank-page-main .contents .content-icon {
  width: 118px;
  height: 118px;
  background-color: #5454d4;
  border-radius: 500px;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  margin-bottom: 48px;
}

.thank-page .thank-page-main .contents .content-text .title {
  color: #19191b;
  font-weight: 700;
  letter-spacing: -2.5px;
  margin-bottom: 12px;
  font-size: 34px;
  line-height: 46px;
}

@media (min-width: 768px) {
  .thank-page .thank-page-main .contents .content-text .title {
    font-size: 80px;
    line-height: 84px;
  }
}

.thank-page .thank-page-main .contents .content-text p {
  color: #696871;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

.thank-page .thank-page-main .contents .content-btn {
  padding-top: 18px;
}

.thank-page .thank-page-main .contents .content-btn .btn {
  min-width: 250px;
  min-height: 60px;
  border-radius: 10px;
  border: 1px solid #c31a12;
  color: #f04037;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.66px;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-transition: .4s;
  transition: .4s;
}

.thank-page .thank-page-main .contents .content-btn .btn:hover {
  background: #c31a12;
  color: #ffff;
}

.thank-page .footer-section {
  background-color: #19191b;
}

.thank-page .footer-section .footer-top {
  padding: 80px 0 60px;
}

.thank-page .footer-title {
  color: #ffffff;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 25px;
}

.thank-page .footer-list a {
  color: rgba(255, 255, 255, 0.7);
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 38px;
}

.thank-page .copyright-area {
  border-top: 1px solid #2F2F31;
  padding: 15px 0;
}

.thank-page .copyright-area p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  font-weight: 300;
  letter-spacing: -0.41px;
  line-height: 38px;
  font-family: "CircularStd", sans-serif;
}

.thank-page .copyright-area .copyright-text {
  margin-bottom: 0;
}

.thank-page .copyright-area .social-icons {
  display: -webkit-inline-box;
  display: inline-flex;
}

.thank-page .copyright-area .social-icons li a {
  color: #FFFFFF;
  font-size: 16px;
  -webkit-transition: .4s;
  transition: .4s;
  padding: 0 3px;
  margin: 0 2.5px;
}

.thank-page .copyright-area .social-icons li a:hover {
  color: #5454d4;
}

/*==== 20.404 -------*/
.error-page {
  /*>>>>>>>> Footer area styling <<<<<<<<<*/
}

.error-page .error-page-main {
  padding-top: 55px;
  padding-bottom: 70px;
}

@media (min-width: 768px) {
  .error-page .error-page-main {
    padding-top: 110px;
    padding-bottom: 140px;
  }
}

.error-page .error-page-main .contents {
  text-align: center;
}

.error-page .error-page-main .contents .content-icon {
  width: 118px;
  height: 118px;
  background-color: rgba(240, 64, 55, 0.1);
  border-radius: 500px;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  margin-bottom: 48px;
}

.error-page .error-page-main .contents .content-text .title {
  color: #19191b;
  font-weight: 700;
  letter-spacing: -2.5px;
  margin-bottom: 12px;
  font-size: 34px;
  line-height: 46px;
}

@media (min-width: 768px) {
  .error-page .error-page-main .contents .content-text .title {
    font-size: 80px;
    line-height: 84px;
  }
}

.error-page .error-page-main .contents .content-text p {
  color: #696871;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

.error-page .error-page-main .contents .content-btn {
  padding-top: 18px;
}

.error-page .error-page-main .contents .content-btn .btn {
  min-width: 250px;
  min-height: 60px;
  border-radius: 10px;
  border: 1px solid #c31a12;
  color: #f04037;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.66px;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-transition: .4s;
  transition: .4s;
}

.error-page .error-page-main .contents .content-btn .btn:hover {
  background: #c31a12;
  color: #ffff;
}

.error-page .footer-section {
  background-color: #19191b;
}

.error-page .footer-section .footer-top {
  padding: 80px 0 60px;
}

.error-page .footer-title {
  color: #ffffff;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 25px;
}

.error-page .footer-list a {
  color: rgba(255, 255, 255, 0.7);
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 38px;
}

.error-page .copyright-area {
  border-top: 1px solid #2F2F31;
  padding: 15px 0;
}

.error-page .copyright-area p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  font-weight: 300;
  letter-spacing: -0.41px;
  line-height: 38px;
  font-family: "CircularStd", sans-serif;
}

.error-page .copyright-area .copyright-text {
  margin-bottom: 0;
}

.error-page .copyright-area .social-icons {
  display: -webkit-inline-box;
  display: inline-flex;
}

.error-page .copyright-area .social-icons li a {
  color: #FFFFFF;
  font-size: 16px;
  -webkit-transition: .4s;
  transition: .4s;
  padding: 0 3px;
  margin: 0 2.5px;
}

.error-page .copyright-area .social-icons li a:hover {
  color: #5454d4;
}

/*==== 21.Terms page -------*/
.terms-page {
  /*>>>>>>>> Footer area styling <<<<<<<<<*/
}

.terms-page .terms-contents .title-main {
  color: #19191b;
  font-weight: 700;
  letter-spacing: -2.5px;
  font-size: 34px;
  line-height: 46px;
}

@media (min-width: 768px) {
  .terms-page .terms-contents .title-main {
    font-size: 80px;
    line-height: 84px;
  }
}

.terms-page .terms-contents .title-block {
  color: #19191b;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -0.66px;
  line-height: 28px;
}

.terms-page .terms-contents p {
  color: #696871;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 28px;
}

.terms-page .terms-contents .term-list li {
  color: #696871;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 24px;
  display: -webkit-box;
  display: flex;
  margin-bottom: 25px;
}

.terms-page .terms-contents .term-list li:before {
  content: "";
  min-width: 9px;
  max-width: 9px;
  min-height: 9px;
  max-height: 9px;
  background-color: #19191b;
  border-radius: 500px;
  display: inline-block;
  margin-right: 13px;
  margin-top: 9px;
}

.terms-page .footer-section {
  background-color: #19191b;
}

.terms-page .footer-section .footer-top {
  padding: 80px 0 60px;
}

.terms-page .footer-title {
  color: #ffffff;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 25px;
}

.terms-page .footer-list a {
  color: rgba(255, 255, 255, 0.7);
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 38px;
}

.terms-page .copyright-area {
  border-top: 1px solid #2F2F31;
  padding: 15px 0;
}

.terms-page .copyright-area p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  font-weight: 300;
  letter-spacing: -0.41px;
  line-height: 38px;
  font-family: "CircularStd", sans-serif;
}

.terms-page .copyright-area .copyright-text {
  margin-bottom: 0;
}

.terms-page .copyright-area .social-icons {
  display: -webkit-inline-box;
  display: inline-flex;
}

.terms-page .copyright-area .social-icons li a {
  color: #FFFFFF;
  font-size: 16px;
  -webkit-transition: .4s;
  transition: .4s;
  padding: 0 3px;
  margin: 0 2.5px;
}

.terms-page .copyright-area .social-icons li a:hover {
  color: #5454d4;
}

/*==== 22.Faq page -------*/
.faq-page .site-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.faq-page .inner-hero-area {
  padding-top: 100px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .faq-page .inner-hero-area {
    padding-top: 190px;
    padding-bottom: 180px;
  }
}

.faq-page .inner-hero-area.bg-gradient {
  background-image: linear-gradient(147deg, rgba(141, 141, 236, 0.17) 0%, rgba(84, 84, 212, 0) 100%);
}

.faq-page .inner-hero-area .inner-hero-text .title {
  color: #19191b;
  font-weight: 700;
  letter-spacing: -2.5px;
  line-height: 1;
  margin-bottom: 15px;
  font-size: 34px;
  line-height: 46px;
}

@media (min-width: 768px) {
  .faq-page .inner-hero-area .inner-hero-text .title {
    margin-bottom: 32px;
    font-size: 80px;
  }
}

.faq-page .inner-hero-area .inner-hero-text p {
  color: #696871;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

.faq-page .page-banner {
  padding-top: 20px;
  padding-bottom: 30px;
}

@media (min-width: 768px) {
  .faq-page .page-banner {
    padding-top: 100px;
    padding-bottom: 80px;
  }
}

.faq-page .page-banner .banner-content .title {
  color: #19191b;
  font-weight: 700;
  letter-spacing: -2.5px;
  font-size: 34px;
  line-height: 46px;
  padding-bottom: 12;
}

@media (min-width: 768px) {
  .faq-page .page-banner .banner-content .title {
    font-size: 60px;
    line-height: 70px;
  }
}

.faq-page .page-banner .banner-content p {
  color: #696871;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
  margin-top: 5px;
}

.faq-page .nav.faq-tab {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  border-radius: 10px;
  border: 1px solid #eae9f2;
  background-color: #f7f7fb;
  padding-top: 15px;
  padding-bottom: 15px;
}

@media (min-width: 1200px) {
  .faq-page .nav.faq-tab {
    margin-right: 45px;
    margin-left: 20px;
  }
}

.faq-page .nav.faq-tab .nav-link {
  padding-left: 40px;
  padding: 8px 1rem;
}

.faq-page .nav.faq-tab .nav-link:hover {
  border-color: transparent;
  color: #5454d4;
}

.faq-page .nav.faq-tab .nav-link.active {
  color: #5454d4;
  background-color: transparent;
  border-color: transparent;
}

.faq-page .single-faq {
  margin-bottom: 35px;
}

.faq-page .single-faq .faq-title {
  color: #19191b;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: -0.75px;
  margin-bottom: 31px;
}

.faq-page .single-faq p {
  color: #696871;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.faq-page .single-faq p:last-child {
  margin-bottom: 0;
}

.faq-page .faq-link p {
  color: #696871;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.faq-page .faq-link a {
  color: #f04037;
}

.faq-page .faq-link a:hover {
  text-decoration: underline;
}

.faq-page .footer-section {
  background-color: #19191b;
}

.faq-page .footer-section .footer-top {
  padding: 80px 0 60px;
}

.faq-page .footer-title {
  color: #ffffff;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 25px;
}

.faq-page .footer-list a {
  color: rgba(255, 255, 255, 0.7);
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 38px;
}

.faq-page .copyright-area {
  border-top: 1px solid #2F2F31;
  padding: 15px 0;
}

.faq-page .copyright-area p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  font-weight: 300;
  letter-spacing: -0.41px;
  line-height: 38px;
  font-family: "CircularStd", sans-serif;
}

.faq-page .copyright-area .copyright-text {
  margin-bottom: 0;
}

.faq-page .copyright-area .social-icons {
  display: -webkit-inline-box;
  display: inline-flex;
}

.faq-page .copyright-area .social-icons li a {
  color: #FFFFFF;
  font-size: 16px;
  -webkit-transition: .4s;
  transition: .4s;
  padding: 0 3px;
  margin: 0 2.5px;
}

.faq-page .copyright-area .social-icons li a:hover {
  color: #5454d4;
}

/*==== 23.review page -------*/
.review-page .page-banner {
  padding-top: 20px;
  padding-bottom: 30px;
}

@media (min-width: 768px) {
  .review-page .page-banner {
    padding-top: 100px;
    padding-bottom: 40px;
  }
}

.review-page .page-banner .banner-content .title {
  color: #19191b;
  font-weight: 700;
  letter-spacing: -2.5px;
  font-size: 34px;
  line-height: 46px;
  padding-bottom: 12;
}

@media (min-width: 768px) {
  .review-page .page-banner .banner-content .title {
    font-size: 60px;
    line-height: 70px;
    padding-bottom: 12px;
  }
}

.review-page .page-banner .banner-content p {
  color: #696871;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
  margin-top: 5px;
}

.review-page .review-section {
  padding-bottom: 20px;
}

@media (min-width: 768px) {
  .review-page .review-section {
    padding-bottom: 90px;
  }
}

.review-page .review-card {
  margin-bottom: 30px;
  display: -webkit-box;
  display: flex;
  width: 100%;
  min-height: 403px;
  border-radius: 5px;
  background-color: #FFFFFF;
  padding: 35px 30px 30px 35px;
  -webkit-transition: .4s;
  transition: .4s;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  border: 1px solid #eae9f2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.review-page .review-card:before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.04);
  content: "";
  -webkit-transition: .4s;
  transition: .4s;
}

.review-page .review-card .review-star {
  margin-bottom: 25px;
}

.review-page .review-card .card-content p {
  font-style: italic;
  color: #19191B;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

.review-page .review-card .card-id {
  display: -webkit-box;
  display: flex;
  margin-top: auto;
  -webkit-box-align: center;
          align-items: center;
}

.review-page .review-card .card-id .id-img {
  width: 45px;
  height: 45px;
  border-radius: 500px;
  overflow: hidden;
  text-align: center;
}

.review-page .review-card .card-id .id-content {
  margin-left: 22px;
}

.review-page .review-card .card-id .id-content h6 {
  color: #1F1F1F;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.34px;
  line-height: 28px;
  margin-bottom: -5px;
}

.review-page .review-card .card-id .id-content span {
  color: #666666;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 28px;
}

.review-page .review-card:hover:before {
  box-shadow: -1px 6px 4px rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.04);
}

.review-page .footer-section {
  background-color: #19191b;
}

.review-page .footer-section .footer-top {
  padding: 80px 0 60px;
}

.review-page .footer-title {
  color: #ffffff;
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 25px;
}

.review-page .footer-list a {
  color: rgba(255, 255, 255, 0.7);
  font-family: "CircularStd", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 38px;
}

.review-page .copyright-area {
  border-top: 1px solid #2F2F31;
  padding: 15px 0;
}

.review-page .copyright-area p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  font-weight: 300;
  letter-spacing: -0.41px;
  line-height: 38px;
  font-family: "CircularStd", sans-serif;
}

.review-page .copyright-area .copyright-text {
  margin-bottom: 0;
}

.review-page .copyright-area .social-icons {
  display: -webkit-inline-box;
  display: inline-flex;
}

.review-page .copyright-area .social-icons li a {
  color: #FFFFFF;
  font-size: 16px;
  -webkit-transition: .4s;
  transition: .4s;
  padding: 0 3px;
  margin: 0 2.5px;
}

.review-page .copyright-area .social-icons li a:hover {
  color: #5454d4;
}

/*==== 24.coming soon page -------*/
/*------------------- 
  Coming Soon 2 
---------------------*/
.coming-soon .page-content {
  text-align: center;
  margin-top: 52px;
  margin-bottom: 55px;
}

.coming-soon .page-content .content-title {
  color: #fff;
  font-weight: 700;
  letter-spacing: -1.88px;
  margin-bottom: 20px;
  font-size: 40px;
  line-height: 50px;
}

@media (min-width: 576px) {
  .coming-soon .page-content .content-title {
    font-size: 60px;
    line-height: 70px;
  }
}

.coming-soon .page-content p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
}

.coming-soon .form-top-text {
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.56px;
  line-height: 39px;
  text-align: center;
}

.coming-soon .notify-form {
  margin: 0 auto;
  max-width: 413px;
}

.coming-soon .notify-form .form-control {
  height: 60px;
  width: 100%;
  color: #9c9aaa;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.56px;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 17px;
}

.coming-soon .notify-form button {
  border-radius: 10px;
}

.coming-soon .site-footer {
  padding-bottom: 70px;
}

.coming-soon .site-footer .social-icons {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  margin: 0 auto;
  height: 100%;
}

.coming-soon .site-footer .social-icons li a {
  color: #f7f7fb;
  font-size: 23px;
  -webkit-transition: .4s;
  transition: .4s;
  padding: 0 3px;
  margin: 0 12px;
  transition: .4s;
}

@media (min-width: 992px) {
  .coming-soon .site-footer .social-icons li a:hover {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
  }
}

.coming-soon-2 .page-content {
  text-align: center;
  padding-top: 50px;
}

.coming-soon-2 .page-content .content-title {
  color: #19191b;
  font-weight: 700;
  letter-spacing: -1.88px;
  padding-bottom: 15px;
  font-size: 28px;
  line-height: 35px;
}

@media (min-width: 400px) {
  .coming-soon-2 .page-content .content-title {
    font-size: 40px;
    line-height: 50px;
  }
}

@media (min-width: 576px) {
  .coming-soon-2 .page-content .content-title {
    font-size: 60px;
    line-height: 70px;
  }
}

.coming-soon-2 .page-content p {
  color: #696871;
  font-weight: 300;
  letter-spacing: -0.66px;
  font-size: 16px;
  line-height: 25px;
}

@media (min-width: 400px) {
  .coming-soon-2 .page-content p {
    font-size: 18px;
    line-height: 30px;
  }
}

@media (min-width: 576px) {
  .coming-soon-2 .page-content p {
    font-size: 21px;
    line-height: 39px;
  }
}

.coming-soon-2 .form-top-text {
  color: #19191b;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.56px;
  line-height: 39px;
  text-align: center;
}

.coming-soon-2 .notify-form {
  margin: 0 auto;
}

.coming-soon-2 .countdown-block {
  display: -webkit-box;
  display: flex;
  padding-top: 3px;
  flex-wrap: wrap;
}

.coming-soon-2 .countdown-block > div {
  width: auto;
  max-width: unset;
  -webkit-box-flex: unset;
          flex: unset;
  border-radius: 8px;
  background-color: #5454d4;
  margin: 0 2.5px;
  text-align: center;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 480px) {
  .coming-soon-2 .countdown-block > div {
    margin: 0 7.5px;
    padding-left: 13px;
    padding-right: 13px;
  }
}

.coming-soon-2 .countdown-block > div .wrapper {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  padding-top: 10px;
  padding-bottom: 15px;
}

.coming-soon-2 .countdown-block .time {
  color: #fff;
  font-weight: 700;
  letter-spacing: -1.19px;
  font-size: 26px;
}

@media (min-width: 480px) {
  .coming-soon-2 .countdown-block .time {
    font-size: 38px;
  }
}

.coming-soon-2 .countdown-block .label {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 300;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 1;
}

@media (min-width: 480px) {
  .coming-soon-2 .countdown-block .label {
    font-size: 16px;
  }
}

.coming-soon-2 .notify-form {
  position: relative;
  max-width: 480px;
}

.coming-soon-2 .notify-form .input-group {
  width: 100%;
  height: 71px;
  border-radius: 10px;
  border: 1px solid #EAE9F2;
  background-color: #FFFFFF;
  position: relative;
}

.coming-soon-2 .notify-form .input-group i {
  position: absolute;
  top: 27px;
  left: 27px;
  font-size: 19px;
  color: #cdccd9;
  margin-top: -2px;
}

.coming-soon-2 .notify-form .input-group input {
  padding-left: 63px;
  padding-right: 15px;
  width: 100%;
  border: 0;
  border-radius: 10px;
}

@media (min-width: 500px) {
  .coming-soon-2 .notify-form .input-group input {
    padding-right: 140px;
  }
}

.coming-soon-2 .notify-form .input-group input:focus {
  box-shadow: none;
  outline: none;
}

.coming-soon-2 .notify-form .input-group input::-webkit-input-placeholder {
  color: #696871;
  font-weight: 400;
}

.coming-soon-2 .notify-form .input-group input::-moz-placeholder {
  color: #696871;
  font-weight: 400;
}

.coming-soon-2 .notify-form .input-group input:-ms-input-placeholder {
  color: #696871;
  font-weight: 400;
}

.coming-soon-2 .notify-form .input-group input::-ms-input-placeholder {
  color: #696871;
  font-weight: 400;
}

.coming-soon-2 .notify-form .input-group input::placeholder {
  color: #696871;
  font-weight: 400;
}

.coming-soon-2 .notify-form button {
  margin-top: 15px;
  width: 100%;
}

@media (min-width: 500px) {
  .coming-soon-2 .notify-form button {
    width: 200px;
    position: absolute;
    margin-top: 0px;
    top: 5px;
    right: 0px;
    margin-right: 5px;
  }
}

@media (min-width: 768px) {
  .coming-soon-2 .notify-form button {
    max-width: 140px;
    font-size: 18px;
  }
}

@media (min-width: 992px) {
  .coming-soon-2 .notify-form button {
    max-width: 140px;
    font-size: 19px;
  }
}

.coming-soon-2 .site-footer {
  padding-top: 50px;
  padding-bottom: 70px;
}

.coming-soon-2 .site-footer .social-icons {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  margin: 0 auto;
  height: 100%;
}

.coming-soon-2 .site-footer .social-icons li a {
  color: #19191b;
  font-size: 23px;
  -webkit-transition: .4s;
  transition: .4s;
  padding: 0 3px;
  margin: 0 12px;
  transition: .4s;
}

@media (min-width: 992px) {
  .coming-soon-2 .site-footer .social-icons li a:hover {
    color: #5454d4;
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
  }
}

.alignment {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: justify;
          justify-content: space-between;
}

@media (min-width: 992px) {
  .alignment {
    min-height: 100vh;
  }
}

.alignment .site-header {
  -webkit-box-pack: start;
          justify-content: flex-start;
}

.alignment .main-content {
  -webkit-box-pack: center;
          justify-content: center;
}

.alignment .site-footer {
  -webkit-box-pack: end;
          justify-content: flex-end;
}

.carousel .thumbs-wrapper {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 20px;
}
