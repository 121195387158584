.icontainer {
  width: 100%;
}

.keyItem_container {
  width: 100%;
  position: relative;
}

.containerkeyItem {
  box-shadow: none;
  background: #fff;
  display: flex;
  border: 2px solid #ffffff;
  border-radius: 31px;
  z-index: 3;
  height: 50px;
  margin: 0 auto;
}

.containerkeyItemHover {
  border-top-left-radius: 31px;
  border-top-right-radius: 31px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-color: rgba(223, 225, 229, 0);
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  display: flex;
  background: #fff;
  height: 50px;
  z-index: 3;
  padding: 3px;
  margin: 1px;
  margin: 0 auto;
}

.containerkeyDropHide {
  display: none;
}

.containerkeyDropdown {
  display: show;
  position: absolute;
  width: 100%;
  text-align: left;
  margin-top: 1px;
  padding: 0px;
  z-index: 1000;
  cursor: default;
  -webkit-user-select: none;
}

.containerkeyDropdown .dropdown {
  background: #fff;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
  border: 0;
  border-radius: 0 0 24px 24px;
  box-shadow: 0 4px 6px 0 rgba(32, 33, 36, 0.28);
  padding-bottom: 4px;
  overflow: hidden;
}

.containerkeyDropdown .dropdown .dropdownDivider {
  border-top: 2px solid #e8eaed;
  margin: 0 14px;
  padding-bottom: 4px;
}

.dropdown .dropdownItem {
  flex: auto;
  padding-bottom: 16px;
}

.dropdownItem .itemBar {
  display: flex;
  align-items: center;
  min-width: 0;
  max-height: none;
  padding: 0;
  cursor: pointer;
}

.dropdownItem .itemBar .containerItem {
  flex: auto;
  display: flex;
  margin: 0 20px;
  align-items: center;
  margin: 0 20px 0 14px;
}

.dropdownItem .itemBar .containerItem .itemIcon {
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  margin-right: 14px;
  background: url("../image/png/search.png") no-repeat 0 0;
  background-size: 20px;
  min-height: 20px;
  min-width: 20px;
  height: 20px;
  width: 20px;
}

.dropdownItem .itemBar .containerItem .optionItem {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-width: 0;
  max-height: none;
  padding: 6px 0;
}

.dropdownItem .itemBar .containerItem .optionItem .optionData {
  display: flex;
  font-size: 16px;
  color: #212121;
  flex: auto;
  align-items: center;
  word-break: break-word;
  padding-right: 8px;
}

.optionData>span {
  flex: auto;
}

.containerkeyItem {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}

.keyFlex {
  flex: 1;
  display: flex;
  padding: 5px 8px 0 16px;
  padding-left: 14px;
}

.keyItem {
  margin-left: 15px;
  line-height: 10;
  font-weight: 500;
  margin-right: 15px;
  margin-top: -5px;
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
}

.keyItem::-webkit-input-placeholder {
  /* Edge */
  color: #9c9b9b;
  text-transform: uppercase;
  font-family: "CircularStd", sans-serif;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1.5px;
  margin-bottom: 25px;
}

.keyItem:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #9c9b9b;
  text-transform: uppercase;
  font-family: "CircularStd", sans-serif;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1.5px;
  margin-bottom: 25px;
}

.keyItem::placeholder {
  color: #9c9b9b;
  text-transform: uppercase;
  font-family: "CircularStd", sans-serif;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1.5px;
  margin-bottom: 25px;
}

.keyIconHide {
  display: none;
}

.keyItem .keyIcon {
  display: flex;
  align-items: center;
  padding-right: 13px;
  margin-top: -5px;
}

.keyIcon .icon {
  margin: auto;
}

.keyIcon .icon .iconItem {
  margin-top: 3px;
  width: 27px;
}

.keyIcon .icon .cleariconItem {
  margin-top: 9px;
  width: 15px;
  margin-right: 9px;
  cursor: pointer;
}

.keyItem_global {
  width: 100%;
  display: block;
  position: relative;
}

.productdetailcolumn {
  margin: 0px;
}

.productdetailname {
  margin: 0px 0px 4px;
  font-weight: 800;
  font-size: 1.28571rem;
  line-height: 24px;
  color: #212121;
  word-break: break-word;
}

.productdetailsellinfo {
  display: flex;
  -webkit-box-flex: 1;
  flex-grow: 1;
}

.productsellinginfo {
  font-size: 1rem;
  line-height: 20px;
}

.productdetailsellinfo>* {
  display: inline-block;
  margin-right: 8px;
}

.price {
  margin-bottom: 4px;
  font-size: 2rem;
  line-height: 34px;
  font-weight: 800;
  color: #212121;
}

.productdivider {
  background: #F0F3F7;
  height: 1px;
  margin: 16px 0px;
}

.productshopinfo {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.productshopphotoswrapper>.productshopphotos {
  margin-top: -4px;
}

.productshopphotos {
  width: 48px;
  height: 48px;
  margin: 0px 12px 0px 0px;
  object-fit: contain;
  border-radius: 50%;
}

.productshopnamewrapper>div>div:first-of-type {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: -4px;
}

.productshopname {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex: 1 1 0%;

  .productshoptitle {
    word-break: break-all;
    font-size: 16px;
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.productcart {
  border: 1px solid #BFC9D9;
  border-radius: 8px;
  padding: 0px 12px;

  .productcarttitle {
    margin: 12px 0px 20px;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #212121;
    text-align: left;
  }

  .productcartbutton {
    margin-bottom: 12px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    border-top: none;
    border-right: none;
    border-left: none;
    border-image: initial;
    border-bottom: 1px solid #F0F3F7;
    padding: 0px 0px 12px;
    cursor: pointer;
    background: transparent;
    text-align: left;
    width: 100%;


  }

  .productcartbutton>img {
    width: 48px;
    height: 48px;
    object-fit: contain;
    margin-right: 8px;
    border-radius: 4px;
  }

  .productcartbutton>p {
    display: block;
    position: relative;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 18px;
    letter-spacing: 0px;
    color: #212121;
    text-decoration: initial;
    margin: 0px;
  }
}

.productcartbuttoncreate {
    background-color: #465ba6;
    border: none;
    border-radius: 8px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    display: block;
    font-family: inherit;
    font-weight: 800;
    font-size: 1rem;
    height: 40px;
    line-height: 20px;
    outline: none;
    position: relative;
    padding: 0px 16px;
    text-indent: initial;
    transition: background-color 300ms ease 0s;
    width: 100%;
    margin: 8px 0px;
}

.productcartbuttonquantity {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.variant_chip {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    vertical-align: top;
    background-color: #FFFFFF;
    color: #6D7588;
    flex: 1 1 0%;
    flex-wrap: nowrap;
    font-size: 1rem;
    line-height: 1.28571rem;
    height: 40px;
    padding: 4px 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 12px;
    position: relative;
    cursor: pointer;
    border: 1px solid #BFC9D9;
    transition: all 0s ease 0s;
    margin-right: 4px;
}

.choosevariantitle {
    display: block;
    position: relative;
    font-weight: 800;
    font-size: 1.14286rem;
    letter-spacing: 0px;
    color: #212121;
    text-decoration: initial;
    margin: 0px 0px 12px;
    line-height: 20px;

    span {
      text-transform: capitalize;
      color: #6D7588;
      font-size: 14px;
      font-weight: normal;
    }
}

.productvariantwrapper {
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
    margin-left: -2px;
}

.variant_photo {
    width: 24px;
    height: 24px;
    font-weight: 600;
    line-height: 16px;
    margin-right: 4px;
    margin-left: -4px;
    // background-image: url(https://images.tokopedia.net/img/cache/150/VqbcmM/2023/6/27/58f01e40-8b96-4343-9ade-1776ebe023d7.jpg);
    // background-position: right center;
    // background-size: 24px;
    border-radius: 4px;
    background-repeat: no-repeat;
}

.productcartbuttonquantityeditor {
  -webkit-box-align: center;
  align-items: center;
  border: solid 1px #BFC9D9;
  border-radius: 8px;
  display: inline-flex;
  padding: 3px;
  transition: border 120ms cubic-bezier(0.2, 0.64, 0.21, 1) 0s;
  min-width: 70px;
  width: 102px;
}

.productcartbuttonminus {
  background-color: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  flex-shrink: 0;
  height: 24px;
  padding: 0px;
  width: 24px;
  appearance: none;
}

.productcartbuttonplus {
  background-color: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  flex-shrink: 0;
  height: 24px;
  padding: 0px;
  width: 24px;
  appearance: none;
}

.productcartbuttonquantityeditorinput {
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  color: #212121;
  font-family: inherit;
  font-size: 14px;
  line-height: 18px;
  outline: none;
  text-align: center;
  flex-shrink: 2;
  -webkit-box-flex: 2;
  flex-grow: 2;
  width: 100%;
}

.productcartjumlah {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0px;
  margin: -1px;
  overflow: hidden;
  clip: rect(0px, 0px, 0px, 0px);
  border: 0px;
}

.productcartstok {
  display: block;
  position: relative;
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
  letter-spacing: 0px;
  color: #212121;
  text-decoration: initial;
  margin: 12px 12px;

}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  .keyItem_global {
    margin-top: 40px;
  }
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  .keyItem_global {
    margin-top: 40px;
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .keyItem_global {
    margin-top: 40px;
  }
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .keyItem_global {
    margin-top: 40px;
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .keyItem_global {
    margin-top: 40px;
  }

  .keyItem_container {
    margin-top: 20px;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .keyItem_global {
    margin-top: 40px;
  }

  .keyItem_container {
    margin-top: 20px;
  }
}