.thumbInner {
  padding: 2px;
  border: 2px solid transparent;
  .media {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: none;
    border-radius: 0;
    .mediaImg {
      // width: 100%;
      border-radius: 10px;
      // height: 196px;
      flex: 0 0 100%;
      position: relative;
      overflow: hidden;
      img {
        position: relative;
        -o-object-fit: cover;
        object-fit: cover;
        height: 195px;
        width: 420px;
      }
      .overlayInner {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        opacity: 0.7;
        transition: all 0.4s;
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), #000);
        background-repeat: repeat-x;
      }
    }

    .mediaBody {
      position: absolute;
      padding: 15px;
      bottom: 0;
      flex: 1;
      span {
        font-style: italic;
        font-size: 12px;
        color: #868686;
      }
      h2 {
        color: #fff;
        font-size: 1.2rem;
        line-height: 1.2;
      }
    }
  }
}

.btnmore {
  padding: 10px;
  width: 100%;
  border-radius: 8px;
  font-family: "CircularStd", sans-serif;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 600;
  color: #2687c5;
  text-transform: uppercase;
  border: 2px solid #2687c5;
}

.innerItem {
  padding: 2px;
  border: 2px solid transparent;
  .inner {
    width: 100%;
  }
  .media {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: none;
    border-radius: 0;
    .mediaImg {
      width: 100%;
      height: 400px;
      position: relative;
      overflow: hidden;
      border-radius: 10px;
      img {
        position: relative;
        -o-object-fit: cover;
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
      .overlayInner {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        opacity: 0.7;
        transition: all 0.4s;
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), #000);
        background-repeat: repeat-x;
      }
    }

    .mediaBody {
      position: absolute;
      padding: 15px;
      bottom: 0;
      flex: 1;
      span {
        font-style: italic;
        font-size: 12px;
        color: #868686;
      }
      h2 {
        color: #fff;
        line-height: 1.2;
        -webkit-line-clamp: 3;
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 8px;
      }
    }
  }
}

.innerafterItem {
  padding: 2px;
  border: 2px solid transparent;
}

.catTitle {
  width: 100%;
  text-align: center;
  margin-top: 70px;
  margin-bottom: 1rem;
}

.catTitle h1 {
  font-weight: 700;
  display: inline-block;
  border-bottom: 6px solid #f2c81a;
  text-transform: uppercase;
  font-size: 30px;
}

.categoryInner {
  padding: 0;
  border: 0;
  width: 100%;
  .categoryWrapper {
    padding: 5px;
    position: relative;
    border: 3px solid transparent;
    cursor: pointer;
    display: block;
    color: #2d2d2d;
    .categoryMedia {
      position: relative;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      border: none;
      border-radius: 0;
      .categoryMediaImg {
        height: 400px;
        img {
          position: relative;
          -o-object-fit: cover;
          object-fit: cover;
          width: 400px;
          height: 180px;
          // height: 100%;
          // width: 100%;
        }
        .categoryoverlay {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          margin: auto;
        }
      }
      .categoryMediaBody {
        position: absolute;
        padding: 15px;
        bottom: 0;
        .categoryTitleBody {
          margin: 5px 0;
          text-transform: capitalize;
          font-style: italic;
          font-size: 12px;
          color: #868686;
        }
        h2 {
          color: #fff;
          font-size: 1.5rem;
          line-height: 1.2;
        }
        p {
          color: #fff;
          -webkit-line-clamp: 1;
        }
        span {
          font-style: italic;
          font-size: 12px;
          color: #868686;
        }
      }
    }
  }
  .categoryMedia {
    display: flex;
    align-items: flex-start;

    .categoryMediaImg {
      width: 100%;
      position: relative;
      overflow: hidden;
      .categoryoverlay {
        opacity: 0.7;
        transition: all 0.4s;
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), #000);
        background-repeat: repeat-x;
      }
    }
  }
  .categoryMediaBody {
    flex: 1;
    h2 {
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-bottom: 8px;
    }
    p {
      font-size: 14px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-bottom: 5px;
    }
  }
}

.section {
  position: relative;
  margin: 10px 0;
}

.categorylistInner {
  padding: 0;
  border: 0;
  .categorylistWrapper {
    padding: 5px;
    position: relative;
    border: 3px solid transparent;
    cursor: pointer;
    display: block;
    color: #2d2d2d;
  }
  first-child .categorylistmedia {
    border-top: none;
    padding-top: 0;
  }
  .categorylistmedia {
    padding: 8px 0;
    margin: 0 20px 0 0px;
    border-top: 1px solid #f4f4f4;
    flex-direction: inherit;
    position: relative;
    display: flex;
    // flex-direction: column;
    background-color: #fff;
    border: none;
    border-radius: 0;
    .categorylistmediaImg {
      width: 210px;
      height: 100%;
      float: left;
      img {
        position: relative;
        -o-object-fit: cover;
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
    .categorylistmediaBody {
      display: table-cell;
      padding: 0 10px;
      h2 {
        font-size: 17px;
        line-height: 24px;
        font-weight: 700;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 8px;
      }
      p {
        font-size: 12px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 5px;
      }
    }
  }
}
.categorylistmediaBody {
  flex: 1;
  span {
    font-style: italic;
    font-size: 12px;
    color: #868686;
  }
  h2 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 5px;
    -webkit-line-clamp: 3;
  }
}
.categorylistmedia {
  display: flex;
  align-items: flex-start;
}
.captialize {
  text-transform: capitalize;
}

.titleheading {
  width: 100%;
  position: relative;
  text-align: left;
  margin: 15px 0;
  h1 {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
    background: #fff;
    padding-right: 15px;
    position: relative;
    margin-bottom: 0;
    font-size: 1.5rem;
  }
}
.titleheading:before {
  content: " ";
  background: #ccc;
  height: 1px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
}

.categorysidebar {
  margin: 0 -8px;
  display: flex;
  flex-wrap: wrap;
}

.categorysidebarInner {
  padding: 0;
  border: 0;
  .categorysidebarWrapper {
    padding: 5px;
    position: relative;
    border: 3px solid transparent;
    cursor: pointer;
    display: block;
    color: #2d2d2d;
    .categorysidebarMedia {
      padding: 8px 0;
      margin: 0 10px;
      border-top: 1px solid #f4f4f4;
      flex-direction: inherit;
      position: relative;
      display: flex;
      background-color: #fff;
      border: none;
      border-radius: 0;
      .categorysidebarMediaImg {
        width: 125px;
        img {
          position: relative;
          -o-object-fit: cover;
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
      .categorysidebarBody {
        display: table-cell;
        padding: 0 10px;
        h2 {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 0;
          -webkit-line-clamp: 3;
        }
        span {
          font-style: italic;
          font-size: 12px;
          color: #868686;
        }
      }
    }
  }
  .categorysidebarMedia {
    display: flex;
    align-items: flex-start;
  }
}

.categorysidebarBody {
  flex: 1;
  h2 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 8px;
  }
}
.componentsearch {
  width: 100%;
  margin-left: 24px;
}

.emptyBlog {
  font-family: "CircularStd", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 12px;
}
.emptyBlogItem {
  font-family: "CircularStd", sans-serif;
  letter-spacing: 1px;
  font-size: 13px;
  list-style-type: disc;
}

.categorynavigation {
  padding-right: 8px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
}

.categorynavItem {
  margin-right: 50px;
  font-family: "CircularStd", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 12px;
  display: inline-block;
  line-height: 27px;
  height: 32px;
  margin-top: 8px;
  padding: 0 16px;
  text-align: center;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .categoryinnerwrapper {
    background: -webkit-linear-gradient(0deg,rgba(255, 255, 255, 0),rgba(255, 255, 255, 1));
    height: 40px;
    position: absolute;
    pointer-events: none;
    right: 0;
    top: 0;
    width: 24px;
    z-index: 2;
  }
  .categorynavwrapper {
    -webkit-user-select: none;
    display: block;
    height: 51px;
    padding: 0;
    position: relative;
    margin-left: -3px;
    margin-right: -3px;
    -webkit-overflow-scrolling: touch;
    overflow-y: hidden;
    overflow-x: scroll;
    height: 40px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .categoryinnerwrapper {
    background: -webkit-linear-gradient(0deg,rgba(255, 255, 255, 0),rgba(255, 255, 255, 1));
    height: 40px;
    position: absolute;
    pointer-events: none;
    right: 0;
    top: 0;
    width: 24px;
    z-index: 2;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .categoryinnerwrapper {
    background: -webkit-linear-gradient(0deg,rgba(255, 255, 255, 0),rgba(255, 255, 255, 1));
    height: 40px;
    position: absolute;
    pointer-events: none;
    right: 0;
    top: 0;
    width: 24px;
    z-index: 2;
  }
}