.circle-logo {
  width: 40px;
  border-radius: 50%;
}

.landing-1 .cta-btns .apple-store {
  padding: 0 9px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.landing-1 .cta-btns .apple-store:hover {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}

.landing-1 .cta-btns .google-play {
  padding: 0 9px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.landing-1 .cta-btns .google-play:hover {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}

.img-icon {
  width: 45px;
}

.copyright-text {
  text-transform: uppercase;
  letter-spacing: 2px !important;
}

.footer-title {
  text-transform: uppercase;
  letter-spacing: 2px !important;
}

.footer-list a {
  text-transform: uppercase;
  letter-spacing: 2px !important;
  font-size: 12px !important;
}

.landing-1 .title-faq {
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
  font-weight: 500;
}

.side-bar-faq {
  border-right: 2px solid #dadada;
  margin-top: 20px;
}

dl,
ol,
ul {
  margin-left: 40px;
}

ul {
  margin-left: 40px;
}

.terms-page .terms-contents ul {
  list-style: inherit;
  list-style-type: inherit;
}

.terms-page .terms-contents li {
  color: #696871;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 28px;
}

.btn-ikanesia {
  background-image: linear-gradient(to bottom right, #4454a3, #5fbad8);
  color: #fff;
  font-size: 21px;
  font-weight: 500;
}
.btn-ikanesia:hover {
  color: #fff;
}

.component-1 {
  max-width: 100%;
  width: 100%;
  flex-grow: 1;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}

.title-heading {
  width: 100%;
  position: relative;
  text-align: left;
}

.title-heading:before {
  content: " ";
  background: #ccc;
  height: 1px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
}

.title-heading .title,
.title-heading h1 {
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  background: #fff;
  padding-right: 15px;
  position: relative;
  margin-bottom: 0;
  font-size: 18px;
}

.title-heading .title a,
.title-heading h1 a {
  color: #2d2d2d;
}

.section-component {
  display: flex;
  flex-wrap: wrap;
  width: 100% !important;
}

.categoryItem {
  padding: 5px;
  position: relative;
  border: 3px solid transparent;
  cursor: pointer;
  display: block;
  color: #2d2d2d;
}

.section-component .thumb .media {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: none;
  border-radius: 0;
}

.media {
  display: flex;
  align-items: flex-start;
}

.section-component .thumb .media .media-img {
  width: 100%;
  height: 240px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.section-component .thumb .media .media-img img {
  position: relative;
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.section-component .media-body {
  flex: 1;
}

.section-component .thumb .media .media-body span.category {
  margin: 5px 0;
}
.section-component .thumb .media .media-body span {
  font-style: italic;
  font-size: 12px;
  color: #868686;
}

.section-component .thumb .media .media-body h2 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 8px;
}

.section-component .thumb .media .media-body h2 {
  font-size: 16px;
  line-height: 20px;
  -webkit-line-clamp: 3;
}

.section-component .thumb .media .media-body p {
  font-size: 14px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 5px;
}

.link-kepo {
  width: 100%;
  text-align: right;
  padding-right: 8px;
}

.link-kepo a {
  font-family: "CircularStd", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
}

.site-wrapper {
  position: relative;
}

.scrollTop {
  position: fixed; 
  right: 0;
  width: 100%;
  bottom: 30px;
  align-items: center;
  height: 20px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
}

.scrollTop:hover{
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
